export const faqData = [
  {
    iconUrl: '/assets/images/faq/ticket_black.png',
    title: 'Create Event',
    items: [
      {
        link: 'https://showshappening.notion.site/Sign-up-to-ShowsHappening-8ceae47f79de4fc9a7568d592701a379',
        icon: '/assets/images/faq/signup.svg',
        text: 'Sign up to ShowsHappening',
      },
      {
        link: 'https://showshappening.notion.site/Register-Organiser-Details-8f62b4dd1bc4480882bc59085516d669',
        icon: '/assets/images/faq/organiser.svg',
        text: 'Register Organiser Details',
      },
      {
        link: 'https://showshappening.notion.site/Create-Event-1158e85716fa48acb0c31ef1751b08b9',
        icon: '/assets/images/faq/event.svg',
        text: 'Create Event',
      },
      {
        link: 'https://showshappening.notion.site/Set-up-Seating-Plan-cbff9b1c3ab14d94bb6bd4390a10269b',
        icon: '/assets/images/faq/seat.svg',
        text: 'Set up Seating Plan',
      },
      {
        link: 'https://showshappening.notion.site/Block-seats-1e8f8076a06b4ebc93c9f91482d52efb',
        icon: '/assets/images/faq/blockSeat.svg',
        text: 'Block Seats',
      },
      {
        link: 'https://showshappening.notion.site/Publish-Event-815acc52ed284245be741b6d8abb498b',
        icon: '/assets/images/faq/publish.svg',
        text: 'Publish Event',
      },
      {
        link: 'https://showshappening.notion.site/Obtaining-a-VAT-Permit-Number-for-your-event-in-Malta-5ef0f465cfe74ba582f4720509b061b7',
        icon: '/assets/images/faq/vat.svg',
        text: 'Obtaining a VAT Permit Number for your event in Malta',
      },
    ],
  },
  {
    iconUrl: '/assets/images/faq/scan.png',
    title: 'Scanning',
    items: [
      {
        link: 'https://showshappening.notion.site/Scanning-ff18602708d5464e900040fbe2dfd51d',
        icon: '/assets/images/faq/vat.svg',
        text: 'Coupons and Offers',
      },
      {
        link: 'https://showshappening.notion.site/Scanning-ff18602708d5464e900040fbe2dfd51d',
        icon: '/assets/images/faq/scan.svg',
        text: 'Scanning',
      },
    ],
  },
  {
    iconUrl: '/assets/images/faq/setting-black.png',
    title: 'Manage Event',
    items: [
      {
        link: 'https://showshappening.notion.site/Register-a-new-user-a47b40943a2848798a3c5c2750091801',
        icon: '/assets/images/faq/signup.svg',
        text: 'Register a new user',
      },
      {
        link: 'https://showshappening.notion.site/Complimentary-Tickets-4a17d2371e2147efab4c76f8f26c1901',
        icon: '/assets/images/faq/vat.svg',
        text: 'Complimentary Tickets',
      },
      {
        link: 'https://showshappening.notion.site/Cash-Sales-04d84dbdedd24e36aa4bdb66115ba410',
        icon: '/assets/images/faq/cashCheck.svg',
        text: 'Cash Sales',
      },
      {
        link: 'https://showshappening.notion.site/Search-View-Modify-Cancel-Orders-27f0cb54bad749bf87f49c45aa2a52d1',
        icon: '/assets/images/faq/search.svg',
        text: 'Search, View, Modify & Cancel Orders',
      },
    ],
  },
  {
    iconUrl: '/assets/images/faq/reporting-black.png',
    title: 'Reporting',
    items: [
      {
        link: 'https://showshappening.notion.site/Attendee-List-8193bbf69e8f45ab88acc922b0952c10',
        icon: '/assets/images/faq/organiser.svg',
        text: 'Attendee List',
      },
      {
        link: 'https://showshappening.notion.site/Event-Report-0a8735a7e82f45fd86f7bfe312c237e6',
        icon: '/assets/images/faq/report.svg',
        text: 'Event Report',
      },
      {
        link: 'https://showshappening.notion.site/Z-Reading-1a139876e38e458997a54897abffa77e',
        icon: '/assets/images/faq/zReading.svg',
        text: 'Z Reading',
      },
      {
        link: '#',
        icon: '/assets/images/faq/search.svg',
        text: 'Search, View, Modify & Cancel Orders',
      },
    ],
  },
];

export interface FeatureDataType {
  iconUrl: string;
  title: string;
  items: string[];
  url: string;
}
