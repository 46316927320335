import dayjs from 'dayjs';

import {
  ORDER_STATUSES_ENUM,
  OrderFullInfoType,
  PAYMENT_TYPE_ENUM,
} from 'types/tickets/tickets.types';

export const orderContent = (orderFullInfo: OrderFullInfoType, eventName: string) => {
  if (orderFullInfo) {
    return [
      [
        {
          title: 'Event name',
          content: eventName,
        },
        {
          title: 'Tickets',
          content: orderFullInfo?.tickets?.length,
        },
        {
          title: 'Order status',
          content: ORDER_STATUSES_ENUM[orderFullInfo?.orderStatus],
        },
        {
          title: 'Card Country',
          content: '---',
        },
      ],
      [
        {
          title: 'Order number',
          content: orderFullInfo?.orderNo,
        },
        {
          title: 'Order date',
          content: dayjs(orderFullInfo?.orderDate).format('DD MMM YYYY, HH:mm'),
        },
        {
          title: 'Order Total',
          content: orderFullInfo?.orderSubTotal,
        },
        {
          title: 'Cash Type',
          content: 'N/A',
        },
      ],
      [
        {
          title: 'Buyer name',
          content: orderFullInfo?.user?.fullName,
        },
        {
          title: 'Buyer email',
          content: orderFullInfo?.user?.email,
        },
        {
          title: 'Payment type',
          content: PAYMENT_TYPE_ENUM[orderFullInfo?.paymentType],
        },
      ],
    ];
  }
};
