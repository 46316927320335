import React, { FC, useCallback, useEffect, useState } from 'react';

import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { extractFlyerName } from 'tools/extractFlyerName';
import { formatDate } from 'tools/formatDate';
import { useSetQuery } from 'tools/hooks';
// import { useDebounce } from 'tools/hooks/debounce.hook';
import { t } from 'tools/i18n';
import { Button, CheckableTag, Checkbox, Form, Icon, Input, Select, Typography, message } from 'ui';
import { formRules } from 'utils/formRules';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { activePromoterSelector } from 'selectors/systemSlice.selector';
import {
  useAddedTagsMutation,
  useCreateEventBaseInfoMutation,
  useEditEventBaseInfoMutation,
  useLazyGetTagsQuery,
} from 'services/events/eventsApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActiveEvent, setIsEditingEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

// import { EVENT_CREATE_FORM_DATES_TYPES } from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-dates/eventCreateFormBasicInfoDates.utils';
// import EventCreateFormBasicInfoSeatingPlan from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-seating-plan';
import WarningModal from 'pages/events/events-create-event-form/warning-modal';

// import EventPopover from 'containers/event-popover';
import FormSubmit from 'containers/form-submit';

import { SH_URL } from 'constants/core-constants';
import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
// import { UserEventType } from 'types/core/userTypes';
import { EventDatesType, EventTagType, MultipleDatesType } from 'types/events/eventTypes';

import EventTypeSwitcher from '../../../../ui/eventTypeSwitcher';
import s from './BasicInfo.module.scss';
import {
  EVENT_CREATE_FORM_BASIC_INFO_ENUM,
  EVENT_CREATE_FORM_BASIC_INFO_TYPE,
  createEventFormBasicInfoContext,
  prepareEventBasicInfo,
} from './basicInfo.utils';
import EventCreateFormBasicInfoDates from './event-create-form-basic-info-dates';
import EventCreateFormBasicInfoLocation from './event-create-form-basic-info-location';
import EventCreateFormBasicInfoPromoter from './event-create-form-basic-info-promoter';

const CreateEventFormBasicInfo: FC = () => {
  const activePromoter = useAppSelector(activePromoterSelector);
  const activeEditingEvent = useAppSelector(createEventFormActiveEventSelector);

  const [createEvent, { isLoading: isCreatingLoading }] = useCreateEventBaseInfoMutation();
  const [editEvent, { isLoading: isEditingLoading }] = useEditEventBaseInfoMutation();
  const isLoading = isCreatingLoading || isEditingLoading;
  const dispatch = useAppDispatch();
  const [getCurrentUser, { data: currentUser, isFetching: userFetching }] =
    useLazyGetCurrentUserQuery();
  const { eventId } = useParams();
  const [getTags, { data: tags }] = useLazyGetTagsQuery();
  const [selectedTags, setSelectedTags] = useState<{ name: string; slug: string; id: string }[]>(
    [],
  );
  const [newTag, setNewTag] = useState<string>('');
  const [addedTag] = useAddedTagsMutation();
  const [isAddedTagShow, setIsAddedTagShow] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [editingList, setEditingList] = useState<EVENT_CREATE_FORM_BASIC_INFO_TYPE>({});
  // const eventName = Form.useWatch(EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE, form);
  // const debounceEventName = useDebounce(eventName, 1000) as string;
  const primaryCategoryValue = Form.useWatch(EVENT_CREATE_FORM_BASIC_INFO_ENUM.CATEGORIES, form);
  const [needSecondaryCategory, setNeedSecondaryCategory] = useState<boolean>(false);
  const secondaryCategoryValue = Form.useWatch('secondaryCategory', form);

  const [multipleDates, setMultipleDates] = useState<MultipleDatesType[]>([]);
  const [fetchedTags, setFetchedTags] = useState<EventTagType[]>([]);
  const [primCategoryTags, setPrimCategoryTags] = useState<EventTagType[]>([]);
  const [secondaryCategoryTags, setSecondaryCategoryTags] = useState<EventTagType[]>([]);
  const [lenghtTilte, setLengthTitle] = useState<number>(0);
  const [isDirty, setIsDirty] = useState<boolean>(form.isFieldsTouched());
  const [hasDateValue, setHasDateValue] = useState<boolean>(false);
  const [isInviteCode, setIsInviteCode] = useState(false);
  const setQuery = useSetQuery();

  const handleChange = (_, all) => {
    const { ...rest } = all;
    const startDate = form.getFieldValue(['showDates', 'startDate']);
    const startTime = form.getFieldValue(['showDates', 'startTime']);
    const isSpecifyEndTime = form.getFieldValue(['showDates', 'isSpecifyEndTime']);
    const endDate = form.getFieldValue(['showDates', 'endDate']);
    const endTime = form.getFieldValue(['showDates', 'endTime']);

    const hasData = isSpecifyEndTime
      ? startDate !== undefined &&
        startDate !== null &&
        startTime !== undefined &&
        startTime !== null &&
        endDate !== undefined &&
        endDate !== null &&
        endTime !== undefined &&
        endTime !== null
      : startDate !== undefined &&
        startDate !== null &&
        startTime !== undefined &&
        startTime !== null;

    const allFieldNames = form.getFieldsValue();
    const fieldsToCheck = Object.keys(allFieldNames).filter((field) => field !== 'showDates');

    if (form.isFieldsTouched(fieldsToCheck)) {
      setIsEditing(true);
      setEditingList({ ...rest, showDates: multipleDates });
      setIsDirty(true);
      dispatch(setIsEditingEvent(true));
    }

    setHasDateValue(hasData);
  };

  const handleCheckBox = (value) => {
    setIsInviteCode(value);
    if (value === false) {
      form?.setFieldValue('inviteCode', '');
    }
  };

  const handleAddTag = useCallback(() => {
    addedTag({ tagName: newTag });
    setIsAddedTagShow(false);
  }, [addedTag, newTag]);

  const handleTitleChange = (e) => {
    setLengthTitle(e.target.value.length);

    if (
      activeEditingEvent?.basicInfo?.status !== 'published' &&
      activeEditingEvent?.basicInfo?.status !== 'approved'
    ) {
      const convertedUrl = e.target.value
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9-]+/g, '-')
        .replace(/-{2,}/g, '-')
        .replace(/-/g, ' ')
        .replace(/\s+/g, '-')
        .replace(/^-+|-+$/g, '');

      form.setFieldValue(EVENT_CREATE_FORM_BASIC_INFO_ENUM.SLUG, convertedUrl);
    }
  };

  const handleSubmit = useCallback(
    async (data) => {
      // const dateType = data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE];
      if (!activeEditingEvent?.eventId) {
        const result = prepareEventBasicInfo(data, currentUser?.promoters[0]?.promoterId);
        // if (dateType === EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES) {
        const updatedMultipleDates = multipleDates.map((date) => ({
          ...date,
          hoursWhenSalesEnd: data['hoursWhenSalesEnd'] ? data['hoursWhenSalesEnd'] : 4,
          beforeOrAfterEvent: data['beforeOrAfterEvent'] ? data['beforeOrAfterEvent'] : 'after',
        }));

        if (multipleDates.length === 0 && hasDateValue) {
          const tempDate = {
            startDate: dayjs(form.getFieldValue(['showDates', 'startDate'])).format('DD-MMM-YYYY'),
            endDate:
              form.getFieldValue(['showDates', 'endDate']) !== null &&
              form.getFieldValue(['showDates', 'endDate']) !== undefined
                ? dayjs(form.getFieldValue(['showDates', 'endDate'])).format('DD-MMM-YYYY')
                : '',
            startTime: dayjs(form.getFieldValue(['showDates', 'startTime'])).format('HH:mm'),
            endTime:
              form.getFieldValue(['showDates', 'endTime']) !== null &&
              form.getFieldValue(['showDates', 'endTime']) !== undefined
                ? dayjs(form.getFieldValue(['showDates', 'endTime'])).format('HH:mm')
                : '',
            doorsOpen: form.getFieldValue(['showDates', 'doorsOpen'])
              ? dayjs(form.getFieldValue(['showDates', 'doorsOpen'])).format('HH:mm')
              : '',
          };
          const tempResult: EventDatesType[] = [];
          tempResult.push(tempDate);
          result.showDates = tempResult;
        } else {
          result.showDates = updatedMultipleDates;
        }

        // }
        const res = await createEvent(result);

        if ('data' in res) {
          setIsEditing(false);
          dispatch(setActiveEvent(res?.data));
          dispatch(setIsEditingEvent(false));
          message.success('Basic info data successfully created!');
          setQuery(CREATE_EVENT_FORM_STEPS_ENUM.DETAILS);
        }
      } else {
        const result = prepareEventBasicInfo(data, activeEditingEvent?.basicInfo?.promoterId);
        // if (dateType === EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES) {
        const updatedMultipleDates = multipleDates.map((date) => ({
          ...date,
          hoursWhenSalesEnd: data['hoursWhenSalesEnd'] ? data['hoursWhenSalesEnd'] : 4,
          beforeOrAfterEvent: data['beforeOrAfterEvent'] ? data['beforeOrAfterEvent'] : 'after',
        }));
        result.showDates = updatedMultipleDates;
        result.seatingPlanId = activeEditingEvent?.basicInfo?.seatingPlanId;
        result.eventType = activeEditingEvent?.basicInfo?.eventType;
        result.summary = activeEditingEvent?.otherDetails?.summary;
        // }
        if (activeEditingEvent?.basicInfo?.dateType === 'recurringEvent') {
          const dates = activeEditingEvent?.basicInfo?.showDates;
          result.showDates = [];
          result.dateType = activeEditingEvent?.basicInfo?.dateType;
          if (dates?.length) {
            const lastDate = dates[dates.length - 1];

            result.lastShowDate = `${dayjs(lastDate.startDate).format('DD-MMM-YYYY')} ${
              lastDate.startTime
            }`;
          }
        }
        const res = await editEvent({ basicInfo: result, eventId: activeEditingEvent?.eventId });

        if ('data' in res) {
          setIsEditing(false);
          dispatch(setIsEditingEvent(false));
          dispatch(setActiveEvent(res?.data));
          message.success('Basic info data successfully saved!');
        }
      }
    },
    [
      activeEditingEvent,
      hasDateValue,
      form,
      createEvent,
      currentUser?.promoters,
      dispatch,
      editEvent,
      multipleDates,
      setQuery,
    ],
  );

  const handleAddSecondaryCategory = useCallback(() => {
    if (!needSecondaryCategory) {
      setNeedSecondaryCategory(true);
    } else {
      setNeedSecondaryCategory(false);
      form.resetFields(['secondaryCategory']);
    }
  }, [form, needSecondaryCategory]);

  useEffect(() => {
    getCurrentUser();
    getTags({ records: 50, term: '' });
  }, [getCurrentUser, getTags]);

  useEffect(() => {
    setEditingList((prevState) => ({
      ...prevState,
      showDates: multipleDates,
    }));
  }, [multipleDates]);

  useEffect(() => {
    if (activeEditingEvent?.basicInfo) {
      if (activeEditingEvent?.basicInfo?.eventName) {
        setLengthTitle(activeEditingEvent?.basicInfo?.eventName.length);
        form.setFieldValue(
          EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE,
          activeEditingEvent?.basicInfo?.eventName,
        );
      }

      setFetchedTags(activeEditingEvent?.basicInfo?.tags);
      form.setFieldValue(
        EVENT_CREATE_FORM_BASIC_INFO_ENUM.TAGS,
        activeEditingEvent?.basicInfo?.tags,
      );

      form.setFieldValue(EVENT_CREATE_FORM_BASIC_INFO_ENUM.CATEGORIES, {
        value: activeEditingEvent?.basicInfo?.categories[0]?.id,
      });

      if (activeEditingEvent?.basicInfo?.categories.length > 1) {
        setNeedSecondaryCategory(true);
        form.setFieldValue('secondaryCategory', {
          value: activeEditingEvent?.basicInfo?.categories[1]?.id,
        });
      }

      form.setFieldValue('privateEvent', activeEditingEvent?.basicInfo?.privateEvent);

      // form.setFieldValue(
      //   EVENT_CREATE_FORM_BASIC_INFO_ENUM.VENUE_TYPE,
      //   activeEditingEvent?.basicInfo?.venueType?.toUpperCase(),
      // );

      form.setFieldValue(
        ['location', 'locationComment'],
        activeEditingEvent?.basicInfo?.location?.locationComment,
      );

      form.setFieldValue(
        'currency',
        activeEditingEvent?.basicInfo?.currency || activePromoter?.currency,
      );

      form.setFieldValue(
        'hoursWhenSalesEnd',
        activeEditingEvent?.basicInfo?.showDates![0].hoursWhenSalesEnd,
      );
      form.setFieldValue(
        'beforeOrAfterEvent ',
        activeEditingEvent?.basicInfo?.showDates![0].beforeOrAfterEvent,
      );

      form.setFieldValue(
        EVENT_CREATE_FORM_BASIC_INFO_ENUM.SLUG,
        activeEditingEvent?.basicInfo?.slug,
      );

      const startSellingAt = activeEditingEvent?.basicInfo?.startSellingAt;
      const startSaleDate = dayjs(activeEditingEvent?.basicInfo?.startSellingAt, 'YYYY-MM-DD');
      const startSaleTime = dayjs(
        activeEditingEvent?.basicInfo?.startSellingAt,
        'YYYY-MM-DD HH:mm',
      );
      if (startSellingAt) {
        form.setFieldValue('isStartSellingAt', true);
        form.setFieldValue('salesDate', startSaleDate);
        form.setFieldValue('salesTime', startSaleTime);
      }

      // form.setFieldValue('eventType', {
      //   value: activeEditingEvent?.basicInfo?.eventType?.toLowerCase(),
      // });

      if (activeEditingEvent?.basicInfo?.eventType === 'seated') {
        if (activeEditingEvent?.basicInfo?.seatingPlanId) {
          form.setFieldValue('seatingPlan', {
            value: activeEditingEvent?.basicInfo?.seatingPlanId,
          });
        }
      }
      // if (activeEditingEvent?.basicInfo?.showDates?.length === 1) {
      //   const startDate = dayjs(
      //     activeEditingEvent?.basicInfo?.showDates[0].startDate,
      //     'DD-MMM-YYYY',
      //   );
      //   const startTime = dayjs(
      //     `${startDate.format('DD-MMM-YYYY')} ${
      //       activeEditingEvent?.basicInfo?.showDates[0].startTime
      //     }`,
      //   );
      //   const endDate = activeEditingEvent?.basicInfo?.showDates[0].endDate
      //     ? dayjs(activeEditingEvent?.basicInfo?.showDates[0].endDate, 'DD-MMM-YYYY')
      //     : null;
      //   const endTime = activeEditingEvent?.basicInfo?.showDates[0].endTime
      //     ? dayjs(
      //         `${endDate?.format('DD-MMM-YYYY')} ${
      //           activeEditingEvent?.basicInfo?.showDates[0].endTime
      //         }`,
      //       )
      //     : null;

      //   form.setFieldValue(['showDates', 'startDate'], startDate);
      //   form.setFieldValue(['showDates', 'startTime'], startTime);

      //   const hoursWhenSalesEnd = activeEditingEvent?.basicInfo?.showDates[0].hoursWhenSalesEnd;
      //   if (hoursWhenSalesEnd) {
      //     form.setFieldValue(['showDates', 'hoursWhenSalesEnd'], hoursWhenSalesEnd);
      //   }

      //   const beforeOrAfterEvent = activeEditingEvent?.basicInfo?.showDates[0].beforeOrAfterEvent;

      //   if (beforeOrAfterEvent) {
      //     form.setFieldValue(['showDates', 'beforeOrAfterEvent'], beforeOrAfterEvent);
      //   }
      //   if (endDate) {
      //     form.setFieldValue(['showDates', 'endDate'], endDate);
      //   }
      //   if (endTime) {
      //     form.setFieldValue(['showDates', 'endTime'], endTime);
      //   }

      //   if (activeEditingEvent?.basicInfo?.showDates[0].showId) {
      //     form.setFieldValue(
      //       ['showDates', 'showId'],
      //       activeEditingEvent?.basicInfo?.showDates[0].showId,
      //     );
      //   }
      // } else {
      setMultipleDates(activeEditingEvent?.basicInfo?.showDates as MultipleDatesType[]);
      // }
      setEditingList(activeEditingEvent?.basicInfo);
    } else {
      form.setFieldValue('currency', activePromoter?.currency);
    }
  }, [activeEditingEvent, activePromoter, form]);

  // useEffect(() => {
  //   console.log(activeEditingEvent);
  //   if (
  //     activeEditingEvent?.basicInfo?.status !== 'published' &&
  //     activeEditingEvent?.basicInfo?.status !== 'approved'
  //   ) {
  //     const convertedUrl = debounceEventName
  //       ?.toLowerCase()
  //       .replace(/[^a-zA-Z0-9-]+/g, '-')
  //       .replace(/-{2,}/g, '-')
  //       .replace(/-/g, ' ')
  //       .replace(/\s+/g, '-')
  //       .replace(/^-+|-+$/g, '');

  //     form.setFieldValue(EVENT_CREATE_FORM_BASIC_INFO_ENUM.SLUG, convertedUrl);
  //   } else {
  //     form.setFieldValue(
  //       EVENT_CREATE_FORM_BASIC_INFO_ENUM.SLUG,
  //       activeEditingEvent?.basicInfo?.slug,
  //     );
  //   }
  // }, [debounceEventName, form, activeEditingEvent]);

  useEffect(() => {
    const primCategory = tags?.categorized.find((item) => item.id === primaryCategoryValue?.value);
    const secondaryCategory = tags?.categorized.find(
      (item) => item.id === secondaryCategoryValue?.value,
    );

    setPrimCategoryTags(primCategory?.tags!);
    setSecondaryCategoryTags(secondaryCategory?.tags!);

    if (primCategory?.tags?.length) {
      setSelectedTags([...primCategory.tags, ...(tags?.mytags || [])]);
    }

    if (secondaryCategory?.tags?.length) {
      if (primCategory?.tags?.length) {
        setSelectedTags((prevState) => [...prevState, ...(secondaryCategory?.tags || [])]);
      } else {
        setSelectedTags(secondaryCategory?.tags);
      }
    }
  }, [primaryCategoryValue, secondaryCategoryValue, tags]);

  return (
    <createEventFormBasicInfoContext.Provider value={{ form, onChangeForm: handleChange }}>
      <Form
        className={s.wrapper}
        form={form}
        onFinish={handleSubmit}
        onValuesChange={handleChange}
        style={{ marginBottom: isEditing ? '100px' : '0' }}>
        <div className={s.eventCreationFormHeader}>
          <Typography type="h2" className={s.title}>
            {t('event_create_form_basic_info')}
          </Typography>
          {/* <EventPopover handleEvent={handleSetActiveEvent} /> */}
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            {extractFlyerName(activeEditingEvent?.media.flyer! || '') ? (
              <img src={activeEditingEvent?.media.flyer!} alt="flyer" className={s.img} />
            ) : (
              <div className={s.emptyFlyer}>
                <Icon name="littleLogo" />
              </div>
            )}
            <Typography type="h4">{activeEditingEvent?.basicInfo.eventName}</Typography>
          </div>
        </div>
        {activeEditingEvent?.eventId && (
          <div className={s.dateLabels}>
            <Typography type="main" color="grey">
              Event was published on:{' '}
              <span style={{ color: 'black', fontSize: 16 }}>
                {formatDate(activeEditingEvent.publishedDateUTC as string)}
              </span>
            </Typography>
            <Typography type="main" color="grey">
              Date tickets are set to end on:{' '}
              <span style={{ color: 'black', fontSize: 16 }}>
                {formatDate(activeEditingEvent.basicInfo.lastShowDate as string)}
              </span>
            </Typography>
          </div>
        )}

        <div className={s.form}>
          <div className={s.info}>
            <div>
              <Typography type="small" className={cn(s.label, s.required)}>
                Event title
              </Typography>
              <Form.Item
                name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE}
                rules={[formRules.required]}>
                <Input
                  onChange={handleTitleChange}
                  placeholder="Your event’s title"
                  name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE}
                />
              </Form.Item>
              <Typography type="small" className={s.titleLabel}>
                {lenghtTilte}/100
              </Typography>
            </div>
            <div>
              <Form.Item name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.LOCATION} hidden>
                <div></div>
              </Form.Item>
              <EventCreateFormBasicInfoLocation />
            </div>
          </div>
          <div className={s.hintInfo}>
            <EventCreateFormBasicInfoPromoter />
          </div>
        </div>

        <div>
          <EventCreateFormBasicInfoDates
            setShowDates={setMultipleDates}
            setIsDirty={setIsEditing}
            multipleDates={multipleDates}
            hasDateValue={hasDateValue}
            setHasDateValue={setHasDateValue}
          />
        </div>
        <div className={s.form}>
          <div className={s.info}>
            <div style={{ marginTop: 24 }}>
              <Typography type="h3">Discoverability</Typography>
            </div>
            <div>
              <Typography type="small" className={cn(s.label, s.required)}>
                Event page URL
              </Typography>
              <div className={s.urlInput}>
                <Typography type="main">
                  {SH_URL}
                  {activePromoter?.url}
                </Typography>

                <Form.Item
                  name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.SLUG}
                  rules={[formRules.eventUrl]}
                  style={{ width: '60%' }}>
                  <Input name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.SLUG} />
                </Form.Item>
              </div>
            </div>
            <div>
              <Typography type="small" className={cn(s.label)}>
                Hide event on ShowsHappening listings
              </Typography>
              <Form.Item name="privateEvent" initialValue={false}>
                <EventTypeSwitcher />
              </Form.Item>
            </div>
            <div className={s.inviteCodeGroup}>
              <Checkbox value={isInviteCode} onChange={(value) => handleCheckBox(value)}>
                <Typography type="main" weight={600}>
                  Use a event invite code?
                </Typography>
              </Checkbox>
              {isInviteCode && (
                <>
                  <Typography type="main" className={cn(s.label)}>
                    Use this feature so that people must enter your password in order to be able to
                    buy a ticket for your event. Leave blank for no password.
                  </Typography>
                  <Form.Item name="inviteCode">
                    <Input name="inviteCode" placeholder="Type in an invite code, if any" />
                  </Form.Item>
                </>
              )}
            </div>

            <div className={s.categories}>
              <div>
                <Typography type="small" className={cn(s.label, s.required)}>
                  {t('common_primary_category')}
                </Typography>
                <Form.Item name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.CATEGORIES}>
                  <Select
                    placeholder={t('common_select_category')}
                    options={tags?.categorized?.map((category) => ({
                      label: category.name,
                      value: category.id,
                    }))}
                  />
                </Form.Item>
              </div>
              {needSecondaryCategory && (
                <div>
                  <Typography type="small" className={cn(s.label)}>
                    {t('common_secondary_category')}
                  </Typography>
                  <Form.Item name="secondaryCategory">
                    <Select
                      placeholder={t('common_secondary_category')}
                      options={tags?.categorized
                        ?.filter((item) => item.id !== primaryCategoryValue?.value)
                        ?.map((category) => ({
                          label: category.name,
                          value: category.id,
                        }))}
                    />
                  </Form.Item>
                </div>
              )}
              <button type="button" className={s.addCategory} onClick={handleAddSecondaryCategory}>
                <Typography type="small">
                  {!needSecondaryCategory ? (
                    <div className={s.addText}>
                      <PlusCircleFilled style={{ fontSize: 20 }} />
                      Add secondary category
                    </div>
                  ) : (
                    <div className={s.addText}>
                      <MinusCircleFilled style={{ fontSize: 20 }} />
                      Remove secondary category
                    </div>
                  )}
                </Typography>
              </button>
            </div>
            <div>
              <Typography type="small" className={cn(s.label)}>
                {t('common_tags')}
              </Typography>
              <div className={s.addTag}>
                <Input
                  name="tags"
                  fullWidth
                  onChange={(e) => setNewTag(e.target.value)}
                  placeholder="Enter Keywords"
                />
                <Button onClick={handleAddTag}>ADD</Button>
              </div>
            </div>

            {!!selectedTags?.length && (
              <div>
                <Form.Item name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.TAGS}>
                  <CheckableTag
                    tags={selectedTags}
                    primaryTags={primCategoryTags}
                    secondaryTags={secondaryCategoryTags}
                    value={fetchedTags}
                    onClick={() => setIsAddedTagShow(!isAddedTagShow)}
                    showAddButton={!tags?.mytags}
                  />
                </Form.Item>
              </div>
            )}

            {/* <EventCreateFormBasicInfoSeatingPlan form={form} /> */}
          </div>
          <div className={s.hintInfo}>
            <div className={s.privateHintWrapper}>
              <div className={s.hint}>
                <div>
                  <Icon name="warning" />
                </div>
                <Trans i18nKey="event_create_form_type_tip" components={{ b: <b /> }} />
              </div>
            </div>
          </div>
        </div>

        <FormSubmit
          createEvent={!activeEditingEvent?.eventId}
          isDirty={isDirty}
          isEditing={isEditing}
          editingList={editingList}
          hasOneDate={hasDateValue}
          type="eventBasic"
          fetching={isLoading}
        />
      </Form>
      {!currentUser?.promoters[0]?.name && !userFetching && !eventId && <WarningModal />}
    </createEventFormBasicInfoContext.Provider>
  );
};

export default CreateEventFormBasicInfo;
