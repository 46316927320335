import { FC, PropsWithChildren, memo } from 'react';

import cn from 'classnames';

import s from './Header.module.scss';

interface HeaderProps {
  className?: string;
}

const Header: FC<PropsWithChildren<HeaderProps>> = (props) => {
  const { children, className = '' } = props;

  return <div className={cn(s.header, { [className]: className })}>{children}</div>;
};

export default memo(Header);
