import React, { useCallback, useState } from 'react';

import { Modal, Progress, Skeleton } from 'antd';
import { formatNumber } from 'tools/formatNumbers';
import { Table, Typography } from 'ui';

import { useLazyGetEventSalesChannelsQuery } from 'services/events/eventsApiService';
import {
  EventDetailedSummaryType,
  EventSalesChannelsType,
  EventSeatMetricsType,
} from 'services/events/evetns.api.type';

import { CURRENCIES_SETTING } from 'constants/core-constants';

import s from './DashboardDateTab.module.scss';

interface DateMetricsProps {
  seatMetrics: EventSeatMetricsType | undefined;
  detailedSummary: EventDetailedSummaryType | undefined;
  salesChannels: EventSalesChannelsType | undefined;
  isLoading: boolean;
  selectedDate: string;
  isSeated: boolean;
  currency: string;
  eventId?: string;
}

const DateMetrics: React.FC<DateMetricsProps> = ({
  seatMetrics,
  detailedSummary,
  salesChannels,
  currency,
  selectedDate,
  isSeated,
  eventId,
}) => {
  const [getEventSalesChannels, { data: eventSalesChannels, isLoading }] =
    useLazyGetEventSalesChannelsQuery();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getTotalValue = (salesChannels: EventSalesChannelsType): number => {
    const totalValue = salesChannels.statistics.reduce((acc, item) => {
      return acc + item.valueSold;
    }, 0);
    return totalValue;
  };
  const extractDate = (dateString: string): string => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];

    return formattedDate;
  };
  const handleGetSalesChannels = useCallback(
    (name) => {
      if (name === 'Ticket Office') {
        setIsOpen(true);
        getEventSalesChannels({ EventIds: [eventId!], Filter: 'Runner' });
      } else if (name === 'Referrer Links') {
        setIsOpen(true);
        getEventSalesChannels({ EventIds: [eventId!], Filter: 'Link' });
      }
    },
    [eventId, getEventSalesChannels],
  );

  const columns = [
    {
      title: 'REFERRER NAME',
      dataIndex: 'linkName',
      key: 'linkName',
      render: (linkName) => {
        return <div>{linkName || '-'}</div>; // Display 'N/A' if linkName is null
      },
    },
    {
      title: 'QTY',
      dataIndex: 'qty',
      key: 'qty',
      render: (qty) => <div>{qty}</div>,
    },
    {
      title: 'VALUE',
      dataIndex: 'value',
      key: 'value',
      render: (value) => (
        <div>
          {currency && CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol} {value}
        </div>
      ),
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (firstName) => <div>{firstName}</div>,
    },
    {
      title: 'LAST NAME',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (lastName) => <div>{lastName}</div>,
    },
    {
      title: 'OUTLET',
      dataIndex: 'outlet',
      key: 'outlet',
      render: (outlet) => <div>{outlet?.name || '-'}</div>,
    },
  ];

  return (
    <>
      <Modal onCancel={() => setIsOpen(false)} open={isOpen} centered footer={null} width={1000}>
        <Table
          style={{ marginTop: 28 }}
          dataSource={eventSalesChannels ? eventSalesChannels[0]?.salesChannels : []}
          loading={isLoading}
          columns={columns}
          pagination={false}
        />
      </Modal>
      <div className={s.dateMetricsWrapper}>
        <div className={s.card}>
          <Typography type="main" color="grey" className={s.title}>
            TOTAL REVENUE
          </Typography>
          <Typography type="h4" className={s.subtitle}>
            {' '}
            {CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol}{' '}
            {salesChannels && formatNumber(getTotalValue(salesChannels))}
          </Typography>
          {/* <Typography type="main" color="grey" className={s.today}>
            {isLoading ? (
              <></>
            ) : (
              <span className={s.green}>
                + {CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol} {formatNumber(detailedSummary.totalSales.today)} today
              </span>
            )}
          </Typography> */}
          <div className={s.metrics}>
            {salesChannels ? (
              salesChannels.statistics.map((data, index) => (
                <div key={index} className={s.row}>
                  <Typography type="main" color="grey">
                    {data.name}:
                  </Typography>
                  <Typography type="main" color="grey" className={s.today}>
                    <span className={s.blue}>
                      {' '}
                      {CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol}{' '}
                      {formatNumber(data.valueSold)}
                    </span>
                  </Typography>
                </div>
              ))
            ) : (
              <Skeleton style={{ marginTop: 24 }} active paragraph={{ rows: 3 }} />
            )}
          </div>
          <div className={s.buttonWrapper}>
            <button className={s.button}>VIEW ALL</button>
          </div>
        </div>
        <div className={s.card}>
          <Typography type="main" color="grey" className={s.title}>
            SALES CHANNELS
          </Typography>
          <Typography type="h4" className={s.subtitle}>
            {salesChannels && salesChannels.statistics.length}
          </Typography>
          <div className={s.metrics}>
            {salesChannels ? (
              salesChannels.statistics.map((data, index) => (
                <div
                  key={index}
                  className={`${s.row} ${
                    data.qty !== 0 && data.name !== 'ShowsHappening.com' ? s.clickable : ''
                  }`}
                  onClick={() =>
                    data.qty !== 0 &&
                    data.name !== 'ShowsHappening.com' &&
                    handleGetSalesChannels(data?.name)
                  }
                  onKeyDown={() => {}}
                  role="button"
                  tabIndex={0}>
                  <Typography type="main">{data.name}:</Typography>
                  <Typography type="main" className={s.today}>
                    <span className={s.blue}> {formatNumber(data.qty)}</span>
                  </Typography>
                </div>
              ))
            ) : (
              <Skeleton style={{ marginTop: 24 }} active paragraph={{ rows: 3 }} />
            )}
          </div>
          <div className={s.buttonWrapper}>
            <button className={s.button}>VIEW ALL</button>
          </div>
        </div>
        {isSeated && seatMetrics && (
          <div className={s.card}>
            <Typography type="main" color="grey" className={s.title}>
              TICKETS SOLD
            </Typography>
            {selectedDate !== 'allDates' && seatMetrics ? (
              seatMetrics.shows
                .filter((show) => extractDate(show.showDate) === selectedDate)
                .map((show, index) => (
                  <Typography key={index} type="h4" className={s.subtitle}>
                    {show.soldTickets}
                    {show.maximumNumberOfAttendees > 0 ? (
                      <span className={s.limit_grey}>
                        &nbsp;/&nbsp;{show.maximumNumberOfAttendees}
                      </span>
                    ) : (
                      <span className={s.limit_grey}>&nbsp;/&nbsp;&#8734;</span>
                    )}
                  </Typography>
                ))
            ) : (
              <Typography type="h4" className={s.subtitle}>
                {seatMetrics.standingTickets + seatMetrics.seatedTickets}
                {seatMetrics.maximumNumberOfAttendees > 0 ? (
                  <span className={s.limit_grey}>
                    &nbsp;/&nbsp;{seatMetrics.maximumNumberOfAttendees}{' '}
                  </span>
                ) : (
                  <span className={s.limit_grey}>&nbsp;/&nbsp;&infin;</span>
                )}
              </Typography>
            )}

            <div className={s.metrics}>
              <div className={s.row}>
                <Typography type="main" color="grey">
                  Seated tickets:
                </Typography>

                {selectedDate !== 'allDates' && seatMetrics ? (
                  seatMetrics.shows
                    .filter((show) => extractDate(show.showDate) === selectedDate)
                    .map((show, index) => (
                      <Typography key={index} type="main" color="grey" className={s.today}>
                        {show.seatedTickets} /{' '}
                        <span style={{ color: '#7f86a4' }}>{show.availableTickets}</span>
                      </Typography>
                    ))
                ) : (
                  <Typography type="main" color="grey" className={s.today}>
                    {seatMetrics.seatedTickets} /{' '}
                    <span style={{ color: '#7f86a4' }}>{seatMetrics.availableTickets}</span>
                  </Typography>
                )}
              </div>
              {!(
                seatMetrics.standingTicketsAvailable === 0 && seatMetrics.standingTickets === 0
              ) && (
                <div className={s.row}>
                  <Typography type="main" color="grey">
                    Standing tickets:
                  </Typography>

                  {selectedDate !== 'allDates' && seatMetrics ? (
                    seatMetrics.shows
                      .filter((show) => extractDate(show.showDate) === selectedDate)
                      .map((show, index) => (
                        <Typography key={index} type="main" color="grey" className={s.today}>
                          {show.standingTickets}
                          {show.standingTicketsAvailable > 0 ? (
                            <span className={s.limit_grey}>
                              &nbsp;/&nbsp;{show.standingTicketsAvailable}
                            </span>
                          ) : (
                            <span className={s.limit_grey}>&nbsp;/&nbsp;&#8734;</span>
                          )}
                        </Typography>
                      ))
                  ) : (
                    <Typography type="main" color="grey" className={s.today}>
                      {seatMetrics.standingTickets}
                      {seatMetrics.standingTicketsAvailable > 0 ? (
                        <span className={s.limit_grey}>
                          &nbsp;/&nbsp;{seatMetrics.standingTicketsAvailable}
                        </span>
                      ) : (
                        <span className={s.limit_grey}>&nbsp;/&nbsp;&#8734;</span>
                      )}
                    </Typography>
                  )}
                </div>
              )}
              <div className={s.row}>
                <Typography type="main" color="grey">
                  Average sold at:
                </Typography>
                {salesChannels && selectedDate !== 'allDates' && seatMetrics ? (
                  seatMetrics.shows
                    .filter((show) => extractDate(show.showDate) === selectedDate)
                    .map((show, index) => (
                      <Typography key={index} type="main" color="grey" className={s.today}>
                        <span className={s.blue}>
                          {' '}
                          {CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol}{' '}
                          {show.soldTickets !== 0
                            ? formatNumber(getTotalValue(salesChannels) / show.soldTickets)
                            : 0}
                        </span>
                      </Typography>
                    ))
                ) : (
                  <Typography type="main" color="grey" className={s.today}>
                    <span className={s.blue}>
                      {' '}
                      {CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol}{' '}
                      {seatMetrics.soldTickets !== 0 && salesChannels
                        ? formatNumber(getTotalValue(salesChannels) / seatMetrics.soldTickets)
                        : 0}
                    </span>
                  </Typography>
                )}
              </div>
              <div className={s.row}>
                <Typography type="main" color="grey">
                  Disabled seats:
                </Typography>

                {selectedDate !== 'allDates' && seatMetrics ? (
                  seatMetrics.shows
                    .filter((show) => extractDate(show.showDate) === selectedDate)
                    .map((show, index) => (
                      <Typography key={index} type="main" color="grey" className={s.today}>
                        {show.disabledSeats} /{' '}
                        <span style={{ color: '#7f86a4' }}>{show.availableTickets}</span>
                      </Typography>
                    ))
                ) : (
                  <Typography type="main" color="grey" className={s.today}>
                    {seatMetrics.disabledSeats} /{' '}
                    <span style={{ color: '#7f86a4' }}>{seatMetrics.availableTickets}</span>
                  </Typography>
                )}
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography type="main" color="grey">
                  Filled seats:
                </Typography>
                {selectedDate !== 'allDates' && seatMetrics ? (
                  seatMetrics.shows
                    .filter((show) => extractDate(show.showDate) === selectedDate)
                    .map((show, index) => (
                      <Progress
                        key={index}
                        style={{ marginTop: 8 }}
                        percent={parseFloat(show.filledSeatsPercentage.toFixed(1))}
                        size={['100%', 20]}
                      />
                    ))
                ) : (
                  <Progress
                    style={{ marginTop: 8 }}
                    percent={parseFloat(seatMetrics.filledSeatsPercentage.toFixed(1))}
                    size={['100%', 20]}
                  />
                )}
              </div>
              <div className={s.buttonWrapper}>
                <button className={s.button}>VIEW ALL</button>
              </div>
            </div>
          </div>
        )}

        {!isSeated && detailedSummary && (
          <div className={s.card}>
            <Typography type="main" color="grey" className={s.title}>
              TICKETS SOLD
            </Typography>

            <Typography type="h4" className={s.subtitle}>
              {detailedSummary.totalTicketsSold.allTime}
            </Typography>

            {/* <Typography type="main" color="grey" className={s.today}>
            <span className={s.green}>
              + {formatNumber(detailedSummary.totalTicketsSold.today)} today
            </span>
          </Typography> */}
            <div className={s.metrics}>
              <div className={s.row}>
                <Typography type="main" color="grey">
                  Average sold at:
                </Typography>

                <Typography type="main" color="grey" className={s.today}>
                  <span className={s.blue}>
                    {' '}
                    {CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol}{' '}
                    {detailedSummary.totalSales.averageAllTime}
                  </span>
                </Typography>
              </div>
              <div className={s.row}>
                <Typography type="main" color="grey">
                  Complimentary:
                </Typography>

                <Typography type="main" color="grey" className={s.today}>
                  {detailedSummary.totalComplimentariesIssued.allTime}
                </Typography>
              </div>
              <div className={s.row} style={{ visibility: 'hidden' }}>
                <Typography type="main" color="grey">
                  Complimentary:
                </Typography>

                <Typography type="main" color="grey" className={s.today}>
                  Skeleton
                </Typography>
              </div>
              <div className={s.row} style={{ visibility: 'hidden' }}>
                <Typography type="main" color="grey">
                  Complimentary:
                </Typography>

                <Typography type="main" color="grey" className={s.today}>
                  Skeleton
                </Typography>
              </div>
              {/* <div className={s.row}>
              <Typography type="main" color="grey">
                Complimentary:
              </Typography>
              <Typography type="main" color="grey" className={s.today}>
                {seatMetrics.}
              </Typography>
            </div> */}
              <div className={s.buttonWrapper}>
                <button className={s.button}>VIEW ALL</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const MemoizedDateMetrics = React.memo(DateMetrics);
export default MemoizedDateMetrics;
