import { t } from 'tools/i18n';

export const formRules = {
  required: {
    required: true,
    message: t('validation_field_not_empty'),
  },
  email: {
    pattern: /\S+@\S+\.\S+/,
    message: t('validation_field_email'),
    validateTrigger: ['onBlur'],
    required: true,
  },
  maltaVat: {
    pattern: /^MT\d{8}$/,
    message: t('validation_field_malta_vat'),
    validateTrigger: ['onBlur'],
  },
  password: {
    required: true,
    pattern: /^(?=.*\d)(?=.*[A-Z]).{6,}$/,
    validateTrigger: ['onBlur'],
    message: 'The field cannot be empty',
  },
  eventUrl: {
    required: true,
    pattern: /^[A-Za-z0-9-]+$/,
    message: 'Only letters, numbers and the "-" sign are allowed',
  },
};
