import { FC, useState } from 'react';

import { Select } from 'antd';
import { Input, Label, TextArea } from 'ui';

import s from './EventOfferType.module.scss';

const defaultOfferTypes = [
  { value: 'per_show', label: 'Per different show' },
  { value: 'per_multiple_number_of_tickets', label: 'Per multiple number of tickets' },
  { value: 'fixed_percentage_discount', label: 'Fixed percentage discount' },
  { value: 'greater_than_number_of_tickets', label: 'Greater or equal than number of tickets' },
  { value: 'fixed_price_on_ticket_type', label: 'Fixed price on each ticket type' },
  { value: 'fixed_price_on_specific_type', label: 'Fixed prices on specific types' },
  { value: 'per_different_event', label: 'Per different event' },
];

const defaultEnableState = [
  { value: 'no', label: 'No' },
  { value: 'yes', label: 'Yes' },
];

interface EventOfferTypeProps {
  setOfferType: (state: string) => void;
  offerType: string;
}

const EventOfferType: FC<EventOfferTypeProps> = (props) => {
  const { offerType, setOfferType } = props;
  const [enableState, setEnableState] = useState(defaultEnableState[0].value);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div style={{ width: '100%' }}>
          <Label>Offer Type</Label>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => setOfferType(value)}
            options={defaultOfferTypes?.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            value={offerType}
            defaultValue=""
          />
        </div>
        <div style={{ width: '100%' }}>
          <Label>Enabled?</Label>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => setEnableState(value)}
            options={defaultEnableState?.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            value={enableState}
            defaultValue=""
          />
        </div>
      </div>
      <div className={s.name}>
        <Label>Name of offer</Label>
        <Input name="offerName" size="middle" placeholder="Enter name"></Input>
      </div>
      <div className={s.description}>
        <Label>Description</Label>
        <TextArea
          name="description"
          size="middle"
          placeholder="Enter description of your offer"></TextArea>
      </div>
    </div>
  );
};

export default EventOfferType;
