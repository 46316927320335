import initialApiService from 'services/initialApiService';

import { EventScanHistoryRequestType, EventScanHistoryResponseType } from './scan.api.type';

const scanApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getEventScanHistory: builder.query<EventScanHistoryResponseType, EventScanHistoryRequestType>({
      query: (body) => ({
        url: `scan/eventscanhistory`,
        method: 'GET',
        params: body,
      }),
    }),
  }),
});

export const { useLazyGetEventScanHistoryQuery } = scanApiService;
