import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/user-management/list';
import { t } from 'tools/i18n';
import { Button, Icon, Typography } from 'ui';

import s from './RegisterBenefitsContent.module.scss';
import { registerBenefitsContent } from './benefitsContent.utils';

const RegisterBenefitsContent: FC = () => {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(LOGIN_ROUTE);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <Icon name="logo" width={320} height={50} className={s.logo} />
        <div className={s.title}>
          <Typography type="h1" className={s.titleText}>
            {t('auth_register_benefits_title')}
          </Typography>
        </div>
        <div className={s.textContent}>
          {registerBenefitsContent.map((item) => (
            <div key={item.label} className={s.item}>
              <Icon name={item.icon} className={s.icon} />

              <div className={s.benefitsText}>{t(item.label)}</div>
            </div>
          ))}
        </div>
        <div className={s.actions}>
          <Typography type="h6">{t('auth_already_have_account')}</Typography>
          <Button variant="outline" onClick={goToLogin}>
            {t('auth_login_log_in')}
          </Button>
        </div>

        <Typography type="main" className={s.helpText}>
          Need help? <span className={s.bold}>Contact Us</span>
        </Typography>
      </div>
    </div>
  );
};

export default RegisterBenefitsContent;
