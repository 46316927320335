import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { Modal } from 'antd';
import { message } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import {
  useAddedTicketTypeMutation,
  useDeleteTicketTypeMutation,
  useLazyGetDatesQuery,
} from 'services/events/eventsApiService';
import { setActiveEvent, setIsEditingEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { EventCreatedType, EventTicketType } from 'types/events/eventTypes';

import { DateTicketStatus } from '../eventCreateFormTickets';
import s from './TicketsList.module.scss';
import TicketItem from './ticket-item';

const { confirm } = Modal;

interface TicketsListProps {
  list: EventTicketType[];
  setList: Dispatch<SetStateAction<EventTicketType[]>>;
  currency: string;
  setActiveEditTicket: Dispatch<SetStateAction<EventTicketType | undefined>>;
  eventType: boolean;
  dateTicketStatus?: DateTicketStatus[];
  currentDateId?: string;
  maxAttendeesAmount?: string;
  eventId?: string;
}
const TicketsList: FC<TicketsListProps> = (props) => {
  const {
    list,
    setList,
    currency,
    setActiveEditTicket,
    eventType,
    dateTicketStatus,
    currentDateId,
    maxAttendeesAmount,
    eventId,
  } = props;

  const [deleteTicket] = useDeleteTicketTypeMutation();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [getDates, { data: dates }] = useLazyGetDatesQuery();

  const [createTickets] = useAddedTicketTypeMutation();

  const sensors = useSensors(useSensor(PointerSensor));
  const dispatch = useAppDispatch();

  useEffect(() => {
    getDates(eventId!);
  }, [eventId, getDates]);

  const showConfirm = (id: string) => {
    confirm({
      title: 'Do you want to delete this ticket type?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      centered: true,
      mask: false,
      async onOk() {
        try {
          setList((items) => items.filter((item) => item.id !== id));
          const res = await deleteTicket(id);

          if ('data' in res) {
            dispatch(setActiveEvent(res.data as EventCreatedType));
            message.success('Ticket Type successfully deleted!');
          }
        } catch (error) {
          console.error('Failed to delete ticket:', error);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const syncTicketsToServer = useCallback(
    async (updatedItems, eventId, maxAttendeesAmount) => {
      try {
        const res = await createTickets({
          ticketTypes: updatedItems,
          ...(!!maxAttendeesAmount && { maxNumberOfAttendees: Number(maxAttendeesAmount) }),
          eventId: eventId || '',
        });

        if ('data' in res) {
          message.success('Ticket types order successfully updated!');
          dispatch(setIsEditingEvent(false));
          return res.data;
        }
      } catch (error) {
        message.error('Failed to update ticket types. Please try again.');
        throw error;
      }
    },
    [createTickets, dispatch],
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;
      dispatch(setIsEditingEvent(true));

      if (active.id && over && over.id !== active.id) {
        const oldIndex = list.findIndex(
          (ticket, index) => `${ticket.name}_${ticket.price}_${index}` === active.id,
        );
        const newIndex = list.findIndex(
          (ticket, index) => `${ticket.name}_${ticket.price}_${index}` === over.id,
        );

        const movedItems = arrayMove(list, oldIndex, newIndex);

        const updatedItems = movedItems.map((item, index) => {
          return { ...item, sortOrder: index };
        });

        dispatch(setActiveEvent({ ...activeEvent!, ticketTypes: updatedItems }));

        try {
          const updatedData = await syncTicketsToServer(updatedItems, eventId, maxAttendeesAmount);

          if (updatedData) {
            dispatch(setActiveEvent(updatedData));
          }
        } catch (error) {
          dispatch(setActiveEvent(activeEvent!));
        }
      }
    },
    [list, dispatch, syncTicketsToServer, eventId, maxAttendeesAmount, activeEvent],
  );

  const handleDeleteItem = (id: string) => {
    showConfirm(id);
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={list.map((ticket, index) => `${ticket.name}_${ticket.price}_${index}`)}>
        <div className={s.wrapper}>
          {list.map((ticket, i) => {
            const matchingStatus = dateTicketStatus?.find((item) => item.id === ticket.id);
            return (
              <TicketItem
                ticketTypes={list}
                isAllDates={list.length > 1}
                eventType={eventType}
                currentDateId={currentDateId}
                ticket={ticket}
                index={i}
                dates={dates}
                currency={currency}
                id={`${ticket?.name}_${ticket.price}_${i}`}
                key={`${ticket?.name}_${ticket.price}_${i}`}
                handleDeleteItem={handleDeleteItem}
                setActiveEditTicket={setActiveEditTicket}
                status={matchingStatus}
                dateTicketStatus={dateTicketStatus}
                maxAttendeesAmount={maxAttendeesAmount}
                eventId={eventId}
              />
            );
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default TicketsList;
