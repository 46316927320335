import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PhoneInput from 'react-phone-input-2';
import mt from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { setTokenCookies } from 'tools/cookieTools';
import { t } from 'tools/i18n';
import { Button, Checkbox, Form, Icon, Input, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import {
  useLazyLoginQuery,
  useRegisterMutation,
} from 'services/user-management/userManagementApiService';

import { EMAIL_PLACEHOLDER, PASSWORD_PLACEHOLDER } from 'constants/core-constants';
import { CORE_URLS } from 'constants/coreUrls';
import { RegisterRequestProps } from 'types/user-management/auth';

import { REGISTER_FORM_ENUM, prepareRegisterFormData } from '../register.utils';
import s from './RegisterForm.module.scss';

const digitPattern = /\d/;
const uppercasePattern = /[A-Z]/;
const RegisterForm: FC = () => {
  const [registration, { isLoading }] = useRegisterMutation();
  const [getUser, { isLoading: loginIsLoading }] = useLazyLoginQuery();
  const [form] = Form.useForm();
  const password = Form.useWatch(REGISTER_FORM_ENUM.PASSWORD, form);
  const [blurPassword, setBlurPassword] = useState(false);
  const [isPasswordDirty, setIsPasswordDirty] = useState(false);
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const passwordValidation = useMemo(() => {
    return {
      length: password?.length >= 6,
      number: digitPattern.test(password),
      uppercase: uppercasePattern.test(password),
    };
  }, [password]);

  const handleSubmit = useCallback(
    async (data: RegisterRequestProps) => {
      const result = prepareRegisterFormData(data);
      if (executeRecaptcha) {
        const token = await executeRecaptcha('register');
        const res = await registration({ ...result, captchaResponse: token });
        if (localStorage.getItem('currentEvent')) {
          localStorage.removeItem('currentEvent');
        }
        if ('data' in res) {
          const userRes = await getUser({
            email: data[REGISTER_FORM_ENUM.EMAIL],
            password: data[REGISTER_FORM_ENUM.PASSWORD],
          });

          if ('data' in userRes) {
            setTokenCookies(userRes?.data?.accessToken, userRes?.data?.refreshToken);
            navigate(DASHBOARD_ROUTE);
          }
        }
      }
    },
    [executeRecaptcha, registration, getUser, navigate],
  );

  useEffect(() => {
    if (password?.length) {
      setIsPasswordDirty(true);
    }
  }, [password]);

  const invalidPasswordField = {
    length: (isPasswordDirty || blurPassword) && !passwordValidation.length,
    number: (isPasswordDirty || blurPassword) && !passwordValidation.number,
    uppercase: (isPasswordDirty || blurPassword) && !passwordValidation.uppercase,
  };

  return (
    <div className={s.wrapper}>
      <Icon name="logo" width={320} height={50} className={s.logo} />
      <Form
        className={s.form}
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
        onFinishFailed={() => setBlurPassword(true)}
        onValuesChange={(changedValues) => console.log(changedValues)}>
        <Typography type="main" className={s.text}>
          {t('auth_new_to_sh')}
        </Typography>

        <div className={s.row}>
          <Form.Item
            label={t('auth_register_first_name')}
            name={REGISTER_FORM_ENUM.FIRST_NAME}
            rules={[formRules.required]}
            fullWidth>
            <Input name={REGISTER_FORM_ENUM.FIRST_NAME} placeholder="Michael" />
          </Form.Item>
          <Form.Item
            label={t('auth_register_last_name')}
            name={REGISTER_FORM_ENUM.SECOND_NAME}
            rules={[formRules.required]}
            fullWidth>
            <Input name={REGISTER_FORM_ENUM.SECOND_NAME} placeholder="Jackson" />
          </Form.Item>
        </div>

        <Form.Item
          className={s.phoneContainer}
          label={t('auth_register_phone')}
          name={REGISTER_FORM_ENUM.PHONE}
          rules={[formRules.required]}>
          <PhoneInput
            containerClass={s.phoneContainer}
            inputClass={s.phone}
            buttonClass={s.phoneButton}
            regions="europe"
            preferredCountries={['mt']}
            country="mt"
            localization={mt}
            inputProps={{ name: REGISTER_FORM_ENUM.PHONE }}
          />
        </Form.Item>

        <div className={s.row}>
          <Form.Item
            label={t('common_email')}
            name={REGISTER_FORM_ENUM.EMAIL}
            rules={[formRules.email]}
            fullWidth>
            <Input name={REGISTER_FORM_ENUM.EMAIL} placeholder={EMAIL_PLACEHOLDER} fullWidth />
          </Form.Item>
          <Form.Item
            label={t('common_confirm_email')}
            name={REGISTER_FORM_ENUM.CONFIRM_EMAIL}
            dependencies={[REGISTER_FORM_ENUM.EMAIL]}
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('email') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The confirm email does not match!'));
                },
              }),
            ]}
            fullWidth>
            <Input
              name={REGISTER_FORM_ENUM.CONFIRM_EMAIL}
              placeholder={EMAIL_PLACEHOLDER}
              fullWidth
            />
          </Form.Item>
        </div>

        <div className={s.block}>
          <div className={s.row}>
            <Form.Item
              label={t('common_password')}
              fullWidth
              name={REGISTER_FORM_ENUM.PASSWORD}
              rules={[formRules.password]}>
              <Input.Password
                name={REGISTER_FORM_ENUM.PASSWORD}
                type="password"
                placeholder={PASSWORD_PLACEHOLDER}
                // onFocus={() => setBlurPassword(false)}
                onBlur={() => setBlurPassword(true)}
              />
            </Form.Item>
            <Form.Item
              label={t('common_confirm_password')}
              fullWidth
              name={REGISTER_FORM_ENUM.CONFIRM_PASSWORD}
              dependencies={[REGISTER_FORM_ENUM.PASSWORD]}
              rules={[
                {
                  required: true,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The confirm password does not match!'));
                  },
                }),
              ]}>
              <Input.Password
                name={REGISTER_FORM_ENUM.CONFIRM_PASSWORD}
                placeholder={PASSWORD_PLACEHOLDER}
              />
            </Form.Item>
          </div>
          <div className={s.row}>
            <div
              className={cn(
                s.hint,
                invalidPasswordField.length && s.invalid,
                isPasswordDirty && !invalidPasswordField.length && s.valid,
              )}>
              6 characters
            </div>
            <div
              className={cn(
                s.hint,
                invalidPasswordField.uppercase && s.invalid,
                isPasswordDirty && !invalidPasswordField.uppercase && s.valid,
              )}>
              Uppercase
            </div>
            <div
              className={cn(
                s.hint,
                invalidPasswordField.number && s.invalid,
                isPasswordDirty && !invalidPasswordField.number && s.valid,
              )}>
              Number
            </div>
          </div>
        </div>

        <div className={s.checkboxes}>
          <Form.Item name={REGISTER_FORM_ENUM.SH_PROMOTIONS}>
            <Checkbox>{t('auth_sh_promotions_text')}</Checkbox>
          </Form.Item>

          <Form.Item name={REGISTER_FORM_ENUM.PROMOTIONS}>
            <Checkbox>{t('auth_promotions_text')}</Checkbox>
          </Form.Item>
        </div>

        <Button htmlType="submit" fullWidth isLoading={isLoading || loginIsLoading}>
          {t('auth_sing_up')}
        </Button>

        <div className={s.privacy}>
          By continuing you agree to ShowsHappening
          <br />
          <a href={CORE_URLS.TERMS_OF_SERVICE} target="_blank" className={s.termsLink}>
            Terms of Service
          </a>
          and
          <a href={CORE_URLS.PRIVACY_POLICY} target="_blank" className={s.termsLink}>
            Privacy Policy
          </a>
          .
        </div>
      </Form>
    </div>
  );
};

export default RegisterForm;
