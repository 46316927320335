import { FC, useCallback, useEffect, useState } from 'react';

import SectionTitle from 'component/section-title/sectionTitle';
import { useDebounce } from 'tools/hooks/debounce.hook';
import { Form, message } from 'ui';

import { isPublishingEventSelector } from 'selectors/eventsSlice.selector';
import { useLazyGetUserEventsQuery } from 'services/user-management/userManagementApiService';
import { setEditingEventId, setIsPublishingEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import EventPopover from 'containers/event-popover';

import { UserEventType } from 'types/core/userTypes';

import Pagination from '../../component/pagination';
import s from './Events.module.scss';
import EventListBlockView from './event-list-block-view';
import EventsHeader from './events-header';
import EventsListTableView from './events-list-table-view';
import { EventFilterType, SortByEnum, initialFilter } from './events.utils';

const Events: FC = () => {
  const [form] = Form.useForm();
  const searchEventName = Form.useWatch('eventName', form);
  const [getUserEvents, { data: userEvents, isFetching }] = useLazyGetUserEventsQuery();
  const [sortBy, setSortBy] = useState<SortByEnum>(SortByEnum.PUBLISHED_DATE);
  const [requestFilter, setRequestFilter] = useState<EventFilterType>(initialFilter);
  const [isTableView, setIsTableView] = useState(false);
  const debouncedSearchEventName = useDebounce(searchEventName, 500) as string;
  const dispatch = useAppDispatch();
  const isPublishEvent = useAppSelector(isPublishingEventSelector);
  const [currentEventId, setCurrentEventId] = useState<string>();

  const showedElements =
    Number(userEvents?.events?.length) +
    (Number(userEvents?.pageNumber) - 1) * Number(requestFilter.PageSize);

  const handlePageChange = useCallback((PageNumber: number) => {
    setRequestFilter((prevState) => ({
      ...prevState,
      PageNumber,
    }));
  }, []);

  useEffect(() => {
    if (sortBy) {
      setRequestFilter((prevState) => ({
        ...prevState,
        SortBy: sortBy,
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    if (debouncedSearchEventName?.length >= 3) {
      setRequestFilter((prevState) => ({
        ...prevState,
        Search: debouncedSearchEventName,
      }));
    } else if (debouncedSearchEventName?.length === 0) {
      setRequestFilter((prevState) => ({
        ...prevState,
        Search: '',
      }));
    }
  }, [debouncedSearchEventName]);

  useEffect(() => {
    getUserEvents(requestFilter);
  }, [getUserEvents, requestFilter]);

  useEffect(() => {
    const storedEvent = localStorage.getItem('currentEvent');
    if (storedEvent) {
      setCurrentEventId(JSON.parse(storedEvent).eventId);
    }
  }, []);

  useEffect(() => {
    if (isPublishEvent) {
      message.success('Event successfully submitted for publication!');

      setTimeout(() => {
        dispatch(setIsPublishingEvent(false));
      }, 2000);
    }
  }, [dispatch, isPublishEvent]);

  const handleSetActiveEvent = useCallback(
    (event: UserEventType) => {
      dispatch(setEditingEventId(event?.eventId));
    },
    [dispatch],
  );

  return (
    <div className={s.wrapper}>
      <div className={s.headerRow}>
        <SectionTitle
          label="common_my_events"
          className={s.title}
          counter={userEvents?.totalRecords}
        />
        <EventPopover handleEvent={handleSetActiveEvent} />
      </div>
      <Form form={form}>
        <EventsHeader
          sortBy={sortBy}
          setSortBy={setSortBy}
          isBlockView={isTableView}
          setIsBlockView={setIsTableView}
        />
      </Form>
      {isTableView && <EventsListTableView list={userEvents?.events} isLoading={isFetching} />}
      {!isTableView && (
        <EventListBlockView
          currentEventId={currentEventId!}
          list={userEvents?.events}
          isLoading={isFetching}
        />
      )}
      {showedElements > 0 && (
        <Pagination
          totalElements={userEvents?.totalRecords}
          showedElements={showedElements}
          currentPage={requestFilter?.PageNumber || 0}
          total={userEvents?.totalPages}
          handlePageChange={handlePageChange}
          handleSizeChange={(size) =>
            setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
          }
        />
      )}
    </div>
  );
};

export default Events;
