import { Dispatch, FC, SetStateAction, useCallback, useContext, useState } from 'react';

import { Button, notification } from 'antd';
import dayjs from 'dayjs';

import { setIsEditingEvent } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import MultipleDateList from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-dates/multiple-dates/multiple-date-list';

import { MultipleDatesType } from 'types/events/eventTypes';

import { createEventFormBasicInfoContext } from '../../basicInfo.utils';
import SingleDate from '../single-date';
import s from './MultipleDates.module.scss';
import { prepareMultipleDates, validateFields } from './multipleDates.utils';

interface MultipleDatesProps {
  setShowsDates: Dispatch<SetStateAction<MultipleDatesType[]>>;
  multipleDates: MultipleDatesType[];
  hasDateValue: boolean;
  setHasDateValue: Dispatch<SetStateAction<boolean>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}
const MultipleDates: FC<MultipleDatesProps> = ({
  setShowsDates,
  multipleDates,
  hasDateValue,
  setIsDirty,
  setHasDateValue,
}) => {
  const { form } = useContext(createEventFormBasicInfoContext);
  const [api, contextHolder] = notification.useNotification();
  const [editIndex, setEditIndex] = useState<number>(-1);
  const dispatch = useAppDispatch();

  const handleClick = useCallback(() => {
    form
      ?.validateFields(validateFields)
      .then(({ showDates }) => {
        const action = editIndex >= 0 ? 'updated' : 'added';
        if (showDates) {
          const result = prepareMultipleDates(showDates);
          if (editIndex >= 0) {
            setShowsDates((prevState) => {
              const updatedDates = [...prevState];
              updatedDates[editIndex] = { ...updatedDates[editIndex], ...result };
              return updatedDates;
            });
            setEditIndex(-1);
          } else {
            setShowsDates((prevState) => (!prevState?.length ? [result] : [...prevState, result]));
          }
          form?.resetFields(validateFields);
          setHasDateValue(false);
          setIsDirty(true);
          dispatch(setIsEditingEvent(true));
          api.open({
            message: `Date Action`,
            description: `The date ${dayjs(showDates?.startDate).format(
              'DD.MM.YYYY',
            )} has been successfully ${action}.`,
          });
        }
      })
      .catch((err) => console.log(err));
  }, [editIndex, form, dispatch, setShowsDates, setIsDirty, setHasDateValue, api]);

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        {contextHolder}
        {/* <div>
          <Typography type="main">{t('date_type_multiple_title')}</Typography>
          <Typography type="main">{t('date_type_multiple_text')}</Typography>
        </div> */}

        <div>
          <SingleDate setEditIndex={setEditIndex} validateStartDate={multipleDates?.length > 0} />
          <Button
            disabled={!hasDateValue}
            type="primary"
            size="large"
            color="white"
            className={s.add}
            onClick={handleClick}>
            <div className={s.plus}>+</div>{' '}
            <div className={s.text}>{editIndex >= 0 ? 'Edit date' : 'Add date'}</div>
          </Button>
        </div>
      </div>
      <div className={s.content}>
        {/* <div>
          <Typography type="main">Ticket sales end hrs before/after event</Typography>
          <div className={s.salesTime}>
            <div className={s.field}>
              <Typography type="small" className={cn(s.label)}>
                Hrs
              </Typography>
              <Form.Item name={['showDates', 'hoursWhenSalesEnd']}>
                <Input.Number
                  style={{ width: '100%' }}
                  type="number"
                  placeholder="Ticket sales end hour"
                  name="hoursWhenSalesEnd"
                />
              </Form.Item>
            </div>
            <div className={s.field}>
              <Typography type="small" className={cn(s.label)}>
                Event
              </Typography>
              <Form.Item
                name={['showDates', 'beforeOrAfterEvent']}
                initialValue="after"
                className={s.field}>
                <Select placeholder="Please select a value">
                  <Option value="after">After</Option>
                  <Option value="before">Before</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </div> */}
        <MultipleDateList
          data={multipleDates}
          setShowsDates={setShowsDates}
          setEditIndex={setEditIndex}
          form={form}
        />
      </div>
    </div>
  );
};

export default MultipleDates;
