import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Modal } from 'antd';
import { Button as AntButton } from 'antd';
import cn from 'classnames';
// import { useNavigate } from 'react-router-dom';
// import { getEditEventFormRoute } from 'routes/events/list';
import { useQuery, useSetQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Button, Icon, Layout, Typography, message } from 'ui';

import {
  createEventFormActiveEventSelector,
  createEventFormEditingEventIdSelector,
  isEditingEventSelector, // createEventFormEditingEventIdSelector,
} from 'selectors/eventsSlice.selector';
import {
  // useLazyGetEventDetailQuery,
  usePauseEventMutation,
  useRestartEventMutation,
} from 'services/events/eventsApiService';
import { setActiveStep, setIsEditingEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import AppNavigationEventInfo from 'containers/app-navigation/app-navigation-event-info';
import PublishEventModal from 'containers/publish-event-modal/publishEventModal';

import {
  CREATE_EVENT_FORM_STEPS_ENUM,
  SEATED_SHOW_NAVMENU_STEPS_ENUM,
  SHOW_NAVMENU_STEPS_ENUM,
  eventMenuSubarrayMap,
  seatedEventMenuSubarrayMap,
} from 'types/core/systemTypes';

import s from './CreateEventNavigationTemplate.module.scss';

interface CreateEventNavigationTemplateProps {
  handleLogout: () => void;
  sameString: (str: string) => boolean;
  handleNavigate: (path: string) => void;
  isOpenedSidebar: boolean;
  apiVersion?: { versionNumber: string };
}

const CreateEventNavigationTemplate: FC<CreateEventNavigationTemplateProps> = (props) => {
  const { handleLogout, isOpenedSidebar, apiVersion } = props;
  const [openedPublishEventModal, setOpenedPublishEventModal] = useState(false);
  const [publishEvent, { isLoading: isPublishLoading }] = useRestartEventMutation();
  const [pauseEvent, { isLoading }] = usePauseEventMutation();
  // const [getEventDetail, { data: activeEvent }] = useLazyGetEventDetailQuery();
  const activeEventId = useAppSelector(createEventFormEditingEventIdSelector);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<string>('');
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const isEditing = useAppSelector(isEditingEventSelector);
  const dispatch = useAppDispatch();
  const setQuery = useSetQuery();
  const query = useQuery();
  // const navigate = useNavigate();

  const stepsBeforeActive = useMemo(() => {
    let index = 0;
    index = Object.keys(CREATE_EVENT_FORM_STEPS_ENUM).indexOf(
      query.get('step') as CREATE_EVENT_FORM_STEPS_ENUM,
    );

    if (query.get('step') === 'SEATING_PLAN' || query.get('step') === 'DISABLE') {
      index = Object.keys(CREATE_EVENT_FORM_STEPS_ENUM).indexOf(
        CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST,
      );
    }
    return Object.keys(CREATE_EVENT_FORM_STEPS_ENUM).slice(0, index);
  }, [query]);

  const buttonSettings = {
    publish: ['draft', 'acceptedTerms'].includes(activeEvent?.basicInfo?.status || ''),
    pause: ['published', 'approved'].includes(activeEvent?.basicInfo?.status || ''),
    replay: ['paused'].includes(activeEvent?.basicInfo?.status || ''),
  };

  const handleChangeStep = useCallback(
    (step: string) => {
      if (activeEvent?.eventId) {
        if (isEditing) {
          setIsConfirmModalOpen(true);
          setCurrentStep(step);
        } else {
          setQuery(step);
          dispatch(setActiveStep(step as CREATE_EVENT_FORM_STEPS_ENUM));
        }
      }
    },
    [activeEvent?.eventId, isEditing, dispatch, setQuery],
  );

  // const goToSectionList = () => {
  //   if (activeEvent) {
  //     navigate(
  //       `${getEditEventFormRoute(activeEvent?.eventId)}?step=${
  //         CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST
  //       }`,
  //     );
  //   }
  // };

  const handlePauseEvent = useCallback(async () => {
    const res = await pauseEvent({ id: activeEvent?.eventId });

    if ('data' in res) {
      message.success('Event successfully paused!');
    }
  }, [activeEvent?.eventId, pauseEvent]);

  const handlePublishEvent = useCallback(async () => {
    const res = await publishEvent({
      id: activeEvent?.eventId,
    });

    if ('data' in res) {
      message.success('Event successfully restarted!');
    }
  }, [activeEvent?.eventId, publishEvent]);

  // useEffect(() => {
  //   if (activeEventId) {
  //     getEventDetail(activeEventId);
  //   }
  // }, [activeEventId, getEventDetail]);

  useEffect(() => {
    if (!query.get('step')) {
      setQuery(CREATE_EVENT_FORM_STEPS_ENUM.BASIC_INFO);
    }
  }, [query, setQuery]);

  return (
    <Layout.Sider opened={isOpenedSidebar} theme="light" type="setting">
      <div className={cn(s.wrapper, s.light)}>
        <AppNavigationEventInfo />
        <div className={s.logoLight}>
          <Icon name="logo" width={252} height={40} />
        </div>
        <div className={cn(s.content, s.setting)}>
          {Object.keys(
            activeEvent?.basicInfo?.eventType === 'seated' && activeEvent?.ticketTypes?.length > 0
              ? SEATED_SHOW_NAVMENU_STEPS_ENUM
              : SHOW_NAVMENU_STEPS_ENUM,
          ).map((key) => (
            <button
              key={key}
              className={cn(s.navLink, s.creation, {
                [s.active]:
                  key === query.get('step') ||
                  (key === CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST &&
                    query.get('step') === 'SEATING_PLAN') ||
                  (key === CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST &&
                    query.get('step') === 'DISABLE'),
                [s.linked]: activeEvent?.eventId,
                [s.permissions]: key === CREATE_EVENT_FORM_STEPS_ENUM.PERMISSIONS,
                ...(!activeEventId && { [s.filled]: stepsBeforeActive.includes(key) }),
              })}
              onClick={() => handleChangeStep(key)}>
              {(activeEvent?.basicInfo?.eventType === 'seated' &&
              activeEvent?.ticketTypes?.length > 0
                ? seatedEventMenuSubarrayMap[key]
                : eventMenuSubarrayMap[key]) &&
                (activeEvent?.basicInfo?.eventType === 'seated' &&
                activeEvent?.ticketTypes?.length > 0
                  ? seatedEventMenuSubarrayMap[key]
                  : eventMenuSubarrayMap[key]
                ).map((item, index) => (
                  <React.Fragment key={index}>
                    <div className={`${index > 0 ? s.secondItemClass : s.label}`}>{item}</div>
                  </React.Fragment>
                ))}
            </button>
          ))}
        </div>
        <div className={s.actionBlock}>
          <button onClick={handleLogout} className={s.actions}>
            <Typography type="main" weight={500} className={s.logout}>
              <Icon name="logout" />
              {isOpenedSidebar && <span className={s.text}>{t('common_log_out')}</span>}
            </Typography>
          </button>
          {buttonSettings.publish && (
            <Button
              fullWidth
              style={{ display: 'none' }}
              onClick={() => setOpenedPublishEventModal(true)}
              size="large">
              {t('common_publish_event')}
            </Button>
          )}
          {buttonSettings.pause && (
            <Button
              fullWidth
              style={{ display: 'none' }}
              onClick={handlePauseEvent}
              size="large"
              color="white"
              isLoading={isLoading}>
              <Icon name="pause" />
              {t('event_pause_event')}
            </Button>
          )}
          {buttonSettings.replay && (
            <Button
              style={{ display: 'none' }}
              fullWidth
              onClick={handlePublishEvent}
              size="large"
              loading={isPublishLoading}>
              <Icon name="play" />
              {t('common_publish_event')}
            </Button>
          )}

          <div className={s.version}>{`ShowsManager ${apiVersion?.versionNumber || 'v.1.0'}`}</div>
        </div>
      </div>
      <PublishEventModal
        open={openedPublishEventModal}
        handleClose={() => setOpenedPublishEventModal(false)}
      />
      <Modal
        centered
        closable={false}
        open={isConfirmModalOpen}
        zIndex={1000}
        footer={[
          <div
            key="div"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: 12,
              marginBottom: 16,
            }}>
            <AntButton
              key="ok"
              type="primary"
              size="large"
              onClick={() => {
                setQuery(currentStep);
                dispatch(setActiveStep(currentStep as CREATE_EVENT_FORM_STEPS_ENUM));
                setIsConfirmModalOpen(false);
                dispatch(setIsEditingEvent(false));
              }}>
              Move without saving
            </AntButton>

            <AntButton
              style={{ width: 120 }}
              key="back"
              size="large"
              type="link"
              onClick={() => setIsConfirmModalOpen(false)}>
              CANCEL
            </AntButton>
          </div>,
        ]}>
        <div style={{ padding: 32 }}>
          <Typography type="h3">You have some unsaved changes.</Typography>
          <Typography type="h6">Are you sure you wish to move away from this window?</Typography>
        </div>
      </Modal>
    </Layout.Sider>
  );
};

export default CreateEventNavigationTemplate;
