export const initialFilter = {
  PageNumber: 1,
  PageSize: 100,
  DateFrom: '',
  DateTo: '',
  EventId: '',
  filter: '',
};

export type ScanFilterType = {
  PageNumber?: number;
  PageSize?: number;
  EventId?: string;
  DateFrom: string;
  DateTo: string;
  filter?: string;
};
