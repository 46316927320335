import { FC, useEffect, useRef, useState } from 'react';

import SectionTitle from 'component/section-title/sectionTitle';
import { useNavigate } from 'react-router-dom';
import { CREATE_EVENT_FORM_ROUTE } from 'routes/events/list';
import { t } from 'tools/i18n';
// import { Typography } from 'ui';
import { Button, Icon } from 'ui';

import {
  useLazyGetEventMetricsQuery,
  useLazyGetEventOrdersQuery,
  useLazyGetEventTicketMetricsQuery,
} from 'services/events/eventsApiService';

// import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import DashboardBookingCapasityChart from 'pages/dashboard/dashboard-booking-capasity-chart';
import DashboardTicketOrdersTable from 'pages/dashboard/dashboard-ticket-orders-table';
import DashboardTicketSoldTable from 'pages/dashboard/dashboard-ticket-sold-table';
import DashboardTicketsRevenueCharts from 'pages/dashboard/dashboard-tickets-revenue-chart';
import DashboardTicketsTypeSoldChart from 'pages/dashboard/dashboard-tickets-type-sold-chart';

import EventPopover from 'containers/event-popover';

import { UserEventType } from 'types/core/userTypes';

import WelcomeModal from '../../component/welcome-modal';
import s from './Dashboard.module.scss';
import MemoizedDashboardDateTab from './dashboard-date-tab/dashboardDateTab';
import DashboardSummaryStats from './dashboard-summary-stats';
import { initialOrderFilter } from './dashboard.utils';

const Dashboard: FC = () => {
  const [activeEvent, setActiveEvent] = useState<UserEventType>();
  const [getTicketsSold, { data: ticketsSoldData }] = useLazyGetEventTicketMetricsQuery();
  const [getEventMetrics, { data: eventMetricData }] = useLazyGetEventMetricsQuery();
  const [getEventOrders, { data: orders }] = useLazyGetEventOrdersQuery();
  const [selectedSoldChartDays, setSelectedSoldChartDays] = useState(14);
  const navigate = useNavigate();
  const goToEventCreate = () => navigate(CREATE_EVENT_FORM_ROUTE);
  const eventRef = useRef<HTMLDivElement | null>(null);
  // const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();

  const handleSelectEvent = (event: UserEventType) => {
    setActiveEvent(event);
  };

  useEffect(() => {
    if (eventRef.current) {
      eventRef.current.scrollIntoView({
        block: 'end',
      });
    }
  }, []);

  useEffect(() => {
    const storedEvent = localStorage.getItem('currentEvent');
    if (storedEvent) {
      setActiveEvent(JSON.parse(storedEvent));
    }
  }, []);

  useEffect(() => {
    if (activeEvent?.eventId) {
      getTicketsSold({ EventId: activeEvent.eventId, NumberOfPastDays: selectedSoldChartDays });
      getEventMetrics(activeEvent.eventId);
      getEventOrders({ ...initialOrderFilter, id: activeEvent?.eventId });
    }
  }, [activeEvent, getTicketsSold, getEventMetrics, getEventOrders, selectedSoldChartDays]);

  return (
    <div className={s.wrapper}>
      <div className={s.row}>
        <div ref={eventRef} className={s.labelrow}>
          <SectionTitle label="common_reports" className={s.title} />
          {/* <div className={s.labelrow}>
            <Typography type="h2" color="grey" className={s.events}>
              {currentUser?.events?.length}
            </Typography>
            <Typography type="h2" color="grey" className={s.events}>
              events
            </Typography>
          </div> */}
        </div>
        <div className={s.eventRow}>
          <EventPopover handleEvent={handleSelectEvent} />
          <Button fullWidth className={s.createButton} onClick={goToEventCreate}>
            <Icon name="plus" className={s.buttonLabel} />
            {t('common_create_event')}
          </Button>
        </div>
      </div>
      {activeEvent?.eventId && (
        <MemoizedDashboardDateTab activeEvent={activeEvent!} eventId={activeEvent?.eventId} />
      )}
      <DashboardSummaryStats activeEvent={activeEvent} eventMetricData={eventMetricData} />

      <DashboardTicketsTypeSoldChart
        data={ticketsSoldData}
        soldToday={eventMetricData?.totalTicketsSold.today || 0}
        setDays={setSelectedSoldChartDays}
        selectedDays={selectedSoldChartDays}
      />

      <div className={s.group}>
        <DashboardTicketsRevenueCharts
          currency={activeEvent?.currency!}
          data={ticketsSoldData}
          todayValue={eventMetricData?.totalSales.today || 0}
          setDays={setSelectedSoldChartDays}
          selectedDays={selectedSoldChartDays}
        />

        <DashboardBookingCapasityChart
          data={eventMetricData?.ticketTypes}
          maximumNumberOfAttendees={eventMetricData?.maximumNumberOfAttendees!}
        />
      </div>

      <div className={s.block}>
        <DashboardTicketSoldTable
          currency={activeEvent?.currency}
          ticketTypes={eventMetricData?.ticketTypes}
        />
        <DashboardTicketOrdersTable currency={activeEvent?.currency} orders={orders?.orders} />
      </div>
      <div className={s.banner}>
        <div className={s.bannerBlock}>
          <Icon name="logo" width={252} height={40} />
          <h2 className={s.bannerTitle}>Download our app</h2>
          <h5 className={s.bannerText}>to scan tickets and follow your ticket sales</h5>
          <div className={s.bannerButtons}>
            <a href="https://apps.apple.com/mt/app/showsmanager/id1591231466" target="_blank">
              <img src="/assets/images/apple.webp" alt="app store" width={162} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.iu.showsmanager"
              target="_blank">
              <img src="/assets/images/google.webp" alt="google play" width={162} />
            </a>
          </div>
        </div>
      </div>
      <WelcomeModal />
    </div>
  );
};

export default Dashboard;
