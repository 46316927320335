import React, { useEffect, useRef, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select, Switch } from 'antd';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { getEditEventFormRoute } from 'routes/events/list';
import { useQuery } from 'tools/hooks';
import { Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import {
  useDisableSeatsMutation,
  useEnableSeatsMutation,
  useLazyGetDisabledSectionQuery,
  useLazyGetSctionsQuery,
  useLazyGetSeatingPlanDetailQuery,
} from 'services/events/eventsApiService';
import { useAppSelector } from 'store/index';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { DISABLE_TYPE, EventTicketType } from 'types/events/eventTypes';

import '../EventCreateFormTickets.module.scss';
import s from './seatingDisable.module.scss';

interface ControlledSVGElement {
  className: string;
  visibilityType: string;
  appliesToAdmin: boolean;
}

const SeatingDisable = () => {
  const navigate = useNavigate();
  const [getSeatingPlanDeatil, { data: seatingPlan }] = useLazyGetSeatingPlanDetailQuery();
  const [disableSeats, { isLoading: disabling }] = useDisableSeatsMutation();
  const [enableSeats, { isLoading: enabling }] = useEnableSeatsMutation();
  const [getSections, { data: sectionData }] = useLazyGetSctionsQuery();
  const query = useQuery();
  const sectionid = query.get('sectionid');
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [svgUrl, setSvgUrl] = useState<string>('');
  const [dates, setDates] = useState<{ showId: string; startDate: string }[]>([]);
  const [tickets, setTickets] = useState<EventTicketType[]>([]);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [dateShowId, setDateShowId] = useState('');
  const [disableType, setDisableType] = useState('Disable');
  const [comment, setComment] = useState('');
  const [getDisabledSections, { data: disabledSectionData }] = useLazyGetDisabledSectionQuery();
  const [selectedCircles, setSelectedCircles] = useState<
    Set<{ id: string; color: string; disableType: string }>
  >(new Set());
  const [disabledSeats, setDisabledSeats] = useState<
    Set<{ id: string; color: string; disableType: string }>
  >(new Set());
  const [selection, setSelection] = useState<{
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  } | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);

  const actionRef = useRef<HTMLDivElement | null>(null);
  const actionRefHeight = actionRef.current?.getBoundingClientRect().height;

  const rgbToHex = (color) => {
    if (color.startsWith('#')) {
      return color.toUpperCase();
    }

    const result = color.match(/\d+/g);
    if (result) {
      const r = parseInt(result[0], 10);
      const g = parseInt(result[1], 10);
      const b = parseInt(result[2], 10);
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    }
    return null;
  };

  useEffect(() => {
    if (activeEvent) {
      getSections(activeEvent.eventId);
    }
  }, [activeEvent, getSections]);

  useEffect(() => {
    if (dateShowId) {
      const params = {
        showId: dateShowId,
        sectionId: sectionid!,
      };

      getDisabledSections(params);
      setSelectedCircles(new Set());
    }
  }, [dateShowId, sectionid, getDisabledSections]);

  useEffect(() => {
    if (disabledSectionData) {
      disabledSectionData[0].disabledSeats.forEach((disabledSeat) => {
        const seatId = disabledSeat.seat.seatId;
        if (seatId) {
          setSelectedCircles((prev) => {
            const newSet = new Set(prev);
            newSet.add({
              id: seatId,
              color: disabledSeat.disableType === 'disabled' ? '#B1B1B1' : '#01474E',
              disableType: disableType,
            });
            return newSet;
          });
        }
      });
    }
  }, [disabledSectionData, disableType]);

  const getControlledSVGElements = (data, showId: string) => {
    const controlledSVGElements: ControlledSVGElement[] = [];

    data.forEach((event) => {
      event.shows.forEach((show) => {
        if (!showId || show.id === showId) {
          controlledSVGElements.push(...show.controlledSVGElements);
        }
      });
    });

    return controlledSVGElements;
  };

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = svgRef.current;
    const circles = svg.querySelectorAll('.seat');
    if (selectedCircles.size > 0) {
      circles.forEach((circle) => {
        const circleElement = circle as SVGElement;
        const circleId = circleElement.getAttribute('id');
        if (circleId) {
          const selectedCircle = Array.from(selectedCircles).find(
            (circle) => circle.id === circleId,
          );
          if (selectedCircle) {
            circleElement.style.fill = selectedCircle.color;
          }
        }
      });
    }

    // const elements = getControlledSVGElements(sectionData, dateShowId);
    // const filteredClassNames = elements
    //   .filter((element) => element.appliesToAdmin === false && element.visibilityType === 'hide')
    //   .map((element) => element.className);

    // const hideElements = svg.querySelectorAll('.stalls');
    // console.log(filteredClassNames);
    // hideElements!.forEach((element) => {
    //   console.log(element);
    //   const svgElement = element as SVGElement;
    //   svgElement.style.display = 'none';
    // });
  }, [selectedCircles, dateShowId, sectionData]);

  const handleCircleClick = (event) => {
    if (isDragEnabled || !tickets) return;
    event.stopPropagation();
    const circle = event.target;
    if (circle.classList.contains('seat')) {
      circle.style.fill = rgbToHex('#ff0000');
      const circleId = circle.getAttribute('id');
      if (circleId) {
        setSelectedCircles((prev) => {
          const newSet = new Set(prev);
          const existingCircle = Array.from(prev).find((circle) => circle.id === circleId);
          if (existingCircle) {
            newSet.delete(existingCircle);
          } else {
            newSet.add({
              id: circleId,
              color: disableType === 'Disable' ? '#B1B1B1' : '#01474E',
              disableType: disableType,
            });
          }
          return newSet;
        });
        setDisabledSeats((prev) => {
          const newSet = new Set(prev);
          const existingCircle = Array.from(prev).find((circle) => circle.id === circleId);
          if (existingCircle) {
            newSet.delete(existingCircle);
          } else {
            newSet.add({
              id: circleId,
              color: disableType === 'Disable' ? '#B1B1B1' : '#01474E',
              disableType: disableType,
            });
          }
          return newSet;
        });
      }
    }
  };

  useEffect(() => {
    if (activeEvent) {
      getSeatingPlanDeatil(activeEvent?.basicInfo.seatingPlanId as string);
      const tempDates =
        activeEvent.basicInfo.showDates?.map((date) => {
          return {
            showId: date.showId as string,
            startDate: date.startDate,
          };
        }) || [];

      if (tempDates) {
        setDates(tempDates);
      }

      const tempTickets = activeEvent.ticketTypes.map((item) => item);
      if (tempTickets) {
        setTickets(tempTickets);
      }
    }
  }, [getSeatingPlanDeatil, activeEvent]);

  useEffect(() => {
    if (dates) {
      setDateShowId(dates[0]?.showId);
    }
  }, [dates]);

  useEffect(() => {
    if (seatingPlan) {
      const currentSection = seatingPlan[0].sections.find(
        (item: { id: string | null }) => item.id === sectionid,
      );
      setSvgUrl(currentSection.svg);
    }
  }, [seatingPlan, sectionid]);

  const handleSave = () => {
    const originalDisableArray = Array.from(selectedCircles);
    const disabledSeatsArray = Array.from(disabledSeats);
    const newDisableSeats = disabledSeatsArray.filter((seat) =>
      originalDisableArray.some((originalSeat) => originalSeat.id === seat.id),
    );

    const newEnableSeats = disabledSeatsArray.filter(
      (seat) => !originalDisableArray.some((originalSeat) => originalSeat.id === seat.id),
    );

    const disablingData = Array.from(newDisableSeats).map((seat) => {
      return {
        seatId: seat.id,
      };
    });

    const enablingData = Array.from(newEnableSeats).map((seat) => {
      return {
        seatId: seat.id,
      };
    });

    const disableResult = {
      showId: dateShowId!,
      sectionId: sectionid!,
      seats: disablingData,
      disableType: disableType as DISABLE_TYPE,
      comments: comment,
    };

    const enableResult = {
      showId: dateShowId!,
      sectionId: sectionid!,
      seats: enablingData,
    };
    if (disablingData.length > 0) {
      disableSeats(disableResult);
    }
    if (enablingData.length > 0) {
      enableSeats(enableResult);
    }
  };

  const handleAllSelectChange = (e) => {
    if (!e.target.checked) {
      setSelectedCircles(new Set());
      return; // Exit early to avoid unnecessary processing
    }

    const svg = svgRef.current;
    const circles = svg?.querySelectorAll('.seat');
    if (circles) {
      circles.forEach((circle) => {
        const circleId = circle.getAttribute('id');
        if (circleId) {
          setSelectedCircles((prev) => {
            const newSet = new Set(prev);
            newSet.add({
              id: circleId,
              color: disableType === 'Disable' ? '#B1B1B1' : '#01474E',
              disableType: disableType,
            });
            return newSet;
          });
        }
      });
    }
  };

  const getScrollOffsets = (element) => {
    let offsetX = 0;
    let offsetY = 0;
    while (element) {
      offsetX += element.scrollLeft;
      offsetY += element.scrollTop;
      element = element.parentElement;
    }
    return { offsetX, offsetY };
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (!isDragEnabled || !svgRef.current || !actionRefHeight) return;

    const svgRect = svgRef.current.getBoundingClientRect();
    const { offsetX, offsetY } = getScrollOffsets(svgRef.current);
    if (actionRef.current) {
      setSelection({
        x1: event.clientX - svgRect.left + 439 - offsetX,
        y1: event.clientY - svgRect.top + 46 + actionRefHeight - offsetY,
        x2: event.clientX - svgRect.left + 439 - offsetX,
        y2: event.clientY - svgRect.top + 46 + actionRefHeight - offsetY,
      });
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragEnabled || !selection || !svgRef.current || !actionRefHeight) return;

    const svgRect = svgRef.current.getBoundingClientRect();
    const { offsetX, offsetY } = getScrollOffsets(svgRef.current);
    setSelection({
      ...selection,
      x2: event.clientX - svgRect.left + 439 - offsetX,
      y2: event.clientY - svgRect.top + 46 + actionRefHeight - offsetY,
    });
  };

  const handleMouseUp = () => {
    if (!isDragEnabled || !selection || !svgRef.current || !actionRefHeight || !tickets) return;

    const { x1, y1, x2, y2 } = selection;
    const rectX = Math.min(x1 - 439, x2 - 439);
    const rectY = Math.min(y1 - 46 - actionRefHeight, y2 - 46 - actionRefHeight);
    const rectWidth = Math.abs(x2 - x1);
    const rectHeight = Math.abs(y2 - y1);

    const svg = svgRef.current;
    const svgRect = svg.getBoundingClientRect();
    const { offsetX, offsetY } = getScrollOffsets(svgRef.current);
    const circles = svg.querySelectorAll('.seat');
    const viewBox = svg.getAttribute('viewBox');
    let Swidth, Sheight;
    if (viewBox) {
      const [, , width, height] = viewBox.split(' ').map(Number);
      Swidth = width;
      Sheight = height;
    }
    circles.forEach((circle) => {
      const cx = parseFloat(circle.getAttribute('cx')!);
      const cy = parseFloat(circle.getAttribute('cy')!);
      if (
        (cx * svgRect.width) / Swidth >= rectX - offsetX &&
        (cx * svgRect.width) / Swidth <= rectX + rectWidth - offsetX &&
        (cy * svgRect.height) / Sheight >= rectY + offsetY &&
        (cy * svgRect.height) / Sheight <= rectY + rectHeight + offsetY
      ) {
        const circleId = circle.getAttribute('id');
        if (circleId) {
          setSelectedCircles((prev) => {
            const newSet = new Set(prev);
            const existingCircle = Array.from(prev).find((circle) => circle.id === circleId);
            if (existingCircle) {
              newSet.delete(existingCircle);
            } else {
              newSet.add({
                id: circleId,
                color: disableType === 'Disable' ? '#B1B1B1' : '#01474E',
                disableType: disableType,
              });
            }
            return newSet;
          });
          setDisabledSeats((prev) => {
            const newSet = new Set(prev);
            const existingCircle = Array.from(prev).find((circle) => circle.id === circleId);
            if (existingCircle) {
              newSet.delete(existingCircle);
            } else {
              newSet.add({
                id: circleId,
                color: disableType === 'Disable' ? '#B1B1B1' : '#01474E',
                disableType: disableType,
              });
            }
            return newSet;
          });
        }
      }
    });

    setSelection(null);
  };

  return (
    <>
      <div className={s.wrapper}>
        <div className={s.actionGroup} ref={actionRef}>
          <Button
            icon={<ArrowLeftOutlined />}
            className={s.backButton}
            onClick={() =>
              navigate(
                `${getEditEventFormRoute(activeEvent?.eventId!)}?step=${
                  CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST
                }`,
              )
            }>
            Sections
          </Button>
          {seatingPlan && (
            <div className={s.title}>
              <Typography type="h2">Seating Plan -</Typography>
              <Typography type="h2">{seatingPlan[0].name}</Typography>
            </div>
          )}
          <div className={s.dates}>
            <Typography type="h5">Select the dates you wish to apply tickets to</Typography>
            <Select
              style={{ width: '50%', marginTop: 16, marginBottom: 36 }}
              onChange={(value) => setDateShowId(value)}
              options={dates?.map((item) => ({
                label: item.startDate,
                value: item.showId,
              }))}
              value={dateShowId}
              defaultValue=""
            />
          </div>

          <div className={s.dates}>
            <Typography type="h5">Operation Type </Typography>
            <Typography type="main">
              (choose from 2 types, Disable which will leave the seat on the plan for users to see
              but cannot be chosen, Hide which will completely remove the seat from the plan.)
            </Typography>
            <Select
              style={{ width: '50%', marginTop: 16, marginBottom: 36 }}
              onChange={(value) => setDisableType(value)}
              options={[
                { value: 'Disable', label: <span>Disable</span> },
                { value: 'Hide', label: <span>Hide</span> },
              ]}
              value={disableType}
              defaultValue=""
            />
          </div>
          <div className={s.dates}>
            <Typography type="h5">Comments</Typography>
            <Input
              style={{ marginTop: 8, marginBottom: 36, height: 36 }}
              onChange={(e) => setComment(e.target.value)}
              placeholder="When disabling a seat you can type any message you wish for future reference."
            />
          </div>

          <div style={{ width: '100%' }}>
            <Button
              type="primary"
              loading={disabling || enabling}
              onClick={handleSave}
              style={{ width: '100%', marginBottom: 32 }}>
              {disableType === 'Disable' ? 'DISABLE/ENABLE' : 'HIDE/SHOW'}
            </Button>
          </div>
          <div className={s.controlOptions}>
            <Checkbox onChange={handleAllSelectChange} style={{ marginBottom: '10px' }}>
              Select All
            </Checkbox>
            <div style={{ display: 'flex', gap: 8, width: 160, justifyContent: 'space-between' }}>
              <Switch
                checked={isDragEnabled}
                onChange={(checked) => setIsDragEnabled(checked)}
                style={{ marginBottom: '10px', height: 16, color: 'white' }}
              />
              <Typography type="main">
                {isDragEnabled ? 'Disable Dragging' : 'Enable Dragging'}
              </Typography>
            </div>
          </div>
        </div>
        <div style={{ width: '70%', overflowX: 'scroll' }}>
          {svgUrl && (
            <ReactSVG
              id="svg"
              key={dateShowId}
              style={{ width: '100%' }}
              src={svgUrl}
              loading={() => <Spin />}
              afterInjection={(svg) => {
                svgRef.current = svg;
                const circles = svg.querySelectorAll('.seat');
                circles.forEach((circle) => {
                  circle.addEventListener('click', handleCircleClick);
                });
                svg.addEventListener('click', handleCircleClick);
                svg.addEventListener('mousedown', handleMouseDown);
                svg.addEventListener('mousemove', handleMouseMove);
                svg.addEventListener('mouseup', handleMouseUp);
              }}
              beforeInjection={(svg) => {
                // Apply styles before the SVG is injected
                svgRef.current = svg;
                const circles = svg.querySelectorAll('.seat');
                circles.forEach((circle) => {
                  const circleElement = circle as SVGElement;
                  const circleId = circleElement.getAttribute('id');
                  if (circleId) {
                    const selectedCircle = Array.from(selectedCircles).find(
                      (circle) => circle.id === circleId,
                    );
                    if (selectedCircle) {
                      circleElement.style.fill = selectedCircle.color;
                    }
                  }

                  const elements = getControlledSVGElements(sectionData, dateShowId);
                  const filteredClassNames = elements
                    .filter(
                      (element) =>
                        element.appliesToAdmin === false && element.visibilityType === 'hide',
                    )
                    .map((element) => element.className);
                  filteredClassNames.forEach((item) => {
                    const hideElements = svg.querySelectorAll(`.${item}`);
                    hideElements!.forEach((element) => {
                      const svgElement = element as SVGElement;
                      svgElement.style.display = 'none';
                    });
                  });
                });
              }}
            />
          )}
        </div>
        {selection && (
          <div
            style={{
              position: 'absolute',
              left: Math.min(selection.x1, selection.x2),
              top: Math.min(selection.y1, selection.y2),
              width: Math.abs(selection.x2 - selection.x1),
              height: Math.abs(selection.y2 - selection.y1),
              border: '1px solid blue',
              pointerEvents: 'none',
            }}
          />
        )}
      </div>
    </>
  );
};

export default SeatingDisable;
