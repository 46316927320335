import { FC, PropsWithChildren } from 'react';

import { Form as FormAntd, FormItemProps as FormItemPropsAntd } from 'antd';
import cn from 'classnames';

import s from './FormItem.module.scss';

interface IFormItemProps extends FormItemPropsAntd {
  className?: string;
  fullWidth?: boolean;
}

export type FormItemProps = PropsWithChildren<IFormItemProps>;

const FormItem: FC<FormItemProps> = (props) => {
  const { className = '', fullWidth, ...otherProps } = props;
  return (
    <FormAntd.Item
      className={cn(s.wrap, { [className]: className, [s.fullWidth]: fullWidth })}
      {...otherProps}
    />
  );
};

export default FormItem;
