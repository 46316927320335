import { RootState } from 'store';

import { PromoterFullInfoType } from '../../types/promoters/promoterTypes';
import { USER_SETTING_STEPS_ENUM } from 'types/core/systemTypes';

export const systemSliceSelector = (state: RootState) => state.systemSlice;

export const isOpenedSiderSelector = (state: RootState): boolean =>
  systemSliceSelector(state).openedSider;

export const userSettingStepSelector = (state: RootState): USER_SETTING_STEPS_ENUM =>
  systemSliceSelector(state).userSettingStep;

export const activePromoterSelector = (state: RootState): PromoterFullInfoType | undefined =>
  systemSliceSelector(state).activePromoter;

export const editingPromoterSelector = (state: RootState): boolean =>
  systemSliceSelector(state).editingPromoter;
