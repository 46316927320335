import { FC, ReactElement, useEffect, useMemo } from 'react';

import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getPromoterEditRoute } from 'routes/user-management/list';
import { t } from 'tools/i18n';
import { Icon, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useLazyGetPromoterInfoQuery } from 'services/promoter/promoterApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { useAppSelector } from 'store/index';

import s from './BasicInfoPromoter.module.scss';

const EventCreateFormBasicInfoPromoter: FC = () => {
  const navigate = useNavigate();
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [getPromoterFullInfo, { data: promoterData }] = useLazyGetPromoterInfoQuery();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);

  const renderUserLogo = useMemo((): ReactElement => {
    if (promoterData) {
      return promoterData?.logo ? (
        <img className={s.logo} src={promoterData?.logo} alt={promoterData?.name} />
      ) : (
        <div className={s.logoTextWrapper}>
          <div className={s.logoText}>{promoterData?.name[0]}</div>
        </div>
      );
    } else {
      return currentUser?.promoters[0]?.logo ? (
        <img
          className={s.logo}
          src={currentUser?.promoters[0]?.logo}
          alt={currentUser?.promoters[0]?.name}
        />
      ) : (
        <div className={s.logoTextWrapper}>
          <div className={s.logoText}>{currentUser?.firstName[0]}</div>
        </div>
      );
    }
  }, [promoterData, currentUser]);

  const handleEditPromoter = (e, promoterId) => {
    e.stopPropagation();
    e.preventDefault();

    navigate(getPromoterEditRoute(promoterId));
  };

  useEffect(() => {
    if (activeEvent?.basicInfo?.promoterId) {
      getPromoterFullInfo(activeEvent?.basicInfo?.promoterId);
    }
  }, [activeEvent, getPromoterFullInfo]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <div className={s.wrapper}>
      <div className={s.text}>
        <div>
          <Icon name="warning" />
        </div>
        <Trans i18nKey={t('event_create_form_promoter_hint')} components={{ b: <b /> }} />
      </div>
      {(!!currentUser?.promoters?.length || promoterData) && (
        <div className={s.promoterContent}>
          <div className={s.popoverWrapper}>
            {renderUserLogo}
            <div>
              <Typography type="main" className={s.name}>
                {promoterData?.name || currentUser?.promoters[0]?.name}
              </Typography>
              <div className={s.email}>
                {promoterData?.email || currentUser?.promoters[0]?.email}
              </div>
              {promoterData?.canEdit && (
                <button
                  className={s.action}
                  onClick={(e) => handleEditPromoter(e, activeEvent?.basicInfo?.promoterId)}
                  type="button">
                  edit
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCreateFormBasicInfoPromoter;
