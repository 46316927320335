import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Icon, Modal, Typography } from 'ui';

import s from './WarningModal.module.scss';

interface WelcomeModalProps {
  // Define your component props here
}

const WarningModal: FC<WelcomeModalProps> = () => {
  const [open, setOpen] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate('/my-events');
  };

  const goToSettings = () => {
    navigate('/organiser-details/create');
  };

  return (
    <Modal handleClose={handleClose} open={open} footer={false}>
      <Icon className={s.iconWrapper} name="edit" />
      <Modal.Header>
        <Typography type="h3">Please fill out the organizer information</Typography>
      </Modal.Header>
      <Modal.Content>
        <div className={s.wrapper}>
          <Typography type="main" className={s.text}>
            To create an event you need information about the promoter, as it will be specified in
            the event tickets, please fill in the information. Click &apos;Fill out&apos; to fill in
            your organiser details before creating your first event.
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 16,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}>
          <Button style={{ paddingLeft: 40, paddingRight: 40 }} onClick={goToSettings}>
            FILL OUT
          </Button>
          <Button style={{ paddingLeft: 40, paddingRight: 40 }} color="ghost" onClick={handleClose}>
            Close
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default WarningModal;
