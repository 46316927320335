import { useEffect, useState } from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Form, Modal, Select } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetQuery } from 'tools/hooks';
import { Button } from 'ui';
// import { getOrdersFullRoute } from 'routes/tikets/list';
import { Label, Table, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import {
  useApplyTicketTypesMutation,
  useDisableSectionMutation,
  useEnableSectionMutation,
  useLazyGetAppliedTicketTypesQuery,
  useLazyGetSctionsQuery,
} from 'services/events/eventsApiService';
import { useAppSelector } from 'store/index';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { SectionListTableType } from 'types/tickets/tickets.types';

import s from './sectionList.module.scss';

// import { TicketType } from 'types/tickets/tickets.types';

enum DISABLE_TYPE_ENUM {
  DISABLE = 'disable',
  HIDE = 'hide',
}

const SectionList = () => {
  // const dispatch = useAppDispatch();
  const setQuery = useSetQuery();
  const [getSections, { data: sectionData }] = useLazyGetSctionsQuery();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [applyTicketTypes, { isLoading }] = useApplyTicketTypesMutation();
  const [disableSection, { isLoading: disabling }] = useDisableSectionMutation();
  const [enableSection, { isLoading: enabling }] = useEnableSectionMutation();
  const [getAppliedTicketTypes] = useLazyGetAppliedTicketTypesQuery();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isDisableModalOpen, setIsDisableModalOpen] = useState<boolean>(false);
  const [dates, setDates] = useState<{ showId: string; startDate: string }[]>([]);
  const [dateShowId, setDateShowId] = useState('');
  const [disableSectionId, setDisableSectionId] = useState<string>('');
  const [disableType, setDisableType] = useState<DISABLE_TYPE_ENUM>(DISABLE_TYPE_ENUM.DISABLE);
  const [disabledType, setDisabledType] = useState<string>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [fetchedData, setFetchedData] = useState<{ [key: string]: any }>({});
  const [form] = Form.useForm();
  const [svgKey, setSvgKey] = useState(0);

  useEffect(() => {
    if (activeEvent) {
      getSections(activeEvent.eventId);
      const tempDates =
        activeEvent.basicInfo.showDates?.map((date) => {
          return {
            showId: date.showId as string,
            startDate: date.startDate,
          };
        }) || [];
      if (tempDates && isDisableModalOpen) {
        setDates(tempDates);
        setDateShowId(tempDates[0].showId);
        form.setFieldsValue({ show: tempDates[0].showId });
      }
    }
  }, [activeEvent, getSections, form, isDisableModalOpen]);

  const GoToNextStep = () => {
    setQuery(CREATE_EVENT_FORM_STEPS_ENUM.TAXES);
  };

  const handleStandingTickets = (value: any, id: string) => {
    const data = {
      eventId: activeEvent?.eventId! as string,
      sectionId: id as string,
      ticketTypeId: value,
      showId: '',
      seats: null,
    };
    applyTicketTypes(data);
  };

  const handleSave = () => {
    const data = { showId: dateShowId, sectionId: disableSectionId, disableType: disableType };
    disableSection(data).then(() => {
      setIsConfirmModalOpen(false);
      setIsDisableModalOpen(false);
      setDisableType(DISABLE_TYPE_ENUM.DISABLE);
      getSections(activeEvent?.eventId!);
    });
  };

  const handleEnable = () => {
    const data = { showId: dateShowId, sectionId: disableSectionId };
    enableSection(data).then(() => {
      setIsConfirmModalOpen(false);
      setIsDisableModalOpen(false);
      setDisableType(DISABLE_TYPE_ENUM.DISABLE);
      getSections(activeEvent?.eventId!);
    });
  };

  const handleOpenConfirmModal = () => {
    form
      .validateFields()
      .then((values) => {
        setDateShowId(values.show);
        setIsConfirmModalOpen(true);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const checkIfGUIDExistsInDisabledSections = (
    guid: string,
    data: SectionListTableType,
    showId: string,
  ) => {
    for (const show of data[0].shows) {
      if (show.id === showId.toLowerCase()) {
        for (const disabledSection of show.disabledSections) {
          if (disabledSection.id === guid) {
            return true;
          }
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (sectionData && disableSectionId) {
      for (const show of sectionData![0]!.shows) {
        if (show.id === dateShowId.toLowerCase()) {
          for (const disabledSection of show.disabledSections) {
            if (disabledSection.id === disableSectionId) {
              setDisabledType(disabledSection.disabledType);
            }
          }
        }
      }
    }
  }, [dateShowId, sectionData, disableSectionId]);

  useEffect(() => {
    if (activeEvent && sectionData) {
      const fetchTicketTypes = async () => {
        const promises = sectionData[0].sections.map((item) => {
          if (item.sectionType === 'standing') {
            const params = {
              eventId: activeEvent.eventId,
              sectionId: item.id,
            };
            return getAppliedTicketTypes(params)
              .unwrap()
              .then((data) => {
                return {
                  id: item.id,
                  ticketTypeId: data[0]?.ticketType?.id || null,
                };
              });
          }
        });

        const results = await Promise.all(promises);
        const newData = {};
        results.forEach((result) => {
          if (result) {
            newData[result.id] = result.ticketTypeId;
          }
        });
        setFetchedData(newData);
        setSvgKey((prevKey) => prevKey + 1);
      };

      fetchTicketTypes();
    }
  }, [activeEvent, sectionData, getAppliedTicketTypes]);
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      render: (eventName) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          {/* <img
            src={record.flyer}
            alt="Event flyer"
            style={{ width: 48, height: 48, borderRadius: '100%' }}
          /> */}
          <span>{eventName}</span>
        </div>
      ),
    },
    {
      title: 'SEATED/STANDING',
      dataIndex: 'sectionType',
      key: 'sectionType',
    },
    {
      title: 'TICKET TYPE',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => {
        const currentItem = sectionData![0].sections.find((item) => item.id === id);
        const itemFetchedData = fetchedData[id] || {}; // Ensure fetchedData[id] is defined
        return currentItem.sectionType === 'seated' ? (
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px', height: 38 }}>
            <button
              onClick={() =>
                navigate(
                  `${pathname}?step=${'SEATING_PLAN'}&sectionid=${id}&eventid=${
                    activeEvent?.eventId
                  }`,
                )
              }
              style={{
                border: 'none',
                backgroundColor: 'white',
                color: '#187DFD',
                cursor: 'pointer',
                zIndex: 30,
              }}>
              Choose ticket types
            </button>
            <button
              onClick={() => navigate(`${pathname}?step=${'DISABLE'}&sectionid=${id}`)}
              style={{
                border: 'none',
                backgroundColor: 'white',
                color: '#187DFD',
                cursor: 'pointer',
                zIndex: 30,
              }}>
              Disable/Enable seats
            </button>
          </div>
        ) : (
          <div>
            {itemFetchedData && (
              <Select
                style={{ width: '50%' }}
                disabled={isLoading}
                onChange={(value) => handleStandingTickets(value, currentItem.id)}
                options={activeEvent?.ticketTypes?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                defaultValue={itemFetchedData}
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'DISABLE/ENABLE',
      dataIndex: 'id',
      key: 'value',
      render: (id) => (
        <button
          onClick={() => {
            setIsDisableModalOpen(true);
            setDisableSectionId(id);
          }}
          style={{
            border: 'none',
            backgroundColor: 'white',
            color: '#187DFD',
            cursor: 'pointer',
            zIndex: 30,
          }}>
          Disable/Enable section
        </button>
      ),
    },
  ];
  return (
    <div className={s.wrapper}>
      {disableSectionId && dateShowId && (
        <Modal
          centered
          closable={false}
          open={isConfirmModalOpen}
          zIndex={1000}
          footer={[
            <Button key="back" onClick={() => setIsConfirmModalOpen(false)}>
              CANCEL
            </Button>,
            <>
              {checkIfGUIDExistsInDisabledSections(disableSectionId, sectionData!, dateShowId!) ? (
                <Button key="submit" type="primary" loading={enabling} onClick={handleEnable}>
                  ENABLE
                </Button>
              ) : (
                <Button key="submit" type="primary" loading={disabling} onClick={handleSave}>
                  {disableType === DISABLE_TYPE_ENUM.DISABLE ? 'DISABLE' : 'HIDE'}
                </Button>
              )}
            </>,
          ]}>
          <div style={{ marginBottom: 16 }}>
            <Typography type="h4">
              Are you sure you want to{' '}
              {dateShowId && (
                <>
                  {checkIfGUIDExistsInDisabledSections(disableSectionId, sectionData!, dateShowId!)
                    ? 'ENABLE'
                    : disableType === DISABLE_TYPE_ENUM.DISABLE
                    ? 'DISABLE'
                    : 'HIDE'}
                </>
              )}{' '}
              this section?
            </Typography>
          </div>
        </Modal>
      )}
      {disableSectionId && dateShowId && (
        <Modal
          centered
          closable={false}
          open={isDisableModalOpen}
          zIndex={40}
          footer={[
            <div key="batchAction" className={s.batchModalButton}>
              <Button
                key="back"
                type="text"
                style={{ width: '100%' }}
                onClick={() => {
                  setIsDisableModalOpen(false);
                  setDisableSectionId('');
                }}>
                Cancel
              </Button>
              <Button
                key="submit"
                style={{ width: '100%' }}
                type="primary"
                onClick={handleOpenConfirmModal}>
                {dateShowId && disableType && (
                  <>
                    {checkIfGUIDExistsInDisabledSections(
                      disableSectionId,
                      sectionData!,
                      dateShowId!,
                    )
                      ? 'ENABLE'
                      : disableType === DISABLE_TYPE_ENUM.DISABLE
                      ? 'DISABLE'
                      : 'HIDE'}
                  </>
                )}
              </Button>
            </div>,
          ]}>
          <Typography type="h3">
            Disable/Enable section<br></br>{' '}
            {sectionData![0].sections?.find((item) => item.id === disableSectionId)?.name}
          </Typography>
          <Form form={form} layout="vertical">
            <Form.Item
              style={{ marginTop: 16 }}
              name="show"
              rules={[
                {
                  required: true,
                  message: 'Select show.',
                },
              ]}>
              <Label>Show</Label>
              <Select
                style={{ width: '100%', marginBottom: 4 }}
                onChange={(value) => {
                  setDateShowId(value);
                  form.setFieldsValue({ show: value });
                }}
                options={dates?.map((item) => ({
                  label: item.startDate,
                  value: item.showId,
                }))}
                defaultValue={dates[0].showId}
              />
            </Form.Item>
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <Typography type="h4">Enabled/Disabled</Typography>
              <p style={{ marginTop: 8, marginBottom: 8 }}>
                This sections is currently{' '}
                <strong>
                  {!checkIfGUIDExistsInDisabledSections(disableSectionId, sectionData!, dateShowId!)
                    ? 'ENABLED'
                    : disabledType === DISABLE_TYPE_ENUM.DISABLE
                    ? 'DISABLED'
                    : 'HIDDEN'}
                </strong>{' '}
                and people can buy tickets in this section.
              </p>
            </div>
            {checkIfGUIDExistsInDisabledSections(disableSectionId, sectionData!, dateShowId!) ===
              false && (
              <Form.Item name="disableType">
                <Label>Disable Type</Label>
                <Select
                  style={{ width: '100%', marginBottom: 4 }}
                  options={[
                    {
                      label: 'Disable',
                      value: DISABLE_TYPE_ENUM.DISABLE,
                    },
                    {
                      label: 'Hide',
                      value: DISABLE_TYPE_ENUM.HIDE,
                    },
                  ]}
                  onChange={(value) => setDisableType(value)}
                  defaultValue={DISABLE_TYPE_ENUM.DISABLE}
                />
              </Form.Item>
            )}
          </Form>
        </Modal>
      )}
      <div className={s.header}>
        <Typography type="h2">Manage Seats</Typography>
      </div>
      <div className={s.title}>
        <Typography type="main">Seating plan title:</Typography>
        {sectionData && <Typography type="h4">{sectionData![0].name}</Typography>}
      </div>
      {sectionData ? (
        <Table
          key={svgKey}
          dataSource={sectionData![0].sections}
          columns={columns}
          pagination={false}
          rowKey="sectionId"
          scroll={{ x: 768 }}
        />
      ) : (
        <LoadingOutlined style={{ fontSize: 36 }} />
      )}
      <div className={s.Next}>
        <div style={{ flex: 1 }}></div>
        <div className={s.buttonWrapper}>
          <Button htmlType="submit" size="large" block onClick={GoToNextStep}>
            <Typography type="main" className={s.button}>
              Next
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SectionList;
