import React, { FC, useCallback, useEffect, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import DownloadSwitcher from 'component/download-switcher';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { Breadcrumbs, Modal, Table, Typography } from 'ui';

import {
  useLazyExportZreadingQuery,
  useLazyGetZreadingQuery,
} from 'services/events/eventsApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import Pagination from '../../../component/pagination';
import SectionTitle from '../../../component/section-title/sectionTitle';
import s from './EventZreadingList.module.scss';

const initialFilter = {
  PageNumber: 1,
  PageSize: 25,
};

const EventZreadingList: FC = () => {
  const { eventId } = useParams();
  const [requestFilter, setRequestFilter] = useState(initialFilter);
  const [exportZreading] = useLazyExportZreadingQuery();
  const [getZreadingList, { data: zReadingList }] = useLazyGetZreadingQuery();
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const currentEvent = currentUser?.events?.find((event) => event.eventId === eventId)?.eventName;
  const [isDownLoading, setIsDownLoading] = useState<boolean>();

  // const [isPDF, setIsPDF] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const BREADCRUMBS_ROUTES = [
    { name: 'My Events', path: MY_EVENTS_ROUTE },
    { name: `Financial Report: ${currentEvent || ''}` },
  ];

  const handleDownloadCsv = useCallback(() => {
    setIsDownLoading(true);
    if (eventId) {
      exportZreading({ id: eventId }).then((data) => {
        if (data.data) {
          const link = document.createElement('a');
          link.href = data.data?.fileName;
          link.setAttribute('download', `attendees-${eventId}.csv`);
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setOpen(false);
          setIsDownLoading(false);
        }
      });
    }
  }, [exportZreading, eventId]);

  const columns = [
    {
      title: 'Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
    },
    {
      title: 'Transaction date',
      dataIndex: 'transactionLocalDate',
      key: 'transactionLocalDate',
      render: (transactionLocalDate: string) => (
        <div>{dayjs(transactionLocalDate).format('DD.MM.YYYY HH:mm')}</div>
      ),
    },
    {
      title: 'Event Name',
      dataIndex: 'eventName',
      key: 'eventName',
    },
    {
      title: 'Show Date',
      dataIndex: 'show',
      key: 'showDate',
      render: (show: { date: string; time: string }) => (
        <div>
          {dayjs(show.date).format('DD.MM.YYYY')} {dayjs(show.time).format('HH:mm')}
        </div>
      ),
    },
    {
      title: 'Order Date',
      dataIndex: 'order',
      key: 'orderDate',
      render: (order: { orderDate: string }) => (
        <div>{dayjs(order.orderDate).format('DD.MM.YYYY HH:mm')}</div>
      ),
    },
    {
      title: 'Order No',
      dataIndex: 'order',
      key: 'orderNo',
      render: (order: { orderNo: string }) => <div>{order.orderNo}</div>,
    },
    {
      title: 'Payment Type',
      dataIndex: 'order',
      key: 'orderPaymentType',
      render: (order: { paymentType: string }) => <div>{order.paymentType}</div>,
    },
    {
      title: 'Cash Type',
      dataIndex: 'cashPaymentType',
      key: 'cashPaymentType',
    },
    {
      title: 'Email',
      dataIndex: 'order',
      key: 'orderUserEmail',
      render: (order: { user: { email: string } }) => <div>{order.user.email}</div>,
    },
    {
      title: 'Name on ticket',
      dataIndex: 'ticketHolderPersonalInfo',
      key: 'ticketHolderPersonalInfo',
      render: (holderInfo: { firstName: string; lastName: string }) => (
        <div>{`${holderInfo.firstName} ${holderInfo.lastName}`}</div>
      ),
    },
    {
      title: 'Runner (if any)',
      dataIndex: 'runnerEmail',
      key: 'runnerEmail',
    },
    {
      title: 'Outlet (if any)',
      dataIndex: 'outlet',
      key: 'outlet',
    },
    {
      title: 'Receipt Number',
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
    },
    {
      title: 'Ticket Type',
      dataIndex: 'ticketType',
      key: 'ticketType',
      render: (ticketType: { name: string }) => <div>{ticketType.name}</div>,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'NET',
      dataIndex: 'total',
      key: 'total',
      render: (total: number, cell: { vatAmount: number }) => <div>{total - cell.vatAmount}</div>,
    },
    {
      title: 'VAT Amount',
      dataIndex: 'vatAmount',
      key: 'vatAmount',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Booking Fee',
      dataIndex: 'bookingFee',
      key: 'bookingFee',
    },
    {
      title: 'Refund Protection',
      dataIndex: 'refundProtectionTotal',
      key: 'refundProtectionTotal',
    },
    {
      title: 'All Total',
      dataIndex: 'allTotal',
      key: 'allTotal',
    },
    {
      title: 'Account',
      dataIndex: 'bankAccountName',
      key: 'bankAccountName',
    },
    {
      title: 'Payment Gateway',
      dataIndex: 'gatewayName',
      key: 'gatewayName',
    },
  ];

  const showedElements =
    Number(zReadingList?.transactions?.length) +
    (Number(zReadingList?.pageNumber) - 1) * Number(requestFilter.PageSize);

  useEffect(() => {
    if (eventId) {
      getZreadingList({ id: eventId, ...requestFilter });
    }
  }, [eventId, getZreadingList, requestFilter]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.block}>
          <div>
            <SectionTitle
              content="Financial Report - For accounting and tax purposes"
              className={s.title}
              withOutMargin
            />
          </div>
          <Button
            icon={<DownloadOutlined />}
            size="large"
            loading={isDownLoading}
            style={{ fontSize: 13 }}
            type="primary"
            onClick={showModal}>
            EXPORT
          </Button>
        </div>

        <Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />
      </div>

      <Modal
        open={open}
        onOk={handleOk}
        handleClose={handleCancel}
        footer={
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
            }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <Button
                icon={<DownloadOutlined />}
                loading={isDownLoading}
                type="primary"
                size="large"
                onClick={handleDownloadCsv}
                style={{ fontSize: 13 }}>
                DOWNLOAD
              </Button>
              <Button style={{ fontSize: 13 }} size="large" onClick={handleCancel}>
                CANCEL
              </Button>
            </div>
            {/* <Label>Please disable all popup blockers</Label> */}
          </div>
        }>
        <Modal.Header>
          <Typography type="h3" weight={700}>
            Export Transaction list
          </Typography>
          {/* <div style={{ marginTop: 16, marginBottom: 16 }}>
            <DownloadSwitcher value={isPDF} onChange={setIsPDF} />
          </div> */}
        </Modal.Header>
        <div style={{ marginTop: 24 }}></div>
        <Typography type="main">
          CSV — Transaction list which includes all important fields including, if available, user
          address, phone numbers, and other information requested by event in comma delimited
          format.
        </Typography>

        {/* <div style={{ marginTop: 16, marginBottom: 24 }}>
          <Label>Ticket type (if you wish to show only specific ticket types)</Label>
          <TextArea rows={20} name="general_admission" placeholder="General Admission"></TextArea>
        </div> */}
      </Modal>

      <Table
        columns={columns}
        dataSource={zReadingList?.transactions}
        pagination={false}
        scroll={{ x: '100%' }}
      />
      <Pagination
        handlePageChange={(page) =>
          setRequestFilter((prevValue) => ({ ...prevValue, PageNumber: page }))
        }
        showedElements={showedElements}
        totalElements={zReadingList?.totalRecords}
        total={zReadingList?.totalPages}
        currentPage={zReadingList?.pageNumber || 1}
        handleSizeChange={(size) =>
          setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
        }
        defaultPageSize={25}
      />
    </div>
  );
};

export default EventZreadingList;
