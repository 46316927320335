import { createContext } from 'react';

import dayjs from 'dayjs';
import { FormInstance } from 'ui';

import { prepareMultipleDates } from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-dates/multiple-dates/multipleDates.utils';
import { PrepareLocationVenueResult } from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-location/location-venue/locationVenue.utils';

import { EventBasicInfoType } from 'types/events/eventTypes';

export enum EVENT_CREATE_FORM_BASIC_INFO_ENUM {
  EVENT_TITLE = 'eventName',
  SLUG = 'slug',
  CATEGORIES = 'categories',
  TAGS = 'tags',
  VENUE_TYPE = 'venueType',
  LOCATION = 'location',
  DATE_TYPE = 'dateType',
  SHOW_DATES = 'showDates',
  LAST_DATE = 'lastDate',
  LAST_TIME = 'lastTime',
  CURRENCY = 'currency',
  VENUE_NAME = 'venueName',
  ADDRESS = 'address',
  DATES = 'showDates',
}

export enum EVENT_VENUE_TYPE_ENUM {
  VENUE = 'Venue',
  ONLINE_EVENT = 'Online',
  NOLOCATION = 'NOLOCATION',
}

export enum EVENT_DATES_TYPES {
  SINGLE_DATE = 'NonRecurringEvent',
  MULTIPLE_DATES = 'NonRecurringEvent',
  RECURRING_DATES = 'RecurringEvent',
  NO_DATES = 'NoDates',
}

export type EVENT_CREATE_FORM_BASIC_INFO_TYPE =
  | Record<EVENT_CREATE_FORM_BASIC_INFO_ENUM, string>
  | {};

export type HandleChangeType = (
  changedValues: Partial<Record<string, PrepareLocationVenueResult>>,
  allValues: FormInstance['getFieldsValue'],
) => void;

interface createEventFormBasicInfoContextInterface {
  form?: FormInstance;
  onChangeForm?: HandleChangeType;
}

export const createEventFormBasicInfoContext =
  createContext<createEventFormBasicInfoContextInterface>({});

export const prepareEventBasicInfo = (data, promoterId) => {
  const categories = [data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.CATEGORIES]];
  if (data.secondaryCategory) {
    categories.push(data.secondaryCategory);
  }
  const result = {
    promoterId: promoterId,
    privateEvent: data.privateEvent,
    eventName: data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE],
    // url: data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.PAGE_URL],
    slug: data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.SLUG],
    dateType: EVENT_DATES_TYPES[data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE]],
    venueType: EVENT_VENUE_TYPE_ENUM[data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.VENUE_TYPE]],
    // seatingPlanId: data?.seatingPlan?.value,
    // eventType: data?.eventType?.value ? data.eventType.value : 'standing',
    startSellingAt:
      data.salesDate && data.salesTime
        ? dayjs(
            `${dayjs(data.salesDate).format('DD-MMM-YYYY')} ${dayjs(data.salesTime).format(
              'HH:mm',
            )}`,
          ).format('DD-MMM-YYYY HH:mm')
        : undefined,
    location: {
      ...data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.LOCATION],
      country: data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.LOCATION]?.country?.value,
    },
    currency: data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.CURRENCY]?.value,
    // showDates: [],
    tags: [...(data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.TAGS] || []), ...(data?.myTags || [])]?.map(
      (tag) => ({
        id: tag.id,
        name: tag.name,
        slug: tag.slug,
      }),
    ),
    categories: categories?.map((category) => ({
      id: category.value,
      name: category.label,
      slug: category.label,
    })),
  } as EventBasicInfoType;
  if (data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.SHOW_DATES]) {
    result.showDates = [prepareMultipleDates(data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.SHOW_DATES])];
  }

  if (data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_DATE]) {
    const lastDate = dayjs(data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_DATE]).format('DD-MMM-YYYY');
    const lastTime = dayjs(data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_TIME]).format('HH:mm');

    result.lastShowDate = `${lastDate} ${lastTime}`;
  }

  return result;
};
