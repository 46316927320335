import { useCallback } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

function useSetQueryHook() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (query: string, name: string = 'step') => {
      navigate(`${pathname}?${name}=${query}`);
    },
    [navigate, pathname],
  );
}

export default useSetQueryHook;
