import { FC } from 'react';

import { Input, Label } from 'ui';

import s from './EventOfferPerShow.module.scss';

const EventCreateFormTaxes: FC = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.row}>
        <div className={s.item}>
          <Label>
            Number of different shows for offer to be applied. If you leave it empty, it will be
            equal to the number of distinct shows.
          </Label>
          <Input name="numberOfShows" size="middle"></Input>
        </div>
        <div className={s.item}>
          <Label>
            Number of tickets to get a discount on (please specify discount in discount field)
          </Label>
          <Input name="numberOfTickets" size="middle"></Input>
        </div>
      </div>
    </div>
  );
};

export default EventCreateFormTaxes;
