export enum SortByEnum {
  PUBLISHED_DATE = 'PublishedDate',
  DATE = 'Date',
}

export type EventFilterType = {
  PageNumber?: number;
  PageSize?: number;
  IncludeMetrics?: boolean;
  Search: string;
  SortBy?: SortByEnum;
};

export const initialFilter: EventFilterType = {
  PageNumber: 1,
  PageSize: 100,
  IncludeMetrics: true,
  Search: '',
  SortBy: SortByEnum.PUBLISHED_DATE,
};
