import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { DownloadOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Select, Spin, Tabs } from 'antd';
import { Modal as antModal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Pagination from 'component/pagination';
import SectionTitle from 'component/section-title/sectionTitle';
import { useParams } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { useDebounce } from 'tools/hooks/debounce.hook';
import { Form, Icon, Input, Label, Link, Modal, Table, TextArea, Typography, message } from 'ui';

import {
  EditingPromoterIdSelector,
  activeEventNameSelector,
  eventNotificaionsSelector,
} from 'selectors/eventsSlice.selector';
import {
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useEditNotificationMutation,
  useLazyExportPromoterFollowersQuery,
  useLazyGetNotificationsQuery,
  useLazyGetPromoterFollowersQuery,
} from 'services/events/eventsApiService';
import {
  NOTIFICATION_TYPE_ENUM,
  NotificationsResType,
  PromoterFollower,
} from 'services/events/evetns.api.type';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setEventNotifications } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import EventLoadingState from 'containers/events-loading-state';

import s from './Notifications.module.scss';
import NotificationItem from './notification-item/notificationItem';
import { FollowerFilterType, SortByEnum, initialFilter } from './notifications.utils';

const { confirm } = antModal;

const Notifications: FC = () => {
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const notifications = useAppSelector(eventNotificaionsSelector);
  const [exportPromoterFollowers] = useLazyExportPromoterFollowersQuery();
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const eventName = useAppSelector(activeEventNameSelector);
  const promoterId = useAppSelector(EditingPromoterIdSelector);
  const [createNotification, { isLoading: creating }] = useCreateNotificationMutation();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [editNotification, { isLoading: editing }] = useEditNotificationMutation();
  const [getNotifications, { isFetching: fetching }] = useLazyGetNotificationsQuery();
  const [getPromoterFollowers, { data: followers, isFetching: followerFetching }] =
    useLazyGetPromoterFollowersQuery();
  const [editNotificationId, setEditNotificationId] = useState<NotificationsResType | null>(null);
  const [requestFilter, setRequestFilter] = useState<FollowerFilterType>(initialFilter);
  const [open, setOpen] = useState(false);
  const [filterEmail, setFilterEmail] = useState<string>('');
  const debouncedSearchEventName = useDebounce(filterEmail, 500) as string;
  const [tableData, setTableData] = useState<PromoterFollower[]>([]);
  const [isCanFetch, setIsCanFetch] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortByEnum>(SortByEnum.EMAIL);
  const [isDownLoading, setIsDownLoading] = useState<boolean>();
  const [tabKey, setTabKey] = useState<string>('notification');

  const showedElements =
    Number(followers?.followers?.length) +
    (Number(followers?.pageNumber) - 1) * Number(requestFilter.PageSize);

  const handlePageChange = useCallback((PageNumber: number) => {
    setRequestFilter((prevState) => ({
      ...prevState,
      PageNumber,
    }));
  }, []);

  const showModal = () => {
    setEditNotificationId(null);
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterEmail(event.target.value);
  };

  useEffect(() => {
    if (sortBy) {
      setRequestFilter((prevState) => ({
        ...prevState,
        SortBy: sortBy,
      }));
    }
  }, [sortBy]);

  useEffect(() => {
    if (debouncedSearchEventName?.length >= 3) {
      setRequestFilter((prevState) => ({
        ...prevState,
        Term: debouncedSearchEventName,
      }));
    } else if (debouncedSearchEventName?.length === 0) {
      setRequestFilter((prevState) => ({
        ...prevState,
        Term: '',
      }));
    }
  }, [debouncedSearchEventName]);

  useEffect(() => {
    const temp = currentUser?.promoters.some((promoter) => promoter?.promoterId === promoterId);
    setIsCanFetch(temp!);
  }, [currentUser, promoterId]);

  useEffect(() => {
    if (promoterId && isCanFetch) {
      const updatedFilter = {
        ...requestFilter,
        Id: promoterId,
      };
      getPromoterFollowers(updatedFilter);
    }
  }, [requestFilter, promoterId, isCanFetch, getPromoterFollowers]);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (eventId) {
        try {
          const res = await getNotifications(eventId);
          if ('data' in res) {
            dispatch(setEventNotifications(res.data as NotificationsResType[]));
          }
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      }
    };

    fetchNotifications();
  }, [getNotifications, eventId, dispatch]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const handleTabChange = (key) => {
    setTabKey(key);
  };

  const showConfirm = async (id: string) => {
    confirm({
      title: 'Do you want to delete this notification?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      centered: true,
      mask: false,
      async onOk() {
        const res = await deleteNotification(id);

        if ('data' in res) {
          message.success('Notification removed successfully.');
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleDeleteItem = (id: string) => {
    showConfirm(id);
  };

  const showExportConfirm = async () => {
    confirm({
      title: 'Would you like to export the followers of this event promoter?',
      icon: <InfoCircleOutlined />,
      content: '',
      centered: true,
      mask: true,
      async onOk() {
        setIsDownLoading(true);
        if (promoterId) {
          exportPromoterFollowers({ id: promoterId }).then((data) => {
            if (data.data) {
              const link = document.createElement('a');
              link.href = data.data?.fileName;
              link.setAttribute('download', `Followers-${promoterId}.csv`);
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setIsDownLoading(false);
            }
          });
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleExport = () => {
    showExportConfirm();
  };

  const handleEditItem = (notification: NotificationsResType) => {
    setEditNotificationId(notification);
    setOpen(true);
  };

  useEffect(() => {
    if (form && editNotificationId && open) {
      form.setFieldValue('title', editNotificationId?.title);
      form.setFieldValue('message', editNotificationId?.message);
      form.setFieldValue('notificationType', editNotificationId?.notificationType);
      form.setFieldValue('sortOrder', editNotificationId?.sortOrder);
    } else if (!editNotificationId) {
      form.setFieldValue('notificationType', NOTIFICATION_TYPE_ENUM.INFORMATION);
    }
  }, [editNotificationId, open, form]);

  const handleSubmit = useCallback(
    async (data) => {
      if (data && eventId) {
        if (!editNotificationId) {
          const result = {
            eventId: eventId,
            message: data.message,
            notificationType: data.notificationType,
            sortOrder: data.sortOrder,
            title: data.title,
          };
          const res = await createNotification(result);
          if ('data' in res) {
            dispatch(setEventNotifications(res?.data as NotificationsResType[]));
            setOpen(false);
            message.success('Successfully created notification.');
          }
        } else {
          const result = {
            id: editNotificationId.id,
            message: data.message,
            notificationType: data.notificationType,
            sortOrder: data.sortOrder,
            title: data.title,
            eventId: eventId,
          };
          const res = await editNotification(result);
          if ('data' in res) {
            dispatch(setEventNotifications(res?.data as NotificationsResType[]));
            setOpen(false);
            message.success('Successfully updated notification.');
          }
        }
      }
    },
    [createNotification, dispatch, eventId, editNotification, editNotificationId],
  );

  useEffect(() => {
    setTableData(followers?.followers!);
  }, [setTableData, followers]);

  const columns = [
    {
      title: 'E-MAIL',
      dataIndex: 'email',
      key: 'email',
      render: (follower) => `${follower}`,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (follower) => `${follower}`,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (follower) => `${follower}`,
    },
    {
      title: 'Number of Tickets',
      dataIndex: 'numberOfTickets',
      key: 'numberOfTickets',
      render: (follower) => `${follower}`,
    },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      render: (follower) => `${follower}`,
    },
    {
      title: 'Following Date',
      dataIndex: 'followingDate',
      key: 'followingDate',
      render: (follower) => `${follower}`,
    },
  ];

  return (
    <div className={s.wrapper}>
      <Link to={MY_EVENTS_ROUTE} label=" <- My Events" labelType="h6" className={s.backLink}></Link>
      <div className={s.row}>
        <SectionTitle label="common_notifications" className={s.title} />
        <div className={s.titleButton}>
          <Typography type="h4">{eventName}</Typography>
          {tabKey === 'notification' ? (
            <Button
              type="primary"
              size="large"
              onClick={showModal}
              style={{ fontSize: 13, paddingLeft: 40, paddingRight: 40 }}>
              + CREATE NOTIFICATION
            </Button>
          ) : (
            <Button
              size="large"
              style={{ fontSize: 13 }}
              icon={<DownloadOutlined />}
              loading={isDownLoading}
              type="primary"
              onClick={handleExport}>
              EXPORT
            </Button>
          )}
        </div>
      </div>
      <div className={s.content}>
        <Tabs
          defaultActiveKey="notification"
          onChange={handleTabChange}
          tabBarStyle={{ color: '#8D949C' }}
          style={{ width: '100%', height: '100%' }}
          items={[
            {
              key: 'notification',
              label: 'Notifications',
              children: (
                <div className={s.notificationList}>
                  {fetching && (!notifications || notifications?.length === 0) ? (
                    <div className={s.emptyNotificatoins}>
                      <Spin indicator={<EventLoadingState />} />
                    </div>
                  ) : !notifications || notifications?.length === 0 ? (
                    <div className={s.emptyNotificatoins}>
                      <div className={s.iconWrapper}>
                        <Icon name="bell" color="grey" />
                      </div>
                      <Typography color="grey">
                        You have no notifications for this event yet
                      </Typography>
                      <Button
                        type="primary"
                        size="large"
                        onClick={showModal}
                        style={{ fontSize: 13, paddingLeft: 40, paddingRight: 40 }}>
                        + CREATE NOTIFICATION
                      </Button>
                    </div>
                  ) : (
                    <>
                      {notifications &&
                        notifications.map((notification, index) => (
                          <NotificationItem
                            key={index}
                            id={notification?.id}
                            setEditNotificationId={handleEditItem}
                            handleDeleteItem={handleDeleteItem}
                            notification={notification}
                          />
                        ))}
                    </>
                  )}
                </div>
              ),
            },
            {
              key: 'followerList',
              label: 'List of followers',
              disabled: !isCanFetch,
              children: (
                <div className={s.notificationList}>
                  <div className={s.followerSearch}>
                    <Input
                      name="email"
                      fullWidth
                      placeholder="Name, E-mail or Mobile"
                      value={filterEmail}
                      onChange={handleSearchChange}
                    />
                    <Select
                      style={{ height: '100%', width: '240px', backgroundColor: 'white' }}
                      onChange={(value) => setSortBy(value)}
                      options={[
                        { value: SortByEnum.NAME, label: 'Name' },
                        { value: SortByEnum.EMAIL, label: 'E-mail' },
                        { value: SortByEnum.NUMBER_OF_TICKETS, label: 'Number of tickets' },
                      ]}
                      defaultValue={sortBy}
                    />
                  </div>
                  <Table
                    style={{ marginTop: 28 }}
                    dataSource={tableData}
                    loading={followerFetching}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 768 }}
                    rowKey="orderId"
                  />
                  {showedElements > 0 && (
                    <Pagination
                      totalElements={followers?.totalRecords}
                      showedElements={showedElements}
                      currentPage={requestFilter?.PageNumber || 0}
                      total={followers?.totalPages}
                      handlePageChange={handlePageChange}
                      handleSizeChange={(size) =>
                        setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
                      }
                    />
                  )}
                </div>
              ),
            },
          ]}
        />
      </div>

      <Modal open={open} onOk={handleOk} handleClose={handleCancel} footer={null}>
        <Modal.Header>
          <Typography type="h3" weight={700}>
            Create notification
          </Typography>
        </Modal.Header>
        <Form className={s.form} form={form} onFinish={handleSubmit}>
          <div>
            <Label>Notification Receivers</Label>
            <Form.Item name="receiver" initialValue="eventWindow">
              <Select
                style={{ width: '100%', height: 38 }}
                options={[{ label: 'Event Window', value: 'eventWindow' }]}
              />
            </Form.Item>
          </div>
          <div>
            <Label>Titile</Label>
            <Form.Item name="title" rules={[{ required: true, message: 'Title is required.' }]}>
              <Input name="title" placeholder="Type notification title" />
            </Form.Item>
          </div>
          <div>
            <Label>Message</Label>
            <Form.Item rules={[{ required: true, message: 'Message is required.' }]} name="message">
              <TextArea name="message" placeholder="Type notification message" />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
            <div style={{ width: '100%' }}>
              <Label>Notification Type</Label>
              <Form.Item fullWidth name="notificationType" initialValue="information">
                <Select
                  options={[
                    { label: 'Information', value: 'information' },
                    { label: 'Warning', value: 'warning' },
                  ]}
                />
              </Form.Item>
            </div>
            <div style={{ width: '100%' }}>
              <Label>Sort order</Label>
              <Form.Item fullWidth name="sortOrder">
                <Input.Number
                  style={{ width: '100%' }}
                  name="sortOrder"
                  placeholder="Type sort order"
                />
              </Form.Item>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 8,
              gap: 8,
              width: '100%',
            }}>
            <Button
              type="primary"
              size="large"
              loading={creating || editing}
              htmlType="submit"
              style={{ fontSize: 13, paddingLeft: 40, paddingRight: 40 }}>
              {!editNotificationId ? 'CREATE' : 'EDIT'}
            </Button>
            <Button
              type="link"
              size="large"
              style={{ fontSize: 13, color: '#7F86A4' }}
              onClick={handleCancel}>
              CANCEL
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Notifications;
