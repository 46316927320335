export enum TERMS_LINKS_ENUM {
  OUR_SERVICES = 'Our Services',
  PAYMENT_METHOD = 'Payment Method',
  FEES = 'Fees',
  REFUNDS = 'Refunds',
  CREDIT_CARD_CHARGEBACKS = 'Credit Card Chargebacks',
  TAX_AND_VAT = 'Tax and Vat',
  REGISTRATION_OBLIGATIONS = 'Your Registration Obligations',
  ACCOUNT_PASSWORD_AND_SECURITY = 'Account, Password and Security',
  CONTENT = 'Content',
  CERTAIN_REMEDIAL_RIGHTS = 'Certain Remedial Rights',
  SUB_DOMAINS = 'Sub Domains',
  ADDITIONAL_SERVICES = 'Additional Services',
  SERVICE_MODIFICATIONS_SUSPENSIONS = 'Service Modifications / Suspensions',
  TERMINATION = 'Termination',
  CHOICE_OF_LAW = 'Choice of Law',
  VIOLATIONS = 'Violations',
}

export type PublishEventModalStepsType = 'first' | 'second' | 'promoter' | undefined;
