import { FC } from 'react';

import classNames from 'classnames';
import { t } from 'tools/i18n';
import { Typography } from 'ui';

import s from './SectionTitle.module.scss';

interface SectionTitleProps {
  label?: string;
  content?: string;
  className?: string;
  counter?: number;
  withOutMargin?: boolean;
}

const SectionTitle: FC<SectionTitleProps> = (props) => {
  const { label, className = '', content, counter, withOutMargin = false } = props;
  return (
    <div className={classNames(s.wrapper, withOutMargin && s.withOutMargin)}>
      <Typography type="h2" className={classNames(s.title, [className])}>
        {!!label && t(label)}
        {!!content && content}
      </Typography>
      {!!counter && <div className={s.counter}>{counter}</div>}
    </div>
  );
};

export default SectionTitle;
