import { FC, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { Form, Input, Typography, message } from 'ui';
import { formRules } from 'utils/formRules';

import {
  useEditPromoterInfoMutation,
  useLazyGetPromoterInfoQuery,
} from 'services/promoter/promoterApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActivePromoterFullInfo } from 'slices/systemSlice';
import { useAppDispatch } from 'store';

import FormSubmit from 'containers/form-submit';

import s from './BankDetails.module.scss';
import BankDetailsBillingInfoForm from './bank-details-billing-info-form';
import BankDetailsStripeInfoInput from './bank-details-stripe-info-input';
import {
  BANK_DETAILS_FORM_ENUM,
  BANK_DETAILS_FORM_TYPE,
  createPaymentGatewaySettingObj,
} from './bankDetails.utils';
import EventModal from './event-modal';

const BankDetails: FC = () => {
  const [form] = Form.useForm();
  const { promoterId } = useParams();
  const [getPromoterFullInfo, { data: promoterData }] = useLazyGetPromoterInfoQuery();
  const dispatch = useAppDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const isEditingRef = useRef(false);
  const [editingList, setEditingList] = useState<BANK_DETAILS_FORM_TYPE>({});
  const [editPromoter, { isLoading }] = useEditPromoterInfoMutation();
  const isCheckedNoStripeUsage = Form.useWatch('notStripeUsed', form);
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [openEventModal, setOpenEventModal] = useState(false);
  const handleChange = (_, all) => {
    setIsEditing(true);
    isEditingRef.current = true;
    setEditingList(all);
  };

  useEffect(() => {
    if (promoterId && promoterId !== 'create') {
      getPromoterFullInfo(promoterId);
    }
  }, [promoterId, getPromoterFullInfo]);

  const handleSubmit = useCallback(
    async (data) => {
      if (promoterData?.id !== undefined) {
        const res = await editPromoter({
          ...promoterData,
          ...data,
          ...(!data?.notStripeUsed &&
            data[BANK_DETAILS_FORM_ENUM.BANK_STRIPE_ACCOUNT_NUMBER]?.length && {
              paymentGatewaySettings: [
                createPaymentGatewaySettingObj(
                  'Stripe',
                  data[BANK_DETAILS_FORM_ENUM.BANK_STRIPE_ACCOUNT_NUMBER],
                ),
              ],
            }),
          country: 'Malta',
        });

        if ('data' in res) {
          setIsEditing(false);
          isEditingRef.current = false;
          dispatch(setActivePromoterFullInfo(res.data));
          message.success('Successfully updated promoter detatils.');
          if (!currentUser?.hasEvents) {
            setOpenEventModal(true);
          }
        }
      }
    },
    [promoterData, dispatch, currentUser, editPromoter],
  );

  useEffect(() => {
    if (promoterData) {
      Object.keys(BANK_DETAILS_FORM_ENUM).forEach((key) => {
        if (key === 'BANK_STRIPE_ACCOUNT_NUMBER') {
          return;
        }
        if (BANK_DETAILS_FORM_ENUM[key] in promoterData) {
          const label = BANK_DETAILS_FORM_ENUM[key];
          form?.setFieldValue(BANK_DETAILS_FORM_ENUM[key], promoterData[label]);
        }
      });

      let isStripeUsed = false;

      if (Array.isArray(promoterData[BANK_DETAILS_FORM_ENUM.BANK_STRIPE_ACCOUNT_NUMBER])) {
        promoterData[BANK_DETAILS_FORM_ENUM.BANK_STRIPE_ACCOUNT_NUMBER].forEach((item) => {
          if (item?.gatewayType === 'stripe') {
            form?.setFieldValue(BANK_DETAILS_FORM_ENUM['BANK_STRIPE_ACCOUNT_NUMBER'], item?.value);
            isStripeUsed = true; // Mark that stripe is used
          }
        });
      }

      // If no stripe item was found, set 'notStripeUsed' to true
      if (!isStripeUsed) {
        form?.setFieldValue('notStripeUsed', true);
      }
    }
    form.setFieldValue(BANK_DETAILS_FORM_ENUM.BILLING_COUNTRY, 'Malta');
  }, [promoterData, form]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <div className={s.wrapper}>
      <Typography type="h2" className={s.title}>
        {t('bank_info_billing_details')}
      </Typography>

      <Form form={form} onFinish={handleSubmit} onValuesChange={handleChange}>
        <div className={s.form}>
          <div className={s.info}>
            <BankDetailsBillingInfoForm form={form} />
            <div>
              <Typography type="small" className={cn(s.label, s.required)}>
                {t('common_vat_number')}
              </Typography>
              <Form.Item
                name={BANK_DETAILS_FORM_ENUM.BILLING_VAT_NUMBER}
                fullWidth
                rules={[formRules.required]}>
                <Input name={BANK_DETAILS_FORM_ENUM.BILLING_VAT_NUMBER} size="large" />
              </Form.Item>
            </div>
          </div>

          <div className={s.info}>
            <Typography type="h3" className={cn(s.title, s.smallPadding)}>
              {t('bank_info_bank_details')}
            </Typography>

            <div>
              <BankDetailsStripeInfoInput form={form} />
              <div className={cn(s.hint, isCheckedNoStripeUsage && s.long)}>
                <b>We value your privacy and security.</b> At ShowsHappening, we do everything to
                make sure your data is safe with us, at all times. Your data is stored securely and
                is used only for internal communications and billing purposes. Please ensure all
                details are correct and updated.
                {isCheckedNoStripeUsage && <div>{t('bank_info_iban_hint')}</div>}
              </div>
            </div>
          </div>
        </div>

        <FormSubmit
          isEditing={isEditing}
          editingList={editingList}
          fetching={isLoading}
          type="bank"
        />
        <EventModal open={openEventModal} setOpen={setOpenEventModal} />
      </Form>
    </div>
  );
};

export default BankDetails;
