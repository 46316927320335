export const minimumAges = [
  'All ages',
  '2+',
  '3+',
  '4+',
  '5+',
  '6+',
  '7+',
  '8+',
  '9+',
  '10+',
  '11+',
  '12+',
  '13+',
  '14+',
  '15+',
  '16+',
  '17+',
  '18+',
  '19+',
  '20+',
  '21+',
];

export const requiredAges = [
  'All ages',
  '3 - 8',
  '3 - 10',
  '3 - 12',
  '3 - 14',
  '3 - 16',
  '3 - 18',
  '3 - 21',
];
