import Group, { GroupProps } from './group';
import InternalNav from './nav';

export type InternalNavType = typeof InternalNav;

export interface NavType extends InternalNavType {
  Group: typeof Group;
}

const Nav = InternalNav as NavType;

Nav.Group = Group;

export type { GroupProps };
export default Nav;
