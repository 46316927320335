import { EventCouponResponseType } from 'services/events/evetns.api.type';
import initialApiService from 'services/initialApiService';

import {
  CloneCouponType,
  CouponBatchReqType,
  ReqCouponType,
  ResCouponType,
} from 'types/coupons/couponTypes';

const couponApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getCoupon: builder.query<ResCouponType, string>({
      query: (couponId) => ({
        url: `coupons/${couponId}`,
        method: 'GET',
      }),
      providesTags: ['getCoupons'],
    }),
    exportCoupons: builder.query<{ fileName: string }, string>({
      query: (id) => ({
        url: `coupons/export/${id}`,
        method: 'GET',
      }),
    }),
    batchCoupons: builder.mutation<{ numberOfCouponsAffected: number }, CouponBatchReqType>({
      query: (data) => ({
        url: `/coupons/batchupdate`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['getCoupons'],
    }),
    getCouponsList: builder.query<
      EventCouponResponseType,
      {
        eventId: string;
        pageNumber?: number;
        pageSize?: number;
        showDisabled?: boolean;
      }
    >({
      query: (body) => ({
        url: `coupons/list/${body.eventId}`,
        method: 'GET',
        params: {
          pageNumber: body.pageNumber,
          pageSize: body.pageSize,
          showDisabled: body.showDisabled,
        },
      }),
    }),
    createCoupon: builder.mutation<ResCouponType, ReqCouponType>({
      query: (data) => ({
        url: `/coupons/create`,
        method: 'POST',
        body: data,
      }),
      // invalidatesTags: [SERVICES_TAGS.PROMOTER_FULL_INFO, SERVICES_TAGS.UPDATE_PROMOTER],
    }),
    editCoupon: builder.mutation<ResCouponType, ReqCouponType>({
      query: (data) => ({
        url: `/coupons/${data.id}/edit`,
        method: 'PUT',
        body: data,
      }),
      // invalidatesTags: [SERVICES_TAGS.PROMOTER_FULL_INFO, SERVICES_TAGS.UPDATE_PROMOTER],
    }),
    cloneCoupon: builder.mutation<ResCouponType, CloneCouponType>({
      query: (data) => ({
        url: `/coupons/${data.couponId}/clone`,
        method: 'PATCH',
        body: { qty: data.qty },
      }),
      // invalidatesTags: [SERVICES_TAGS.PROMOTER_FULL_INFO, SERVICES_TAGS.UPDATE_PROMOTER],
    }),
    disableCoupon: builder.mutation<ResCouponType, { couponId: string }>({
      query: (data) => ({
        url: `/coupons/${data.couponId}/disable`,
        method: 'PATCH',
      }),
      // invalidatesTags: [SERVICES_TAGS.PROMOTER_FULL_INFO, SERVICES_TAGS.UPDATE_PROMOTER],
    }),
    enableCoupon: builder.mutation<ResCouponType, { couponId: string }>({
      query: (data) => ({
        url: `/coupons/${data.couponId}/enable`,
        method: 'PATCH',
      }),
    }),
    deleteCoupon: builder.mutation<ResCouponType, { couponId: string }>({
      query: (data) => ({
        url: `/coupons/${data.couponId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyExportCouponsQuery,
  useBatchCouponsMutation,
  useCreateCouponMutation,
  useLazyGetCouponsListQuery,
  useEditCouponMutation,
  useLazyGetCouponQuery,
  useCloneCouponMutation,
  useDisableCouponMutation,
  useEnableCouponMutation,
  useDeleteCouponMutation,
} = couponApiService;
