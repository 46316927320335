import React, { useCallback } from 'react';

import cn from 'classnames';
import { getIsLoggedIn } from 'tools/cookieTools';
import { Icon, Layout } from 'ui';

import { isOpenedSiderSelector } from 'selectors/systemSlice.selector';
import { isLoggedInSelector } from 'selectors/userSlice.selectors';
import { setOpenedSider } from 'slices/systemSlice';
import { useAppDispatch, useAppSelector } from 'store';

import AppNavigation from 'containers/app-navigation';

import s from './App.module.scss';
import Pages from './pages';

function App() {
  const isAuth = getIsLoggedIn();
  const isLogged = useAppSelector(isLoggedInSelector);
  const initiallyLogged = isLogged || isAuth;
  const dispatch = useAppDispatch();
  const isOpenedSidebar = useAppSelector(isOpenedSiderSelector);

  const handleClick = useCallback(() => {
    dispatch(setOpenedSider(!isOpenedSidebar));
  }, [isOpenedSidebar, dispatch]);
  const keyPressHandler = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      return;
    }
  };

  return (
    <div className="App">
      <Layout auth={initiallyLogged}>
        <Layout.Content isAuth={initiallyLogged}>
          {initiallyLogged && (
            <div className={cn(s.mobileHeader)}>
              <button className={cn(s.menu, isOpenedSidebar && s.rotate)} onClick={handleClick}>
                <div className={cn(s.menuLine, isOpenedSidebar && s.rotate)}></div>
                <div className={cn(s.menuLine, isOpenedSidebar && s.rotate)}></div>
              </button>
              <div className={s.logo}>
                <Icon name="logo" width={252} height={40} />
              </div>
            </div>
          )}
          <Pages />
        </Layout.Content>
        {initiallyLogged && (
          <div>
            <AppNavigation initiallyLogged={initiallyLogged} />
            {isOpenedSidebar && (
              <div
                className={s.shadow}
                role="button"
                tabIndex={0}
                onKeyDown={keyPressHandler}
                onClick={() => {
                  dispatch(setOpenedSider(false));
                }}></div>
            )}
          </div>
        )}
      </Layout>
    </div>
  );
}

export default App;
