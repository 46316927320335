export enum REGISTER_BENEFITS_TEXT_ENUM {
  AUTH_BENEFITS_KEEP_UPDATES = 'AUTH_BENEFITS_KEEP_UPDATES',
  AUTH_BENEFITS_DISCOVER_EVENTS = 'AUTH_BENEFITS_DISCOVER_EVENTS',
  AUTH_BENEFITS_BUY_TICKETS = 'AUTH_BENEFITS_BUY_TICKETS',
  AUTH_BENEFITS_DISCOUNTS = 'AUTH_BENEFITS_DISCOUNTS',
  AUTH_BENEFITS_SELL_TICKETS = 'AUTH_BENEFITS_SELL_TICKETS',
}

export const registerBenefitsContent = [
  {
    label: REGISTER_BENEFITS_TEXT_ENUM.AUTH_BENEFITS_KEEP_UPDATES.toLowerCase(),
    icon: 'loginEvent',
  },
  {
    label: REGISTER_BENEFITS_TEXT_ENUM.AUTH_BENEFITS_DISCOVER_EVENTS.toLowerCase(),
    icon: 'loginManage',
  },
  {
    label: REGISTER_BENEFITS_TEXT_ENUM.AUTH_BENEFITS_BUY_TICKETS.toLowerCase(),
    icon: 'loginMetric',
  },
];
