import React, { FC, useCallback, useEffect, useState } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Button as AntdButton, Divider, Modal, Pagination } from 'antd';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'tools/hooks/debounce.hook';
import { Button, Checkbox, Icon, Input, Link, Table, Typography, message } from 'ui';

import {
  useBatchCouponsMutation,
  useLazyExportCouponsQuery,
  useLazyGetCouponsListQuery,
} from 'services/coupons/couponApiService';
import { useLazyGetEventQuery } from 'services/events/eventsApiService';

import { COUPON_REQUEST_TYPE } from 'types/coupons/couponTypes';

import SectionTitle from '../../../component/section-title/sectionTitle';
import { MY_EVENTS_ROUTE, getCouponCreateRoute } from '../../../routes/events/list';
import s from './EventsCouponsPage.module.scss';
import { EventsCouponsPageActionDropdown } from './containers';

interface EventsCouponsPageProps {
  // Define your component props here
}

const { confirm } = Modal;

const EventsCouponsPage: FC<EventsCouponsPageProps> = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [getEvent, { data: event }] = useLazyGetEventQuery();
  const [getCoupons, { data: coupons }] = useLazyGetCouponsListQuery();
  const [batchCoupons, { data: numberOfAffectedCoupons, isLoading }] = useBatchCouponsMutation();
  const [exportCoupons] = useLazyExportCouponsQuery();
  const [currentPage, setCurrentPage] = useState(1);
  const [isDownLoading, setIsDownLoading] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [showDisabledCoupons, setShowDisabledCoupons] = useState<boolean>(false);
  const [terms, setTerms] = useState<string>('');
  const debouncedTerms = useDebounce(terms, 500) as string;

  const updateData = () => {
    if (eventId && typeof eventId === 'string') {
      setCurrentPage(1);
      getCoupons({ eventId, pageNumber: 1, pageSize: 100, showDisabled: showDisabledCoupons });
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      setTerms('');
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (eventId && typeof eventId === 'string') {
      getEvent(eventId);
      getCoupons({ eventId, pageNumber: 1, pageSize: 100, showDisabled: showDisabledCoupons });
    }
  }, [eventId, getCoupons, getEvent, showDisabledCoupons]);

  const handleFetch = (page: number, size: number) => {
    setCurrentPage(page);
    if (eventId && typeof eventId === 'string') {
      getCoupons({ eventId, pageNumber: page, pageSize: size, showDisabled: showDisabledCoupons });
    }
  };

  const columns = [
    {
      title: 'Coupon Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Creation Date',
      dataIndex: 'creationDate',
      key: 'creationDate',
      render: (row: string) => {
        return dayjs(row).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Discount Type',
      dataIndex: 'couponTypeDescription',
      key: 'couponTypeDescription',
      render: (row: string) => {
        return <div style={{ maxWidth: 200, whiteSpace: 'normal' }}>{row}</div>;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Redemptions',
      dataIndex: 'numberOfCouponsAvailable',
      key: 'numberOfCouponsAvailable',
    },
    {
      title: 'Usage',
      dataIndex: 'deliveryType',
      key: 'deliveryType',
    },
    {
      title: 'Max no.',
      dataIndex: 'maximumNumberOfTickets',
      key: 'maximumNumberOfTickets',
    },
    {
      title: 'Status',
      dataIndex: 'disabled',
      key: 'disabled',
      render: (disabled) => {
        return disabled ? 'Disabled' : 'Enabled';
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (row) => {
        return (
          <EventsCouponsPageActionDropdown
            eventId={eventId!}
            couponId={row.id}
            status={row.disabled}
            updateData={updateData}
          />
        );
      },
    },
  ];

  const goToCouponCreation = useCallback(() => {
    navigate(getCouponCreateRoute(String(eventId || '')));
  }, [eventId, navigate]);

  const handleChangeTerms = (e) => {
    setTerms(e.target.value);
  };

  const showExportConfirm = async () => {
    confirm({
      title: 'Would you like to export the coupons of this event?',
      icon: <InfoCircleOutlined />,
      content: '',
      centered: true,
      mask: true,
      async onOk() {
        setIsDownLoading(true);
        if (eventId) {
          exportCoupons(eventId).then((data) => {
            if (data.data) {
              const link = document.createElement('a');
              link.href = data.data?.fileName;
              link.setAttribute('download', `Coupons-${eventId}.csv`);
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setIsDownLoading(false);
            }
          });
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleExport = () => {
    showExportConfirm();
  };

  useEffect(() => {
    if (debouncedTerms?.length >= 2) {
      batchCoupons({
        term: debouncedTerms,
        eventId: eventId!,
        requestType: COUPON_REQUEST_TYPE.VIEW_NUMBER_OF_COUPONS,
      });
    }
  }, [debouncedTerms, batchCoupons, eventId]);

  const handleDisableCoupons = async () => {
    const res = await batchCoupons({
      term: terms,
      eventId: eventId!,
      requestType: COUPON_REQUEST_TYPE.DISABLE,
    });

    if ('data' in res) {
      if (eventId && typeof eventId === 'string') {
        const sss = await getCoupons({
          eventId,
          pageNumber: 1,
          pageSize: 100,
          showDisabled: showDisabledCoupons,
        });

        if ('data' in sss) {
          message.success('Successfully disabled coupons');
          setIsModalOpen(false);
        }
      }
    }
  };

  const handleEnableCoupons = async () => {
    const res = await batchCoupons({
      term: terms,
      eventId: eventId!,
      requestType: COUPON_REQUEST_TYPE.ENABLE,
    });

    if ('data' in res) {
      if (eventId && typeof eventId === 'string') {
        const sss = await getCoupons({
          eventId,
          pageNumber: 1,
          pageSize: 100,
          showDisabled: showDisabledCoupons,
        });

        if ('data' in sss) {
          message.success('Successfully enabled coupons');
          setIsModalOpen(false);
        }
      }
    }
  };

  return (
    <div className={s.wrapper}>
      <Link to={MY_EVENTS_ROUTE} label="My Events" labelType="h6" className={s.backLink}></Link>
      <div className={s.header}>
        <SectionTitle
          content={`Coupons for ${event?.eventName}`}
          className={s.title}
          withOutMargin
        />

        {/*<Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />*/}

        <div className={s.searchBlock}>
          <div className={s.searchInput}>
            <AntdButton
              style={{ fontSize: 13 }}
              size="large"
              type="default"
              onClick={() => {
                setTerms('');
                setIsModalOpen(true);
              }}>
              Disable / Enable Coupon
            </AntdButton>
          </div>
          <div>
            <Button
              size="large"
              style={{ fontSize: 13 }}
              loading={isDownLoading}
              type="primary"
              onClick={handleExport}>
              <Icon name="download" size={24} />
              EXPORT COUPONS
            </Button>
          </div>
          <div>
            <Button onClick={goToCouponCreation} size="large" className={s.createBtn}>
              +&nbsp; Create coupon
            </Button>
          </div>
        </div>
      </div>
      <Checkbox value={showDisabledCoupons} onChange={setShowDisabledCoupons}>
        Show disabled coupons
      </Checkbox>
      <div style={{ overflowY: 'scroll', marginTop: 16 }}>
        <Table dataSource={coupons?.coupons || []} columns={columns} />
      </div>
      <Pagination
        current={currentPage}
        defaultCurrent={currentPage}
        total={coupons?.totalRecords}
        style={{ marginTop: 24 }}
        onChange={(page, size) => handleFetch(page, size)}
        onShowSizeChange={(current, size) => handleFetch(current, size)}
      />
      <Modal
        centered
        closable={false}
        open={isModalOpen}
        width={800}
        footer={[
          <div
            key="div"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              gap: 12,
              marginBottom: 16,
            }}>
            <Button
              style={{ fontSize: 13 }}
              key="ok"
              color="primary"
              disabled={
                isLoading ||
                numberOfAffectedCoupons?.numberOfCouponsAffected === null ||
                numberOfAffectedCoupons?.numberOfCouponsAffected === undefined ||
                numberOfAffectedCoupons?.numberOfCouponsAffected === 0
              }
              size="large"
              onClick={handleEnableCoupons}>
              ENABLE COUPONS
            </Button>

            <Button
              style={{ fontSize: 13 }}
              key="disable"
              color="secondary"
              size="large"
              disabled={
                isLoading ||
                numberOfAffectedCoupons?.numberOfCouponsAffected === null ||
                numberOfAffectedCoupons?.numberOfCouponsAffected === undefined ||
                numberOfAffectedCoupons?.numberOfCouponsAffected === 0
              }
              onClick={handleDisableCoupons}>
              DISABLE COUPONS
            </Button>

            <Button
              style={{ fontSize: 13 }}
              key="back"
              color="ghost"
              size="large"
              onClick={() => setIsModalOpen(false)}>
              CANCEL
            </Button>
          </div>,
        ]}>
        <div style={{ padding: 16 }}>
          <Typography type="h4">Find & disable coupons for {event?.eventName}</Typography>
          <Divider />
          <Typography type="main">
            Enter the exact description text to find matching coupons.
          </Typography>
          <Input
            style={{ marginTop: 6, marginBottom: 8 }}
            name="terms"
            placeholder="Find coupons"
            icon="search"
            value={terms}
            onChange={handleChangeTerms}
          />
          {numberOfAffectedCoupons?.numberOfCouponsAffected !== null &&
            numberOfAffectedCoupons?.numberOfCouponsAffected !== undefined && (
              <Typography type="label">
                {numberOfAffectedCoupons?.numberOfCouponsAffected} ticket
                {numberOfAffectedCoupons?.numberOfCouponsAffected > 1 && 's'} with coupons found
              </Typography>
            )}
        </div>
      </Modal>
    </div>
  );
};

export default EventsCouponsPage;
