export enum SortByEnum {
  EMAIL = 'email',
  NAME = 'name',
  NUMBER_OF_TICKETS = 'numberOfTickets',
}

export type FollowerFilterType = {
  Id: string;
  PageNumber?: number;
  PageSize?: number;
  Term: string;
  SortBy: SortByEnum;
};

export const initialFilter: FollowerFilterType = {
  Id: '',
  PageNumber: 1,
  PageSize: 100,
  Term: '',
  SortBy: SortByEnum.EMAIL,
};
