import { FC, useCallback } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { FORGOT_PASSWORD_ROUTE } from 'routes/user-management/list';
import { setTokenCookies } from 'tools/cookieTools';
import { t } from 'tools/i18n';
import { Button, Form, Icon, Input, Label, NavLink, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { useLazyLoginQuery } from 'services/user-management/userManagementApiService';

import { EMAIL_PLACEHOLDER, PASSWORD_PLACEHOLDER } from 'constants/core-constants';
import { CORE_URLS } from 'constants/coreUrls';

import { LOGIN_FORM_ENUM } from '../login.utils';
import s from './LoginForm.module.scss';

const LoginForm: FC = () => {
  const [getUser, { isLoading: loginIsLoading }] = useLazyLoginQuery();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (data: { email: string; password: string }) => {
      if (localStorage.getItem('currentEvent')) {
        localStorage.removeItem('currentEvent');
      }
      const res = await getUser({ email: data.email, password: data.password });

      if ('data' in res) {
        setTokenCookies(res?.data?.accessToken, res?.data?.refreshToken);
        navigate(MY_EVENTS_ROUTE);
      }
    },
    [getUser, navigate],
  );

  return (
    <div className={s.wrapper}>
      <Icon name="logo" width={320} height={50} className={s.logo} />
      <Form className={s.form} onFinish={handleSubmit} layout="vertical">
        <Typography type="h2" className={s.text}>
          {t('auth_login_welcome_back')}
        </Typography>

        <Form.Item
          fullWidth
          name={LOGIN_FORM_ENUM.EMAIL}
          rules={[formRules.required, formRules.email]}>
          <div>
            <Label>{t('common_email')}</Label>
            <Input name={LOGIN_FORM_ENUM.EMAIL} placeholder={EMAIL_PLACEHOLDER} size="large" />
          </div>
        </Form.Item>

        <Form.Item fullWidth name={LOGIN_FORM_ENUM.PASSWORD} rules={[formRules.required]}>
          <div>
            <Label>{t('common_password')}</Label>
            <Input.Password
              name={LOGIN_FORM_ENUM.PASSWORD}
              type={LOGIN_FORM_ENUM.PASSWORD}
              placeholder={PASSWORD_PLACEHOLDER}
              size="large"
            />
          </div>
        </Form.Item>

        <div className={s.forgotBlock}>
          <NavLink
            label={t('auth_login_forgot_password')}
            to={FORGOT_PASSWORD_ROUTE}
            className={cn(s.link, s.forgotLink)}
            typographyType="main"
          />
        </div>

        <Button htmlType="submit" fullWidth size="large" isLoading={loginIsLoading}>
          {t('auth_login_log_in')}
        </Button>

        <div className={s.privacy}>
          By continuing you agree to ShowsHappening
          <br />
          <a href={CORE_URLS.TERMS_OF_SERVICE} target="_blank" className={s.termsLink}>
            Terms of Service
          </a>
          and
          <a href={CORE_URLS.PRIVACY_POLICY} target="_blank" className={s.termsLink}>
            Privacy Policy
          </a>
          .
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
