import { FC, PropsWithChildren } from 'react';

import { Popover as AntPopover, PopoverProps as PopoverPropsAntd } from 'antd';
import 'antd/lib/popover/style/index';
import cn from 'classnames';

import s from './Popover.module.scss';

export interface PopoverProps extends PropsWithChildren, PopoverPropsAntd {
  isOpened: boolean;
  handleOpenChange: (isOpened: boolean) => void;
  circle?: boolean;
}

const Popover: FC<PopoverProps> = (props) => {
  const {
    children,
    className = '',
    content,
    title,
    trigger = 'click',
    placement = 'bottom',
    handleOpenChange,
    isOpened,
    circle,
    destroyTooltipOnHide,
    ...othersProps
  } = props;

  return (
    <AntPopover
      destroyTooltipOnHide={destroyTooltipOnHide}
      className={className}
      content={content}
      title={title}
      trigger={trigger}
      open={isOpened}
      onOpenChange={handleOpenChange}
      placement={placement}
      {...othersProps}>
      <div className={cn(isOpened && s.active, { [s.circle]: circle })}>{children}</div>
    </AntPopover>
  );
};

export default Popover;
