import React, { FC, useEffect, useState } from 'react';

import { ArrowRightOutlined } from '@ant-design/icons';
import cn from 'classnames';
import dayjs from 'dayjs';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { FAQ_ROUTE, FEATURE_ROUTE } from 'routes/help/list';
import { TICKETS_ORDERS_ROUTE } from 'routes/tikets/list';
import { getBankDetailEditRoute, getPromoterEditRoute } from 'routes/user-management/list';
import { t } from 'tools/i18n';
import { Icon, Layout, NavLink, Typography } from 'ui';

import { setEditingEventId } from 'slices/eventsSlice';
import { setOpenedSider } from 'slices/systemSlice';
import { useAppDispatch } from 'store/index';

import { UserProfileType } from 'types/core/userTypes';

import s from '../AppNavigation.module.scss';
import AppNavigationUserInfo from '../app-navigation-user-info';
import { SOCIAL_NAVIGATIONS_CONFIG } from '../appNavigation.utils';

interface CoreNavigationTemplateProps {
  isOpenedSidebar: boolean;
  currentUser?: UserProfileType;
  apiVersion?: { versionNumber: string };
  handleLogout: () => void;
}

const CoreNavigationTemplate: FC<CoreNavigationTemplateProps> = (props) => {
  const { isOpenedSidebar, currentUser, handleLogout } = props;
  const [isShowAdminRoutes, setIsShowAdminRoutes] = useState(false);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const dispatch = useAppDispatch();

  const handleCloseSidebar = () => {
    if (window.innerWidth < 767) {
      dispatch(setOpenedSider(false));
    }
    dispatch(setEditingEventId(''));
  };

  useEffect(() => {
    const roles = currentUser?.roles;
    const admin = roles?.find((role) => role.roleCode === 'ADM');

    if (currentUser && !admin) {
      setIsUserAdmin(false);
    } else {
      setIsUserAdmin(true);
    }
  }, [currentUser]);

  const NAVIGATIONS_CONFIG = [
    {
      to: MY_EVENTS_ROUTE,
      label: t('common_my_events'),
      icon: 'myEvents',
    },
    // {
    //   to: DASHBOARD_ROUTE,
    //   label: t('common_sellers'),
    //   icon: 'ticketSeller',
    // },
    {
      to: TICKETS_ORDERS_ROUTE,
      label: t('common_tickets_orders'),
      icon: 'ticketOrders',
    },
    {
      to: DASHBOARD_ROUTE,
      label: t('common_reports'),
      icon: 'ticketReports',
    },

    {
      to: getPromoterEditRoute(
        currentUser?.promoters[0]?.promoterId ? currentUser?.promoters[0]?.promoterId : 'create',
      ),
      label: t('common_organiser_details'),
      icon: 'house',
    },
    {
      to: getBankDetailEditRoute(
        currentUser?.promoters[0]?.promoterId ? currentUser?.promoters[0]?.promoterId : 'create',
      ),
      label: t('common_bank_details'),
      icon: 'dashboard',
    },

    // {
    //   to: TICKETS_SCANS_ROUTE,
    //   label: t('common_tickets_scan'),
    //   icon: 'ticketScan',
    // },
    // {
    //   to: OUTLETS_ROUTE,
    //   label: t('common_outlets'),
    //   icon: 'ticketKiosk',
    // },
    // {
    //   to: SELLERS_ROUTE,
    //   label: t('common_sellers'),
    //   icon: 'ticketSeller',
    // },
  ];

  return (
    <Layout.Sider opened={isOpenedSidebar}>
      <div className={s.wrapper}>
        <div className={s.logo}>
          <Icon name="logo" width={252} height={40} />
        </div>
        <AppNavigationUserInfo user={currentUser} isOpenedSidebar={isOpenedSidebar} />
        <div className={s.content}>
          {NAVIGATIONS_CONFIG.map((config) => (
            <button key={config.label} className={s.navLinkWrap} onClick={handleCloseSidebar}>
              <NavLink
                label={config.label}
                to={config.to}
                icon={config.icon}
                className={s.link}
                eventCounter={currentUser?.events?.length || 0}
              />
            </button>
          ))}
          {isUserAdmin && (
            <>
              <button
                className={cn(s.navLinkWrap, s.link, s.adminBtn, { [s.active]: isShowAdminRoutes })}
                onClick={() => setIsShowAdminRoutes((prev) => !prev)}>
                <Icon size={20} className={s.icon} name="profile" />
                Admin Tools
              </button>

              {isShowAdminRoutes && (
                <div className={s.adminRoutesWrapper}>
                  <NavLink
                    label="Users List"
                    to="/admin/users"
                    className={s.link}
                    eventCounter={currentUser?.events?.length || 0}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className={s.socialsLink}>
          {SOCIAL_NAVIGATIONS_CONFIG.map((item) => (
            <a key={item.label} href={item.to} className={s.socialsLinkItem} target="_blank">
              {item.label}
            </a>
          ))}
          <NavLink
            label="AVAILABLE TOOLS"
            typographyType="main"
            weight={700}
            to={FEATURE_ROUTE}
            className={s.additionalLinkItem}
            eventCounter={currentUser?.events?.length || 0}
          />
          <NavLink
            label="Help & Faq"
            typographyType="main"
            weight={700}
            to={FAQ_ROUTE}
            className={s.additionalLinkItem}
            eventCounter={currentUser?.events?.length || 0}
          />
        </div>
        <button onClick={handleLogout} className={s.actions}>
          <Typography type="main" weight={700} className={s.logout}>
            <Icon name="logout" />
            {isOpenedSidebar && <span className={s.text}>{t('common_log_out')}</span>}
          </Typography>
        </button>
        <div className={s.version}>
          <a href="https://www.showshappening.com" className={s.showshappening} target="_blank">
            <Icon name="shLogo" color="grey" size={20} />
            ShowsHappening.com
            <ArrowRightOutlined />
          </a>
          <div>
            <div className={s.bannerButtons}>
              <a href="https://apps.apple.com/mt/app/showsmanager/id1591231466" target="_blank">
                <img src="/assets/images/apple.webp" alt="app store" width={84} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.iu.showsmanager"
                target="_blank">
                <img src="/assets/images/google.webp" alt="google play" width={84} />
              </a>
            </div>
          </div>
          <div style={{ textAlign: 'left', fontSize: 9 }}>
            ©{dayjs().year()} ShowsHappening Ltd. All rights reserved v2.0
          </div>
        </div>
      </div>
    </Layout.Sider>
  );
};

export default CoreNavigationTemplate;
