import { FC } from 'react';

import cn from 'classnames';

import { LinkProps } from '../../link';
import NavLink from '../../nav-link';
import Typography from '../../typography';
import s from './Group.module.scss';

type GroupedLinkType = LinkProps & { group: string };

export interface GroupProps {
  title: string;
  links: GroupedLinkType[];
  className?: string;
}

const Group: FC<GroupProps> = ({ title, links, className = '' }) => {
  return (
    <div className={cn(s.group, { [className]: className })}>
      <Typography className={s.title} type="table">
        {title}
      </Typography>
      <div className={s.links}>
        {links
          .filter((item) => item.group === title)
          .map((link, index) => {
            return <NavLink key={`link__${index}__${link.label}`} {...link} />;
          })}
      </div>
    </div>
  );
};

export default Group;
