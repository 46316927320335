export const initialOrderFilter = {
  PageNumber: 1,
  PageSize: 10,
  SortByColumn: 'ByDate',
  SortType: 'Descending',
  DateFrom: '',
  DateTo: '',
  id: '',
  filter: '',
};
