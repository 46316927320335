import { FC, PropsWithChildren } from 'react';

export type WithSkeletonLoaderProps = PropsWithChildren<{
  isLoading?: boolean;
  skeletonLoader?: React.ReactElement;
}>;

const WithSkeletonLoader: FC<WithSkeletonLoaderProps> = ({
  children,
  isLoading,
  skeletonLoader,
}): React.ReactElement => {
  if (isLoading && skeletonLoader) {
    return skeletonLoader;
  }

  return <>{children}</>;
};

export default WithSkeletonLoader;
