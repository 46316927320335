import React, { FC, useEffect, useState } from 'react';

import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import SectionTitle from 'component/section-title/sectionTitle';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { TICKETS_ORDERS_ROUTE } from 'routes/tikets/list';
import { Breadcrumbs, Dropdown, Form, Icon, Label, Modal, Typography } from 'ui';
import PasswordInput from 'ui/input/password-input';

import { useCancelOrdersMutation } from 'services/events/eventsApiService';
import { useLazyDownloadTicketsQuery } from 'services/events/eventsApiService';
import { useLazyGetOrderFullInfoQuery } from 'services/tickets/ticketsApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import { orderContent } from 'pages/orders/order-full-info/orderFullInfo.utils';

import s from './OrderFullInfo.module.scss';
import OrderRow from './order-row';
import SendTicketModal from './send-ticket-modal';

const DROPDOWN_ITEMS = [{ key: 'send', label: <button className={s.contentItem}>Send</button> }];

const { TextArea } = Input;

const OrderFullInfo: FC = () => {
  const params = useParams<{ id: string }>();
  const [getOrderFullInfo, { data: orderFullInfo }] = useLazyGetOrderFullInfoQuery();
  const [cancelOrders, { data: canceledOrders, isLoading: loading }] = useCancelOrdersMutation();
  const [downloadTickets] = useLazyDownloadTicketsQuery();
  const [isDownLoading, setIsDownLoading] = useState<boolean>(false);
  const [eventName, setEventName] = useState('');
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [openedSendTicketModal, setOpenedSendTicketModal] = useState(false);
  const [ticketIDs, setTicektIDs] = useState<string[]>([]);
  const [isOpenHintModal, setIsOpenHintModal] = useState<boolean>(false);
  const [isNotifyUser, setIsNotifyUser] = useState<boolean>(false);
  const [isRefundProtection, setIsRefundProtection] = useState<boolean>(false);
  const [isCanceled, setIsCanceled] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const navigate = useNavigate();

  const handleDownloadAttendees = () => {
    setIsDownLoading(true);
    if (params.id) {
      downloadTickets({ id: params.id }).then((data) => {
        if (data.data) {
          const link = document.createElement('a');
          link.href = data.data?.fileName;
          link.setAttribute('download', `attendees-${params.id}.pdf`);
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsDownLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    const roles = currentUser?.roles;
    const admin = roles?.find((role) => role.roleCode === 'ADM');
    if (currentUser && !admin) {
      setIsUserAdmin(false);
    } else {
      setIsUserAdmin(true);
    }
  }, [currentUser]);

  const handleConfirm = () => {
    setIsOpenHintModal(false);
    navigate('/tickets-orders');
  };

  const handleSubmit = (values: { reason: string; password: string }) => {
    const data = {
      ticketIds: ticketIDs,
      reason: values.reason,
      email: orderFullInfo?.user.email as string,
      password: values.password,
      bookingFeePercentageRefund: 0,
      notifyUser: isNotifyUser,
      refundOrderProtection: isUserAdmin ? isRefundProtection : true,
      orderId: params.id as string,
    };
    cancelOrders(data);
  };

  useEffect(() => {
    if (canceledOrders?.ticketsCancelled.length! > 0) {
      setIsCanceled(true);
    }
  }, [canceledOrders]);

  useEffect(() => {
    if (params.id) {
      getOrderFullInfo(params.id);
    }
  }, [getOrderFullInfo, params.id]);

  const handleClickDropdownItems = (event: { key: string }) => {
    if (event.key === 'send') {
      setOpenedSendTicketModal(true);
      setOpenedDropdown(false);
    }
  };

  const onChangeNotify = (e: CheckboxChangeEvent) => {
    setIsNotifyUser(e.target.checked);
  };
  const onChangeProtection = (e: CheckboxChangeEvent) => {
    setIsRefundProtection(e.target.checked);
  };

  useEffect(() => {
    if (orderFullInfo) {
      setEventName(orderFullInfo?.tickets[0]?.ticketType?.name);
      const tempList = orderFullInfo.tickets.map((ticket) => ticket.ticketId);
      setTicektIDs(tempList);
    }
  }, [orderFullInfo]);

  const BREADCRUMBS_ROUTES = [
    { name: 'Orders', path: TICKETS_ORDERS_ROUTE },
    { name: `Order: ${!!orderFullInfo && orderFullInfo?.orderNo}` },
  ];

  // const ticketsColumns = [
  //   {
  //     title: 'PAX',
  //     dataIndex: 'numberOfPersons',
  //     key: 'numberOfPersons',
  //   },
  //   {
  //     title: 'Name',
  //     dataIndex: 'ticketHolderPersonalInfo',
  //     key: 'ticketHolderPersonalInfo',
  //     render: (ticketHolderPersonalInfo: { firstName: string; lastName: string }) =>
  //       ticketHolderPersonalInfo?.firstName,
  //   },
  //   {
  //     title: 'Surname',
  //     dataIndex: 'ticketHolderPersonalInfo',
  //     key: 'ticketHolderPersonalInfo',
  //     render: (ticketHolderPersonalInfo: { firstName: string; lastName: string }) =>
  //       ticketHolderPersonalInfo?.lastName,
  //   },
  //   {
  //     title: 'Ticket Type',
  //     dataIndex: 'ticketType',
  //     key: 'ticketType',
  //     render: (ticketType: { description: string; id: string; name: string }) => ticketType?.name,
  //   },
  //   {
  //     title: 'Sold At',
  //     dataIndex: 'subTotal',
  //     key: 'subTotal',
  //     render: (value) => `€ ${value.toFixed(2)}`,
  //   },
  //   {
  //     title: 'Attendance Date',
  //     dataIndex: 'attendanceDate',
  //     key: 'attendanceDate',
  //     render: (attendanceDate: string) => dayjs(attendanceDate).format('DD MMM YYYY, HH:mm'),
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'ticketStatus',
  //     key: 'ticketStatus',
  //     render: (ticketStatus: TICKET_STATUS_ENUM) => TICKET_STATUS_ENUM[ticketStatus],
  //   },
  // ];

  return (
    <>
      {!!orderFullInfo && (
        <div className={s.wrapper}>
          <SectionTitle content="Order Full info" className={s.title} />
          <Breadcrumbs routes={BREADCRUMBS_ROUTES} />

          <div className={s.content}>
            {orderContent(orderFullInfo, eventName)?.map((content, index) => (
              <div key={index} className={s.item}>
                {content.map((item, i) => (
                  <div key={i} className={s.rowItem}>
                    <Label>{item.title}</Label>
                    <Typography type="main" weight={600}>
                      {item.content}
                    </Typography>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <SectionTitle content="Ticket Details" className={s.subTitle} />
          {/* <Table
            dataSource={orderFullInfo.tickets}
            columns={ticketsColumns}
            rowKey="ticketId"
            pagination={{ pageSize: 5, showSizeChanger: false }}
            scroll={{ x: 768 }}
          /> */}
          <OrderRow data={orderFullInfo.tickets} />
          <div className={s.optionWrapper}>
            <Dropdown
              open={openedDropdown}
              onOpenChange={setOpenedDropdown}
              items={DROPDOWN_ITEMS}
              handleItemClick={handleClickDropdownItems}
              className={s.dropdown}
              placement="top">
              <div className={s.option}>
                <Typography type="main" weight={600}>
                  Ticket Option
                </Typography>
                <Icon name="arrowDropdown" />
              </div>
            </Dropdown>
          </div>
          <div className={s.footer}>
            <div>
              <Button
                danger
                size="large"
                color="grey"
                onClick={() => setIsOpenHintModal(true)}
                icon={<DeleteOutlined />}>
                Cancel Order
              </Button>
            </div>
            <div className={s.actions}>
              {/* <Button color="grey" size="large" icon={<PrinterFilled />}>
                Print tickets
              </Button> */}
              <Button
                onClick={handleDownloadAttendees}
                color="grey"
                size="large"
                loading={isDownLoading}
                icon={<DownloadOutlined />}>
                Download order
              </Button>
              <Button type="primary" size="large" color="grey" icon={<EditOutlined />}>
                MODIFY ORDER
              </Button>
            </div>
          </div>
        </div>
      )}

      <Modal handleClose={() => setIsOpenHintModal(false)} open={isOpenHintModal} footer={false}>
        {isCanceled ? (
          <Modal.Content>
            <div style={{ textAlign: 'center', width: '100%', paddingTop: 32, paddingBottom: 32 }}>
              <Icon name="success" size={48} />
              <Typography type="h2">Success</Typography>
              <Typography type="h4">Order has been cancelled successfully</Typography>
              <Button
                style={{ marginTop: 16 }}
                type="primary"
                size="large"
                color="grey"
                onClick={handleConfirm}>
                CONTINUE
              </Button>
            </div>
          </Modal.Content>
        ) : (
          <>
            <Modal.Header>
              <Typography className={s.modalText} type="h3">
                Are you sure you want to cancel this order?
              </Typography>
            </Modal.Header>

            <Modal.Content>
              <Form onFinish={handleSubmit} form={form}>
                <div>
                  <p>
                    By cancelling this order this line will contain any important info, suggestion
                    or concequence.{' '}
                  </p>

                  <h4 style={{ marginTop: 32, marginBottom: 12 }}>
                    Please state your reason for this cancellation:
                  </h4>
                  <Form.Item name="reason" fullWidth>
                    <TextArea
                      name="reason"
                      style={{ marginBottom: 16, marginTop: 12 }}
                      rows={6}
                      placeholder="Share details"></TextArea>
                  </Form.Item>
                  {isUserAdmin === false && (
                    <Form.Item
                      name="password"
                      fullWidth
                      rules={[{ required: true, message: 'Password is required.' }]}>
                      <PasswordInput name="password" placeholder="input password" />
                    </Form.Item>
                  )}

                  {isUserAdmin && (
                    <Checkbox
                      style={{ marginTop: 24 }}
                      defaultChecked
                      onChange={onChangeProtection}>
                      Refund Protection
                    </Checkbox>
                  )}

                  <Checkbox style={{ marginTop: 24 }} defaultChecked onChange={onChangeNotify}>
                    Notify user?
                  </Checkbox>
                </div>
                <div className={s.modalButtons}>
                  <Button
                    type="primary"
                    size="large"
                    color="grey"
                    onClick={() => setIsOpenHintModal(false)}>
                    NO, DON&apos;T CANCEL
                  </Button>
                  <Button danger size="large" color="grey" htmlType="submit" loading={loading}>
                    CANCEL ORDER
                  </Button>
                </div>
              </Form>
            </Modal.Content>
          </>
        )}
      </Modal>

      <SendTicketModal
        open={openedSendTicketModal}
        orderId={orderFullInfo?.orderId || ''}
        handleClose={() => setOpenedSendTicketModal(false)}
      />
    </>
  );
};

export default OrderFullInfo;
