import { FC, SVGProps } from 'react';

import { CoreIcons } from './default/coreIcons';
import { NavbarIcons } from './navbar/navbarIcons';
import { RegisterIcons } from './register/registerIcons';

export const Icons: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  ...RegisterIcons,
  ...NavbarIcons,
  ...CoreIcons,
};
