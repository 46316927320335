import { createApi } from '@reduxjs/toolkit/query/react';

import { SERVICES_TAGS } from 'constants/servicesTags';

import { BASE_QUERY_WITH_AUTH } from './utils';

export const API_NAME = 'initialApi';

const initialApiService = createApi({
  reducerPath: API_NAME,
  baseQuery: BASE_QUERY_WITH_AUTH,
  tagTypes: [...Object.values(SERVICES_TAGS)],
  endpoints: () => ({}),
  keepUnusedDataFor: 120,
});

export default initialApiService;
