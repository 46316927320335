import { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getPromoterEditRoute } from 'routes/user-management/list';
import { handleCutString } from 'tools/cutstring';
// import { useHover } from 'tools/hooks';
import { Link } from 'ui';

import { PromoterType, UserProfileType } from 'types/core/userTypes';

import s from './AppNavigationUserInfo.module.scss';

interface AppNavigationUserInfoProps {
  user?: UserProfileType;
  isOpenedSidebar: boolean;
}

const AppNavigationUserInfo: FC<AppNavigationUserInfoProps> = (props) => {
  const { user, isOpenedSidebar } = props;
  const navigate = useNavigate();
  const [promoter, setPromoter] = useState<PromoterType | null>(null);
  const hoverRef = useRef(null);

  const renderUserLogo = useMemo((): ReactElement => {
    if (promoter && promoter.logo) {
      return <img className={s.logoImg} src={promoter.logo} alt={promoter.name} />;
    } else {
      return <div className={s.logo}>{promoter ? promoter.name[0] : user?.firstName[0]}</div>;
    }
  }, [promoter, user]);

  const handleGoToSetting = useCallback(
    (promoterId) => {
      navigate(getPromoterEditRoute(promoterId));
    },
    [navigate],
  );

  useEffect(() => {
    if (user?.promoters) {
      setPromoter(user.promoters[0]);
    }
  }, [user]);

  const keyPressHandler = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      return;
    }
  };

  return (
    <div className={s.wrapper} ref={hoverRef}>
      <>
        <div
          className={s.promoter}
          onClick={() => handleGoToSetting(user?.promoters[0]?.promoterId)}
          role="button"
          tabIndex={0}
          onKeyDown={keyPressHandler}>
          {/* {isHoverUserInfo && (
            <div className={s.settingLogo}>
              <Icon name="setting" size={28} />
            </div>
          )} */}
          {renderUserLogo}
        </div>
        <div className={s.info}>
          <span>
            {isOpenedSidebar
              ? promoter?.name || user?.fullName
              : handleCutString(promoter?.name || user?.fullName || '', 13)}
          </span>
          {user?.email && user.email.length >= 25 ? (
            <Tooltip placement="right" title={user.email}>
              <span>
                {isOpenedSidebar
                  ? handleCutString(user.email, 25)
                  : handleCutString(user.email, 13)}
              </span>
            </Tooltip>
          ) : (
            <span>
              {isOpenedSidebar
                ? handleCutString(user?.email || '', 25)
                : handleCutString(user?.email || '', 13)}
            </span>
          )}
          <Link
            to={getPromoterEditRoute(
              user?.promoters[0]?.promoterId ? user?.promoters[0]?.promoterId : 'create',
            )}
            label="EDIT PROFILE"
            className={s.link}
            labelType="main"
          />
        </div>
      </>
    </div>
  );
};

export default AppNavigationUserInfo;
