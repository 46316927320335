import { FC, useState } from 'react';

import { Select } from 'antd';
import { Input, Label } from 'ui';

import s from './EventOfferPerMultipleTickets.module.scss';

const EventOfferPerMultipleTickets: FC = () => {
  const [discountType, setDiscountType] = useState<string>('%');

  return (
    <div className={s.wrapper}>
      <div className={s.row}>
        <div className={s.item}>
          <Label>Number of tickets to get free tickets</Label>
          <Input name="numberOfShows" size="middle"></Input>
        </div>
        <div className={s.item}>
          <Label>
            Number of tickets to get a discount on (please specify discount in discount field)
          </Label>
          <Input name="numberOfTickets" size="middle"></Input>
        </div>
      </div>
      <div className={s.row}>
        <div className={s.item}>
          <Label>Discount</Label>
          <Input name="numberOfShows" size="middle"></Input>
        </div>
        <div className={s.item}>
          <Label>Type</Label>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => setDiscountType(value)}
            options={[
              { value: '%', label: '%' },
              { value: '€', label: '€' },
            ]}
            value={discountType}
            defaultValue=""
          />
        </div>
      </div>
      <div className={s.row} style={{ marginTop: 16 }}>
        <Label>
          When the offer depends on the number of tickets, leaving this field empty, means that the
          user would get X number of free tickets set in the field Number of tickets to get a
          discount on, whilst if you input anything the user will get a discount percentage on the
          number of tickets marked in the field Number of tickets to get a discount on.
        </Label>
      </div>
    </div>
  );
};

export default EventOfferPerMultipleTickets;
