import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { InputNumber, Radio, RadioChangeEvent } from 'antd';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, Button, Icon, Input, Label, Typography } from 'ui';

import { useCreateCouponMutation } from 'services/coupons/couponApiService';
import { useLazyGetEventDetailQuery } from 'services/events/eventsApiService';

import { COUPON_DELIVERY_TYPE, COUPON_DISCOUNT_TYPE } from 'types/coupons/couponTypes';
import { EventTicketType } from 'types/events/eventTypes';

import SectionTitle from '../../../component/section-title/sectionTitle';
import { MY_EVENTS_ROUTE, getCouponsRoute } from '../../../routes/events/list';
import s from './EventsCreateCouponPage.module.scss';
import CreateCouponFixDiscountAllTicketType from './containers/create-coupon-fix-discount-all-ticket-type';
import CreateCouponNumberTicketType from './containers/create-coupon-number-ticket-type';
import CreateCouponSpecificTicketType from './containers/create-coupon-specific-ticket-type';
import CreateCouponTypes from './containers/create-coupon-types';
import CreateCouponWholeOrderType from './containers/create-coupon-whole-order-type';

interface EventsCreateCouponPageProps {
  // Define your component props here
}

const usageOption = [
  { label: 'Single User', value: COUPON_DELIVERY_TYPE.SINGLE_USER },
  { label: 'Mulitiple Users', value: COUPON_DELIVERY_TYPE.MULTI_USER },
];

const ipOptionType = [
  { label: 'yes', value: true },
  { label: 'no', value: false },
];

const userOptionType = [
  { label: 'yes', value: true },
  { label: 'no', value: false },
];

const EventsCreateCouponPage: FC<EventsCreateCouponPageProps> = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [getSelectedEventDetails, { data: eventDetails }] = useLazyGetEventDetailQuery();
  const [createCoupon, { isLoading: isCreateLoading }] = useCreateCouponMutation();
  const [selectedCouponType, setSelectedCouponType] = useState('discountOnNumberOfTickets');
  const [selectedUsageType, setSelectedUsageType] = useState(COUPON_DELIVERY_TYPE.SINGLE_USER);
  const [numberAvailableCoupon, setNumberAvailableCoupon] = useState(0);
  const [ipOption, setIpOption] = useState(false);
  const [userOption, setUserOption] = useState(false);
  const [discountType, setDiscountType] = useState('');
  const [totalAmount, setTotalAmount] = useState<number | null>();
  const [selectedTicketTypes, setSelectedTicketTypes] = useState<
    { ticketType: EventTicketType; qty: string; value: string }[]
  >([]);
  const [specificTicketTypes, setSpecificTicketTypes] = useState<
    { ticketType: EventTicketType; value: string }[]
  >([]);
  const [inputsData, setInputsData] = useState({
    minimumOrderTotal: 0,
    maximumNumberOfTickets: 0,
  });
  const [code, setCode] = useState<string>();
  const [description, setDescription] = useState<string>('');
  const [isOpenHintModal, setIsOpenHintModal] = useState(false);
  const BREADCRUMBS_ROUTES = [
    { name: 'My Events', path: MY_EVENTS_ROUTE },
    {
      name: `Coupons: ${eventDetails?.basicInfo?.eventName || ''}`,
      path: getCouponsRoute(eventId || ''),
    },
    { name: `Coupon creation` },
  ];

  const onChangeUsageType = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedUsageType(value);
    if (value === COUPON_DELIVERY_TYPE.SINGLE_USER) {
      setIpOption(false);
      setUserOption(false);
      setNumberAvailableCoupon(0);
    }
  };

  const onChangeIpOption = ({ target: { value } }: RadioChangeEvent) => {
    setIpOption(value);
  };

  const onChangeUserOption = ({ target: { value } }: RadioChangeEvent) => {
    setUserOption(value);
  };

  const onChangeAvailableCoupon = (value: number | null) => {
    setNumberAvailableCoupon(value as number);
  };

  const handleSetTicketTypes = (
    ticketTypes: { ticketType: EventTicketType; qty: string; value: string }[],
  ) => {
    setSelectedTicketTypes(ticketTypes);
  };

  const handleSetSpecificTicketTypes = (
    specificTicketTypes: { ticketType: EventTicketType; value: string }[],
  ) => {
    setSpecificTicketTypes(specificTicketTypes);
  };

  const onChangeInput = (value: number | null, type: string) => {
    setInputsData({ ...inputsData, [type]: value });
  };

  const onChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCode(value);
  };

  const onChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDescription(value);
  };

  const handleSubmit = async () => {
    const deliveryType =
      selectedUsageType === COUPON_DELIVERY_TYPE.SINGLE_USER
        ? COUPON_DELIVERY_TYPE.SINGLE_USER
        : COUPON_DELIVERY_TYPE.MULTI_USER;
    const minimumOrderTotal = Number(inputsData.minimumOrderTotal);
    const maximumNumberOfTickets = Number(inputsData.maximumNumberOfTickets);
    if (selectedCouponType === 'discountOnNumberOfTickets') {
      const sendTicketTypes = selectedTicketTypes.map((item) => ({
        ticketTypeId: item.ticketType.id as string,
        value: parseFloat(item.value.toString()),
        qty: parseInt(item.qty, 10),
      }));
      const data = {
        id: null,
        code: code,
        description: description,
        eventId: eventId,
        deliveryType: deliveryType,
        discountType: COUPON_DISCOUNT_TYPE.DEDUCTION,
        couponType: selectedCouponType,
        email: '',
        disabled: false,
        restrictByIP: ipOption,
        canBeRedeemedBySameUser: userOption,
        numberOfCouponsAvailable: numberAvailableCoupon,
        minimumOrderTotal: minimumOrderTotal,
        maximumNumberOfTickets: maximumNumberOfTickets,
        totalDiscount: 0,
        ticketTypes: sendTicketTypes,
        showRestrictions: [],
      };
      await createCoupon(data);
      navigate(getCouponsRoute(eventId || ''));
    } else if (selectedCouponType === 'discountOnAllTypes') {
      const data = {
        id: null,
        code: code,
        description: description,
        eventId: eventId,
        deliveryType: deliveryType,
        discountType: discountType,
        couponType: selectedCouponType,
        email: '',
        disabled: false,
        restrictByIP: ipOption,
        canBeRedeemedBySameUser: userOption,
        numberOfCouponsAvailable: numberAvailableCoupon,
        minimumOrderTotal: minimumOrderTotal,
        maximumNumberOfTickets: maximumNumberOfTickets,
        totalDiscount: totalAmount,
        ticketTypes: [],
        showRestrictions: [],
      };
      await createCoupon(data);
      navigate(getCouponsRoute(eventId || ''));
    } else if (selectedCouponType === 'discountOnSpecificTypes') {
      const sendTicketTypes = specificTicketTypes.map((item) => ({
        ticketTypeId: item.ticketType.id as string,
        qty: 1,
        value: parseInt(item.value, 10),
      }));
      const data = {
        id: null,
        code: code,
        description: description,
        eventId: eventId,
        deliveryType: deliveryType,
        discountType: COUPON_DISCOUNT_TYPE.DEDUCTION,
        couponType: selectedCouponType,
        email: '',
        disabled: false,
        restrictByIP: ipOption,
        canBeRedeemedBySameUser: userOption,
        numberOfCouponsAvailable: numberAvailableCoupon,
        minimumOrderTotal: minimumOrderTotal,
        maximumNumberOfTickets: maximumNumberOfTickets,
        totalDiscount: 0,
        ticketTypes: sendTicketTypes,
        showRestrictions: [],
      };
      await createCoupon(data);
      navigate(getCouponsRoute(eventId || ''));
    } else if (selectedCouponType === 'discountOnWholeOrder') {
      const data = {
        id: null,
        code: code,
        description: description,
        eventId: eventId,
        deliveryType: deliveryType,
        discountType: COUPON_DISCOUNT_TYPE.DEDUCTION,
        couponType: selectedCouponType,
        email: '',
        disabled: false,
        restrictByIP: ipOption,
        canBeRedeemedBySameUser: userOption,
        numberOfCouponsAvailable: numberAvailableCoupon,
        minimumOrderTotal: minimumOrderTotal,
        maximumNumberOfTickets: maximumNumberOfTickets,
        totalDiscount: totalAmount,
        ticketTypes: [],
        showRestrictions: [],
      };
      await createCoupon(data);
      navigate(getCouponsRoute(eventId || ''));
    }
  };

  const content = {
    discountOnNumberOfTickets: (
      <CreateCouponNumberTicketType
        tickets={eventDetails?.ticketTypes}
        setTicketTypes={handleSetTicketTypes}
      />
    ),
    discountOnAllTypes: (
      <CreateCouponFixDiscountAllTicketType
        setDiscountType={setDiscountType}
        setTotalAmount={setTotalAmount}
      />
    ),
    discountOnSpecificTypes: (
      <CreateCouponSpecificTicketType
        tickets={eventDetails?.ticketTypes}
        setDiscountType={setDiscountType}
        setSpecificTicketTypes={handleSetSpecificTicketTypes}
      />
    ),
    discountOnWholeOrder: <CreateCouponWholeOrderType setTotalAmount={setTotalAmount} />,
  };

  useEffect(() => {
    if (eventId && typeof eventId === 'string') {
      getSelectedEventDetails(eventId);
    }
  }, [eventId, getSelectedEventDetails]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <SectionTitle content="Coupon creation" className={s.title} withOutMargin />

        <Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />
      </div>

      <div>
        <Typography type="h3" className={s.blockTitle}>
          Recipients
          <button className={s.questionBtn} onClick={() => setIsOpenHintModal(true)}>
            <Icon name="question" size={15} />
          </button>
        </Typography>
        <CreateCouponTypes
          activeType={selectedCouponType}
          setActiveType={setSelectedCouponType}
          isOpenHintModal={isOpenHintModal}
          setIsOpenHintModal={setIsOpenHintModal}
        />
      </div>

      {content[selectedCouponType]}

      <div style={{ marginTop: 32 }}>
        <Typography type="h3" className={s.blockTitle}>
          Coupon Usage
          <button className={s.questionBtn}>
            <Icon name="question" size={15} />
          </button>
        </Typography>

        <Radio.Group
          options={usageOption}
          optionType="button"
          value={selectedUsageType}
          onChange={onChangeUsageType}
        />
      </div>

      {selectedUsageType === COUPON_DELIVERY_TYPE.MULTI_USER && (
        <div className={s.optionalSetting} style={{ marginTop: 16 }}>
          <Label>Number of coupons Available</Label>
          <InputNumber
            name="couponName"
            placeholder="0"
            onChange={onChangeAvailableCoupon}
            size="large"
            style={{ width: '50%' }}
          />
          <div className={s.inputWrapper}>
            <div className={s.inputBlock}>
              <Label required>Restrict by IP address?</Label>
              <Radio.Group
                options={ipOptionType}
                optionType="button"
                value={ipOption}
                onChange={onChangeIpOption}
              />
            </div>
            <div className={s.inputBlock}>
              <Label required>Can same user redeem more than once?</Label>
              <Radio.Group
                options={userOptionType}
                optionType="button"
                value={userOption}
                onChange={onChangeUserOption}
              />
            </div>
          </div>
        </div>
      )}
      <div style={{ marginTop: 32 }}>
        <Typography type="h3" className={s.blockTitle}>
          Optional Settings
          <button className={s.questionBtn}>
            <Icon name="question" size={15} />
          </button>
        </Typography>

        <div>
          <div className={s.optionalSetting}>
            <div className={s.inputWrapper}>
              <div className={s.inputBlock}>
                <Label required>Minimum order total</Label>
                <InputNumber
                  style={{ width: '100%' }}
                  name="minimumOrderTotal"
                  value={inputsData.minimumOrderTotal}
                  size="large"
                  placeholder="0"
                  prefix="€"
                  onChange={(e) => onChangeInput(e, 'minimumOrderTotal')}
                />
              </div>
              <div className={s.inputBlock}>
                <Label required>Maximum no. of Tickets</Label>
                <InputNumber
                  name="maximumNumberOfTickets"
                  size="large"
                  style={{ width: '100%' }}
                  value={inputsData.maximumNumberOfTickets}
                  placeholder="0"
                  onChange={(e) => onChangeInput(e, 'maximumNumberOfTickets')}
                />
              </div>
            </div>
            <Label>Coupon code name</Label>
            <Input
              name="couponName"
              fullWidth
              placeholder="Enter coupon name"
              onChange={onChangeCode}
            />
            <div style={{ marginTop: 16 }}></div>
            <Label>Description</Label>
            <Input
              name="description"
              fullWidth
              placeholder="Enter description"
              onChange={onChangeDescription}
            />
          </div>
        </div>
      </div>

      <Button className={s.saveBtn} onClick={handleSubmit} disabled={isCreateLoading}>
        Save
      </Button>
    </div>
  );
};

export default EventsCreateCouponPage;
