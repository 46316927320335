import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Tabs } from 'antd';

import {
  useLazyGetDatesQuery,
  useLazyGetDetailedSummaryQuery,
  useLazyGetSalesChannelsQuery,
  useLazyGetSeatMetricsQuery,
} from 'services/events/eventsApiService';

import { UserEventType } from 'types/core/userTypes';

import s from './DashboardDateTab.module.scss';
import AllDateCard from './allDateCard';
import DateCard from './dateCard';
import DateMetrics from './dateMetrics';

interface DashboardDateTabProps {
  eventId: string | undefined;
  activeEvent: UserEventType;
}

const DashboardDateTab: React.FC<DashboardDateTabProps> = ({ eventId, activeEvent }) => {
  const [eventType, setEventType] = useState<string>('');
  const [getDates, { data: dates, isFetching: loading }] = useLazyGetDatesQuery();
  const [getSeatMetrics, { data: seatMetrics }] = useLazyGetSeatMetricsQuery();
  const [getDetailedSummary, { currentData: detailedSummary }] = useLazyGetDetailedSummaryQuery();
  const [getSalesChannels, { currentData: salesChannels }] = useLazyGetSalesChannelsQuery();
  const [sortedDates, setSortedDates] = useState({});
  const [activeDateId, setActiveDateId] = useState<string | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>();

  const getMetricsData = useCallback(
    (eventId: string, dateId?: string) => {
      getSalesChannels({ EventId: eventId, DateId: dateId });
      if (eventType === 'standing') {
        getDetailedSummary({ EventId: eventId, DateId: dateId });
      }
    },
    [getSalesChannels, eventType, getDetailedSummary],
  );

  const handleDateCardClick = useCallback(
    (eventId, dateId, date) => {
      setActiveDateId(dateId);
      setSelectedDate(date);
      getMetricsData(eventId, dateId);
    },
    [getMetricsData],
  );

  const handleAllDateCardClick = useCallback(
    (eventId) => {
      setActiveDateId('allDates');
      setSelectedDate('allDates');
      getMetricsData(eventId);
    },
    [getMetricsData],
  );

  const handleTabChange = useCallback(
    (key) => {
      if (key !== 'defaultKey' && sortedDates && eventId) {
        const dateId = sortedDates[key][0].dateId;
        setActiveDateId(dateId);
        setSelectedDate(sortedDates[key][0].date);
        getMetricsData(eventId, dateId);
      } else if (key === 'defaultKey' && eventId && dates) {
        if (dates.dates?.length > 1) {
          setActiveDateId('allDates');
          setSelectedDate('allDates');
        } else {
          setActiveDateId(dates.dates[0].dateId);
          setSelectedDate(dates.dates[0].date);
        }
        getMetricsData(eventId);
      }
    },
    [sortedDates, eventId, dates, getMetricsData],
  );

  useEffect(() => {
    if (eventId) {
      getDates(eventId);
      setEventType(activeEvent?.seatedStandingType as string);
      if (activeEvent?.seatedStandingType === 'seated') {
        getSeatMetrics({ EventId: eventId });
      } else if (activeEvent?.seatedStandingType === 'standing') {
        getDetailedSummary({ EventId: eventId });
      }
    }
  }, [getDates, activeEvent, getSeatMetrics, getDetailedSummary, eventId]);

  useEffect(() => {
    const sortEvents = (dates) => {
      const sorted = dates.dates.reduce((acc, row) => {
        const month = row.date.split('-')[1]; // Extract month
        if (!acc[month]) {
          acc[month] = [];
        }
        acc[month].push(row);
        return acc;
      }, {});

      setSortedDates(sorted);
    };
    if (dates && eventId) {
      sortEvents(dates);
      if (dates.dates?.length > 1) {
        setActiveDateId('allDates');
        setSelectedDate('allDates');
      } else {
        setActiveDateId(dates.dates[0].dateId);
        setSelectedDate(dates.dates[0].date);
      }
      getMetricsData(eventId);
    }
  }, [dates, eventId, getMetricsData]);

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1); // monthNumber is 1-based, while setMonth expects 0-based
    return date.toLocaleString('default', { month: 'long' });
  };

  return (
    <div>
      <Tabs
        defaultActiveKey="defaultKey"
        onChange={handleTabChange}
        tabBarStyle={{ color: '#8D949C' }}
        style={{ width: '100%' }}
        items={[
          {
            key: 'defaultKey',
            label: 'All dates',
            children: loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            ) : (
              <div className={s.dateCardTabPane}>
                {dates && eventId && dates.dates.length > 1 && (
                  <AllDateCard
                    startDate={dates.dates[0].date}
                    endDate={dates.dates[dates.dates.length - 1].date}
                    status={dates.avaibilityStatus}
                    eventId={eventId}
                    onClick={handleAllDateCardClick}
                    isActive={activeDateId === 'allDates'}
                  />
                )}
                {dates &&
                  eventId &&
                  dates.dates.map((date, key) => (
                    <DateCard
                      key={key}
                      date={date.date}
                      status={date.status}
                      isPastDate={date.pastDate}
                      dateId={date.dateId}
                      eventId={eventId}
                      time={date.timeFrom}
                      onClick={handleDateCardClick}
                      isActive={activeDateId === date.dateId}
                    />
                  ))}
              </div>
            ),
          },
          ...Object.keys(sortedDates).map((month) => ({
            key: month,
            label: getMonthName(month),
            children: loading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            ) : (
              <div className={s.dateCardTabPane}>
                {sortedDates[month] &&
                  eventId &&
                  sortedDates[month].map((date, key) => (
                    <DateCard
                      key={key}
                      date={date.date}
                      status={date.status}
                      dateId={date.dateId}
                      time={date.timeFrom}
                      isPastDate={date.pastDate}
                      eventId={eventId}
                      onClick={handleDateCardClick}
                      isActive={activeDateId === date.dateId}
                    />
                  ))}
              </div>
            ),
          })),
        ]}
      />

      <DateMetrics
        eventId={eventId}
        isSeated={activeEvent?.seatedStandingType === 'seated'}
        selectedDate={selectedDate!}
        isLoading={loading}
        seatMetrics={seatMetrics!}
        detailedSummary={detailedSummary!}
        salesChannels={salesChannels!}
        currency={activeEvent?.currency!}
      />
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.eventType === nextProps.eventType && prevProps.eventId === nextProps.eventId;
};

// Wrap the component with React.memo and provide the custom comparison function
const MemoizedDashboardDateTab = React.memo(DashboardDateTab, areEqual);

export default MemoizedDashboardDateTab;
