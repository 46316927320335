import {
  // CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Upload, UploadProps } from 'antd';
// import type { CollapseProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import cn from 'classnames';
import { getBase64 } from 'tools/base64';
import { convertBytes } from 'tools/convertImageSize';
import { handleCutString } from 'tools/cutstring';
import { getImageFormatFromBase64 } from 'tools/getImageType';
import { t } from 'tools/i18n';
import { Form, Icon, Input, Label, Modal as NewModal, TextArea, Typography, message } from 'ui';
import { formRules } from 'utils/formRules';

import {
  useAddTicketGroupMutation,
  useDeleteTicketGroupImageMutation,
  useEditTicketGroupMutation,
} from 'services/events/eventsApiService';
import { setTicketGroups } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import { EventTicketGroupReqType, EventTicketGroupResType } from 'types/events/eventTypes';

import s from './CreateTicketGroupModal.module.scss';

const { Dragger } = Upload;
const { confirm } = Modal;

interface CreateTicketModalProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  activeEditTicketGroup?: EventTicketGroupResType;
  setActiveEditTicketGroup: Dispatch<SetStateAction<EventTicketGroupResType | undefined>>;
  currency: string;
  eventId: string;
}

const CreateTicketGroupModal: FC<CreateTicketModalProps> = (props) => {
  const {
    open,
    onClose,

    activeEditTicketGroup,
    setActiveEditTicketGroup,
    eventId,
  } = props;

  const [inputValue, setInputValue] = useState<string>('');
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const [createTicketGroup, { isLoading }] = useAddTicketGroupMutation();
  const [editTicketGroup, { isLoading: isEditing }] = useEditTicketGroupMutation();
  const [deleteTicketGroupImage] = useDeleteTicketGroupImageMutation();

  const [imageObj, setImageObj] = useState<{
    name?: string;
    url?: string;
    size?: string;
    format?: string;
    base64?: string;
  }>();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [loadFlyer, setLoadFlyer] = useState<{ loading?: boolean; done?: boolean }>();

  const uploadProps: UploadProps = {
    onRemove: () => {
      setImageObj(undefined);
    },
    beforeUpload: (file) => {
      getBase64(file, (url) => {
        const decodedInfo = getImageFormatFromBase64(url);

        setLoadFlyer({ loading: true, done: false });
        setImageObj({
          name: file.name,
          url,
          size: convertBytes(file.size),
          format: `image/${decodedInfo?.format}`,
          base64: url,
        });

        setTimeout(() => {
          setLoadFlyer({ loading: false, done: true });
        }, 1000);
      });

      return false;
    },
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    hasControlInside: undefined,
  };
  const handleDeleteLogo = (id: string) => {
    showConfirm(id);
  };

  const showConfirm = async (id: string) => {
    confirm({
      title: 'Do you want to delete this ticket group image?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      centered: true,
      mask: false,
      async onOk() {
        setLogoUrl('');
        setLoadFlyer({});
        setImageObj({});
        const res = await deleteTicketGroupImage(id);

        if ('data' in res) {
          message.success('Ticket group image removed successfully.');

          const sortedResponse = [...(res?.data || [])].sort((a, b) => a.sortOrder - b.sortOrder);
          dispatch(setTicketGroups(sortedResponse));
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  useEffect(() => {
    if (activeEditTicketGroup) {
      form.setFieldsValue(activeEditTicketGroup);
      setLogoUrl(activeEditTicketGroup?.imageURL);
    } else {
      setLogoUrl('');
    }
  }, [activeEditTicketGroup, form]);

  useEffect(() => {
    if (imageObj?.url) {
      setLogoUrl(imageObj.url);
    } else {
      setLogoUrl('');
    }
  }, [imageObj, setLogoUrl]);

  const handleClose = useCallback(() => {
    onClose(false);
    form.resetFields();

    if (activeEditTicketGroup?.name) {
      setActiveEditTicketGroup(undefined);
    }
  }, [activeEditTicketGroup?.name, form, onClose, setActiveEditTicketGroup]);

  const handleCreateTicket = useCallback(
    async (data) => {
      if (activeEditTicketGroup?.id) {
        const newTicket: EventTicketGroupReqType = {
          id: activeEditTicketGroup?.id as string,
          name: data.name,
          description: data.description,
          sortOrder: 0,
          imageBase64: imageObj?.base64 as string,
          eventId: eventId,
        };
        const res = await editTicketGroup(newTicket);

        if ('data' in res) {
          const sortedResponse = [...(res?.data || [])].sort((a, b) => a.sortOrder - b.sortOrder);
          dispatch(setTicketGroups(sortedResponse));
          setImageObj(undefined);
          message.success('Ticket group updated successfully.');
        }
      } else {
        const newTicket: EventTicketGroupReqType = {
          name: data.name,
          description: data.description,
          sortOrder: 0,
          imageBase64: imageObj?.base64 as string,
          eventId: eventId,
        };
        const res = await createTicketGroup(newTicket);

        if ('data' in res) {
          const sortedResponse = [...(res?.data || [])].sort((a, b) => a.sortOrder - b.sortOrder);
          dispatch(setTicketGroups(sortedResponse));
          setImageObj(undefined);
          message.success('Ticket group created successfully.');
        }
      }

      handleClose();
    },
    [
      activeEditTicketGroup,
      editTicketGroup,
      imageObj,
      handleClose,
      dispatch,
      createTicketGroup,
      eventId,
    ],
  );

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <NewModal
      style={{ marginTop: 24, marginBottom: 24 }}
      handleClose={handleClose}
      open={open}
      footer={null}
      className={s.modal}>
      <NewModal.Header>
        <Typography type="h2">
          {!activeEditTicketGroup?.name ? 'Create ticket group' : 'Edit ticket group'}
        </Typography>
      </NewModal.Header>

      <Form form={form} onFinish={handleCreateTicket}>
        <NewModal.Content className={s.content}>
          <div className={s.item}>
            <Label required>Ticket Group Name</Label>
            <Form.Item fullWidth name="name" rules={[formRules.required]}>
              <Input
                onChange={handleInputChange}
                name="name"
                maxLength={100}
                placeholder="Example: General Admission or VIP"
              />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Typography type="table" weight={600}>
                {`${inputValue.length}/100`}
              </Typography>
            </div>
          </div>

          {/* <div className={s.row}>
            <div className={s.item}>
              <Label required>{t('event_create_max_ticket_capacity')}</Label>
              <Form.Item
                name={EVENT_CREATE_EVENT_TICKET_ENUM.MAX_NUMBER_OF_TICKETS_TO_BE_SOLD}
                fullWidth
                rules={!hasNoMaxQuantity ? [formRules.required] : []}>
                <Input.Number
                  placeholder="0"
                  disabled={hasNoMaxQuantity}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div>
              <Checkbox value={hasNoMaxQuantity} onChange={setHasNoMaxQuantity}>
                <Typography type="main">Ticket type has no max quantity.</Typography>
              </Checkbox>
            </div>
          </div> */}

          <div className={s.item}>
            <Label>Ticket Description</Label>
            <Form.Item name="description">
              <TextArea
                className={s.description}
                color="grey"
                name="description"
                placeholder="Tell your attendees more about this ticket group type."
              />
            </Form.Item>
          </div>
          <div style={{ marginTop: 16 }}>
            <div className={s.block}>
              <ImgCrop
                modalProps={{ okButtonProps: { block: true }, cancelButtonProps: { block: true } }}
                aspect={2 / 3}
                aspectSlider
                rotationSlider={false}
                quality={1}>
                <Dragger {...uploadProps} className={s.dragger}>
                  {logoUrl ? (
                    <img src={logoUrl} alt="flyer" className={s.image} />
                  ) : (
                    <div className={s.hintWrapper}>
                      <Icon name="poster" size={60} />
                      <Typography type="h3" className={s.uploadHint}>
                        Add a poster
                      </Typography>
                      <Typography type="small" className={s.uploadHintType}>
                        {t('setting_upload_logo_type')}
                      </Typography>
                    </div>
                  )}
                </Dragger>
              </ImgCrop>

              {logoUrl && (
                <div className={s.uploadWrapper}>
                  {imageObj && (
                    <div>
                      <Typography type="small" color="grey">
                        {handleCutString(imageObj?.name || '')}
                      </Typography>

                      <div className={cn(s.upload)}>
                        <div
                          className={cn(s.content, {
                            [s.fill]: loadFlyer?.loading,
                            [s.done]: loadFlyer?.done,
                          })}
                        />
                      </div>
                    </div>
                  )}

                  <button
                    type="button"
                    onClick={() => handleDeleteLogo(activeEditTicketGroup?.id!)}
                    className={s.delete}>
                    <Icon name="trashBox" size={18} />
                  </button>
                </div>
              )}
            </div>
          </div>
        </NewModal.Content>
        <NewModal.Actions>
          <div className={s.actions}>
            <Button
              style={{ paddingLeft: 24, paddingRight: 24, fontSize: 14 }}
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading || isEditing}>
              {activeEditTicketGroup?.name ? 'SAVE CHANGES' : 'ADD TICKET GROUP'}
            </Button>
            <Button
              style={{ paddingLeft: 24, paddingRight: 24, fontSize: 14 }}
              color="grey"
              size="large"
              onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </NewModal.Actions>
      </Form>
    </NewModal>
  );
};

export default CreateTicketGroupModal;
