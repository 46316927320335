import React, { FC } from 'react';

import { Empty } from 'antd';
import cn from 'classnames';
import SectionTitle from 'component/section-title/sectionTitle';
import { formatNumber } from 'tools/formatNumbers';
import { Typography } from 'ui';

import { CURRENCIES_SETTING } from 'constants/core-constants';
// import ProgressCircle from './components/progress-circle';
import { TicketDataType } from 'types/events/eventTypes';

import s from './DashboardTicketSoldTable.module.scss';

interface DashboardTicketSoldTableProps {
  ticketTypes?: TicketDataType[];
  currency: string | undefined;
}

const DashboardTicketSoldTable: FC<DashboardTicketSoldTableProps> = (props) => {
  const { ticketTypes, currency } = props;
  if (ticketTypes?.length === 0) {
    return (
      <div className={cn(s.wrapper)}>
        <div className={s.header}>
          <div>
            <SectionTitle content="Ticket Types Sold" className={s.title} withOutMargin />
            <Typography type="main" color="grey">
              Issued tickets overview
            </Typography>
          </div>
        </div>
        <div className={s.emptyContent}>
          <Empty description="No data available" />
        </div>
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <SectionTitle content="Revenue" className={s.title} withOutMargin />
          <Typography type="main" color="grey">
            Value of tickets sold
          </Typography>
        </div>
      </div>
      <div className={s.content}>
        {ticketTypes?.map((type) => {
          // const hasMaxTickets = type.maximumNumberOfTickets !== 0;
          // const percentage = hasMaxTickets
          //   ? (type.qtyOfTicketsSold / type.maximumNumberOfTickets) * 100
          //   : 0;
          return (
            <div key={type.type} className={s.item}>
              {/* <ProgressCircle size={40} radius={32} stroke={8} percentage={percentage} /> */}
              <div className={s.subItem}>
                <Typography type="main" className={s.title}>
                  {type.qtyOfTicketsSold}
                </Typography>
                <Typography type="main" color="grey">
                  {type.type}
                </Typography>
                <Typography
                  type="main"
                  className={type.today.qtyOfTicketsSold > 0 ? s.green : s.grey}>
                  {type.today.qtyOfTicketsSold > 0 ? `+${type.today.qtyOfTicketsSold}` : 0} today
                </Typography>
              </div>
              <div className={s.subItem}>
                <Typography type="main" className={s.title}>
                  {type.numberOfComplimentaries}
                </Typography>
                <Typography type="main" color="grey">
                  Complimentary
                </Typography>
                <Typography
                  type="main"
                  className={type.today.numberOfComplimentaries > 0 ? s.green : s.grey}>
                  {type.today.numberOfComplimentaries > 0
                    ? `+${type.today.numberOfComplimentaries}`
                    : 0}{' '}
                  today
                </Typography>
              </div>
              <div className={s.subItem}>
                <Typography type="main" className={s.title}>
                  {currency && CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol}{' '}
                  {formatNumber(type.totalAmount)}
                </Typography>
                <Typography type="main" color="grey">
                  Total Amount
                </Typography>
                <Typography type="main" className={type.today.totalAmount > 0 ? s.green : s.grey}>
                  {type.today.totalAmount > 0
                    ? `+${currency && CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol}${
                        type.today.totalAmount
                      }`
                    : 0}{' '}
                  today
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardTicketSoldTable;
