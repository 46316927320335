import { SERVICES_TAGS } from '../../../constants/servicesTags';
import {
  LoginAsUserResponseType,
  UserEventsResponseType,
} from 'services/user-management/userManagement.api.type';

import { UserProfileType, UserType } from 'types/core/userTypes';
import {
  LoginRequestProps,
  RefreshTokenRequestProps,
  RegisterRequestProps,
  RegisterResponseProps,
} from 'types/user-management/auth';

import initialApiService from '../initialApiService';

const userManagementApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.query<UserType, LoginRequestProps>({
      query: (body) => ({
        url: '/users/login',
        method: 'POST',
        body,
      }),
    }),
    register: builder.mutation<RegisterResponseProps, RegisterRequestProps>({
      query: (body) => ({
        url: '/users/register',
        method: 'POST',
        body,
      }),
    }),
    refreshToken: builder.mutation<UserType, RefreshTokenRequestProps>({
      query: (body) => ({
        url: '/users/refresh-token',
        method: 'POST',
        body,
      }),
    }),
    getCurrentUser: builder.query<UserProfileType, void>({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.UPDATE_PROMOTER],
    }),
    searchUser: builder.query<UserProfileType[], { term: string }>({
      query: (params) => ({
        url: '/users/search',
        method: 'GET',
        params,
      }),
    }),
    getUserEvents: builder.query<
      UserEventsResponseType,
      { IncludeMetrics?: boolean; PageSize?: number; PageNumber?: number; Search?: string }
    >({
      query: (params) => ({
        url: '/users/events',
        method: 'GET',
        params,
      }),
      providesTags: [SERVICES_TAGS.GET_USERS_EVENT],
    }),
    getUserList: builder.query<UserType[], string>({
      query: (params) => ({
        url: `/admin/searchusers?term=${params}`,
        method: 'GET',
      }),
    }),
    loginAsUser: builder.mutation<LoginAsUserResponseType, { email: string }>({
      query: (body) => ({
        url: '/admin/loginas',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const loginEndpoint = userManagementApiService.endpoints.login;

export const {
  useLazyLoginQuery,
  useRegisterMutation,
  useLazyGetCurrentUserQuery,
  useGetCurrentUserQuery,
  useLazySearchUserQuery,
  useLazyGetUserEventsQuery,
  useLazyGetUserListQuery,
  useLoginAsUserMutation,
} = userManagementApiService;
