export const SELLERS_ROUTE = '/sellers';

export const OUTLETS_ROUTE = '/outlets';

export const REPORTS_ROUTE = '/reports';

export const NOTIFICATION_ROUTE = '/notifications/:eventId';

export const CALENDAR_ROUTE = '/calendar';

export const getNotificationRoute = (eventId: string) => `/notifications/${eventId}`;
