import React, { FC, useCallback, useEffect, useState } from 'react';

import SectionTitle from 'component/section-title/sectionTitle';
import { useParams } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { Breadcrumbs, Button, DatePicker, Label } from 'ui';

// import { createEventFormActiveStepSelector } from 'selectors/eventsSlice.selector';
import { useLazyGetEventDetailQuery } from 'services/events/eventsApiService';
import { removeActiveEvent, setActiveEvent, setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch } from 'store';

import { EventCreatedType } from 'types/events/eventTypes';

import s from './EventOffer.module.scss';
import EventOfferFixedDiscount from './event-offer-fixed-discount';
import EventOfferFixedPriceSpecificTypes from './event-offer-fixed-price-specific-types';
import EventOfferFixedPriceTicketType from './event-offer-fixed-price-ticket-type';
import EventOfferGreaterNumberTickets from './event-offer-greater-number-tickets';
import EventOfferPerMultipleTickets from './event-offer-per-multiple-tickets';
import EventOfferPerShow from './event-offer-per-show';
import EventOfferType from './event-offer-type';

const EventOffer: FC = () => {
  // const activeStep = useAppSelector(createEventFormActiveStepSelector);
  const [getEventDetail] = useLazyGetEventDetailQuery();
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const [selectedOfferType, setSelectedOfferType] = useState('per_show');

  const BREADCRUMBS_ROUTES = [
    { name: 'My Events', path: MY_EVENTS_ROUTE },
    { name: `Event offers` },
  ];
  const handleGetEditingEvent = useCallback(
    async (eventId: string) => {
      const res = await getEventDetail(eventId);

      if ('data' in res) {
        dispatch(setActiveEvent(res.data as EventCreatedType));
      }
    },
    [dispatch, getEventDetail],
  );

  useEffect(() => {
    if (eventId) {
      dispatch(setEditingEventId(eventId));
      handleGetEditingEvent(eventId);
    }
  }, [dispatch, eventId, getEventDetail, handleGetEditingEvent]);

  useEffect(() => {
    return () => {
      dispatch(removeActiveEvent());
    };
  }, [dispatch]);

  const content = {
    per_show: <EventOfferPerShow />,
    per_multiple_number_of_tickets: <EventOfferPerMultipleTickets />,
    fixed_percentage_discount: <EventOfferFixedDiscount />,
    greater_than_number_of_tickets: <EventOfferGreaterNumberTickets />,
    fixed_price_on_ticket_type: <EventOfferFixedPriceTicketType />,
    fixed_price_on_specific_type: <EventOfferFixedPriceSpecificTypes />,
  };

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <SectionTitle content="Event Offers" className={s.title} withOutMargin />

        <Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />
      </div>
      <EventOfferType setOfferType={setSelectedOfferType} offerType={selectedOfferType} />
      {content[selectedOfferType]}

      <div className={s.row}>
        <div className={s.item}>
          <Label>Date from (if any)</Label>
          <DatePicker fullWidth format="ddd DD MMMM YYYY" />
        </div>
        <div className={s.item}>
          <Label>Date to (if any)</Label>
          <DatePicker fullWidth format="ddd DD MMMM YYYY" />
        </div>
      </div>

      <Button style={{ marginTop: 24, marginBottom: 16 }}>+ CREATE NEW</Button>
    </div>
  );
};

export default EventOffer;
