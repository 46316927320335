import { SERVICES_TAGS } from 'constants/servicesTags';
import initialApiService from 'services/initialApiService';

import {
  AppliedTicketRequestType,
  AppliedTicketResponseType,
  DisableSeatsReqType,
  DisableSeatsResType,
  DisabledSectionResponse,
  EnableSeatsReqType,
  EventBasicInfoType,
  EventCreatedType,
  EventTicketGroupReqType,
  EventTicketGroupResType,
  EventType,
} from 'types/events/eventTypes';
import { SectionListTableType } from 'types/tickets/tickets.types';

import {
  AcceptTermsAndConditionsPropsType,
  AppliedTicketTypeRequestType,
  AppliedTicketTypeResponseType,
  ChangeTicketTypeAvailabilityRequest,
  ChangeTicketTypeAvailabilityResponse,
  DatesType,
  EventAttendeesReqType,
  EventAttendeesResponseType,
  EventCategoriesWithTag,
  EventCreateFormDetailsPropsType,
  EventCreateFormTaxesAndContactPropsType,
  EventCreateFormTaxesPropsType,
  EventDetailedSummaryType,
  EventFlyerPropsType,
  EventMetricsResponseType,
  EventPermissionResponseType,
  EventPromoDetailsPropsType,
  EventResponseType,
  EventSalesChannelsType,
  EventSeatMetricsType,
  EventTaxReportPropsType,
  EventTaxReportResponseType,
  EventTicketMetricsResponseType,
  EventTicketsPropsType,
  EventsSalesChannelsResType,
  GetDisabledSectionRequestType,
  NotificationCreateReqType,
  NotificationsResType,
  OrderCancelRequestData,
  OrderCancelResponseType,
  OrdersListPropsType,
  OrdersListResponseType,
  PromoterFollowersResType,
  SeatingPlanDetailResponseType,
  SeatingPlansResponseType,
  ZReadingResponseType,
} from './evetns.api.type';

const eventsApiService = initialApiService.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<EventType[], void>({
      query: () => ({
        url: 'events/categories',
        method: 'GET',
      }),
    }),
    getTags: build.query<
      EventCategoriesWithTag,
      { term: string; records: number; categoryIds?: string }
    >({
      query: (params) => ({
        url: 'events/tags',
        method: 'GET',
        params,
      }),
      providesTags: ['GET_CATEGORIES'],
    }),
    createEventBaseInfo: build.mutation<EventCreatedType, EventBasicInfoType>({
      query: (data) => ({
        url: 'events/eventbasicinfo',
        method: 'POST',
        body: data,
      }),
    }),
    editEventBaseInfo: build.mutation<
      EventCreatedType,
      { eventId: string; basicInfo: EventBasicInfoType }
    >({
      query: (data) => ({
        url: 'events/eventbasicinfo',
        method: 'PATCH',
        body: data,
      }),
    }),
    createEventDetails: build.mutation<EventCreatedType, EventCreateFormDetailsPropsType>({
      query: (data) => ({
        url: 'events/otherdetails',
        method: 'PATCH',
        body: data,
      }),
    }),
    getEvent: build.query<EventResponseType, string>({
      query: (id) => ({
        url: `events/getevent/${id}`,
        method: 'GET',
      }),
    }),
    getEventDetail: build.query<EventCreatedType, string>({
      query: (id) => ({
        url: `events/detail/${id}`,
        method: 'GET',
      }),
      providesTags: ['EventDetail', 'restartEvent', 'pauseEvent', 'deleteEvent'],
    }),
    deleteEvent: build.mutation<void, string>({
      query: (id) => ({
        url: `events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICES_TAGS.GET_USERS_EVENT],
    }),
    cloneEvent: build.mutation<EventCreatedType, string>({
      query: (id) => ({
        url: `events/clone/${id}`,
        method: 'POST',
      }),
    }),
    uploadEventFlyer: build.mutation<EventCreatedType, EventFlyerPropsType>({
      query: (data) => ({
        url: 'events/flyer',
        method: 'PATCH',
        body: data,
      }),
    }),
    addedTicketType: build.mutation<EventCreatedType, EventTicketsPropsType>({
      query: (data) => ({
        url: `events/tickettypes`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteTicketType: build.mutation<EventCreatedType, string>({
      query: (id) => ({
        url: `events/tickettype/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TicketTypes'],
    }),
    addTicketGroup: build.mutation<EventTicketGroupResType[], EventTicketGroupReqType>({
      query: (data) => ({
        url: `events/tickettypegroup`,
        method: 'POST',
        body: data,
      }),
    }),
    editTicketGroup: build.mutation<EventTicketGroupResType[], EventTicketGroupReqType>({
      query: (data) => ({
        url: `events/tickettypegroup`,
        method: 'PATCH',
        body: data,
      }),
    }),
    deleteTicketGroup: build.mutation<EventTicketGroupResType[], string>({
      query: (id) => ({
        url: `events/tickettypegroup/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TicketTypeGroup'],
    }),
    deleteTicketGroupImage: build.mutation<EventTicketGroupResType[], string>({
      query: (id) => ({
        url: `events/tickettypegroupimage/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['TicketTypeGroupImage'],
    }),
    getTicketGroups: build.query<EventTicketGroupResType[], string>({
      query: (id) => ({
        url: `/events/tickettypegroups/${id}`,
        method: 'GET',
      }),
    }),
    getSeatingPlanDetail: build.query<SeatingPlanDetailResponseType, string>({
      query: (id) => ({
        url: `/seatingplans/detail/${id}`,
        method: 'GET',
      }),
    }),

    getSctions: build.query<SectionListTableType, string>({
      query: (id) => ({
        url: `/events/seatingplan/${id}`,
        method: 'GET',
      }),
    }),
    getDates: build.query<DatesType, string>({
      query: (id) => ({
        url: `/events/datesavailabilities/${id}`,
        method: 'GET',
      }),
    }),
    applyTicketTypes: build.mutation<AppliedTicketResponseType, AppliedTicketRequestType>({
      query: (data) => ({
        url: 'seatingplans/applytickettypes',
        method: 'POST',
        body: data,
      }),
    }),
    getAppliedTicketTypes: build.query<AppliedTicketTypeResponseType, AppliedTicketTypeRequestType>(
      {
        query: (params) => ({
          url: `seatingplans/tickettypes`,
          method: 'GET',
          params,
        }),
      },
    ),
    transferTicketTypes: build.mutation<
      string,
      { eventId: string; fromTicketTypeId: string; toTicketTypeId: string }
    >({
      query: (body) => ({
        url: `seatingplans/transfertickettypes`,
        method: 'POST',
        body,
      }),
    }),

    disableSection: build.mutation<
      DisabledSectionResponse,
      { showId: string; sectionId: string; disableType: string }
    >({
      query: (body) => ({
        url: `seatingplans/disablesection`,
        method: 'PATCH',
        body,
      }),
    }),
    enableSection: build.mutation<DisabledSectionResponse, { showId: string; sectionId: string }>({
      query: (body) => ({
        url: `seatingplans/enablesection`,
        method: 'PATCH',
        body,
      }),
    }),
    disableSeats: build.mutation<DisableSeatsResType, DisableSeatsReqType>({
      query: (body) => ({
        url: `seatingplans/disableseats`,
        method: 'POST',
        body,
      }),
    }),
    enableSeats: build.mutation<DisableSeatsResType, EnableSeatsReqType>({
      query: (body) => ({
        url: `seatingplans/enableseats`,
        method: 'POST',
        body,
      }),
    }),
    getDisabledSection: build.query<DisableSeatsResType, GetDisabledSectionRequestType>({
      query: (params) => ({
        url: `seatingplans/sectiondisableinfo`,
        method: 'GET',
        params,
      }),
    }),
    addedTaxesEvent: build.mutation<EventCreatedType, EventCreateFormTaxesPropsType>({
      query: (data) => ({
        url: `events/taxdetails`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['EventDetail'],
    }),

    addedTaxesAndContact: build.mutation<EventCreatedType, EventCreateFormTaxesAndContactPropsType>(
      {
        query: (data) => ({
          url: `events/taxandcontact`,
          method: 'PATCH',
          body: data,
        }),
        invalidatesTags: ['EventDetail'],
      },
    ),
    publishEvent: build.mutation<EventCreatedType, { id?: string }>({
      query: (body) => ({
        url: `events/publish`,
        method: 'PATCH',
        body,
      }),
    }),
    pauseEvent: build.mutation<EventCreatedType, { id?: string }>({
      query: (body) => ({
        url: `events/pause`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['pauseEvent'],
    }),
    restartEvent: build.mutation<EventCreatedType, { id?: string }>({
      query: (body) => ({
        url: `events/restart`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['restartEvent'],
    }),
    getEventSalesChannels: build.query<
      EventsSalesChannelsResType,
      { EventIds: string[]; Filter: string }
    >({
      query: (params) => ({
        url: `/events/saleschannels`,
        method: 'GET',
        params,
      }),
    }),
    getEventMetrics: build.query<EventMetricsResponseType, string>({
      query: (id) => ({
        url: `eventmetrics/ticketsold`,
        method: 'GET',
        params: {
          id,
        },
      }),
    }),
    getEventTicketMetrics: build.query<
      EventTicketMetricsResponseType,
      { EventId: string; NumberOfPastDays: number }
    >({
      query: (params) => ({
        url: `eventmetrics/ticketsboughtperday`,
        method: 'GET',
        params,
      }),
    }),
    getDetailedSummary: build.query<EventDetailedSummaryType, { EventId: string; DateId?: string }>(
      {
        query: (params) => ({
          url: `/eventmetrics/detailedsummary`,
          method: 'GET',
          params,
        }),
      },
    ),
    getSalesChannels: build.query<EventSalesChannelsType, { EventId: string; DateId?: string }>({
      query: (params) => ({
        url: `/eventmetrics/saleschannels`,
        method: 'GET',
        params,
      }),
      providesTags: [SERVICES_TAGS.GET_SALES_CHANNELS],
    }),
    getSeatMetrics: build.query<EventSeatMetricsType, { EventId: string }>({
      query: (params) => ({
        url: `/eventmetrics/seats`,
        method: 'GET',
        params,
      }),
    }),
    getEventOrders: build.query<OrdersListResponseType, OrdersListPropsType>({
      query: (params) => ({
        url: `events/orders`,
        method: 'GET',
        params,
      }),
    }),
    cancelOrders: build.mutation<OrderCancelResponseType, OrderCancelRequestData>({
      query: (body) => ({
        url: `/tickets/canceltickets`,
        method: 'DELETE',
        body,
      }),
    }),
    downloadTickets: build.query<
      {
        fileName: string;
      },
      { id: string }
    >({
      query: (params) => ({
        url: `tickets/download`,
        method: 'GET',
        params: {
          OrderId: params.id,
        },
      }),
    }),
    getEventPermissions: build.query<EventPermissionResponseType, string>({
      query: (id) => ({
        url: `events/permissions/${id}`,
        method: 'GET',
      }),
      providesTags: ['permissions'],
    }),
    setUserEventPermissions: build.mutation<
      EventPermissionResponseType,
      { email: string; eventId: string; roleCode: string }
    >({
      query: (body) => ({
        url: `events/permission`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['permissions'],
    }),
    deleteUserEventPermissions: build.mutation<EventPermissionResponseType, string>({
      query: (id) => ({
        url: `events/permission`,
        method: 'DELETE',
        params: {
          id,
        },
      }),
      invalidatesTags: ['permissions'],
    }),
    acceptTermsAndConditions: build.mutation<EventCreatedType, AcceptTermsAndConditionsPropsType>({
      query: (body) => ({
        url: 'events/accepttermsandconditions',
        method: 'PATCH',
        body,
      }),
    }),
    addedTags: build.mutation<void, { tagName: string }>({
      query: (body) => ({
        url: '/events/suggesttag',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GET_CATEGORIES'],
    }),
    getEventAttendees: build.query<
      EventAttendeesResponseType,
      { id: string; PageNumber?: number; PageSize?: number }
    >({
      query: (params) => ({
        url: `events/attendees`,
        method: 'GET',
        params,
      }),
    }),
    exportAttendees: build.query<
      {
        fileName: string;
      },
      EventAttendeesReqType
    >({
      query: (params) => ({
        url: `events/exportAttendees`,
        method: 'GET',
        params: params,
      }),
    }),
    getZreading: build.query<ZReadingResponseType, { id: string }>({
      query: (params) => ({
        url: 'events/zreading',
        method: 'GET',
        params,
      }),
    }),
    exportZreading: build.query<
      {
        fileName: string;
      },
      { id: string }
    >({
      query: (params) => ({
        url: 'events/exportZreading',
        method: 'GET',
        params: {
          EventId: params.id,
        },
      }),
    }),
    getTaxReport: build.query<EventTaxReportResponseType, { id: string }>({
      query: (params) => ({
        url: 'events/taxreport',
        method: 'GET',
        params,
      }),
    }),
    exportTaxReport: build.query<
      {
        fileName: string;
      },
      EventTaxReportPropsType
    >({
      query: (body) => ({
        url: 'events/exportTaxReport',
        method: 'POST',
        body,
      }),
    }),
    deleteEventDates: build.mutation<void, string>({
      query: (id) => ({
        url: `events/date/${id}`,
        method: 'DELETE',
      }),
    }),
    getSeatingPlans: build.query<SeatingPlansResponseType, void>({
      query: () => ({
        url: '/seatingplans',
        method: 'GET',
      }),
    }),
    setEventPromoDetails: build.mutation<EventCreatedType, EventPromoDetailsPropsType>({
      query: (body) => ({
        url: 'events/promotedetails',
        method: 'PATCH',
        body,
      }),
    }),
    uploadPromotionMedia: build.mutation<EventCreatedType, FormData>({
      query: (body) => ({
        url: 'events/uploadpromotionmedia',
        method: 'POST',
        body,
      }),
    }),
    deletePromotionMedia: build.mutation<EventCreatedType, string>({
      query: (id) => ({
        url: `events/promotionmedia`,
        method: 'DELETE',
        params: {
          id: id,
        },
      }),
    }),
    changeTicketTypeAvailabilities: build.mutation<
      ChangeTicketTypeAvailabilityResponse,
      ChangeTicketTypeAvailabilityRequest
    >({
      query: (body) => ({
        url: 'events/changetickettypeavailabilities',
        method: 'PATCH',
        body,
      }),
    }),
    getNotifications: build.query<NotificationsResType[], string>({
      query: (id) => ({
        url: `events/notifications/${id}`,
        method: 'GET',
      }),
    }),
    createNotification: build.mutation<NotificationsResType[], NotificationCreateReqType>({
      query: (body) => ({
        url: 'events/notification',
        method: 'POST',
        body,
      }),
    }),
    deleteNotification: build.mutation<NotificationsResType[], string>({
      query: (id) => ({
        url: `events/notification/${id}`,
        method: 'DELETE',
      }),
    }),
    editNotification: build.mutation<NotificationsResType[], NotificationsResType>({
      query: (body) => ({
        url: 'events/notification',
        method: 'PUT',
        body,
      }),
    }),
    getPromoterFollowers: build.query<
      PromoterFollowersResType,
      { Id: string; Term: string; PageNumber?: number; PageSize?: number }
    >({
      query: (params) => ({
        url: `promoters/followers`,
        method: 'GET',
        params,
      }),
    }),
    exportPromoterFollowers: build.query<
      {
        fileName: string;
      },
      { id: string }
    >({
      query: (params) => ({
        url: 'promoters/followers/export',
        method: 'GET',
        params: {
          id: params.id,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetNotificationsQuery,
  useCreateNotificationMutation,
  useEditNotificationMutation,
  useDeleteNotificationMutation,
  useLazyGetPromoterFollowersQuery,
  useLazyExportPromoterFollowersQuery,
  useLazyGetCategoriesQuery,
  useLazyGetTagsQuery,
  useCreateEventBaseInfoMutation,
  useCloneEventMutation,
  useLazyGetEventQuery,
  useLazyGetEventMetricsQuery,
  useLazyGetEventTicketMetricsQuery,
  useLazyGetDetailedSummaryQuery,
  useLazyGetSalesChannelsQuery,
  useLazyGetSeatMetricsQuery,
  useUploadEventFlyerMutation,
  useCreateEventDetailsMutation,
  useAddedTicketTypeMutation,
  useAddedTaxesEventMutation,
  useAddedTaxesAndContactMutation,
  useDeleteEventMutation,
  useLazyGetEventDetailQuery,
  useLazyGetEventOrdersQuery,
  useLazyGetEventPermissionsQuery,
  useSetUserEventPermissionsMutation,
  useDeleteUserEventPermissionsMutation,
  usePublishEventMutation,
  usePauseEventMutation,
  useRestartEventMutation,
  useAcceptTermsAndConditionsMutation,
  useEditEventBaseInfoMutation,
  useAddedTagsMutation,
  useLazyGetEventSalesChannelsQuery,
  useLazyGetEventAttendeesQuery,
  useLazyExportAttendeesQuery,
  useLazyGetZreadingQuery,
  useLazyExportZreadingQuery,
  useLazyGetTaxReportQuery,
  useLazyExportTaxReportQuery,
  useDeleteEventDatesMutation,
  useLazyGetSeatingPlansQuery,
  useSetEventPromoDetailsMutation,
  useCancelOrdersMutation,
  useLazyDownloadTicketsQuery,
  useDeleteTicketTypeMutation,
  useLazyGetSeatingPlanDetailQuery,
  useLazyGetSctionsQuery,
  useLazyGetDatesQuery,
  useApplyTicketTypesMutation,
  useLazyGetAppliedTicketTypesQuery,
  useTransferTicketTypesMutation,
  useDisableSectionMutation,
  useEnableSectionMutation,
  useDisableSeatsMutation,
  useEnableSeatsMutation,
  useLazyGetDisabledSectionQuery,
  useChangeTicketTypeAvailabilitiesMutation,
  useUploadPromotionMediaMutation,
  useDeletePromotionMediaMutation,
  useAddTicketGroupMutation,
  useEditTicketGroupMutation,
  useLazyGetTicketGroupsQuery,
  useDeleteTicketGroupMutation,
  useDeleteTicketGroupImageMutation,
} = eventsApiService;
