import React, { FC } from 'react';

import { Typography } from 'ui';

import s from './Faq.module.scss';

interface FaqCardProps {
  iconUrl: string;
  title: string;
  items: {
    link: string;
    icon: string;
    text: string;
  }[];
}

const FaqCard: FC<FaqCardProps> = (props) => {
  const { iconUrl, title, items } = props;

  return (
    <div className={s.featureWrapper}>
      <img src={iconUrl} alt="featureImg" width={60} />
      <Typography type="h2">{title}</Typography>
      <ul style={{ listStyleType: 'none' }}>
        {items &&
          items.map((item, index) => (
            <li key={index}>
              <a className={s.listItem} href={item.link} target="_blank">
                <img src={item.icon} alt="featureImg" width={40} />
                <Typography type="h6">{item.text}</Typography>
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default FaqCard;
