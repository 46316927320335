import { FC, useCallback, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { getAttendeesRoute, getEditEventFormRoute, getZreadingRoute } from 'routes/events/list';
import { Dropdown, Icon } from 'ui';

import { useDeleteEventMutation } from 'services/events/eventsApiService';
import { setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import s from 'pages/events/events-list-table-view/EventList.module.scss';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventWithMetricsType } from 'types/events/eventTypes';

import { DASHBOARD_ROUTE } from '../../../routes/dashboard/list';

const DROPDOWN_ITEMS = (url: string) => [
  { key: 'edit', label: <div className={s.dropdownItem}>Edit</div> },
  { key: 'managePermissions', label: <div className={s.dropdownItem}>Manage Permissions</div> },
  {
    key: 'viewEvent',
    label: (
      <a href={url} target="_blank" className={s.dropdownItem}>
        View Event
      </a>
    ),
  },
  { key: 'viewStats', label: <div className={s.dropdownItem}>View Stats</div> },
  { key: 'zReadingList', label: <div className={s.dropdownItem}>Financial Report</div> },
  { key: 'attendeesList', label: <div className={s.dropdownItem}>Event Attendees</div> },
  { key: 'delete', label: <div className={classNames(s.dropdownItem, s.delete)}>Delete</div> },
];

interface EventListActionsDropdownProps {
  event: EventWithMetricsType;
}

const { confirm } = Modal;

const EventListActionsDropdown: FC<EventListActionsDropdownProps> = (props) => {
  const { event } = props;
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [deleteEvent] = useDeleteEventMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const showConfirm = useCallback(() => {
    confirm({
      title: <>Do you want to delete this {event?.eventName} Event?</>,
      icon: <ExclamationCircleOutlined style={{ fontSize: 32 }} />,
      content: <>This action cannot be undone.</>,
      okText: 'Yes',
      okType: 'danger',
      centered: true,
      mask: true,
      onOk() {
        deleteEvent(event.eventId).then(() => {
          setOpenedDropdown(false);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }, [deleteEvent, event]);

  const handleDeleteEvent = useCallback(() => {
    showConfirm();
  }, [showConfirm]);

  const handleItemClick = useCallback(
    (ev: { key: string }) => {
      const { key } = ev;
      if (key === 'viewEvent') {
        setOpenedDropdown(false);
      }

      if ('edit' === key) {
        setOpenedDropdown(false);
        dispatch(setEditingEventId(event.eventId));
        navigate(getEditEventFormRoute(event.eventId));
      }

      if (key === 'managePermissions') {
        setOpenedDropdown(false);
        dispatch(setEditingEventId(event.eventId));
        navigate(
          `${getEditEventFormRoute(event.eventId)}?step=${
            CREATE_EVENT_FORM_STEPS_ENUM.PERMISSIONS
          }`,
        );
      }

      if (key === 'viewStats') {
        navigate(`${DASHBOARD_ROUTE}?event=${event.eventId}`);
      }

      if (key === 'zReadingList') {
        navigate(getZreadingRoute(event.eventId));
      }

      if (key === 'attendeesList') {
        navigate(getAttendeesRoute(event?.eventId));
      }

      if (key === 'delete') {
        setOpenedDropdown(false);
        handleDeleteEvent();
      }
    },
    [dispatch, event?.eventId, handleDeleteEvent, navigate],
  );

  return (
    <>
      <Dropdown
        items={DROPDOWN_ITEMS(event.url)}
        placement="bottomRight"
        open={openedDropdown}
        onOpenChange={setOpenedDropdown}
        handleItemClick={handleItemClick}>
        <div className={s.dropdownButton}>
          <Icon name="verticalDots" />
        </div>
      </Dropdown>
    </>
  );
};

export default EventListActionsDropdown;
