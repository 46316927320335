import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Radio, Select, Switch } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import { DatePicker, Form, Icon, Input, Label, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useAppSelector } from 'store/index';

import {
  EVENT_CREATE_FORM_BASIC_INFO_ENUM,
  createEventFormBasicInfoContext,
} from 'pages/events/events-create-event-form/event-create-form-basic-info/basicInfo.utils';

import { MultipleDatesType } from 'types/events/eventTypes';

import s from './EventCreateFormBasicInfoDates.module.scss';
import { EVENT_CREATE_FORM_DATES_TYPES } from './eventCreateFormBasicInfoDates.utils';
import MultipleDates from './multiple-dates';

const { Option } = Select;

// import NoDates from './no-dates';
// import SingleDate from './single-date';

interface EventCreateFormBasicInfoDatesProps {
  setShowDates: Dispatch<SetStateAction<MultipleDatesType[]>>;
  multipleDates: MultipleDatesType[];
  hasDateValue: boolean;
  setHasDateValue: Dispatch<SetStateAction<boolean>>;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const EventCreateFormBasicInfoDates: FC<EventCreateFormBasicInfoDatesProps> = ({
  setShowDates,
  multipleDates,
  hasDateValue,
  setHasDateValue,
  setIsDirty,
}) => {
  const { form } = useContext(createEventFormBasicInfoContext);
  const [salesStartTime, setSalesStartTime] = useState(false);
  const activeEditingEvent = useAppSelector(createEventFormActiveEventSelector);
  // const dateType = Form.useWatch(EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE, form);

  const scheduleType = activeEditingEvent?.basicInfo?.dateType === 'recurringEvent';

  const onSwitchChange = useCallback(
    (checked) => {
      setSalesStartTime(checked);
      if (!checked) {
        form?.setFieldsValue({ salesDate: '' });
        form?.setFieldsValue({ salesTime: '' });
        form?.setFieldsValue({ hoursWhenSalesEnd: 4 });
        form?.setFieldsValue({ beforeOrAfterEvent: 'after' });
      }
    },
    [form],
  );

  useEffect(() => {
    if (activeEditingEvent?.basicInfo?.startSellingAt) {
      setSalesStartTime(true);
    } else {
      form?.setFieldsValue({ salesDate: '' });
      form?.setFieldsValue({ salesTime: '' });
      form?.setFieldsValue({ hoursWhenSalesEnd: 4 });
      form?.setFieldsValue({ beforeOrAfterEvent: 'after' });
    }
  }, [activeEditingEvent?.basicInfo?.startSellingAt, form]);
  // const renderRadioButton = () => {
  //   return Object.keys(EVENT_CREATE_FORM_DATES_TYPES).map((key) => (
  //     <Radio.Button value={key} key={key}>
  //       {t(`date_type_${key.toLowerCase()}`)}
  //     </Radio.Button>
  //   ));
  // };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  // const renderDateContent = () => {
  //   switch (dateType) {
  //     case EVENT_CREATE_FORM_DATES_TYPES.SINGLE_DATE:
  //       return <SingleDate />;
  //     case EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES:
  //       return <MultipleDates setShowsDates={setShowDates} multipleDates={multipleDates} />;
  //     case EVENT_CREATE_FORM_DATES_TYPES.NO_DATES:
  //       return <NoDates />;
  //   }
  // };

  useEffect(() => {
    if (activeEditingEvent?.basicInfo?.showDates && !scheduleType) {
      // const dates = activeEditingEvent?.basicInfo?.showDates;
      // if (activeEditingEvent?.basicInfo?.dateType === 'noDates') {
      //   form?.setFieldValue(
      //     EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE,
      //     EVENT_CREATE_FORM_DATES_TYPES.NO_DATES,
      //   );
      //   if (dates?.length === 1) {
      //     if (dates[0]?.doorsOpen) {
      //       form?.setFieldValue(
      //         EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_DATE,
      //         dayjs(`${dates[0]?.endDate} ${dates[0]?.endTime}`),
      //       );
      //       form?.setFieldValue(
      //         EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_TIME,
      //         dayjs(`${dates[0]?.endDate} ${dates[0]?.endTime}`),
      //       );
      //     }
      //   }
      // } else {
      //   form?.setFieldValue(
      //     EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE,
      //     EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES,
      //   );
      // }
      // if (dates?.length === 1) {
      //   if (dates[0]?.doorsOpen) {
      //     form?.setFieldValue(
      //       ['showDates', 'doorsOpen'],
      //       dayjs(`${dates[0]?.startDate} ${dates[0]?.doorsOpen}`),
      //     );
      //   }
      // }
    }
  }, [activeEditingEvent, form, scheduleType]);

  return (
    <div className={s.wrapper}>
      {scheduleType && (
        <div className={s.glassWrapper}>
          <div className={s.glass}>
            <div className={s.glassContent}>
              <Icon name="logo" width={250} height={70} />
              <Typography type="h3" className={s.glassText}>
                Currently schedule settings have not yet been added to the ShowsManager, please use
                the dashboard in{' '}
                <a
                  className={s.link}
                  href="https://www.showshappening.com/myevents"
                  target="_blank">
                  ShowsHappening
                </a>
              </Typography>
            </div>
          </div>
        </div>
      )}
      <div className={cn(s.dateWrapper, { [s.blur]: scheduleType })}>
        <div className={s.content}>
          <Typography type="h3" className={s.title}>
            Existing Dates
          </Typography>

          {/* <div className={s.launchBlock}>
            <Checkbox value={nededLaunchDate} onChange={setNededLaunchDate}>
              <Typography type="main" weight={600}>
                Would you like to add a start date for the ticket launch?
              </Typography>
              <Typography type="main">
                By doing this you can publish the event now and share the link and then open ticket
                sales on the date and time that you set.
              </Typography>
            </Checkbox>

            {nededLaunchDate && (
              <div className={s.block}>
                <div>
                  <Label>Start Sales Date</Label>
                  <Form.Item name="salesDate">
                    <DatePicker
                      fullWidth
                      disabledDate={(current) => disabledDate(current)}
                      format="ddd DD MMMM YYYY"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Label>Start Sales Time</Label>
                  <Form.Item name="salesTime">
                    <DatePicker.TimePicker fullWidth format="HH:mm" />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>*/}

          <div className={s.radioWrapper}>
            <Form.Item
              name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE}
              initialValue={EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES}
              hidden>
              <Radio.Group buttonStyle="solid" className={s.tabs}></Radio.Group>
            </Form.Item>
          </div>
          {/* {renderDateContent()} */}
          <MultipleDates
            hasDateValue={hasDateValue}
            setShowsDates={setShowDates}
            multipleDates={multipleDates}
            setIsDirty={setIsDirty}
            setHasDateValue={setHasDateValue}
          />
          <div style={{ marginTop: 32, marginBottom: 12 }}>
            <Typography type="h4">Ticket sales start and end time</Typography>
          </div>

          <div
            style={{ display: 'flex', alignItems: 'center', gap: 8, textTransform: 'uppercase' }}>
            <Switch checked={salesStartTime} onChange={onSwitchChange} />
            <Typography type="main" weight={700}>
              SPECIFY Ticket sales start & END time
            </Typography>
          </div>

          <div style={{ marginLeft: 44, marginTop: 4, marginBottom: 16 }}>
            <Typography type="main">
              Specify sales start and end time.{' '}
              <strong>
                By default ticket sales will start when you will publish (and we approve) your event
                and end 4h after your event starts.
              </strong>
            </Typography>
          </div>

          {salesStartTime && (
            <div>
              <div className={s.block}>
                <div style={{ width: '100%' }}>
                  <Label>Start Sales Date</Label>
                  <Form.Item name="salesDate">
                    <DatePicker
                      fullWidth
                      disabledDate={(current) => disabledDate(current)}
                      format="ddd DD MMMM YYYY"
                    />
                  </Form.Item>
                </div>
                <div style={{ width: '100%' }}>
                  <Label>Start Sales Time</Label>
                  <Form.Item name="salesTime">
                    <DatePicker.TimePicker fullWidth format="HH:mm" />
                  </Form.Item>
                </div>
              </div>
              <div>
                <div className={s.salesTime}>
                  <div className={s.field}>
                    <Typography type="small" className={cn(s.label)}>
                      Ticket Sales End
                    </Typography>
                    <Form.Item name="hoursWhenSalesEnd" initialValue={4}>
                      <Input.Number
                        style={{ width: '100%' }}
                        type="number"
                        placeholder="Ticket sales end hour"
                        name="hoursWhenSalesEnd"
                      />
                    </Form.Item>
                  </div>
                  <div className={s.field}>
                    <Typography type="small" className={cn(s.label)}>
                      Event
                    </Typography>
                    <Form.Item name="beforeOrAfterEvent" initialValue="after" className={s.field}>
                      <Select placeholder="Please select a value">
                        <Option value="after">After</Option>
                        <Option value="before">Before</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={s.hintWrapper}>
          <div className={s.hint}>
            <div>
              <Icon name="warning" />
            </div>
            <Trans i18nKey="event_create_form_launch_date_tip" components={{ b: <b /> }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCreateFormBasicInfoDates;
