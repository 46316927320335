import { FC } from 'react';

import s from './EventsEmptyState.module.scss';

const EventsLoadingState: FC = () => {
  return (
    <div className={s.empty}>
      <img src="/assets/images/logo-loop.svg" className={s.img} alt="loading" />
    </div>
  );
};

export default EventsLoadingState;
