export enum ORGANISER_DETAILS_FORM {
  COMPANY_NAME = 'name',
  COMPANY_EMAIL = 'email',
  ABOUT_COMPANY = 'about',
  COMPANY_URL = 'url',
  COMPANY_ADDRESS = 'address',
  COMPANY_PHONE = 'mobileNumber',
  COMPANY_TOWN = 'town',
  COMPANY_POSTCODE = 'postcode',
  COMPANY_COUNTRY = 'country',
  COMPANY_CURRENCY = 'currency',
}

export type ORGANISER_DETAILS_FORM_TYPE = Record<ORGANISER_DETAILS_FORM, string> | {};
