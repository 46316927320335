import { FC } from 'react';

import cn from 'classnames';
import { Modal, Typography } from 'ui';

import { createEventCouponType } from '../utils';
import s from './CreateCouponTypes.module.scss';

interface CreateCouponTypesProps {
  activeType: string;
  setActiveType: (key: string) => void;
  isOpenHintModal: boolean;
  setIsOpenHintModal: (isOpen: boolean) => void;
}

const CreateCouponTypes: FC<CreateCouponTypesProps> = (props) => {
  const { activeType, setActiveType, setIsOpenHintModal, isOpenHintModal } = props;

  return (
    <>
      <div className={s.wrapper}>
        {createEventCouponType.map((item) => (
          <div
            key={item.key}
            className={cn(s.item, { [s.active]: item.key === activeType })}
            onClick={() => setActiveType(item.key)}
            role="button"
            onKeyDown={() => setActiveType(item.key)}
            tabIndex={0}>
            {item.label}
          </div>
        ))}
      </div>

      <Modal handleClose={() => setIsOpenHintModal(false)} open={isOpenHintModal} footer={false}>
        <Modal.Header>
          <Typography type="h3">Discount Type</Typography>
        </Modal.Header>

        <Modal.Content>
          <div className={s.hintWrapper}>
            {createEventCouponType.map((item) => (
              <div key={item.key}>
                <Typography type="h5">{item.label}</Typography>
                <Typography type="main" className={s.hintText}>
                  {item.description}
                </Typography>
              </div>
            ))}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CreateCouponTypes;
