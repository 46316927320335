import { FC } from 'react';

import { FormInstance } from 'antd/es/form/hooks/useForm';
import { t } from 'tools/i18n';
import { Checkbox, Form, Input, Typography } from 'ui';

import s from '../BankDetails.module.scss';
import { BANK_DETAILS_FORM_ENUM } from '../bankDetails.utils';

interface BankDetailsStripeProps {
  form: FormInstance;
}

const BankDetailsStripe: FC<BankDetailsStripeProps> = (props) => {
  const { form } = props;
  const isCheckedNoStripeUsage = Form.useWatch('notStripeUsed', form);

  return (
    <div className={s.stripe}>
      {!isCheckedNoStripeUsage && (
        <div>
          <Typography type="main">{t('bank_info_stripe_title')}</Typography>
          <div style={{ marginTop: 12 }}>
            <Typography type="small" className={s.label}>
              {t('bank_info_stripe_id')}
            </Typography>
            <Form.Item name={BANK_DETAILS_FORM_ENUM.BANK_STRIPE_ACCOUNT_NUMBER} fullWidth>
              <Input name={BANK_DETAILS_FORM_ENUM.BANK_STRIPE_ACCOUNT_NUMBER} size="large" />
            </Form.Item>
          </div>
        </div>
      )}
      {isCheckedNoStripeUsage && (
        <div className={s.column}>
          <div>
            <Typography type="small" className={s.label}>
              {t('bank_info_iban_label')}
            </Typography>
            <Form.Item name={BANK_DETAILS_FORM_ENUM.BANK_IBAN_NUMBER} fullWidth>
              <Input name={BANK_DETAILS_FORM_ENUM.BANK_IBAN_NUMBER} size="large" />
            </Form.Item>
          </div>

          <div>
            <Typography type="small" className={s.label}>
              {t('bank_info_account_holder_name')}
            </Typography>
            <Form.Item name={BANK_DETAILS_FORM_ENUM.BANK_HOLDER_NAME} fullWidth>
              <Input name={BANK_DETAILS_FORM_ENUM.BANK_HOLDER_NAME} size="large" />
            </Form.Item>
          </div>
        </div>
      )}

      <Form.Item className={s.noStripeCheckbox} name="notStripeUsed">
        <Checkbox>
          <Typography type="main">{t('bank_info_no_stripe')}</Typography>
        </Checkbox>
      </Form.Item>
    </div>
  );
};

export default BankDetailsStripe;
