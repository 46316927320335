import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { Form, Input, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { SH_URL } from 'constants/core-constants';

import s from '../OrganiserDetails.module.scss';
import { ORGANISER_DETAILS_FORM } from '../organiserDetailsForm.utils';

const YourPage: FC = () => {
  return (
    <div>
      <Typography type="small" className={cn(s.label, s.required)}>
        {t('common_your_page')}
      </Typography>

      <div className={s.yourSiteWrap}>
        <Typography type="main" className={s.shUrl}>
          {SH_URL}
        </Typography>
        <Form.Item name={ORGANISER_DETAILS_FORM.COMPANY_URL} rules={[formRules.eventUrl]} fullWidth>
          <Input fullWidth name={ORGANISER_DETAILS_FORM.COMPANY_URL} size="large" />
        </Form.Item>
      </div>
    </div>
  );
};

export default YourPage;
