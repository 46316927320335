import React, { FC } from 'react';

import SectionTitle from 'component/section-title/sectionTitle';
import { Icon } from 'ui';

import s from './Features.module.scss';
import { featureData } from './feature.util';
import FeatureCard from './featureCard';

const Features: FC = () => {
  const chunkSize = Math.ceil(featureData.length / 3);
  return (
    <div className={s.bigContainter}>
      <SectionTitle label="Available Tools" className={s.title} />
      <div className={s.featuresContainter}>
        <div className={s.featureColumn}>
          {featureData.slice(0, chunkSize).map((feature, index) => (
            <div key={index} className="mx-6 sm:mx-0">
              <FeatureCard
                iconUrl={feature.iconUrl}
                title={feature.title}
                items={feature.items}
                url={feature.url}
              />
            </div>
          ))}
        </div>
        <div className={s.featureColumn}>
          {featureData.slice(chunkSize, chunkSize * 2).map((feature, index) => (
            <div key={index} className="mx-6 sm:mx-0">
              <FeatureCard
                iconUrl={feature.iconUrl}
                title={feature.title}
                items={feature.items}
                url={feature.url}
              />
            </div>
          ))}
        </div>
        <div className={s.featureColumn}>
          {featureData.slice(chunkSize * 2).map((feature, index) => (
            <div key={index} className="mx-6 sm:mx-0">
              <FeatureCard
                iconUrl={feature.iconUrl}
                title={feature.title}
                items={feature.items}
                url={feature.url}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={s.banner}>
        <div className={s.bannerBlock}>
          <Icon name="logo" width={252} height={40} />
          <h2 className={s.bannerTitle}>Download our app</h2>
          <h5 className={s.bannerText}>to scan tickets and follow your ticket sales</h5>
          <div className={s.bannerButtons}>
            <a href="https://apps.apple.com/mt/app/showsmanager/id1591231466" target="_blank">
              <img src="/assets/images/apple.webp" alt="app store" width={162} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.iu.showsmanager"
              target="_blank">
              <img src="/assets/images/google.webp" alt="google play" width={162} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
