import ParentDatePicker, { DatePickerOnChangeParams, DatePickerProps } from './datePicker';
import Time from './time';

export type CompoundedComponent = typeof ParentDatePicker & {
  TimePicker: typeof Time;
};

const DatePicker = ParentDatePicker as CompoundedComponent;
DatePicker.TimePicker = Time;

export type { DatePickerProps, DatePickerOnChangeParams };
export default DatePicker;
