import { useCallback, useEffect, useState } from 'react';

import { DatePicker, Radio, RadioChangeEvent } from 'antd';
import Pagination from 'component/pagination';
import SectionTitle from 'component/section-title/sectionTitle';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Table } from 'ui';

import { useLazyGetEventScanHistoryQuery } from 'services/scan/scanApiService';

import s from './EventScanning.module.scss';
import { ScanFilterType, initialFilter } from './scan.utils';

const { RangePicker } = DatePicker;

const EventScanningPage = () => {
  const { eventId } = useParams();
  const [selectedRange, setSelectedRange] = useState('allTime');
  const [getEventScanHistory, { data, isLoading, error }] = useLazyGetEventScanHistoryQuery();
  const [requestFilter, setRequestFilter] = useState<ScanFilterType>(initialFilter);
  const [rangeDates, setRangeDates] = useState<[Dayjs | null, Dayjs | null] | undefined>(undefined);

  useEffect(() => {
    if (eventId && requestFilter) {
      getEventScanHistory({ ...requestFilter, EventId: eventId });
    }
  }, [eventId, requestFilter, getEventScanHistory]);

  const handleRangeChange = useCallback((dates) => {
    const fromString = dates && dates[0] ? dates[0].format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : '';
    const toString = dates && dates[1] ? dates[1].format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : '';
    if (fromString !== null && toString !== null) {
      setRequestFilter((prevState) => ({
        ...prevState,
        DateFrom: fromString,
        DateTo: toString,
        PageNumber: 1,
      }));
    }
  }, []);

  const handleRadioChange = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedRange(value);
    let fromDate: moment.Moment | null = null;
    let toDate: moment.Moment | null = null;

    switch (value) {
      case 'today':
        fromDate = moment().subtract(1, 'days');
        toDate = moment();
        break;
      case 'yesterday':
        fromDate = moment().subtract(2, 'days');
        toDate = moment().subtract(1, 'days');
        break;
      case 'beforeWeek':
        fromDate = moment().subtract(7, 'days');
        toDate = moment();
        break;
      case 'allTime':
        fromDate = null;
        toDate = null;
        break;
      default:
        break;
    }

    handleRangeChange([fromDate, toDate]);
    const fromDateDayjs = fromDate ? dayjs(fromDate.toISOString()) : null;
    const toDateDayjs = toDate ? dayjs(toDate.toISOString()) : null;

    const selectedDates: [Dayjs | null, Dayjs | null] = [fromDateDayjs, toDateDayjs];
    setRangeDates(selectedDates);
  };

  const columns = [
    {
      title: 'DATE',
      dataIndex: 'scannedDate',
      key: 'scannedDate',
      render: (date) => {
        return <div>{dayjs(date).format('DD.MM.YYYY')}</div>;
      },
    },
    {
      title: 'FIRST NAME',
      dataIndex: 'order',
      key: 'firstName',
      render: (order) => <div>{order?.user?.firstName}</div>,
    },
    {
      title: 'LAST NAME',
      dataIndex: 'order',
      key: 'lastName',
      render: (order) => <div>{order?.user?.lastName}</div>,
    },
    {
      title: 'E-MAIL',
      dataIndex: 'order',
      key: 'eMail',
      render: (order) => <div>{order?.user?.email}</div>,
    },
    {
      title: 'WHO SCANNED',
      dataIndex: 'scannedBy',
      key: 'scannedBy',
      render: (data) => <div>{data?.fullName}</div>,
    },
  ];

  const showedElements =
    Number(data?.history?.length) + (Number(data?.pageNumber) - 1) * Number(requestFilter.PageSize);

  const options = [
    { label: 'All Time', value: 'allTime' },
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'Past Week', value: 'beforeWeek' },
  ];

  return (
    <div className={s.wrapper}>
      <div className={s.block}>
        <div>
          <SectionTitle content="Scanning information" className={s.title} withOutMargin />
        </div>
      </div>
      <div>
        <div className={s.header}>
          <RangePicker
            value={rangeDates}
            onCalendarChange={handleRangeChange}
            className={s.rangePicker}
          />
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            options={options}
            onChange={handleRadioChange}
            value={selectedRange}></Radio.Group>
        </div>
      </div>
      <Table
        style={{ marginTop: 28 }}
        dataSource={!error ? data?.history : []}
        loading={isLoading}
        columns={columns}
        pagination={false}
        scroll={{ x: 768 }}
      />
      {showedElements > 0 && (
        <Pagination
          totalElements={data?.totalRecords}
          showedElements={showedElements}
          currentPage={requestFilter?.PageNumber || 0}
          total={data?.totalPages}
          handlePageChange={(page) =>
            setRequestFilter((prevValue) => ({ ...prevValue, PageNumber: page }))
          }
          handleSizeChange={(size) =>
            setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
          }
        />
      )}
    </div>
  );
};

export default EventScanningPage;
