import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import { Dropdown, MenuProps } from 'antd';
import Chart, { ChartData, ChartOptions } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import cn from 'classnames';
import SectionTitle from 'component/section-title/sectionTitle';
import dayjs from 'dayjs';
import { Icon, Typography } from 'ui';
import { colors, gradientGenerator } from 'utils/colors';

import { EventTicketMetricsType } from 'types/events/metricsTypes';

import s from './DashboardTicketsTypeSoldChart.module.scss';

interface DashboardTicketsTypeSoldChartProps {
  data?: EventTicketMetricsType[];
  soldToday: number;
  selectedDays: number;
  setDays: Dispatch<SetStateAction<number>>;
}

const dateMenu: MenuProps['items'] = [
  { key: '7', label: <div className={s.dropdownLabel}>Past 7 days</div> },
  { key: '14', label: <div className={s.dropdownLabel}>Past 14 days</div> },
  { key: '30', label: <div className={s.dropdownLabel}>Past 30 days</div> },
];

const DashboardTicketsTypeSoldChart: FC<DashboardTicketsTypeSoldChartProps> = ({
  data,
  soldToday,
  selectedDays,
  setDays,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstanceRef = useRef<Chart<'line'> | null>(null);
  const isChartInitializedRef = useRef(false);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [openTypeDropdown, setOpenTypeDropdown] = useState(false);
  const [openDateDropdown, setOpenDateDropdown] = useState(false);

  const allData = data
    ?.map((item) => item.listOfTicketTypes?.map((d) => d.numberOfTicketsSold))
    .flat();
  const totalNumberOfTicketsSold = allData?.reduce((a, b) => a + b, 0);

  const dataWithColors = useMemo(() => {
    return data?.map((item) => ({
      ...item,
      listOfTicketTypes: item.listOfTicketTypes.map((type, i) => ({
        ...type,
        color: colors[i].color,
        gradient: colors[i].gradient,
      })),
    }));
  }, [data]);

  const ticketTypes =
    dataWithColors && dataWithColors[0]
      ? dataWithColors[0].listOfTicketTypes.map((type) => ({
          type: type.ticketTypeName,
          color: type.color,
        }))
      : [];

  const handleClickDropdownItems = (event: { key: string }) => {
    setOpenDateDropdown(false);
    setDays(Number(event.key));
  };

  const menu: MenuProps['items'] = ticketTypes?.map(({ type, color }) => ({
    key: type,
    label: (
      <div className={s.customCheckbox}>
        <input
          type="checkbox"
          id={type}
          checked={selectedLabels.includes(type)}
          onClick={(event) => event.stopPropagation()} // Prevent the option from being immediately deselected
          onChange={(event) => {
            if (event.target.checked) {
              setSelectedLabels((prev) => [...prev, type]);
            } else {
              setSelectedLabels((prev) => prev.filter((t) => t !== type));
            }
          }}
        />
        <label htmlFor={type}>
          <div
            className={s.checkboxBox}
            style={{
              backgroundColor: selectedLabels.includes(type) ? color : 'transparent',
              borderColor: color || '#B1C2CF',
            }}></div>

          <span className={s.dropdownLabel}>{type}</span>
        </label>
      </div>
    ),
  }));

  useEffect(() => {
    if (!chartRef.current) return;

    const chartOptions: ChartOptions<'line'> = {
      responsive: true,
      layout: {
        autoPadding: true,
        padding: {
          top: 12,
        },
      },
      maintainAspectRatio: false,
      interaction: {
        intersect: false,
      },
      animation: false,
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
      },
      elements: {
        line: {
          borderWidth: 2, // Increase line width
          borderColor: 'rgba(0, 0, 0, 0.5)', // Set line color
          backgroundColor: 'rgba(0, 0, 0, 0.1)', // Set fill color
          fill: '-1', // Fill area under the line
        },
      },
      scales: {
        x: {
          position: 'bottom',
          border: {
            dash: [4, 5],
          },
          beginAtZero: true,
          grid: {
            color: 'rgba(0, 0, 0, 0.1)',
            drawTicks: false,
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false,
            drawTicks: false,
          },
          ticks: {
            display: false,
            stepSize: 11,
          },
        },
      },
    };

    const filteredData = dataWithColors?.map((item) => ({
      date: item.date,
      listOfTicketTypes: item.listOfTicketTypes.filter((type) =>
        selectedLabels.includes(type.ticketTypeName),
      ),
    }));

    const chartData: ChartData<'line'> = {
      labels: filteredData?.map((item) => dayjs(item.date).format('ddd')),
      datasets:
        (!!filteredData?.length &&
          filteredData[0]?.listOfTicketTypes.map((type) => ({
            data: filteredData?.map((item) => {
              const ticketType = item.listOfTicketTypes.find(
                (t) => t.ticketTypeName === type.ticketTypeName,
              );
              return ticketType ? ticketType.numberOfTicketsSold : 0;
            }),
            label: type.ticketTypeName,
            backgroundColor: type.gradient || 'transparent',
            borderWidth: 2.5,
            borderColor: type.color || 'black',
            fill: true,
            datalabels: {
              display: false,
            },
          }))) ||
        [],
    };

    const maxData = filteredData?.map((item, index) => {
      let max = 0;
      chartData.datasets.forEach((dataset) => {
        if (Number(dataset.data[index]) > max) {
          max = Number(dataset.data[index]);
        }
      });
      return max;
    });

    chartData.datasets.push({
      data: maxData || [],
      fill: false,
      datalabels: {
        anchor: 'end',
        align: 'top',
        color: '#7F86A4',
        backgroundColor: 'transparent',
        font: {
          size: 14,
        },
        offset: (context) => {
          const scale = context.chart.scales['y'];
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return scale.getPixelForValue(Number(value)) - scale.top - 11;
        },
      },
    });

    const ctx = chartRef.current.getContext('2d');

    if (ctx) {
      gradientGenerator(ctx);
      if (isChartInitializedRef.current && chartInstanceRef.current) {
        chartInstanceRef.current.data.labels = chartData.labels;
        chartInstanceRef.current.data.datasets = chartData.datasets;
        chartInstanceRef.current?.update();
      } else {
        chartInstanceRef.current = new Chart(ctx, {
          type: 'line',
          data: chartData,
          options: chartOptions,
          plugins: [ChartDataLabels],
        });
        isChartInitializedRef.current = true;
      }
    }
  }, [data, selectedLabels, dataWithColors]);

  useEffect(() => {
    if (data?.length) {
      setSelectedLabels(data[0].listOfTicketTypes.map((type) => type.ticketTypeName));
    }
  }, [data]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <SectionTitle content="Tickets Sold" className={s.title} withOutMargin />
          <Typography type="main" color="grey">
            Quantity of tickets sold
          </Typography>
        </div>
        <div className={s.actions}>
          <div className={s.actions}>
            <Dropdown
              menu={{ items: dateMenu, onClick: handleClickDropdownItems }}
              open={openDateDropdown}
              trigger={['click']}
              onOpenChange={setOpenDateDropdown}
              className={s.dropdownContent}
              placement="bottom">
              <div className={s.dropdownButton}>
                <Typography type="h5" weight={500} className={s.dropdownTitle}>
                  Past {selectedDays} days
                </Typography>
                <Icon
                  name="arrowDropdown"
                  className={cn(s.arrowDropdown, { [s.open]: openDateDropdown })}
                />
              </div>
            </Dropdown>
            <Dropdown
              menu={{ items: menu }}
              trigger={['click']}
              open={openTypeDropdown}
              onOpenChange={setOpenTypeDropdown}
              className={s.dropdownContent}
              placement="bottom">
              <div className={s.dropdownButton}>
                <Typography type="h5" className={s.dropdownTitle}>
                  Ticket Types
                </Typography>
                <Icon
                  name="arrowDropdown"
                  className={cn(s.arrowDropdown, { [s.open]: openTypeDropdown })}
                />
              </div>
            </Dropdown>
          </div>

          <div className={s.totalBlock}>
            <Typography type="h3" className={s.totlal}>
              {totalNumberOfTicketsSold}
            </Typography>
            <Typography type="main" weight={500}>
              <span className={s.green}>+{soldToday} today</span>
            </Typography>
          </div>
        </div>
      </div>

      <div className={s.chartContainer}>
        <canvas
          id="dashboardTicketsTypeSoldChart"
          ref={chartRef}
          style={{ width: '100%', height: '100%' }}
        />
      </div>
    </div>
  );
};

export default DashboardTicketsTypeSoldChart;
