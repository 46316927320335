import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { Select } from 'antd';
import LayoutSwitcher from 'component/layout-switcher';
import { useNavigate } from 'react-router-dom';
import { CREATE_EVENT_FORM_ROUTE } from 'routes/events/list';
import { t } from 'tools/i18n';
import { Button, Form, Icon, Input } from 'ui';

import { setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import { SortByEnum } from '../events.utils';
import s from './EventsHeader.module.scss';

interface EventsHeaderProps {
  isBlockView: boolean;
  setIsBlockView: Dispatch<SetStateAction<boolean>>;
  sortBy: SortByEnum;
  setSortBy: Dispatch<SetStateAction<SortByEnum>>;
}

const EventsHeader: FC<EventsHeaderProps> = (props) => {
  const { isBlockView, setIsBlockView, sortBy, setSortBy } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleNavigateToCreateEventForm = useCallback(() => {
    dispatch(setEditingEventId(''));
    navigate(CREATE_EVENT_FORM_ROUTE);
  }, [dispatch, navigate]);

  return (
    <div className={s.header}>
      <div className={s.searchBlock}>
        <Form.Item name="eventName" fullWidth>
          <Input name="eventName" placeholder="Event name" icon="search" fullWidth />
        </Form.Item>
        <Form.Item name="sortBy">
          <Select
            style={{ height: '100%', width: '160px', backgroundColor: 'white' }}
            onChange={(value) => setSortBy(value)}
            options={[
              { value: 'PublishedDate', label: 'Published Date' },
              { value: 'Date', label: 'Date' },
            ]}
            defaultValue={sortBy}
          />
        </Form.Item>
      </div>

      <div className={s.actions}>
        <Button className={s.eventButton} onClick={handleNavigateToCreateEventForm}>
          <Icon name="plus" />
          {t('common_create_event')}
        </Button>
        <div className={s.row}>
          <Button color="tertiary">
            <Icon name="refreshArrows" />
          </Button>
          <LayoutSwitcher value={isBlockView} onChange={setIsBlockView} />
        </div>
      </div>
    </div>
  );
};

export default EventsHeader;
