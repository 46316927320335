import { FC, useCallback, useEffect, useState } from 'react';

import Pagination from 'component/pagination';
import { useNavigate } from 'react-router-dom';
import { getOrdersFullRoute } from 'routes/tikets/list';
import { handleCutString } from 'tools/cutstring';
import { Form, Icon, Table, TableProps, Tooltip } from 'ui';

import { useLazyGetEventOrdersQuery } from 'services/events/eventsApiService';

import { CURRENCIES_SETTING } from 'constants/core-constants';
import { SORT_OPTIONS_ORDERS } from 'constants/sort';
import { OrderTableType, TicketType } from 'types/tickets/tickets.types';

import OrdersHeader from '../orders-header/ordersHeader';
import { OrdersFilterType, initialFilter } from '../orders.utils';

interface OrdersListProps {
  currency: string | undefined;
  eventId: string | undefined;
  eventName: string | undefined;
  flyer: string | undefined;
}

const OrdersList: FC<OrdersListProps> = (props) => {
  const { currency, eventId, eventName, flyer } = props;
  const [getEventOrders, { data: orders, isLoading }] = useLazyGetEventOrdersQuery();
  const [form] = Form.useForm();
  const searchString = Form.useWatch('eventName', form);
  const [requestFilter, setRequestFilter] = useState<OrdersFilterType>(initialFilter);
  const [tableData, setTableData] = useState<OrderTableType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (eventId && requestFilter) {
      getEventOrders({ ...requestFilter, id: eventId });
    }
  }, [eventId, requestFilter, getEventOrders]);

  const handleTableChange = useCallback<NonNullable<TableProps['onChange']>>(
    (_pag, _filter, sorter) => {
      if (!Array.isArray(sorter)) {
        if (sorter.order) {
          setRequestFilter((prevState) => ({
            ...prevState,
            SortType: sorter.order === 'ascend' ? 'Ascending' : 'Descending',
            SortByColumn: sorter.field === 'orderDate' ? 'byDate' : undefined,
          }));
        } else {
          setRequestFilter((prevState) => ({
            ...prevState,
            SortType: '',
            SortByColumn: '',
          }));
        }
      }
    },
    [],
  );

  const handleRangeChange = useCallback((dates: [string, string]) => {
    const fromString = dates ? dates[0] : '';
    const toString = dates ? dates[1] : '';
    if (fromString !== null && toString !== null) {
      setRequestFilter((prevState) => ({
        ...prevState,
        DateFrom: fromString,
        DateTo: toString,
        PageNumber: 1,
      }));
    }
  }, []);

  useEffect(() => {
    if (searchString?.length > 3) {
      setRequestFilter((prevState) => ({
        ...prevState,
        filter: searchString,
      }));
    } else if (searchString?.length === 0) {
      setRequestFilter((prevState) => ({
        ...prevState,
        filter: '',
      }));
    }
  }, [searchString]);

  useEffect(() => {
    const updatedData = orders?.orders.map((element) => ({
      ...element,
      eventName: eventName as string,
      flyer: flyer as string,
    }));
    setTableData(updatedData!);
  }, [orders, setTableData, eventName, flyer]);

  const showedElements =
    Number(orders?.orders?.length) +
    (Number(orders?.pageNumber) - 1) * Number(requestFilter.PageSize);

  const handlePageChange = useCallback((PageNumber: number) => {
    setRequestFilter((prevState) => ({
      ...prevState,
      PageNumber,
    }));
  }, []);

  const columns = [
    {
      title: 'Event name',
      dataIndex: 'eventName',
      key: 'eventName',
      render: (eventName, record) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <img
            src={record.flyer}
            alt="Event flyer"
            style={{ width: 48, height: 48, borderRadius: '100%' }}
          />
          <Tooltip title={eventName} placement="top">
            <span>
              {eventName.length > 30 ? handleCutString(eventName, 30, false, 10) : eventName}
            </span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      key: 'orderDate',
      sorter: !!handleTableChange,
      sortDirections: SORT_OPTIONS_ORDERS,
      showSorterTooltip: false,
    },
    { title: 'Order Number', dataIndex: 'orderNo', key: 'orderNo' },
    {
      title: 'Buyer Name',
      dataIndex: 'user',
      key: 'buyerName',
      render: (user) => `${user.firstName} ${user.lastName}`,
    },

    {
      title: 'Status',
      dataIndex: 'orderStatus',
      key: 'status',
      render: (status) => {
        let icon;
        let color = '';
        if (status === 'completed') {
          icon = <Icon name="complete" width={12} height={12} />;
          color = '#02C29A';
        } else {
          icon = <Icon name="refund" />;
          color = '#FF4B53';
        }
        return (
          <span style={{ color, display: 'flex', alignItems: 'center', gap: '8px' }}>
            {icon} {status}
          </span>
        );
      },
    },
    { title: 'Tickets', dataIndex: 'numberOfPersons', key: 'tickets' },
    {
      title: 'Value',
      dataIndex: 'orderSubTotal',
      key: 'value',
      render: (value) =>
        `${currency && CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol} ${value.toFixed(2)}`,
    },
  ];
  return (
    <div>
      <Form form={form}>
        <OrdersHeader onRangeChange={handleRangeChange} />
      </Form>
      <Table
        style={{ marginTop: 28 }}
        dataSource={tableData}
        loading={isLoading}
        columns={columns}
        pagination={false}
        onChange={handleTableChange}
        rowKey="orderId"
        onRow={(record: TicketType) => {
          return {
            onClick: () => {
              navigate(getOrdersFullRoute(record.orderId));
            },
          };
        }}
        scroll={{ x: 768 }}
      />
      {showedElements > 0 && (
        <Pagination
          totalElements={orders?.totalRecords}
          showedElements={showedElements}
          currentPage={requestFilter?.PageNumber || 0}
          total={orders?.totalPages}
          handlePageChange={handlePageChange}
          handleSizeChange={(size) =>
            setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
          }
        />
      )}
    </div>
  );
};

export default OrdersList;
