import { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import { Dropdown, MenuProps } from 'antd';
import { Chart } from 'chart.js';
import cn from 'classnames';
import SectionTitle from 'component/section-title/sectionTitle';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { Icon, Typography } from 'ui';
import { colors } from 'utils/colors';

import { Font } from 'chartjs-plugin-datalabels/types/options';
import { CURRENCIES_SETTING } from 'constants/core-constants';
import { EventTicketMetricsType } from 'types/events/metricsTypes';

import s from './DashboardTicketsRevenueChart.module.scss';

interface DashboardTicketsRevenueChartsProps {
  data?: EventTicketMetricsType[];
  selectedDays: number;
  setDays: Dispatch<SetStateAction<number>>;
  todayValue: number;
  currency: string;
}

const dateMenu: MenuProps['items'] = [
  { key: '7', label: <div className={s.dropdownLabel}>Past 7 days</div> },
  { key: '14', label: <div className={s.dropdownLabel}>Past 14 days</div> },
  { key: '30', label: <div className={s.dropdownLabel}>Past 30 days</div> },
];

const DashboardTicketsRevenueCharts: FC<DashboardTicketsRevenueChartsProps> = ({
  data,
  setDays,
  selectedDays,
  todayValue,
  currency,
}) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstanceRef = useRef<Chart | null>(null);
  const [selectedLabels, setSelectedLabels] = useState<string[]>([]);
  const [openTypeDropdown, setOpenTypeDropdown] = useState(false);
  const [openDateDropdown, setOpenDateDropdown] = useState(false);
  const allData = data
    ?.map((item) => item.valueOfTicketsSold?.map((d) => d.valueOfTicketsSold))
    .flat();
  const totalNumberOfTicketsSold = allData?.reduce((a, b) => a + b, 0);

  const dataWithColors = useMemo(() => {
    return data?.map((item) => ({
      ...item,
      valueOfTicketsSold: item.valueOfTicketsSold.map((type, i) => ({
        ...type,
        color: colors[i].color,
        gradient: colors[i].gradient,
      })),
    }));
  }, [data]);

  const ticketTypes =
    dataWithColors && dataWithColors[0]
      ? dataWithColors[0].valueOfTicketsSold.map((type) => ({
          type: type.ticketTypeName,
          color: type.color,
        }))
      : [];

  const menu: MenuProps['items'] = ticketTypes?.map(({ type, color }) => ({
    key: type,
    label: (
      <div className={s.customCheckbox}>
        <input
          type="checkbox"
          id={type}
          checked={selectedLabels.includes(type)}
          onClick={(event) => event.stopPropagation()} // Prevent the option from being immediately deselected
          onChange={(event) => {
            if (event.target.checked) {
              setSelectedLabels((prev) => [...prev, type]);
            } else {
              setSelectedLabels((prev) => prev.filter((t) => t !== type));
            }
          }}
        />
        <label htmlFor={type}>
          <div
            className={s.checkboxBox}
            style={{
              backgroundColor: selectedLabels.includes(type) ? color : 'transparent',
              borderColor: color || '#B1C2CF',
            }}></div>

          <span className={s.dropdownLabel}>{type}</span>
        </label>
      </div>
    ),
  }));

  const handleClickDropdownItems = (event: { key: string }) => {
    setOpenDateDropdown(false);
    setDays(Number(event.key));
  };

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx && data) {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        const ticketTypes =
          dataWithColors &&
          dataWithColors[0].valueOfTicketsSold.map((ticket) => ({
            type: ticket.ticketTypeName,
            color: ticket.color,
          }));
        const datasets = ticketTypes?.map(({ type: ticketType, color }, index) => ({
          label: ticketType,
          data: data.map((item) => {
            const ticket = item.valueOfTicketsSold.find((t) => t.ticketTypeName === ticketType);
            return ticket ? ticket.valueOfTicketsSold : 0;
          }),
          backgroundColor: color,
          borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
            Math.random() * 255,
          )}, ${Math.floor(Math.random() * 255)}, 1)`,
          borderWidth: 1.5,
          stack: index % 2 === 0 ? 'Stack 0' : 'Stack 1',
          barPercentage: 1.0,
          offset: 100,
          maxBarThickness: 28,
          datalabels: {
            display: true,
            color: 'black',
            backgroundColor: 'transparent',
            font: {
              weight: 'normal' as Font['weight'], // Explicitly define the type as Font['weight']
              size: 12,
            },
            offset: () => 0,
          },
        }));

        chartInstanceRef.current = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: data.map((item) => dayjs(item.date).format('ddd')),
            datasets: datasets || [],
          },
          // plugins: [ChartDataLabels],
          options: {
            scales: {
              x: {
                border: {
                  dash: [4, 5],
                },
                offset: true,
                beginAtZero: true,
                grid: {
                  color: '#E0E0E0',
                  drawTicks: false,
                },
              },
              y: {
                beginAtZero: true,
                grid: {
                  display: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
            responsive: true,
            layout: {
              autoPadding: true,
              padding: {
                top: 12,
              },
            },
            maintainAspectRatio: false,
            interaction: {
              intersect: false,
            },
            animation: false,
            plugins: {
              legend: {
                display: false, // Hide the legend
              },
            },
          },
        });
      }
    }

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [data, dataWithColors]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <SectionTitle content="Revenue" className={s.title} withOutMargin />
          <Typography type="main" color="grey">
            Value of tickets sold
          </Typography>
        </div>
        <div className={s.actions}>
          <div className={s.actions}>
            <Dropdown
              menu={{ items: dateMenu, onClick: handleClickDropdownItems }}
              open={openDateDropdown}
              trigger={['click']}
              onOpenChange={setOpenDateDropdown}
              className={s.dropdownContent}
              placement="bottom">
              <div className={s.dropdownButton}>
                <Typography type="h5" weight={700} className={s.dropdownTitle}>
                  Past {selectedDays} days
                </Typography>
                <Icon
                  name="arrowDropdown"
                  className={cn(s.arrowDropdown, { [s.open]: openDateDropdown })}
                />
              </div>
            </Dropdown>
            <Dropdown
              menu={{ items: menu }}
              trigger={['click']}
              open={openTypeDropdown}
              onOpenChange={setOpenTypeDropdown}
              className={s.dropdownContent}
              placement="bottom">
              <div className={s.dropdownButton}>
                <Typography type="h5" weight={700} className={s.dropdownTitle}>
                  Ticket Types
                </Typography>
                <Icon
                  name="arrowDropdown"
                  className={cn(s.arrowDropdown, { [s.open]: openTypeDropdown })}
                />
              </div>
            </Dropdown>
          </div>
          <div className={s.totalBlock}>
            <Typography type="h3" className={s.totlal}>
              {currency && CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol}{' '}
              {totalNumberOfTicketsSold}
            </Typography>
            <Typography type="main" weight={500}>
              <span className={s.green}>
                + {currency && CURRENCIES_SETTING[currency?.toUpperCase()]?.symbol} {todayValue}{' '}
                today
              </span>
            </Typography>
          </div>
        </div>
      </div>

      <div className={s.chartContainer}>
        <canvas ref={chartRef} style={{ width: '100%', height: '100%' }} />
      </div>
    </div>
  );
};

export default DashboardTicketsRevenueCharts;
