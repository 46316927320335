import { Typography } from 'ui';

import s from './DashboardDateTab.module.scss';

interface DateCardProps {
  date: string;
  status: string;
  dateId: string;
  isPastDate: boolean;
  time: string;
  eventId: string;
  onClick: (eventId: string, dateId: string, date: string) => void;
  isActive: boolean;
}

const DateCard: React.FC<DateCardProps> = ({
  date,
  status,
  dateId,
  eventId,
  time,
  isPastDate,
  onClick,
  isActive,
}) => {
  const statusClass =
    isPastDate && status.toLowerCase() === 'available'
      ? s['pastAvailable']
      : isPastDate
      ? s['pastDate']
      : s[status.toLowerCase()];

  const statusBorderClass = s[`${status.toLowerCase()}Border`];
  const activeClass = isActive ? s.active : '';

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1); // monthNumber is 1-based, while setMonth expects 0-based
    return date.toLocaleString('default', { month: 'long' });
  };

  const getStatusText = (status, isPastDate) => {
    switch (status.toLowerCase()) {
      case 'available':
        if (isPastDate) {
          return 'PAST EVENT';
        } else {
          return 'ON SALE';
        }
      case 'fewremaining':
        if (isPastDate) {
          return 'PAST EVENT & ALMOST SOLDOUT';
        } else {
          return 'ALMOST SOLD OUT';
        }
      case 'soldout':
        if (isPastDate) {
          return 'PAST EVENT & SOLD OUT';
        } else {
          return 'SOLD OUT';
        }
      case 'hidden':
        if (isPastDate) {
          return 'PAST EVENT & HIDDEN';
        } else {
          return 'HIDDEN';
        }
      case 'disabled':
        if (isPastDate) {
          return 'PAST EVENT & DISABLED';
        } else {
          return 'DISABLED';
        }
      case 'tickettypesnotavailable':
        if (isPastDate) {
          return 'PAST & TICKETS NOT AVAILABLE';
        } else {
          return 'TICKET TYPES NOT AVAILABLE';
        }
      default:
        return 'Status unknown.';
    }
  };

  const handleKeyPress = () => {};

  const getWeekdayName = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  };
  return (
    <div className={`${s.dateCardWrapper} ${activeClass}`}>
      <div
        className={`${s.dateCardBody} ${statusBorderClass}`}
        role="button"
        onKeyDown={handleKeyPress}
        tabIndex={0}
        onClick={() => onClick(eventId, dateId, date)}>
        <Typography type="h5" className={`${s.month} ${activeClass}`} weight={700}>
          {getMonthName(date.split('-')[1])}
        </Typography>
        <Typography type="h2" className={`${s.date} ${activeClass}`}>
          {date.split('-')[2]}
        </Typography>
        <Typography type="main" color="grey" className={`${s.time} ${activeClass}`}>
          {time}
        </Typography>
        <Typography type="main" color="grey" className={`${s.week} ${activeClass}`}>
          {getWeekdayName(date)}
        </Typography>
      </div>
      <div
        className={`${s.dateCardBottom} ${statusClass} ${activeClass}`}
        role="button"
        onKeyDown={handleKeyPress}
        tabIndex={0}
        onClick={() => onClick(eventId, dateId, date)}>
        {getStatusText(status, isPastDate)}
      </div>
    </div>
  );
};

export default DateCard;
