import { FC, PropsWithChildren, memo } from 'react';

import cn from 'classnames';

import s from './Layout.module.scss';

export interface LayoutProps {
  auth?: boolean;
  className?: string;
}

const Layout: FC<PropsWithChildren<LayoutProps>> = (props) => {
  const { auth, children, className = '' } = props;

  return (
    <div className={cn(s.layout, { [s.auth]: !!auth, [className]: className })}>{children}</div>
  );
};

export default memo(Layout);
