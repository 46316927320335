import React, { FC } from 'react';

import SectionTitle from 'component/section-title/sectionTitle';
import { Button, Icon, Typography } from 'ui';

import s from './Faq.module.scss';
import { faqData } from './faq.util';
import FaqCard from './faqCard';

const FAQ: FC = () => {
  const chunkSize = Math.ceil(faqData.length / 2);

  const goToNotion = () => {
    window.open(
      'https://showshappening.notion.site/ShowsManager-Help-FAQ-637a72445e2e4bc5b53684b2351ef81a',
      '_blank',
    );
  };
  return (
    <div className={s.bigContainter}>
      <SectionTitle label="ShowsManager Help & FAQ" className={s.title} />
      <div className={s.faqHeader}>
        <Typography type="h6">
          We have created this resource to help you get started with ShowsManager and
          ShowsHappening. Can&lsquo;t find what you&lsquo;re looking for? <br></br>Contact us at
          <a href="mailto:hello@showshappening.com" style={{ textDecoration: 'none' }}>
            {' '}
            hello@showshappening.com
          </a>
        </Typography>
      </div>
      <div className={s.faqCard}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
          <img src="/assets/images/notion.png" alt="featureImg" width={40} />
          <Typography type="h5">
            We made FAQ Notion page for you to understand our service better
          </Typography>
        </div>
        <Button onClick={goToNotion}>GO TO NOTION →</Button>
      </div>
      <div className={s.featuresContainter}>
        <div className={s.featureColumn}>
          {faqData.slice(0, chunkSize).map((feature, index) => (
            <div key={index} className="mx-6 sm:mx-0">
              <FaqCard iconUrl={feature.iconUrl} title={feature.title} items={feature.items} />
            </div>
          ))}
        </div>
        <div className={s.featureColumn}>
          {faqData.slice(chunkSize, chunkSize * 2).map((feature, index) => (
            <div key={index} className="mx-6 sm:mx-0">
              <FaqCard iconUrl={feature.iconUrl} title={feature.title} items={feature.items} />
            </div>
          ))}
        </div>
      </div>
      <div className={s.banner}>
        <div className={s.bannerBlock}>
          <Icon name="logo" width={252} height={40} />
          <h2 className={s.bannerTitle}>Download our app</h2>
          <h5 className={s.bannerText}>to scan tickets and follow your ticket sales</h5>
          <div className={s.bannerButtons}>
            <a href="https://apps.apple.com/mt/app/showsmanager/id1591231466" target="_blank">
              <img src="/assets/images/apple.webp" alt="app store" width={162} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.iu.showsmanager"
              target="_blank">
              <img src="/assets/images/google.webp" alt="google play" width={162} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
