import initialApiService from 'services/initialApiService';
import { TicketsFullInfoResponseType } from 'services/tickets/tickets.api.type';

const ticketsApiService = initialApiService.injectEndpoints({
  endpoints: (build) => ({
    getTickets: build.query<void, { TakeLastDays: number }>({
      query: ({ TakeLastDays }) => ({
        url: `tickets/lastorders`,
        method: 'GET',
        params: {
          TakeLastDays,
        },
      }),
    }),
    getOrderFullInfo: build.query<TicketsFullInfoResponseType, string>({
      query: (id) => ({
        url: `tickets/order/${id}`,
        method: 'GET',
      }),
    }),
    sendTicketToEmail: build.query<TicketsFullInfoResponseType, { OrderId: string; Email: string }>(
      {
        query: (params) => ({
          url: `tickets/email`,
          method: 'GET',
          params,
        }),
      },
    ),
  }),
});

export const {
  useLazyGetTicketsQuery,
  useLazyGetOrderFullInfoQuery,
  useLazySendTicketToEmailQuery,
} = ticketsApiService;
