import { Dispatch, FC, SetStateAction } from 'react';

import { Input } from 'antd';
import { Checkbox, Form, Icon, Label, Typography } from 'ui';

import { EventCreatedType } from 'types/events/eventTypes';
import { PromoterFullInfoType } from 'types/promoters/promoterTypes';

import s from '../PublishEventModalPromoterStep.module.scss';

interface PromoterStepVatInfoProps {
  isChargeVat: boolean;
  setIsChargeVat: Dispatch<SetStateAction<boolean>>;
  isEditingPromoter: boolean;
  promoter?: PromoterFullInfoType;
  activeEvent?: EventCreatedType;
}

const PromoterStepVatInfo: FC<PromoterStepVatInfoProps> = (props) => {
  const { isChargeVat, setIsChargeVat, isEditingPromoter, promoter, activeEvent } = props;

  return (
    <div className={s.vatInfo}>
      <Typography type="h3">VAT Related Info</Typography>
      {activeEvent?.taxDetails?.permitNumber !== null && (
        <div className={s.checkboxBlock}>
          <Checkbox value={isChargeVat} onChange={setIsChargeVat} />
          <Typography type="main" color="grey">
            Will you charge VAT?
          </Typography>
        </div>
      )}
      <div className={s.promoterWrapper}>
        <Typography type="main" weight={600} className={s.hint}>
          <div>
            <Icon name="warning" size={24} />
          </div>
          <div>
            Please read this carefully and submit correct information. It is your responsibility to
            ensure that all the information which you will submit is correct. SHowsHappening is not
            responsible for any incorrect information you submit. Your VAT details will be printed
            on the ticket
          </div>
        </Typography>
        <div className={s.promoterInfo}>
          <div>
            <Label className={s.label}>VAT Number</Label>
            {isEditingPromoter ? (
              <Form.Item
                name="billingVATNumber"
                initialValue={promoter?.billingVATNumber}
                rules={[{ required: true, message: '' }]}>
                <Input bordered={false} />
              </Form.Item>
            ) : (
              <Typography type="main" weight={600}>
                {promoter?.billingVATNumber}
              </Typography>
            )}
          </div>
          <div>
            <Label className={s.label}>VAT Permit Number</Label>
            {isEditingPromoter ? (
              <Form.Item
                initialValue={activeEvent?.taxDetails?.permitNumber}
                name="permitNumber"
                rules={[{ required: true, message: '' }]}>
                <Input bordered={false} />
              </Form.Item>
            ) : (
              <Typography type="main" weight={600}>
                {activeEvent?.taxDetails?.permitNumber}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoterStepVatInfo;
