import { PromoterType, UserProfileType } from 'types/core/userTypes';

export type EventType = {
  id: string;
  name: string;
};

export type EventTagType = {
  id: string;
  name: string;
  slug: string;
};

export type EventTagsResponseType = EventTagType[];

export type EventShowDateType = {
  startDate: string;
  startTime: string;
  endDate?: string;
  endTime?: string;
  doorsOpen?: string;
  duration?: { hours: number; minutes: number };
  showId?: string;
  isShowHidden?: boolean;
  beforeOrAfterEvent?: string;
  hoursWhenSalesEnd?: number;
};

export type MultipleDatesType = Omit<EventShowDateType, 'duration'> & {
  duration: number;
  showId?: string;
};

export type EventDatesType = {
  startDate: string;
  startTime: string;
  endDate?: string;
  endTime?: string;
  hoursWhenSalesEnd?: number;
  beforeOrAfterEvent?: string;
  isEndTimeApproximate?: boolean;
  caption?: string;
  doorsOpen?: string;
  isShowHidden?: boolean;
  isSoldOut?: boolean;
  ignoreSeatingAlgorithm?: boolean;
  showId?: string;
};

export type EventBasicInfoType = {
  promoterId: string;
  lastShowDate?: string;
  dateType: string;
  eventName: string;
  eventFlyer?: string;
  summary?: string;
  slug?: string;
  url: string;
  startSellingAt?: string;
  privateEvent?: boolean;
  categories: EventTagType[];
  tags: EventTagType[];
  venueType: string;
  maximumNumberOfAttendees?: number | null;
  currency?: string;
  status: string;
  location: {
    venueName: string;
    locationLink?: string;
    locationComment?: string;
    streetAddress: string;
    postCode?: string;
    country?: string;
    town?: string;
    fullAddress?: string;
    latLng: {
      lat: string;
      lng: string;
    };
  };
  eventType?: string;
  seatingPlanId?: string | null;
  showDates?: EventDatesType[];
};

export type EventCreateFormDetailsType = {
  summary?: string;
  fullDescription: string;
  minimumAge: string;
  freeUpToAge?: string;
  agesRequiringTicket?: string;
  childTicketRequired?: boolean;
  idRequired?: boolean;
  accessibleTicketsAvailable?: boolean;
  accessibleTicketsDescription?: string;
  fAQs?: Record<string, string>;
  promoterTerms?: string;
  showsHappeningTerms?: string;
};

export type EventCreateFormDetailsPropsType = {
  eventId: string;
  otherDetails: EventCreateFormDetailsType;
};

export type EventTicketType = {
  id: string;
  name: string;
  description: string;
  price: number;
  sortOrder: number;
  maxNumberOfTicketsToBeSold: number;
  showRemainingTickets: boolean;
  hideFromPublic: boolean;
  minQtyPerOrder: number;
  maxQtyPerOrder: number;
  colour: string;
  availibilityControlType?: TICKET_AVAILABILITY_CONTROL_TYPE;
  availibilityControlInfo?: {
    availableFrom?: string;
    availableTo?: string;
    dateNotYetAvailableDisplayType?: 'hidden' | 'disabled'; // Assuming these are the possible values
    linkToTickeTypeId?: string;
    percentageToActivate?: number;
  };
  issueType: 'normal' | 'guestList' | 'GuestListVIP' | 'Transport' | 'Combo' | 'Addon'; // Assuming there could be other types
  group: {
    name: string;
    description: string;
    sortOrder: number;
    eventId: string;
    id: string;
    imageURL: string;
  };
};

export type EventTicketGroupReqType = {
  id?: string;
  name: string;
  description: string;
  sortOrder: number;
  eventId: string;
  imageBase64: string;
};

export type EventTicketGroupResType = {
  id?: string;
  name: string;
  description: string;
  sortOrder: number;
  eventId: string;
  imageURL: string;
};

export type EventCreatedType = {
  eventId: string;
  basicInfo: EventBasicInfoType;
  publishedDateUTC: string;
  otherDetails: EventCreateFormDetailsType;
  taxDetails: {
    refundPolicy: string;
    permitNumber: string | null;
    vATRate: number;
  };
  contactDetails: {
    email: string;
    mobileNumber: string;
    address: string;
    town: string;
    postCode: string;
    country: string;
  };
  media: {
    flyer: string | null;
    watermarkedFlyer: string | null;
  };
  ticketTypes: EventTicketType[];
  promoteDetails?: {
    ticketTypesToConsiderForPromotionalOffers?: {
      qty: number;
      name: string;
      id: string;
    }[];
    eventArtistTags?: {
      name?: string;
      atName?: string;
      url?: string;
    }[];
    eventSponsorTags?: {
      name?: string;
      atName?: string;
      url?: string;
    }[];
    promotionalImages?: {
      fileName: string;
      id: string;
    }[];
    videoLink?: string;
    facebookLink?: string;
    instagramLink?: string;
    googleAnalyticsCode?: string;
    facebookPixelCode?: string;
    promotionalQuestionReplies?: {
      name?: string;
      questionId?: string;
      replyValue?: string;
    }[];
  };
};

export enum REFUND_POLICY_TYPE {
  DAY1_BEFORE_EVENT = 'Day1BeforeEvent',
  DAY7_BEFORE_EVENT = 'Day7BeforeEvent',
  DAY3_BEFORE_EVENT = 'Day3BeforeEvent',
  NO_REFUNDS = 'NoRefunds',
  DO_NOT_SPECIFY_REFUND = 'DoNotSpecifyRefund',
}

export enum TICKET_SALES_STATUS {
  AVAILABLE = 'available',
  FEW_REMAININT = 'fewRemaining',
  SOLD_OUT = 'soldOut',
  HIDDEN = 'hidden',
  DISABLED = 'disabled',
  TICKET_TYPES_NOT_AVAILABLE = 'ticketTypesNotAvailable',
}

export enum TICKET_AVAILABILITY_CONTROL_TYPE {
  MANUAL = 'manual',
  BY_DATE = 'byDate',
  BY_PERCENTAGE = 'byPercentage',
}

export type SingleEventType = {
  disableUserReviews: boolean;
  lastShowDate: string;
  firstShowDate: string;
  nextShowDate: string;
  promoter: PromoterType;
  tags: EventTagType[];
  venue: {
    name: string;
    address: string;
    googleLocation: string;
  };
  description: string;
  summary: string;
  scheduleType: string;
  permissions: string[];
  resources: string[];
  media: string[];
  socials: string[];
  canEdit: boolean;
  dates: [
    {
      dateId: string;
      date: string;
      timeFrom: string;
      dateTo: string;
      timeTo: string;
      doorsOpenAt: string;
    },
  ];
  ticketTypes: string[];
  maximumNumberOfTickets: number;
  eventFlyer: string;
  eventName: string;
  eventId: string;
};

export type TicketDataType = {
  type: string;
  qtyOfTicketsSold: number;
  maximumNumberOfTickets: number;
  today: {
    qtyOfTicketsSold: number;
    totalAmount: number;
    numberOfComplimentaries: number;
  };
  totalAmount: number;
  averageTicketPrice: number | string;
  numberOfComplimentaries: number;
};

export type EventMetricsType = {
  ticketTypes: TicketDataType[];
  currency?: string;
  eventId: string;
  eventName: string;
  maximumNumberOfAttendees: number;
  totalTicketsSold: {
    today: number;
    allTime: number;
  };
  totalSales: {
    today: number;
    allTime: number;
  };
  totalComplimentariesIssued: {
    today: number;
    allTime: number;
  };
};

export type EventPermissionsType = {
  permissionId: string;
  roleCode: string;
  roleName: string;
  user: UserProfileType;
};

export type EventWithMetricsType = {
  metrics: EventMetricsType;
  dates: {
    dateId: string;
    date: string;
    timeFrom: string;
    dateTo: string;
    timeTo: string;
    doorsOpenAt: string;
  }[];
  allDates: string;
  venue: {
    name: string;
    address: string;
    googleLocation: string;
  };
  pastEvent: boolean;
  permissions: string[];
  salesEndAt: string;
  salesEndAtCountdown: string;
  status: string;
  lastShowDate: string;
  eventScheduleType: string;
  promoter: PromoterType;
  eventFlyer: string;
  eventName: string;
  eventId: string;
  seatedStandingType: string;
  url: string;
};

export type EventCouponType = {
  id: string;
  code: string;
  eventId: string;
  deliveryType: string;
  discountType: string;
  couponType: string;
  email: string;
  disabled: true;
  restrictByIP: true;
  canBeRedeemedBySameUser: true;
  numberOfCouponsAvailable: number;
  minimumOrderTotal: number;
  maximumNumberOfTickets: number;
  totalDiscount: number;
  areTicketTypesEditable: boolean;
  couponTypeDescription: string;
  restrictedToSpecificShows: boolean;
  redeems: number;
  redeemsRemaining: number;
  creationDate: string;
  ticketTypes: {
    id: string;
    ticketTypeId: string;
    name: string;
    qty: number;
    value: number;
  }[];
  redeemed: boolean;
  orders: {
    orderId: string;
    orderNo: string;
    currency: string;
    orderDate: string;
    orderTotal: number;
    orderSubTotal: number;
    bookingFee: number;
    paymentType: string;
    orderStatus: string;
    user: {
      firstName: string;
      fullName: string;
      mobileNumber: string;
      lastName: string;
      userId: string;
      email: string;
    };
  }[];
  showRestrictions: {
    id: string;
    showId: string;
    date: string;
    time: string;
  }[];
};

export type AppliedTicketRequestType = {
  eventId: string;
  showId: string;
  sectionId: string;
  ticketTypeId: string;
  seats:
    | {
        seatId: string;
        ticketTypeId: string;
      }[]
    | null;
};

export type AppliedTicketResponseType = {
  eventId: string;
  ticketType: {
    name: string;
    ticketTypeId: string;
    description: string;
  };
  sectionType: string;
  sectionName: string;
  sectionId: string;
  showDate: string;
  showTime: string;
  eventName: string;
  showId: string;
  ticketTypeSeatsAllocated: {
    ticketTypeName: string;
    colour: string;
    seatId: string;
    ticketTypeId: string;
  }[];
};

type Section = {
  sectionId: string;
  displayName: string;
};

type DisableInfo = {
  disableType: string;
};

type SeatLocation = {
  locationId: string;
  name: string;
};

type SeatSection = {
  sectionId: string;
  displayName: string;
};

type Seat = {
  seatId: string;
  fullDisplayName: string;
  rowName: string;
  seatNumber: string;
  section: SeatSection;
  location: SeatLocation;
};

type DisabledSeat = {
  seat: Seat;
};

export type DisabledSectionResponse = {
  section: Section;
  isAvailable: boolean;
  disableInfo: DisableInfo;
  showId: string;
  showDate: string; // ISO 8601 date string
  showTime: string; // ISO 8601 time string
  disabledSeats: DisabledSeat[];
};

export type DisableSeatsResType = {
  section: { sectionId: string; displayName: string };
  isAvailable: boolean;
  disableInfo: { disableType: string };
  showId: string;
  showDate: string;
  showTime: string;
  disabledSeats: {
    seatId: string;
    fullDisplayName: string;
    rowName: string;
    seatNumber: string;
    section: { sectionId: string; displayName: string };
    location: { locationId: string; name: string };
  }[];
};

export enum DISABLE_TYPE {
  DISABLE = 'Disable',
  HIDE = 'Hide',
}

export type DisableSeatsReqType = {
  showId: string;
  sectionId: string;
  seats: { seatId: string }[];
  disableType: DISABLE_TYPE;
  comments: string;
};

export type EnableSeatsReqType = {
  showId: string;
  sectionId: string;
  seats: { seatId: string }[];
};
