import { FC } from 'react';

import Background from '../../../component/background';
import s from './Register.module.scss';
import RegisterBenefitsContent from './benefits-content';
import RegisterForm from './register-form';

const Register: FC = () => {
  return (
    <Background>
      <div className={s.wrapper}>
        <RegisterBenefitsContent />
        <RegisterForm />
      </div>
    </Background>
  );
};

export default Register;
