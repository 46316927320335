import React, { FC, useCallback, useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Switch } from 'antd';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { extractFlyerName } from 'tools/extractFlyerName';
import { useSetQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Form, Icon, Input, Label, Typography, Upload, message } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import {
  useDeletePromotionMediaMutation,
  useSetEventPromoDetailsMutation,
  useUploadPromotionMediaMutation,
} from 'services/events/eventsApiService';
import { setActiveEvent, setIsEditingEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

// import SiteFeatures from 'pages/events/events-create-event-form/event-create-form-promote-page/event-create-form-promote-page-features';
import PhoneMockup from 'pages/events/events-create-event-form/event-create-form-promote-page/event-create-form-promote-page-phone/PhoneMockup';
import TicketForm from 'pages/events/events-create-event-form/event-create-form-promote-page/event-create-form-promote-page-tickets';

import { VIDEO_PLACEHOLDER } from 'constants/core-constants';
import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType } from 'types/events/eventTypes';

import s from './EventCreateFormPromotePage.module.scss';

interface EventCreateFormPromotePageProps {
  // Define your component props here
}

const EventCreateFormPromotePage: FC<EventCreateFormPromotePageProps> = () => {
  const dispatch = useAppDispatch();
  const { eventId } = useParams();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [setPromoDetails, { isLoading }] = useSetEventPromoDetailsMutation();
  const [uploadPromotionMedia] = useUploadPromotionMediaMutation();
  const [deletePromotionMedia] = useDeletePromotionMediaMutation();
  const [promoteQuestion, setPromoteQuestion] = useState<Record<string, boolean>>({});
  const [isDirty, setIsDirty] = useState<boolean>(false);

  // const [fileType, setFileType] = useState<'image' | 'video'>();
  const [ticketsForGiveAway, setTicketsForGiveAway] = useState<
    { name: string; price: number; amount: number; id: string }[]
  >([]);
  // const [fileUrl, setFileUrl] = useState<string>();
  const [loading, setLoading] = useState<string>('');

  const [artist, setArtist] = useState<
    {
      artistName: string;
      artistTag: string;
      artistSite: string;
    }[]
  >([]);
  const [sponsor, setSponsor] = useState<
    {
      sponsorName: string;
      sponsorTag: string;
      sponsorSite: string;
    }[]
  >([]);
  const [artistForm] = Form.useForm();
  const [sponsorForm] = Form.useForm();
  const [socialForm] = Form.useForm();
  // const [featuresForm] = Form.useForm();

  const setQuery = useSetQuery();

  const handleChange = () => {
    setIsDirty(socialForm.isFieldsTouched());
    dispatch(setIsEditingEvent(true));
  };

  useEffect(() => {
    if (activeEvent?.promoteDetails?.promotionalQuestionReplies) {
      const initialPromoteQuestion = activeEvent?.promoteDetails?.promotionalQuestionReplies.reduce(
        (acc, item) => {
          acc[item?.questionId!] = item?.replyValue! === 'true';
          return acc;
        },
        {} as Record<string, boolean>,
      );
      setPromoteQuestion(initialPromoteQuestion);
    }
    if (activeEvent?.promoteDetails?.facebookLink) {
      socialForm.setFieldValue('facebookLink', activeEvent?.promoteDetails?.facebookLink);
    }
    if (activeEvent?.promoteDetails?.googleAnalyticsCode) {
      socialForm.setFieldValue(
        'googleAnalyticsCode',
        activeEvent?.promoteDetails?.googleAnalyticsCode,
      );
    }
    if (activeEvent?.promoteDetails?.facebookPixelCode) {
      socialForm.setFieldValue('facebookPixelCode', activeEvent?.promoteDetails?.facebookPixelCode);
    }
    if (activeEvent?.promoteDetails?.instagramLink) {
      socialForm.setFieldValue('instagramLink', activeEvent?.promoteDetails?.instagramLink);
    }
    if (activeEvent?.promoteDetails?.videoLink) {
      socialForm.setFieldValue('videoLink', activeEvent?.promoteDetails?.videoLink);
    }
    if (activeEvent?.promoteDetails?.eventArtistTags) {
      const temp = activeEvent?.promoteDetails?.eventArtistTags.map((item) => ({
        artistName: item.atName as string,
        artistTag: item.name as string,
        artistSite: item.url as string,
      }));
      setArtist(temp);
    }
  }, [activeEvent, socialForm]);

  const extractFileName = (url: string): string => {
    return url.substring(url.lastIndexOf('/') + 1);
  };

  const handleDeleteMedia = useCallback(
    async (id: string) => {
      const response = await deletePromotionMedia(id);

      if ('data' in response) {
        dispatch(setActiveEvent(response.data as EventCreatedType));
        setLoading('');
      }
    },
    [deletePromotionMedia, dispatch],
  );
  const handleSend = useCallback(async () => {
    if (
      isDirty ||
      artist.length !== activeEvent?.promoteDetails?.eventArtistTags?.length ||
      sponsor.length > 0
    ) {
      const res = await setPromoDetails({
        eventId: activeEvent?.eventId || '',
        promoteDetails: {
          googleAnalyticsCode: socialForm.getFieldValue('googleAnalyticsCode'),
          facebookPixelCode: socialForm.getFieldValue('facebookPixelCode'),
          videoLink: socialForm.getFieldValue('videoLink'),
          facebookLink: socialForm.getFieldValue('facebookLink'),
          instagramLink: socialForm.getFieldValue('instagramLink'),
          ticketTypesToConsiderForPromotionalOffers: ticketsForGiveAway.map((item) => ({
            qty: item.amount,
            name: item.name,
            id: item.id,
          })),
          eventArtistTags: artist.map((item) => ({
            name: item.artistName,
            atName: item.artistTag,
            url: item.artistSite,
          })),
          eventSponsorTags: sponsor.map((item) => ({
            name: item.sponsorName,
            atName: item.sponsorTag,
            url: item.sponsorSite,
          })),
          promotionalQuestionReplies: activeEvent?.promoteDetails?.promotionalQuestionReplies?.map(
            (item) => ({
              ...item,
              replyValue: promoteQuestion[item.questionId || ''],
            }),
          ),
        },
      });

      if ('data' in res) {
        message.success('Promo data successfully added!');
        dispatch(setActiveEvent(res?.data as EventCreatedType));
        setIsDirty(false);
        dispatch(setIsEditingEvent(false));
        if (!eventId) {
          setQuery(CREATE_EVENT_FORM_STEPS_ENUM.PUBLISH);
        }
      }
    } else {
      setQuery(CREATE_EVENT_FORM_STEPS_ENUM.PUBLISH);
    }
  }, [
    dispatch,
    eventId,
    setQuery,
    isDirty,
    setPromoDetails,
    activeEvent?.promoteDetails?.eventArtistTags,
    activeEvent?.eventId,
    activeEvent?.promoteDetails?.promotionalQuestionReplies,
    ticketsForGiveAway,
    artist,
    sponsor,
    socialForm,
    promoteQuestion,
  ]);

  const handleDelete = useCallback(
    async (item) => {
      if (
        isDirty ||
        artist.length !== activeEvent?.promoteDetails?.eventArtistTags?.length ||
        sponsor.length > 0
      ) {
        const newArtists = artist.filter((artist) => artist.artistName !== item.artistName);
        const res = await setPromoDetails({
          eventId: activeEvent?.eventId || '',
          promoteDetails: {
            googleAnalyticsCode: socialForm.getFieldValue('googleAnalyticsCode'),
            facebookPixelCode: socialForm.getFieldValue('facebookPixelCode'),
            videoLink: socialForm.getFieldValue('videoLink'),
            facebookLink: socialForm.getFieldValue('facebookLink'),
            instagramLink: socialForm.getFieldValue('instagramLink'),
            ticketTypesToConsiderForPromotionalOffers: ticketsForGiveAway.map((item) => ({
              qty: item.amount,
              name: item.name,
              id: item.id,
            })),
            eventArtistTags: newArtists.map((item) => ({
              name: item.artistName,
              atName: item.artistTag,
              url: item.artistSite,
            })),
            eventSponsorTags: sponsor.map((item) => ({
              name: item.sponsorName,
              atName: item.sponsorTag,
              url: item.sponsorSite,
            })),
            promotionalQuestionReplies:
              activeEvent?.promoteDetails?.promotionalQuestionReplies?.map((item) => ({
                ...item,
                replyValue: promoteQuestion[item.questionId || ''],
              })),
          },
        });

        if ('data' in res) {
          message.warning('Artist data successfully removed!');
          dispatch(setActiveEvent(res?.data as EventCreatedType));
          setIsDirty(false);
          dispatch(setIsEditingEvent(false));
          if (!eventId) {
            setQuery(CREATE_EVENT_FORM_STEPS_ENUM.PUBLISH);
          }
        }
      }
    },
    [
      dispatch,
      activeEvent?.promoteDetails?.eventArtistTags,
      eventId,
      isDirty,
      setQuery,
      setPromoDetails,
      activeEvent?.eventId,
      activeEvent?.promoteDetails?.promotionalQuestionReplies,
      ticketsForGiveAway,
      artist,
      sponsor,
      socialForm,
      promoteQuestion,
    ],
  );

  const customRequest = async (options) => {
    const { file, onError } = options;

    try {
      const data = new FormData();
      data.append('file', file);
      data.append('eventId', activeEvent?.eventId!);

      const response = await uploadPromotionMedia(data);

      if ('data' in response) {
        dispatch(setActiveEvent(response.data as EventCreatedType));
        setLoading('done');
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleAddTagsInfo = (data, field) => {
    if (field === 'artist') {
      setArtist((prev) => [...prev, data]);
      artistForm.resetFields();
    }

    if (field === 'sponsor') {
      setSponsor((prev) => [...prev, data]);
      sponsorForm.resetFields();
    }
  };

  // const handleNext = () => {
  //   if (
  //     activeEvent?.basicInfo?.status === 'published' ||
  //     activeEvent?.basicInfo?.status === 'approved'
  //   ) {
  //     navigate(MY_EVENTS_ROUTE);
  //   } else {
  //     setQuery(CREATE_EVENT_FORM_STEPS_ENUM.PUBLISH);
  //   }
  // };

  return (
    <div className={cn(s.wrapper)}>
      <div className={s.detailHeader}>
        <Typography type="h2" className={s.title}>
          Promote your event
        </Typography>

        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          {extractFlyerName(activeEvent?.media.flyer! || '') ? (
            <img src={activeEvent?.media.flyer!} alt="flyer" className={s.headerimg} />
          ) : (
            <div className={s.emptyFlyer}>
              <Icon name="littleLogo" />
            </div>
          )}
          <Typography type="h4">{activeEvent?.basicInfo.eventName}</Typography>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.container}>
          <div>
            <div className={s.block}>
              <div className={s.subtitle}>
                We can help you connect with the right audiences to boost your tickets sales.
              </div>
            </div>

            <div className={s.uploadWrapper}>
              <Typography type="h3" weight={700}>
                Files
              </Typography>
              <Typography type="main">
                This could be instagram post or stories, video, poster etc.
              </Typography>
              <div className={s.upload}>
                <Upload
                  hasControlInside={true}
                  customRequest={customRequest}
                  accept=".jpg, .jpeg, .png, .gif, .mp4"
                  maxCount={1}
                  showUploadList={false}
                  beforeUpload={(file) => {
                    const isImageOrVideo =
                      file.type.startsWith('image/') || file.type.startsWith('video/');
                    // setFileType(file.type.startsWith('image/') ? 'image' : 'video');
                    if (!isImageOrVideo) {
                      message.error('Only images and video files can be uploaded!');
                    }
                    return isImageOrVideo;
                  }}
                  onChange={(info) => {
                    if (info.file.status === 'uploading') {
                      setLoading('uploading');
                      return;
                    }
                    if (info.file.status === 'done') {
                      message.success(`${info.file.name} successfully uploaded!`);
                      setLoading('done');
                    } else if (info.file.status === 'error') {
                      message.error(`${info.file.name} upload failed.`);
                    }
                  }}>
                  <div className={s.uploadBlock}>
                    <Button loading={loading === 'uploading'} className={s.chooseButton}>
                      CHOOSE FILES
                    </Button>
                    <Typography type="main" color="grey">
                      or drag PDF, JPEG or PNG less than 5MB
                    </Typography>
                  </div>
                </Upload>
              </div>
            </div>
            <div className={s.uploadedFiles}>
              <div style={{ marginTop: 8, marginBottom: 8 }}>
                <Typography type="h5">UPLOADED FILES</Typography>
              </div>
              {activeEvent?.promoteDetails?.promotionalImages?.map((file) => (
                <div key={file.id} className={s.uploadedFile}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    {file.fileName.endsWith('.mp4') ? (
                      <video width={64} height={40}>
                        <source src={file.fileName} type="video/mp4" />
                        <track
                          kind="captions"
                          src="path-to-captions.vtt"
                          srcLang="en"
                          label="English"
                          default
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <img src={file.fileName} width={64} height={40} alt="promotionMedia" />
                    )}
                    <Typography type="main" color="grey">
                      {extractFileName(file.fileName)}
                    </Typography>
                  </div>
                  <Button
                    icon={<DeleteOutlined />}
                    className={s.deleteButton}
                    onClick={() => handleDeleteMedia(file.id)}></Button>
                </div>
              ))}
              {loading && (
                <div className={s.fileUpload}>
                  <div
                    className={`${s.fileContent} ${loading === 'uploading' ? s.fill : ''} ${
                      loading === 'done' ? s.done : ''
                    }`}
                  />
                </div>
              )}
            </div>
          </div>

          <Form
            className={s.fullWidth}
            onFinish={(data) => handleAddTagsInfo(data, 'artist')}
            form={artistForm}>
            <Typography type="h3" weight={700}>
              Social Media Handles
            </Typography>
            <div className={s.inputBlock}>
              <Typography type="main" weight={500}>
                Let us know which social media accounts to tag in posts related to your event. This
                could be yours, artists, sponsors or venue. It will help spread the word about your
                event.
              </Typography>
              <div className={s.form}>
                <Form.Item name="artistName">
                  <Input name="artistName" placeholder="Michael Jackson" />
                </Form.Item>
                <Form.Item name="artistTag">
                  <Input name="artistTag" placeholder="@michaeljackson" />
                </Form.Item>
                <Form.Item name="artistSite">
                  <Input name="artistSite" placeholder="michaeljackson.com" />
                </Form.Item>
              </div>

              <div>
                <Button type="primary" htmlType="submit" disabled={artist.length === 2}>
                  + Add Social Media Handle
                </Button>
              </div>
              {artist.map((item, index) => (
                <div key={index} className={s.artistList}>
                  <div
                    style={{
                      maxWidth: 180,
                      minWidth: 120,
                    }}>
                    {item.artistName}
                  </div>
                  <div
                    style={{
                      maxWidth: 180,
                      minWidth: 120,
                    }}>
                    {item.artistSite}
                  </div>
                  <div
                    style={{
                      maxWidth: 180,
                      minWidth: 120,
                    }}>
                    {item.artistTag}
                  </div>
                  <div
                    style={{
                      maxWidth: 180,
                      minWidth: 120,
                    }}>
                    <Button
                      htmlType="button"
                      onClick={() => handleDelete(item)}
                      style={{ color: '#FF4B53', borderColor: '#FF4B53' }}>
                      <DeleteOutlined color="#FF4B53" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </Form>

          <Form
            style={{ display: 'none' }}
            className={s.fullWidth}
            onFinish={(data) => handleAddTagsInfo(data, 'sponsor')}
            form={sponsorForm}>
            <Typography type="h3" weight={700}>
              Sponsor Tags
            </Typography>
            <div className={s.inputBlock}>
              <Typography type="main" weight={500}>
                Confirm the event&apos;s sponsors social media accounts and website
              </Typography>
              <div className={s.form}>
                <Form.Item name="sponsorName">
                  <Input name="sponsorName" placeholder="Coca-Cola" />
                </Form.Item>
                <Form.Item name="sponsorTag">
                  <Input name="sponsorTag" placeholder="@cocacola" />
                </Form.Item>
                <Form.Item name="sponsorSite">
                  <Input name="sponsorSite" placeholder="www.coca-cola.com" />
                </Form.Item>
              </div>
              <div>
                <Button type="primary" htmlType="submit" disabled={sponsor.length === 2}>
                  + Add sponsor
                </Button>
              </div>
            </div>
          </Form>

          <Form className={s.fullWidth} form={socialForm} onValuesChange={handleChange}>
            <div className={s.socialWrapper}>
              <Typography type="h3">Video</Typography>
              <div>
                <Label>{t('field_video')}</Label>
                <Form.Item name="videoLink">
                  <Input name="videoLink" placeholder={VIDEO_PLACEHOLDER} />
                </Form.Item>
              </div>

              <Typography type="h3">{t('field_social_media')}</Typography>
              <div>
                <Label>Facebook Link</Label>
                <Form.Item name="facebookLink">
                  <Input name="facebookLink" placeholder="https://www.facebook.com/" />
                </Form.Item>
              </div>
              <div>
                <Label>Instagram Link</Label>
                <Form.Item name="instagramLink">
                  <Input name="instagramLink" placeholder="https://www.instagram.com/" />
                </Form.Item>
              </div>
              <div>
                <Typography type="h3">Facebook Pixel Code</Typography>
                <Label>
                  Do not copy and paste the whole script. You just need to type the Pixel code as
                  number (for example: 822143223355222)
                </Label>
                <Form.Item name="facebookPixelCode">
                  <Input
                    name="facebookPixelCode"
                    placeholder="Pixel code (if any) for marketing purposes"
                  />
                </Form.Item>
              </div>
              <div>
                <Typography type="h3">Google analytics tracking code</Typography>
                <Label>
                  (BETA — type in your Google analytics tracking code which should be in this format
                  UA-XXXXX-X and you will be able to see your Event page views in your Google
                  analytics)
                </Label>
                <Form.Item name="googleAnalyticsCode">
                  <Input name="googleAnalyticsCode" placeholder="Enter tracking code" />
                </Form.Item>
              </div>
            </div>
          </Form>
          <div className={s.fullWidth}>
            <Typography type="h3" weight={700}>
              Ticket give-aways on social media
            </Typography>
            <Typography type="main" weight={500}>
              Offer up some complimentary tickets which we will use to create hype around your
              event. This could take the form of a competition or other types of online engagement.
              Let us know the quantity and type of complimentary tickets that we can use to promote
              your event and we’ll be in touch with a proposed way forward.
            </Typography>
            <TicketForm
              setIsDirty={setIsDirty}
              promoteDetails={
                activeEvent?.promoteDetails?.ticketTypesToConsiderForPromotionalOffers
              }
              tickets={activeEvent?.ticketTypes}
              setTicket={setTicketsForGiveAway}
            />
          </div>
          <div className={s.promoteQuestionBlock}>
            {activeEvent?.promoteDetails?.promotionalQuestionReplies &&
              activeEvent?.promoteDetails?.promotionalQuestionReplies.map((item) => (
                <div key={item.questionId} className={s.questionId}>
                  {/*<Label className={s.questionLabel}>{item.name}</Label>*/}
                  {/*<Input*/}
                  {/*  name={item.questionId || ''}*/}
                  {/*  placeholder="Enter your answer here"*/}

                  {/*  }*/}
                  {/*/>*/}
                  <div>
                    <Icon name="warning" />
                  </div>
                  <Switch
                    checked={promoteQuestion[item?.questionId || '']}
                    onChange={(checked) => {
                      setPromoteQuestion((prevState) => ({
                        ...prevState,
                        [item?.questionId || '']: checked,
                      }));
                      setIsDirty(true);
                      dispatch(setIsEditingEvent(true));
                    }}
                  />
                  <Typography type="main" className={s.questionLabel}>
                    {item.name}
                  </Typography>
                </div>
              ))}
          </div>
        </div>
        <div className={s.phone}>
          <PhoneMockup artistTag={artist} sponsorTag={sponsor} />
        </div>
      </div>

      <div className={cn(s.nextWrapper, s.show)}>
        <div style={{ flex: 1 }}></div>
        <div className={s.buttonWrapper}>
          <Button
            className={s.nextButton}
            loading={isLoading}
            type="primary"
            size="large"
            block
            onClick={handleSend}>
            <Typography type="main" className={s.buttonText}>
              {isDirty ||
              artist.length !== activeEvent?.promoteDetails?.eventArtistTags?.length ||
              sponsor.length > 0
                ? 'Save'
                : 'Next'}
            </Typography>
          </Button>
        </div>
      </div>

      {/*<div className={s.fullWidth} style={{ marginTop: 32 }}>*/}
      {/*  <div className={s.subtitle}>ShowsHappening.com website features</div>*/}
      {/*  <Form form={featuresForm}>*/}
      {/*    <SiteFeatures />*/}
      {/*  </Form>*/}
      {/*</div>*/}
    </div>
  );
};

export default EventCreateFormPromotePage;
