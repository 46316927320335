import React, { FC, useCallback, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { formatNumber } from 'tools/formatNumbers';
import { Icon, Typography } from 'ui';

import {
  useLazyExportTaxReportQuery,
  useLazyGetTaxReportQuery,
} from 'services/events/eventsApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import EventTaxReportRecipientsModal from 'pages/events/events-tax-report/event-tax-report-recipients-modal';
import { MONTHS } from 'pages/events/events-tax-report/eventTaxReport.utils';

import SectionTitle from '../../../component/section-title/sectionTitle';
import s from './EventTaxReport.module.scss';

const { TextArea } = Input;

interface EventTaxReportProps {
  // Define your component props here
}

const EventTaxReport: FC<EventTaxReportProps> = () => {
  const { eventId } = useParams();
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [getTaxReport, { data: taxReport }] = useLazyGetTaxReportQuery();
  const [exportTaxReport] = useLazyExportTaxReportQuery();
  const currentEvent = currentUser?.events?.find((event) => event.eventId === eventId)?.eventName;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [recipients, setRecipients] = useState<string[]>([]);
  const [comment, setComment] = useState('');

  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate(MY_EVENTS_ROUTE);
  }, [navigate]);

  const handleAddRecipients = useCallback(
    (email: string) => {
      setRecipients((prevState) => [...prevState, email]);
    },
    [setRecipients],
  );

  const handleExportTaxReport = useCallback(
    (type: 'PDF' | 'Email') => {
      if (eventId) {
        if (type === 'PDF') {
          exportTaxReport({ id: eventId, destination: 'PDF' }).then((data) => {
            if (data.data) {
              const link = document.createElement('a');
              link.href = data.data?.fileName;
              link.setAttribute('download', `tax-report-${eventId}.csv`);
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          });
        }

        if (type === 'Email') {
          exportTaxReport({
            id: eventId,
            destination: 'Email',
            comments: comment,
            recipients: [...(taxReport?.defaultRecipients || []), ...recipients],
          });
        }
      }
    },
    [comment, eventId, exportTaxReport, recipients, taxReport?.defaultRecipients],
  );

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    if (eventId) {
      getTaxReport({ id: eventId });
    }
  }, [eventId, getTaxReport]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <Button className={s.changedButton} type="link" onClick={handleBack}>
          <ArrowLeftOutlined /> My Events
        </Button>
        <div className={s.block}>
          <div>
            <SectionTitle
              content={`Tax report for ${currentEvent} ${dayjs().format('YYYY')}`}
              className={s.title}
              withOutMargin
            />
          </div>
        </div>

        <div className={s.text}>
          This tax report has to be sent to the VAT department not later than 15 days of your event
          using ShowsHappening from this window. Underneath you will find a breakdown of all
          statistics that will be sent to the VAT department.
        </div>

        <div className={s.recipientsBlock}>
          <div className={s.subTitle}>Recipients</div>
          <div className={s.recipientsList}>
            {taxReport?.defaultRecipients?.map((item, i) => (
              <Typography type="main" key={`${item}-${i}`}>
                {item}
              </Typography>
            ))}
            {recipients.map((item, i) => (
              <Typography type="main" key={`${item}-${i}`} color="blue">
                {item}
              </Typography>
            ))}
          </div>
          <button className={s.addButton} onClick={() => setIsOpenModal(true)}>
            <div className={s.plus}>
              <Icon name="plus" color="#fff" />
            </div>

            <div className={s.addText}>Add recipient</div>
          </button>
        </div>
      </div>
      <div className={s.content}>
        <div className={s.table}>
          <div className={s.tableHead}>
            <div>
              <Typography type="h6" className={s.bold}>
                Year
              </Typography>
            </div>
            <div>
              <Typography type="h6" className={s.bold}>
                Month
              </Typography>
            </div>
            <div>
              <Typography type="h6" className={s.bold}>
                Ticket type
              </Typography>
            </div>
            <div>
              <Typography type="h6" className={s.bold}>
                Qty
              </Typography>
            </div>
            <div>
              <Typography type="h6" className={s.bold}>
                Gross ({taxReport?.currency || 'EUR'})
              </Typography>
            </div>
          </div>
          <div className={s.tableBody}>
            {taxReport?.salesByMonth?.map((item, i) => (
              <div key={i} className={cn(s.tableBodyItem, i % 2 === 0 && s.even)}>
                <div>
                  <Typography type="main" className={s.tableText}>
                    {item.year}
                  </Typography>
                </div>
                <div>
                  <Typography type="main" className={s.tableText}>
                    {MONTHS[item.month - 1]}
                  </Typography>
                </div>
                <div>
                  <Typography type="main" className={s.tableText}>
                    {item.ticketType}
                  </Typography>
                </div>
                <div>
                  <Typography type="main" className={s.tableText}>
                    {item.qty}
                  </Typography>
                </div>
                <div>
                  <Typography type="main" className={s.tableText}>
                    {formatNumber(item.gross)}
                  </Typography>
                </div>
              </div>
            ))}
            <div className={s.tableBodyItem}>
              <div className={s.totalQty}>
                <Typography type="h6" className={s.bold}>
                  {taxReport?.qty}
                </Typography>
              </div>
              <div className={s.totalGross}>
                <Typography type="h6" className={s.bold}>
                  {formatNumber(taxReport?.gross)}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={s.footer}>
          <div className={s.footerHead}>
            <div className={s.subTitle}>Comments</div>
            <div className={s.tableText}>
              Type in any comments you want to send to the TAX department in this report
            </div>
          </div>
          <div className={s.comment}>
            <TextArea
              name="comment"
              rows={6}
              maxLength={500}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
          <div className={s.tableText}>
            Click{' '}
            <button className={s.download} onClick={() => handleExportTaxReport('PDF')}>
              here
            </button>{' '}
            to view the report. To send the report to the VAT department please click on the button
            below
          </div>

          <div>
            <Button
              type="primary"
              style={{ fontSize: 14, paddingLeft: 48, paddingRight: 48 }}
              size="large"
              onClick={() => handleExportTaxReport('Email')}>
              Send report
            </Button>
          </div>
        </div>
      </div>

      <EventTaxReportRecipientsModal
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        handleAddRecipients={handleAddRecipients}
      />
    </div>
  );
};

export default EventTaxReport;
