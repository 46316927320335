import React, { useEffect, useRef } from 'react';

import { Empty } from 'antd';
import { Chart } from 'chart.js';
import cn from 'classnames';
import SectionTitle from 'component/section-title/sectionTitle';
import { Typography } from 'ui';
import { colors } from 'utils/colors';

import { TicketDataType } from 'types/events/eventTypes';

import s from './DashboardBookingCapasityChart.module.scss';

interface ChartProps {
  data?: TicketDataType[];
  maximumNumberOfAttendees: number;
}

const DashboardBookingCapasityChart: React.FC<ChartProps> = ({
  data,
  maximumNumberOfAttendees,
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart<'doughnut', number[], string> | null>(null);
  const totalValue = data?.reduce((a, b) => a + b.qtyOfTicketsSold, 0);
  // const maxValue = data?.reduce((a, b) => Math.max(a, b.maximumNumberOfTickets), 0);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const labels = data?.map((d) => d.type);
      const qtyOfTicketsSold = data?.map((d) => d.qtyOfTicketsSold);
      const backgroundColors = data?.map((_, i) => colors[i % colors.length]);
      const chartColors = backgroundColors?.map((item) => item.color);

      if (maximumNumberOfAttendees > 0) {
        const totalQtyOfTicketsSold = data?.reduce(
          (total, item) => total + item.qtyOfTicketsSold,
          0,
        );
        labels?.push('Available');
        qtyOfTicketsSold?.push(maximumNumberOfAttendees - totalQtyOfTicketsSold!);
        chartColors?.push('#E7E9F1');
      }

      // If there's an existing chart instance, destroy it before creating a new one
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const chartData = {
        labels: labels,
        datasets: [
          {
            label:
              chartColors && chartColors[data?.length!] === '#E7E9F1' ? 'Tickets' : 'Tickets Sold',
            data: qtyOfTicketsSold || [],
            backgroundColor: chartColors || [],
            borderWidth: 1,
          },
        ],
      };

      if (chartRef.current && chartData) {
        chartInstance.current = new Chart(chartRef.current, {
          type: 'doughnut',
          data: chartData,
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            cutout: 70,
            scales: {
              y: {
                beginAtZero: true,
                border: {
                  width: 0,
                },
                grid: {
                  display: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
              x: {
                beginAtZero: true,
                border: {
                  width: 0,
                },
                grid: {
                  display: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          },
        });
      }
    }
  }, [data, maximumNumberOfAttendees]);

  if (data?.length === 0) {
    return (
      <div className={cn(s.wrapper, s.empty)}>
        <div className={s.header}>
          <div>
            <SectionTitle content="Booking Capacity" className={s.title} withOutMargin />
            <Typography type="main" color="grey">
              Issued tickets overview
            </Typography>
          </div>
        </div>

        <div className={s.emptyContent}>
          <Empty description="No data available" />
        </div>
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <SectionTitle content="Booking Capacity" className={s.title} withOutMargin />
          <Typography type="main" color="grey">
            Issued tickets overview
          </Typography>
        </div>
        <div className={s.totalBlock}>
          <Typography type="h3" className={s.totlal}>
            {totalValue}
          </Typography>
          {maximumNumberOfAttendees > 0 ? (
            <Typography type="h3" className={s.limit_grey}>
              &nbsp;/&nbsp;{maximumNumberOfAttendees}
            </Typography>
          ) : (
            <Typography type="h3" className={s.limit_grey}>
              &nbsp;/&nbsp;∞
            </Typography>
          )}
        </div>
      </div>
      <div className={s.chartContainer}>
        <canvas ref={chartRef} style={{ width: 264, height: 264 }} />
      </div>
    </div>
  );
};

export default DashboardBookingCapasityChart;
