import { InputRef } from 'antd';

import InternalInput, { InputProps } from './input';
import NumericInput from './numeric-input';
import PasswordInput, { PasswordInputProps } from './password-input';

type CompoundedComponents = typeof InternalInput & {
  Number: typeof NumericInput;
  Password: typeof PasswordInput;
};

const Input = InternalInput as CompoundedComponents;
Input.Number = NumericInput;
Input.Password = PasswordInput;

export type { InputProps, InputRef, PasswordInputProps };
export default Input;
