import internalMessage from './message';

type TMessage = typeof internalMessage & {
  default: {
    config: typeof internalMessage.config;
    destroy: typeof internalMessage.destroy;
    error: typeof internalMessage.error;
    info: typeof internalMessage.info;
    loading: typeof internalMessage.loading;
    open: typeof internalMessage.open;
    success: typeof internalMessage.success;
    useMessage: typeof internalMessage.useMessage;
    warn: typeof internalMessage.warn;
    warning: typeof internalMessage.warning;
  };
};

export type { TMessage };
const message = internalMessage as TMessage;
export default message as TMessage;
