import {
  EventCouponType,
  EventCreateFormDetailsType,
  EventMetricsType,
  EventPermissionsType,
  EventTicketType,
  REFUND_POLICY_TYPE,
  SingleEventType,
  TICKET_SALES_STATUS,
} from 'types/events/eventTypes';
import { EventTicketMetricsType } from 'types/events/metricsTypes';
import { OrdersListType } from 'types/tickets/tickets.types';

export type EventTicketsPropsType = {
  eventId: string;
  maxNumberOfAttendees?: number;
  ticketTypes: EventTicketType[];
};

export type EventCreateFormDetailsPropsType = {
  eventId: string;
  otherDetails: EventCreateFormDetailsType;
};

export type EventFlyerPropsType = {
  eventId: string;
  flyerBase64: string;
  extension?: string;
};

export type EventCreateFormTaxesPropsType = {
  eventId: string;
  taxDetails: {
    refundPolicy?: REFUND_POLICY_TYPE;
    permitNumber?: string;
    vatRate?: number;
  };
};

export type EventCreateFormTaxesAndContactPropsType = {
  eventId: string;
  taxDetails: {
    refundPolicy?: REFUND_POLICY_TYPE;
    permitNumber?: string | null;
    vatRate?: number;
  };
  contactDetails: {
    email?: string;
    mobileNumber?: string;
    address?: string;
    town?: string;
    postcode?: string;
    country?: string;
  };
};

export type EventResponseType = SingleEventType;

export type EventMetricsResponseType = EventMetricsType;

export type OrdersListResponseType = OrdersListType;

export type OrdersListPropsType = {
  PageNumber?: number;
  PageSize?: number;
  SortByColumn?: string;
  SortType?: string;
  id?: string;
  DateFrom: string;
  DateTo: string;
  filter?: string;
};

export type EventPermissionResponseType = EventPermissionsType[];

export type EventTicketMetricsResponseType = EventTicketMetricsType[];

export type EventDetailedSummaryType = {
  eventFlyer: string;
  eventName: string;
  eventId: string;
  currency: string;
  timeZone: string;
  dateId: string;
  availableTickets: number;
  hasLimit: boolean;
  totalTicketsSold: {
    allTime: number;
    today: number;
  };
  totalSales: {
    allTime: number;
    today: number;
    averageAllTime: number;
  };
  totalComplimentariesIssued: {
    allTime: number;
    today: number;
  };
  ticketTypes: {
    type: string;
    qtyOfTicketsSold: number;
    today: {
      qtyOfTicketsSold: number;
      totalAmount: number;
      numberOfComplimentaries: number;
    };
    maximumNumberOfTickets: number;
    totalAmount: number;
    averageTicketPrice: number;
    numberOfComplimentaries: number;
  }[];
};

export type EventSalesChannelsType = {
  event: {
    eventFlyer: string;
    eventName: string;
    eventId: string;
    currency: string;
    timeZone: string;
  };
  statistics: {
    name: string;
    valueSold: number;
    qty: number;
  }[];
};

export type EventsSalesChannelsResType = {
  eventName: string;
  eventId: string; // UUID format
  currency: string;
  eventFlyer: string;
  salesChannels: {
    firstName: string;
    profilePic: string;
    lastName: string;
    outlet: {
      name: string;
    };
    linkName: string;
    qty: number;
    value: number;
    referrerType: 'runner' | 'link' | 'userLinkReferrer' | 'direct';
  }[];
}[];

export type EventSeatMetricsType = {
  disabledSeats: number;
  availableTickets: number;
  soldTickets: number;
  standingTickets: number;
  standingTicketsAvailable: number;
  maximumNumberOfAttendees: number;
  seatedTickets: number;
  filledSeatsPercentage: number;
  remaining: number;
  sections: {
    disabledSeats: number;
    availableTickets: number;
    soldTickets: number;
    standingTickets: number;
    standingTicketsAvailable: number;
    maximumNumberOfAttendees: number;
    seatedTickets: number;
    filledSeatsPercentage: number;
    remaining: number;
    sectionName: string;
    message: string;
  }[];
  shows: {
    disabledSeats: number;
    availableTickets: number;
    soldTickets: number;
    standingTickets: number;
    standingTicketsAvailable: number;
    maximumNumberOfAttendees: number;
    seatedTickets: number;
    filledSeatsPercentage: number;
    remaining: number;
    showDate: string;
    message: string;
  }[];
};

export type AcceptTermsAndConditionsPropsType = {
  id?: string;
  chargeVAT?: boolean;
  permitNumber?: string;
  vatNumber?: string;
  promoterCompanyName?: string;
  promoterAddress?: string;
  promoterTown?: string;
  promoterMobileNumber?: string;
  promoterEmail?: string;
  ibanNumber?: string;
  bankAccountHolderName?: string;
  promoterPostCode?: string;
};

export type EventCategoriesWithTag = {
  categorized: {
    id: number;
    name: string;
    tags: { id: string; name: string; slug: string }[];
  }[];
  mytags: { id: string; name: string; slug: string }[];
};

type PaginationType = {
  nextPage: number;
  pageNumber: number;
  pageSize: number;
  previousPage: number;
  totalPages: number;
  totalRecords: number;
  totalRecordsInPage: number;
};

export type EventAttendeesType = {
  scannedDate: string;
  order: {
    refundProtection: string;
    remarks: string;
    referredBy: string;
    gatewayType: string;
    orderId: string;
    orderNo: string;
    currency: string;
    orderDate: string;
    orderTotal: number;
    orderSubTotal: number;
    bookingFee: number;
    paymentType: string;
    orderStatus: number;
    user: {
      firstName: string;
      onBoarded: boolean;
      fullName: string;
      mobileNumber: string;
      lastName: string;
      gender: string;
      userId: string;
      email: string;
      street1: string;
      street2: string;
      countryName: string;
      optInForPromoterPromotionalMaterial: boolean;
      optInForshowsHappeningPromotionalMaterial: boolean;
      dateOfBirth: string;
      dateOfBirthRange: string;
    };
  };
  ticketHolderPersonalInfo: {
    firstName: string;
    lastName: string;
  };
  numberOfPersons: string;
  ticketId: string;
  ticketType: {
    name: string;
    id: string;
    description: string;
  };
  receiptNumber: string;
  subTotal: number;
  bookingFee: number;
  total: number;
  seat: number;
  show: {
    showId: string;
    eventId: string;
    date: string;
    time: string;
  };
  ticketStatus: string;
  barCode: string;
  attendanceDate: string;
  longAttendanceDate: string;
  dateId: string;
  scheduleType: string;
};

export type EventAttendeesResponseType = PaginationType & {
  attendees: EventAttendeesType[];
};

export type EventAttendeesReqType = {
  EventId?: string;
  DateId?: string;
  TicketTypeId?: string;
};

export type ZReadingResponseType = PaginationType & {
  transactions: {}[];
};

export type EventTaxReportResponseType = {
  currency: string;
  id: string;
  eventName: string;
  comments: string;
  gross: number;
  qty: number;
  defaultRecipients: string[];
  salesByMonth: {
    month: number;
    year: number;
    gross: number;
    qty: number;
    ticketType: string;
  }[];
};

export type EventTaxReportPropsType = {
  id: string;
  comments?: string;
  recipients?: string[];
  destination: 'PDF' | 'Email';
};

export type SeatingPlansResponseType = {
  name: string;
  id: string;
}[];

export type SeatingPlanDetailResponseType = {
  topPlanSVG: string;
  name: string;
  id: string;
  sections: {
    id: string;
    name: string;
    svg: string;
    sectionType: string;
  }[];
};

interface Sections {
  id: string;
  name: string;
  svg: string;
  sectionType: string;
  ticketType: {
    name: string;
    ticketTypeId: string;
    description: string;
  };
}

export type PlanListTableType = {
  topPlanSVG: string;
  name: string;
  id: string;
  sections: Sections[];
};

export type EventPromoDetailsPropsType = {
  eventId: string;
  promoteDetails: {
    videoLink?: string;
    facebookLink?: string;
    instagramLink?: string;
    googleAnalyticsCode?: string;
    facebookPixelCode?: string;
    ticketTypesToConsiderForPromotionalOffers?: {
      qty: number;
      name: string;
      id: string;
    }[];
    eventArtistTags?: {
      name?: string;
      atName?: string;
      url?: string;
    }[];
    eventSponsorTags?: {
      name?: string;
      atName?: string;
      url?: string;
    }[];
    promotionalQuestionReplies?: {
      name?: string;
      questionId?: string;
      replyValue?: boolean;
    }[];
  };
};

export type EventCouponResponseType = {
  nextPage: 0;
  previousPage: 0;
  pageSize: 0;
  pageNumber: 0;
  totalPages: 0;
  totalRecords: 0;
  totalRecordsInPage: 0;
  coupons: EventCouponType[];
};

type TicketType = {
  name: string;
  ticketTypeId: string;
  description: string;
};

type Section = {
  sectionId: string;
  displayName: string;
};

type Location = {
  locationId: string;
  name: string;
};

type Seat = {
  seatId: string;
  fullDisplayName: string;
  section: Section;
  location: Location;
};

type Show = {
  showId: string;
  eventId: string;
  date: string; // Date string, e.g., "2024-05-08T11:22:53.163Z"
  time: string; // Time string, e.g., "2024-05-08T11:22:53.163Z"
};

type TicketHolderPersonalInfo = {
  firstName: string;
  lastName: string;
};

type Holder = {
  firstName: string;
  fullName: string;
  mobileNumber: string;
  lastName: string;
  userId: string;
  email: string;
};

type TicketCancelled = {
  ticketHolderPersonalInfo: TicketHolderPersonalInfo;
  numberOfPersons: number;
  barcodeVisibilityType: 'Available';
  ticketId: string;
  value: number;
  ticketType: TicketType;
  receiptNumber: string;
  subTotal: number;
  bookingFee: number;
  total: number;
  seat: Seat;
  show: Show;
  ticketStatus: 'Open';
  barcode: string;
  attendanceDate: string;
  longAttendanceDate: string;
  dateId: string;
  scheduleType: 'NonRecurring';
  holder: Holder;
};

export type OrderCancelResponseType = {
  orderNo: string;
  ticketsCancelled: TicketCancelled[];
  subTotalRefunded: number;
  bookingFeeRefunded: number;
  refundProtectionRefunded: number;
  totalRefunded: number;
};

export type OrderCancelRequestData = {
  ticketIds: string[];
  reason: string;
  email: string;
  password: string;
  notifyUser: boolean;
  bookingFeePercentageRefund: number;
  refundOrderProtection: boolean;
  orderId: string;
};

export type DatesType = {
  id: string;
  eventName: string;
  avaibilityStatus: string;
  dates: {
    date: string;
    dateId: string;
    timeFrom: string;
    dateTo: string;
    timeTo: string;
    doorsOpenAt: string;
    hint: string;
    status: string;
    pastDate: boolean;
    ticketTypes: {
      id: string;
      name: string;
      status: TICKET_SALES_STATUS;
    };
  }[];
};

export type AppliedTicketTypeResponseType = {
  eventId: string;
  ticketType: { name: string; ticketTypeId: string; description: string };
  sectionType: string;
  sectionName: string;
  sectionId: string;
  showDate: string;
  showTime: string;
  eventName: string;
  showId: string;
  ticketTypeSeatsAllocated: {
    ticketTypeName: string;
    colour: string;
    seatId: string;
    ticketTypeId: string;
  }[];
};

export type AppliedTicketTypeRequestType = {
  eventId: string;
  sectionId: string;
  showId?: string;
};

export type GetDisabledSectionRequestType = {
  showId: string;
  sectionId: string;
};

export type ChangeTicketTypeAvailabilityRequest = {
  eventId: string;
  maxAttendees: number | undefined;
  ticketTypes?: {
    availabilityType: TICKET_SALES_STATUS; // Assuming availabilityType can have specific values
    ticketTypeId: string | undefined;
  }[];
  dateIds: string[] | undefined;
};

export type ChangeTicketTypeAvailabilityResponse = {
  id: string;
  eventName: string;
  dates: {
    date: string;
    dateId: string;
    timeFrom: string;
    dateTo: string;
    timeTo: string;
    doorsOpenAt: string;
    status: TICKET_SALES_STATUS;
    pastDate: boolean;
    ticketTypes: {
      id: string;
      name: string;
      status: TICKET_SALES_STATUS;
    }[];
  }[];
};

export enum NOTIFICATION_TYPE_ENUM {
  INFORMATION = 'information',
  WARNING = 'warning',
}

export type NotificationsResType = {
  message: string;
  title: string;
  notificationType: NOTIFICATION_TYPE_ENUM; // Assuming other types may be possible
  id: string;
  eventId: string;
  sortOrder: number;
};

export type NotificationCreateReqType = {
  message: string;
  title: string;
  notificationType: NOTIFICATION_TYPE_ENUM; // Assuming other types may be possible
  eventId: string;
  sortOrder: number;
};

export type PromoterFollower = {
  firstName: string;
  fullName: string;
  mobileNumber: string;
  lastName: string;
  userId: string;
  email: string;
  followingDate: string;
  numberOfTickets: number;
};

export type PromoterFollowersResType = {
  nextPage: number;
  previousPage: number;
  pageSize: number;
  pageNumber: number;
  totalPages: number;
  totalRecords: number;
  totalRecordsInPage: number;
  followers: PromoterFollower[];
};
