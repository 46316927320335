import { FC, useCallback, useEffect, useState } from 'react';

import { InputNumber, Radio, RadioChangeEvent } from 'antd';
import { Button, Form, Label, Select, Table, Typography } from 'ui';

import { EventTicketType } from 'types/events/eventTypes';

import s from './CreateCouponSpecificTicketType.module.scss';

interface CreateCouponSpecificTicketTypeProps {
  tickets?: EventTicketType[];
  setDiscountType: (discountType: string) => void;
  setSpecificTicketTypes: (ticketTypes: { ticketType: EventTicketType; value: string }[]) => void;
}

const options = [
  { label: 'Deduct', value: 'deduction' },
  { label: 'Fixed Price', value: 'fixedPrice' },
];

const CreateCouponSpecificTicketType: FC<CreateCouponSpecificTicketTypeProps> = (props) => {
  const { tickets, setSpecificTicketTypes, setDiscountType } = props;
  const [selectedTicketType, setSelectedTicketType] = useState<
    { ticketType: EventTicketType; value: string }[]
  >([]);
  const [form] = Form.useForm();

  useEffect(() => {
    setDiscountType('deduction');
  }, [setDiscountType]);

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setDiscountType(value);
  };

  const handleSubmit = useCallback(
    (values: { ticketType: { label: string; value: string }; value: string }) => {
      setSelectedTicketType((prevState) => {
        const existingIndex = prevState.findIndex(
          (item) => item.ticketType.id === values.ticketType.value,
        );

        if (existingIndex !== -1) {
          const temp = prevState.map((item, index) => {
            if (index === existingIndex) {
              return {
                ...values,
                ticketType: tickets?.find(
                  (item) => item.id === values.ticketType.value,
                ) as EventTicketType,
              };
            }
            return item;
          });
          setSpecificTicketTypes(temp);
          return temp;
        }

        const tempValue = [
          ...prevState,
          {
            ...values,
            ticketType: tickets?.find(
              (item) => item.id === values.ticketType.value,
            ) as EventTicketType,
          },
        ];
        setSpecificTicketTypes(tempValue);
        return tempValue;
      });

      form.resetFields();
    },
    [form, tickets, setSpecificTicketTypes],
  );

  return (
    <div className={s.wrapper}>
      <Typography type="h3" className={s.blockTitle}>
        Ticket Type Discounts
      </Typography>
      <Typography type="main">
        Choose ticket types on which you would like to give a discount from the available ticket
        types underneath
      </Typography>

      <div className={s.content}>
        <div>
          <Form onFinish={handleSubmit} form={form}>
            <Label required>Ticket Type</Label>
            <Form.Item
              name="ticketType"
              fullWidth
              rules={[
                {
                  required: true,
                  message: 'Select ticket type!',
                },
              ]}>
              <Select
                options={tickets?.map((item) => ({ label: item.name, value: item.id }))}
                placeholder="Select ticket type"
                fullWidth
              />
            </Form.Item>

            <div className={s.inputWrapper}>
              <div className={s.inputBlock}>
                <Label required>Specific Discount type</Label>
                <Form.Item name="discountType" fullWidth initialValue="deduction">
                  <Radio.Group options={options} optionType="button" onChange={onChange} />
                </Form.Item>
              </div>
              <div className={s.inputBlock}>
                <Label required>Discount (€)</Label>
                <Form.Item
                  name="value"
                  fullWidth
                  rules={[
                    {
                      required: true,
                      message: 'Input quantity!',
                    },
                  ]}>
                  <InputNumber
                    style={{ width: '100%' }}
                    size="large"
                    name="value"
                    placeholder="0"
                  />
                </Form.Item>
              </div>
            </div>

            <Button fullWidth htmlType="submit">
              Add
            </Button>
          </Form>
        </div>
        <div>
          {selectedTicketType.length > 0 && (
            <Table
              dataSource={selectedTicketType}
              rowKey="ticketType"
              columns={[
                {
                  title: 'Ticket Type',
                  dataIndex: 'ticketType',
                  render: (ticketType) => ticketType.name,
                },
                {
                  title: 'Discount (€)',
                  dataIndex: 'value',
                },
                {
                  title: 'Action',
                  render: (row) => {
                    const onEdit = () => {
                      form.setFieldValue('ticketType', {
                        label: row.ticketType.name,
                        value: row.ticketType.id,
                      });
                      form.setFieldValue('value', row.value);
                    };
                    const onDelete = () => {
                      setSelectedTicketType((prevState) => {
                        const temp = prevState.filter(
                          (item) => item.ticketType.id !== row.ticketType.id,
                        );
                        setSpecificTicketTypes(temp);
                        return temp;
                      });
                    };

                    return (
                      <div className={s.actionButtons}>
                        <button onClick={onEdit}>Edit</button>
                        <button onClick={onDelete}>Delete</button>
                      </div>
                    );
                  },
                },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateCouponSpecificTicketType;
