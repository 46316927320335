//auth constants
export const EMAIL_PLACEHOLDER = 'mail@example.com';
export const PASSWORD_PLACEHOLDER = '********';
export const VIDEO_PLACEHOLDER = 'https://www.youtube.com/';
export const SH_URL = 'www.showshappening.com/';

export const CURRENCIES_SETTING: { [key: string]: { code: string; symbol: string; name: string } } =
  {
    EUR: {
      code: 'eur',
      symbol: '€',
      name: 'Euro',
    },
    GBP: {
      code: 'gbp',
      symbol: '£',
      name: 'Sterling',
    },
  };
