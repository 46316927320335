import { Form as FormAntd } from 'antd';
import { FormInstance } from 'antd/es/form/hooks/useForm';

import InternalForm, { FormProps } from './form';
import FormItem from './form-item';
import { FormItemProps } from './form-item/formItem';

type CompoundedComponents = typeof InternalForm & {
  Item: typeof FormItem;
  useForm: typeof FormAntd.useForm;
  useFormInstance: typeof FormAntd.useFormInstance;
  useWatch: typeof FormAntd.useWatch;
  List: typeof FormAntd.List;
  ErrorList: typeof FormAntd.ErrorList;
  Provider: typeof FormAntd.Provider;
};

const Form = InternalForm as CompoundedComponents;
Form.Item = FormItem;
Form.useForm = FormAntd.useForm;
Form.useFormInstance = FormAntd.useFormInstance;
Form.useWatch = FormAntd.useWatch;
Form.List = FormAntd.List;
Form.ErrorList = FormAntd.ErrorList;
Form.Provider = FormAntd.Provider;

export type { FormProps, FormItemProps, FormInstance };
export default Form;
