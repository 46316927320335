import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { Modal } from 'antd';
import { message } from 'ui';

import {
  useDeleteTicketGroupMutation,
  useEditTicketGroupMutation,
} from 'services/events/eventsApiService';
import { setTicketGroups } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import { EventTicketGroupResType } from 'types/events/eventTypes';

import s from './TicketGroupsList.module.scss';
import TicketGroupItem from './ticket-group-item';

const { confirm } = Modal;

interface TicketsGroupListProps {
  list: EventTicketGroupResType[];
  currency: string;
  setActiveEditTicketGroup: Dispatch<SetStateAction<EventTicketGroupResType | undefined>>;
  eventType: boolean;
  currentDateId?: string;
  maxAttendeesAmount?: string;
  eventId?: string;
}
const TicketGroupsList: FC<TicketsGroupListProps> = (props) => {
  const { list, setActiveEditTicketGroup } = props;
  const dispatch = useAppDispatch();

  const [deleteTicketGroup] = useDeleteTicketGroupMutation();
  const [editTicketGroup] = useEditTicketGroupMutation();

  const sensors = useSensors(useSensor(PointerSensor));

  const showConfirm = async (id: string) => {
    confirm({
      title: 'Do you want to delete this ticket group?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      centered: true,
      mask: false,
      async onOk() {
        const res = await deleteTicketGroup(id);

        if ('data' in res) {
          message.success('Ticket group removed successfully.');
          const sortedResponse = [...(res?.data || [])].sort((a, b) => a.sortOrder - b.sortOrder);
          dispatch(setTicketGroups(sortedResponse));
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const syncTicketsToServer = useCallback(
    async (updatedItem) => {
      const res = await editTicketGroup(updatedItem);

      if ('data' in res) {
        const sortedResponse = [...(res?.data || [])].sort((a, b) => a.sortOrder - b.sortOrder);
        dispatch(setTicketGroups(sortedResponse));
        return res.data;
      }
    },
    [dispatch, editTicketGroup],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id && over && over.id !== active.id) {
        const oldIndex = list.findIndex((ticket, index) => `${ticket.name}_${index}` === active.id);
        const newIndex = list.findIndex((ticket, index) => `${ticket.name}_${index}` === over.id);

        const movedItems = arrayMove(list, oldIndex, newIndex);

        // Update the sortOrder field in the objects
        const updatedItems = movedItems.map((item, index) => {
          return { ...item, sortOrder: index };
        });

        const sortedResponse = [...(updatedItems || [])].sort((a, b) => a.sortOrder - b.sortOrder);
        dispatch(setTicketGroups(sortedResponse));
        updatedItems.forEach((item) => {
          syncTicketsToServer(item);
        });
      }
    },
    [list, dispatch, syncTicketsToServer],
  );

  const handleDeleteItem = (id: string) => {
    showConfirm(id);
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={list.map((ticketGroup, index) => `${ticketGroup.name}_${index}`)}>
        <div className={s.wrapper}>
          {list.map((ticketGroup, i) => {
            return (
              <TicketGroupItem
                ticketGroup={ticketGroup}
                index={i}
                key={`${ticketGroup?.name}_${i}`}
                handleDeleteItem={handleDeleteItem}
                setActiveEditTicketGroup={setActiveEditTicketGroup}
              />
            );
          })}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default TicketGroupsList;
