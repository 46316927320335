import { FC, useState } from 'react';

import { Dropdown, Icon, Typography } from 'ui';

import { NotificationsResType } from 'services/events/evetns.api.type';

import s from '../Notifications.module.scss';

interface NotificationItemProps {
  handleDeleteItem: (id: string) => void;
  setEditNotificationId: (notification: NotificationsResType) => void;
  notification: NotificationsResType;
  id: string;
}

const DROPDOWN_ITEMS = [
  { key: 'edit', label: <div className={s.dropdownItem}>Edit</div> },
  { key: 'delete', label: <div className={s.dropdownItem}>Delete</div> },
];

const NotificationItem: FC<NotificationItemProps> = ({
  handleDeleteItem,
  id,
  notification,
  setEditNotificationId,
}) => {
  const [openedDropdown, setOpenedDropdown] = useState(false);

  const handleClickDropdownItems = (event: { key: string }, id: string) => {
    if (event.key === 'delete') {
      setOpenedDropdown(false);
      handleDeleteItem(id);
    }

    if (event.key === 'edit') {
      setOpenedDropdown(false);
      setEditNotificationId(notification);
    }
  };
  return (
    <div className={s.notificationItem}>
      <div>
        <Typography type="h5">{notification?.title}</Typography>
        <Typography type="main" color="grey">
          {notification?.message}
        </Typography>
      </div>
      <Dropdown
        items={DROPDOWN_ITEMS}
        placement="bottomRight"
        handleItemClick={(event) => handleClickDropdownItems(event, id as string)}
        open={openedDropdown}
        onOpenChange={setOpenedDropdown}>
        <div className={s.dropdownButton}>
          <Icon name="verticalDots" color="#7d7da2" />
        </div>
      </Dropdown>
    </div>
  );
};

export default NotificationItem;
