import { FC } from 'react';

import { Switch as SwitchAntd } from 'antd';
import { SwitchProps } from 'antd';
import 'antd/lib/switch/style/index';

import './Switch.module.scss';

interface SwitchPropsImpl extends Omit<SwitchProps, 'onChange'> {
  onChange?: (checked: boolean) => void;
  value?: boolean;
}
const Switch: FC<SwitchPropsImpl> = (props) => {
  const { onChange = () => {}, value = false, ...otherProps } = props;

  const handleChange = (checked: boolean) => {
    onChange(checked);
  };
  return <SwitchAntd {...otherProps} onChange={handleChange} checked={value} />;
};

export default Switch;
