import { FC, useCallback } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';

import { setIsEditingEvent } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import s from './AppNavigationEventInfo.module.scss';

const AppNavigationEventInfo: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleBack = useCallback(() => {
    dispatch(setIsEditingEvent(false));
    navigate(MY_EVENTS_ROUTE);
  }, [navigate, dispatch]);

  return (
    <button className={s.wrapper} onClick={handleBack}>
      <div className={s.back}>
        <ArrowLeftOutlined />
      </div>
      <span className={s.backText}>Main Menu</span>
      {/*<div className={s.eventInfo}>*/}
      {/*  <div className={s.flyerWrapper}>*/}
      {/*    {activeEvent?.media?.flyer ? (*/}
      {/*      <img src={activeEvent?.media?.flyer} alt="eventFlyer" className={s.flyer} />*/}
      {/*    ) : (*/}
      {/*      <Icon name="littleLogo" />*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*  <div className={s.hideMobile}>*/}
      {/*    <Typography type="main" weight={700}>*/}
      {/*      {basicEventInfo ? basicEventInfo.eventName : t('common_your_event')}*/}
      {/*    </Typography>*/}
      {/*    <Typography className={s.text}>{t('common_fill_in_details')}</Typography>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </button>
  );
};

export default AppNavigationEventInfo;
