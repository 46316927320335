import { FC } from 'react';

import { LOGIN_ROUTE } from 'routes/user-management/list';
import { t } from 'tools/i18n';
import { Button, Form, Icon, Input, NavLink, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { LOGIN_FORM_ENUM } from '../login/login.utils';
import s from './ForgotPassword.module.scss';

const ForgotPassword: FC = () => {
  return (
    <div className={s.wrapper}>
      <Form className={s.form}>
        <Icon name="logo" width={170} height={24} />
        <Typography type="main" className={s.text}>
          {t('auth_reset_password')}
        </Typography>

        <Form.Item
          fullWidth
          label={t('common_email')}
          name={LOGIN_FORM_ENUM.EMAIL}
          rules={[formRules.required, formRules.email]}>
          <Input name={LOGIN_FORM_ENUM.EMAIL} placeholder="mail@example.com" size="large" />
        </Form.Item>

        <Button htmlType="submit" fullWidth size="large">
          {t('auth_login_log_in')}
        </Button>
        <NavLink label={t('common_cancel')} to={LOGIN_ROUTE} className={s.link} />
      </Form>
    </div>
  );
};

export default ForgotPassword;
