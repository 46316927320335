import React, { FC, PropsWithChildren } from 'react';

import cn from 'classnames';
import { Typography } from 'ui';

import s from './Label.module.scss';

interface LabelProps {
  className?: string;
  required?: boolean;
}

const Label: FC<PropsWithChildren<LabelProps>> = (props) => {
  const { className = '', required, children } = props;
  return (
    <Typography
      type="small"
      className={cn(s.label, { [s.required]: required, [className]: className })}>
      {children}
    </Typography>
  );
};

export default Label;
