export const initialValue = `
<ul>
<li>You acknowledge that ShowsHappening is a booking platform and is not authorised to issue refunds unless instructed to do so by the Organiser/Promoter/Venue. In the case of a refund being issued for tickets bought, refund will be issued on the ticket face value (excluding booking fee or charge). If you opted for a Refundable Booking the terms and conditions found at <a href="refundable.me/extended/en">refundable.me/extended/en</a> shall apply.</li>
<li>You acknowledge that ShowsHappening provides a payment method where payment is collected on behalf of the Organiser/Promoter/Venue as duly authorised, however your contractual relationship other than for the booking fee and the service we offer shall be with the Organiser/Promoter/Venue. ShowsHappening is not responsible for the organisation or staging of the Event and has no control over the cancellation or rescheduling of or for material changes to any Event.</li>
<li>ShowsHappening shall provide you with all information and any changes to your ticket as soon as reasonably possible.</li>
<li>By completing a purchase, you are confirming that you have read any specific terms and conditions which the Organiser/Promoter/Venue has issued with regard to this event.</li>
<li>All tickets or passes should be checked on purchase. Whilst every effort shall be made mistakes cannot always be rectified. Accordingly, please check your Tickets carefully on receipt and contact us immediately if there is a mistake.</li>
<li>ShowsHappening is not responsible for the actions or failures of any Organiser/Promoter/Venue. Under no circumstances shall we be liable for death, personal injury, loss or damage to property suffered by you or your guests arising out of attendance at an Event, unless caused by our negligence</li>
<li>In the event of any inconsistency with the terms of the Organiser/Promoter/Venue, those of ShowsHappening shall prevail.</li>
<li>Information is collected from those registering with <a href="www.showshappening.com">www.showshappening.com</a> in order to facilitate ticket purchase or other services available. This information is collected and used in accordance with <a href="https://www.showshappening.com/home/privacy">ShowsHappening's Privacy Policy</a>, which forms part of these conditions.</li>
</ul>
`;

export const promoterTerms = `
<ul>
<li>A valid ticket must be produced to get into an Event. Removing any part, altering or copying the ticket may invalidate your ticket.</li>
<li>You acknowledge that the Organiser may, where necessary add, withdraw or substitute artists and/or vary advertised programmes, event times, seating arrangements and audience capacity. Such changes should not be material changes to the event and shall occur either due to instructions by authorities, unavailability of the artists or due to other reasons beyond the Organiser’s control. This will not prejudice any rights you may have for a refund.</li>
<li>If the Event is rescheduled or moved to another date, the Organiser will give you the options of either retaining or exchanging your tickets for the new date/location or a refund. If the Event is cancelled, you may be offered the option to transfer your ticket to a future event or a refund on your tickets. Please note that any Booking Fees and/or Transaction Fees are not refundable in these circumstances.</li>
<li>If the Event is rescheduled, changed, moved or cancelled the Organiser cannot be held responsible for any resulting costs you may incur for travel, accommodation and other related goods, services or compensation.</li>
<li>Tickets cannot be resold for commercial gain. If the Organiser has reason to believe that tickets have been re-sold for such purposes, they may be invalidated, and the holder will be refused admission.</li>
<li>For the safety of people at the Event, the Organiser reserves the right to refuse admission and conduct security searches as they deem appropriate.</li>
<li>The Organiser shall not be responsible for any loss, damage, death or injury howsoever caused unless (i) the relevant party has breached its legal obligations; and (ii) such loss or damage is a direct and reasonably foreseeable result of the breach. For the avoidance of doubt, the Organiser will not be responsible for the actions of any third party, including, but not limited to, the exhibitors or performers.</li>
<li>If all or any of the Event is planned to take place outdoors it will take place regardless of weather conditions unless deemed unsafe by the safety officer. Whilst every attempt shall be made to secure a replacement indoor venue, there shall be no refunds for bad weather if the event takes place.</li>
<li>The Organiser does not accept any responsibility for any loss or damage to personal property brought to the Event.</li>
<li>Attendees may be filmed, photographed or sound recorded for broadcast, publication or security purposes whilst at the Event by the Organiser which shall include sponsors and other partners authorized by the Organiser.</li>
<li>Video and sound recordings are not permitted in theatrical and performance areas of the Event unless written permission is provided by the Organiser.</li>
<li>Events involving clubbing and drinks often have legal requirements for age. Anyone under age will be refused entry.</li>
<li>In accordance with the law, visitors aged under 17 are not permitted to purchase/sample alcoholic drinks.</li>
<li>The relevant Maltese law will apply to these Terms and Conditions and Courts of Malta will have exclusive jurisdiction in relation to the Terms and Conditions. ShowsHappening Terms and Conditions</li>
</ul>
`;
