import { FC } from 'react';

import { InputNumber } from 'antd';
import { Typography } from 'ui';

import s from './CreateCouponWholeOrderType.module.scss';

interface CreateCouponWholeOrderTypeProps {
  // Define your component props here
  setTotalAmount: (setTotalAmount: number) => void;
}

const CreateCouponWholeOrderType: FC<CreateCouponWholeOrderTypeProps> = (props) => {
  // Write your component code here
  const { setTotalAmount } = props;
  const handleAmountChange = (value: number | null) => {
    if (value !== null) {
      setTotalAmount(value);
    }
  };

  return (
    <div className={s.wrapper}>
      <Typography type="h3" className={s.blockTitle}>
        Discount Amount
      </Typography>

      <InputNumber
        style={{ width: '50%' }}
        size="large"
        name="amount"
        placeholder="0.00"
        prefix="€"
        onChange={handleAmountChange}
      />
    </div>
  );
};

export default CreateCouponWholeOrderType;
