import { FC, useCallback, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { t } from 'tools/i18n';
import { Icon, Typography } from 'ui';

import {
  EVENT_CREATE_FORM_BASIC_INFO_ENUM,
  EVENT_CREATE_FORM_BASIC_INFO_TYPE,
} from 'pages/events/events-create-event-form/event-create-form-basic-info/basicInfo.utils';
import { EventCreateFormDetailsListType } from 'pages/events/events-create-event-form/event-create-form-details/eventCreateFormDetails.utils';
import { EventCreateTaxesFormType } from 'pages/events/events-create-event-form/event-create-form-taxes/eventCreateFormTaxes.utils';
import { BANK_DETAILS_FORM_TYPE } from 'pages/user-management/user-setting/bank-details/bankDetails.utils';
import { ORGANISER_DETAILS_FORM_TYPE } from 'pages/user-management/user-setting/organiser-details/organiserDetailsForm.utils';

import s from './FormSubmit.module.scss';
import {
  BANK_DETAILS_FORM_REQUIRED_FIELDS,
  EVENT_CREATE_FORM_BASIC_REQUIRED_FIELDS,
  EVENT_CREATE_FORM_DETAILS_REQUIRED_FIELDS,
  EVENT_CREATE_FORM_TAXES_REQUIRED_FIELDS,
  ORGANISER_DETAILS_FORM_REQUIRED_FIELDS,
} from './formSubmit.utils';

interface FormSubmitProps {
  createEvent?: boolean;
  isEditing: boolean;
  isDirty?: boolean;
  hasOneDate?: boolean;
  editingList?:
    | ORGANISER_DETAILS_FORM_TYPE
    | BANK_DETAILS_FORM_TYPE
    | EVENT_CREATE_FORM_BASIC_INFO_TYPE
    | EventCreateTaxesFormType
    | EventCreateFormDetailsListType;
  type?: 'organiser' | 'bank' | 'eventBasic' | 'eventDetails' | 'taxesDetails' | 'empty';
  onClick?: () => void;
  fetching?: boolean;
}

const requiredFieldsTypes = {
  organiser: ORGANISER_DETAILS_FORM_REQUIRED_FIELDS,
  bank: BANK_DETAILS_FORM_REQUIRED_FIELDS,
  eventBasic: EVENT_CREATE_FORM_BASIC_REQUIRED_FIELDS,
  eventDetails: EVENT_CREATE_FORM_DETAILS_REQUIRED_FIELDS,
  taxesDetails: EVENT_CREATE_FORM_TAXES_REQUIRED_FIELDS,
  empty: [],
};

const FormSubmit: FC<FormSubmitProps> = (props) => {
  const {
    isEditing,
    editingList,
    type = 'organiser',
    onClick,
    fetching,
    createEvent,
    hasOneDate,
    isDirty,
  } = props;
  const [neededFields, setNeededFields] = useState<string[] | undefined>();
  const requiredFields = requiredFieldsTypes[type];

  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate(MY_EVENTS_ROUTE);
  }, [navigate]);

  useEffect(() => {
    if (isEditing && editingList) {
      let data: string[] = [];
      if (
        type === 'eventBasic' &&
        ['SINGLE_DATE'].includes(editingList[EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE])
      ) {
        data = [...requiredFields];
      } else if (
        ['NO_DATES', 'RECURRING_DATES'].includes(
          editingList[EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE],
        )
      ) {
        data = [...requiredFields, 'lastDate', 'lastTime'];
      } else if (hasOneDate) {
        data = [...requiredFields].filter(
          (item) => item !== EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATES,
        );
      } else {
        data = [...requiredFields];
      }

      const result = data.map((field) => {
        if (field === 'location') {
          const missingFields: string[] = [];
          if (!editingList[field].streetAddress) {
            missingFields.push('street');
          }
          if (!editingList[field].fullAddress || editingList[field].fullAddress === undefined) {
            missingFields.push('venue');
          }
          return missingFields.length ? missingFields : [];
        } else if (field === 'fullDescription' && editingList[field] === '<p></p>') {
          return field;
        } else if (
          !editingList[field] ||
          (Array.isArray(editingList[field]) && editingList[field].length === 0)
        ) {
          return field;
        }
      });
      const filteredResult = result.flat(Infinity).filter(Boolean);

      setNeededFields(filteredResult as string[]);
    }
  }, [isEditing, editingList, requiredFields, type, hasOneDate]);

  return (
    <div className={cn(s.wrapper, (isEditing || isDirty) && s.show)}>
      <div style={{ flex: 1 }}>
        {!!neededFields?.length && (
          <div className={s.hint}>
            <Icon name="warning" />
            <div>
              <b>Note.</b> Please enter all required information in the fields marked with an{' '}
              <span>*</span> to proceed to the next step. Missing fields:{' '}
              {neededFields?.map((field) => field && <b key={field}> {t(`field_${field}`)}, </b>)}
            </div>
          </div>
        )}
      </div>
      <div className={s.buttonWrapper}>
        {isDirty && !isEditing && !createEvent ? (
          <div className={s.changedWrapper}>
            <Typography type="h6" className={s.changeText}>
              CHANGES SAVED
            </Typography>
            <Button className={s.changedButton} type="link" onClick={handleBack}>
              <ArrowLeftOutlined /> <strong>Return to My Events</strong>
            </Button>
          </div>
        ) : (
          <Button
            className={s.button}
            htmlType="submit"
            size="large"
            type="primary"
            block
            disabled={!!neededFields?.length}
            onClick={onClick}
            loading={fetching}>
            <Typography type="main" className={s.buttonText}>
              {createEvent ? 'Next' : 'Save'}
            </Typography>
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormSubmit;
