export const EVENT_STATUS_CONFIG = {
  publish: {
    title: 'Publish Event',
    className: 'publish',
    type: 'publish',
    icon: 'play',
  },
  pause: {
    title: 'Pause Event',
    className: 'pause',
    type: 'pause',
    icon: 'pause',
  },
  replay: {
    title: 'Replay Event',
    className: 'replay',
    type: 'replay',
    icon: 'play',
  },
};
