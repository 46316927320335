import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ArrowLeftOutlined, ControlOutlined } from '@ant-design/icons';
import { Button, Checkbox, Modal, Select, Switch } from 'antd';
import { Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { getEditEventFormRoute } from 'routes/events/list';
import { useQuery } from 'tools/hooks';
import { Icon, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import {
  useApplyTicketTypesMutation,
  useEditEventBaseInfoMutation,
  useLazyGetAppliedTicketTypesQuery,
  useLazyGetSctionsQuery,
  useLazyGetSeatingPlanDetailQuery,
  useTransferTicketTypesMutation,
} from 'services/events/eventsApiService';
import { setActiveEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventBasicInfoType, EventTicketType } from 'types/events/eventTypes';

import '../EventCreateFormTickets.module.scss';
import s from './seatingPlan.module.scss';

interface ControlledSVGElement {
  className: string;
  visibilityType: string;
  appliesToAdmin: boolean;
}

const SeatingPlan = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getSeatingPlanDeatil, { data: seatingPlan }] = useLazyGetSeatingPlanDetailQuery();
  const [getSections, { data: sectionData }] = useLazyGetSctionsQuery();
  const [editEvent, { isLoading: isEditing }] = useEditEventBaseInfoMutation();
  const [transferTicketTypes, { isLoading: transferring }] = useTransferTicketTypesMutation();
  const query = useQuery();
  const sectionid = query.get('sectionid');
  const eventid = query.get('eventid');
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [svgUrl, setSvgUrl] = useState<string>('');
  const [dates, setDates] = useState<{ showId: string; startDate: string }[]>([]);
  const [tickets, setTickets] = useState<EventTicketType[]>([]);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [dateShowId, setDateShowId] = useState('');
  const [fromTicket, setFromtTicket] = useState<{ id: string; name: string }>({
    id: '',
    name: '',
  });
  const [toTicket, setToTicket] = useState<{ id: string; name: string }>({
    id: '',
    name: '',
  });
  const [batchTicketError, setBatchTicketError] = useState<boolean>(false);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [selectedTicketIndex, setSelectedTicketIndex] = useState(0);
  const [applyTicketTypes, { isLoading }] = useApplyTicketTypesMutation();
  const [getAppliedTicketTypes, { data: appliedTicketTypes }] = useLazyGetAppliedTicketTypesQuery();
  const [selectedCircles, setSelectedCircles] = useState<
    Set<{ id: string; color: string; ticketTypeId: string }>
  >(new Set());
  const [selection, setSelection] = useState<{
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  } | null>(null);
  const svgRef = useRef<SVGSVGElement | null>(null);
  const selectedTicketIndexRef = useRef(selectedTicketIndex);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBatchModalOpen, setBatchModalOpen] = useState(false);
  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleBatchModalOk = () => {
    transferTicketTypes({
      eventId: activeEvent?.eventId as string,
      fromTicketTypeId: fromTicket.id,
      toTicketTypeId: toTicket.id,
    }).then(() => {
      if (activeEvent) {
        const params = {
          eventId: activeEvent.eventId,
          sectionId: sectionid!,
        };
        getAppliedTicketTypes(params).then(() => {
          setBatchModalOpen(false);
          setConfirmModalOpen(false);
          window.location.reload();
        });
      }
    });
  };

  const handleOk = async () => {
    const result = activeEvent?.basicInfo ? { ...activeEvent.basicInfo } : null;

    const tempShowDates = result?.showDates!.map((item) => ({
      ...item,
      ignoreSeatingAlgorithm: selectedDates.includes(item.showId!) ? false : true,
    }));

    const updatedBasicInfo = {
      ...(activeEvent?.basicInfo as EventBasicInfoType),
      showDates: tempShowDates,
    };
    const res = await editEvent({
      basicInfo: updatedBasicInfo!,
      eventId: activeEvent?.eventId as string,
    });
    if ('data' in res) {
      setIsModalOpen(false);
      dispatch(setActiveEvent(res?.data));
    }
  };

  useEffect(() => {
    selectedTicketIndexRef.current = selectedTicketIndex;
  }, [selectedTicketIndex]);

  const actionRef = useRef<HTMLDivElement | null>(null);
  const actionRefHeight = actionRef.current?.getBoundingClientRect().height;

  const rgbToHex = (color) => {
    if (color.startsWith('#')) {
      return color.toUpperCase();
    }

    const result = color.match(/\d+/g);
    if (result) {
      const r = parseInt(result[0], 10);
      const g = parseInt(result[1], 10);
      const b = parseInt(result[2], 10);
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    }
    return null;
  };

  const handleClick = (index) => {
    setSelectedTicketIndex(index);
  };

  useEffect(() => {
    if (activeEvent) {
      getSections(activeEvent.eventId);
    }
  }, [activeEvent, getSections]);

  useEffect(() => {
    if (activeEvent) {
      const params = {
        eventId: activeEvent.eventId,
        sectionId: sectionid!,
      };
      const appliedAlgorithms =
        activeEvent.basicInfo.showDates
          ?.map((item) => (!item.ignoreSeatingAlgorithm ? (item.showId as string) : null))
          .filter((id) => id !== null) || [];
      setSelectedDates(appliedAlgorithms);

      getAppliedTicketTypes(params);
      setSelectedCircles(new Set());
    }
  }, [activeEvent, sectionid, getAppliedTicketTypes]);

  useEffect(() => {
    if (appliedTicketTypes) {
      appliedTicketTypes[0].ticketTypeSeatsAllocated.forEach((seat) => {
        const seatId = seat.seatId;
        if (seatId) {
          setSelectedCircles((prev) => {
            const newSet = new Set(prev);
            newSet.add({
              id: seatId,
              color: rgbToHex(seat.colour)!,
              ticketTypeId: seat.ticketTypeId!,
            });
            return newSet;
          });
        }
      });
    }
  }, [appliedTicketTypes]);

  const getControlledSVGElements = (data, showId: string) => {
    const controlledSVGElements: ControlledSVGElement[] = [];

    data.forEach((event) => {
      event.shows.forEach((show) => {
        if (!showId || show.id === showId) {
          controlledSVGElements.push(...show.controlledSVGElements);
        }
      });
    });

    return controlledSVGElements;
  };

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = svgRef.current;
    const circles = svg.querySelectorAll('.seat');
    if (selectedCircles.size > 0) {
      circles.forEach((circle) => {
        const circleElement = circle as SVGElement;
        const circleId = circleElement.getAttribute('id');
        if (circleId) {
          const selectedCircle = Array.from(selectedCircles).find(
            (circle) => circle.id === circleId,
          );
          if (selectedCircle) {
            circleElement.style.fill = selectedCircle.color;
          }
        }
      });
    }

    // const elements = getControlledSVGElements(sectionData, dateShowId);
    // const filteredClassNames = elements
    //   .filter((element) => element.appliesToAdmin === false && element.visibilityType === 'hide')
    //   .map((element) => element.className);

    // const hideElements = svg.querySelectorAll('.stalls');
    // console.log(filteredClassNames);
    // hideElements!.forEach((element) => {
    //   console.log(element);
    //   const svgElement = element as SVGElement;
    //   svgElement.style.display = 'none';
    // });
  }, [selectedCircles, dateShowId, sectionData]);

  const handleSubmit = useCallback(() => {
    const seatData = Array.from(selectedCircles).map((seat) => {
      return {
        seatId: seat.id,
        ticketTypeId: seat.ticketTypeId,
      };
    });

    const result = {
      eventId: eventid as string,
      showId: dateShowId || '',
      sectionId: sectionid as string,
      ticketTypeId: '',
      seats: seatData,
    };
    applyTicketTypes(result);
  }, [eventid, selectedCircles, dateShowId, applyTicketTypes, sectionid]);

  const handleCircleClick = (event) => {
    if (isDragEnabled || !tickets) return;
    event.stopPropagation();
    const circle = event.target;
    if (circle.classList.contains('seat')) {
      circle.style.fill = rgbToHex(tickets[selectedTicketIndex].colour);
      const circleId = circle.getAttribute('id');
      if (circleId) {
        setSelectedCircles((prev) => {
          const newSet = new Set(prev);
          const existingCircle = Array.from(prev).find((circle) => circle.id === circleId);
          if (existingCircle) {
            existingCircle.color = rgbToHex(tickets[selectedTicketIndex].colour)!;
            existingCircle.ticketTypeId = tickets[selectedTicketIndex].id!;
          } else {
            newSet.add({
              id: circleId,
              color: rgbToHex(tickets[selectedTicketIndex].colour)!,
              ticketTypeId: tickets[selectedTicketIndex].id!,
            });
          }
          return newSet;
        });
      }
    }
  };

  useEffect(() => {
    if (activeEvent) {
      getSeatingPlanDeatil(activeEvent?.basicInfo.seatingPlanId as string);
      const tempDates =
        activeEvent.basicInfo.showDates?.map((date) => {
          return {
            showId: date.showId as string,
            startDate: date.startDate,
          };
        }) || [];

      const defaultDate = { showId: '', startDate: 'All Dates' };
      const extendedDates = [defaultDate, ...tempDates];
      if (extendedDates) {
        setDates(extendedDates);
      }

      const tempTickets = activeEvent.ticketTypes.map((item) => item);
      if (tempTickets) {
        setTickets(tempTickets);
      }
    }
  }, [getSeatingPlanDeatil, activeEvent]);

  // useEffect(() => {
  //   console.log(seatingPlan, eventid, sectionid);
  // }, [seatingPlan, eventid, sectionid]);

  useEffect(() => {
    if (seatingPlan) {
      const currentSection = seatingPlan[0]?.sections.find(
        (item: { id: string | null }) => item.id === sectionid,
      );
      setSvgUrl(currentSection?.svg);
    }
  }, [seatingPlan, sectionid]);

  const handleAllSelectChange = (e) => {
    if (!e.target.checked) {
      setSelectedCircles(new Set());
      return; // Exit early to avoid unnecessary processing
    }

    const svg = svgRef.current;
    const circles = svg?.querySelectorAll('.seat');
    if (circles) {
      circles.forEach((circle) => {
        const circleId = circle.getAttribute('id');
        if (circleId) {
          setSelectedCircles((prev) => {
            const newSet = new Set(prev);
            const currentTicketIndex = selectedTicketIndexRef.current;
            newSet.add({
              id: circleId,
              color: rgbToHex(tickets[currentTicketIndex].colour)!,
              ticketTypeId: tickets[currentTicketIndex].id!,
            });
            return newSet;
          });
        }
      });
    }
  };

  const handleAlgorithmDateChange = (e: CheckboxChangeEvent, selectedDate: string) => {
    const isChecked = e.target.checked;
    if (selectedDate === '') {
      if (isChecked) {
        const allShowIds = dates.flatMap((date) => date.showId);
        setSelectedDates(allShowIds);
      } else {
        setSelectedDates([]);
      }
    } else {
      if (isChecked) {
        setSelectedDates([...selectedDates, selectedDate]);
      } else {
        setSelectedDates(selectedDates.filter((date) => date !== selectedDate && date !== ''));
      }
    }
  };
  const getScrollOffsets = (element) => {
    let offsetX = 0;
    let offsetY = 0;
    while (element) {
      offsetX += element.scrollLeft;
      offsetY += element.scrollTop;
      element = element.parentElement;
    }
    return { offsetX, offsetY };
  };

  const handleMouseDown = (event: MouseEvent) => {
    if (!isDragEnabled || !svgRef.current || !actionRefHeight) return;

    const svgRect = svgRef.current.getBoundingClientRect();
    const { offsetX, offsetY } = getScrollOffsets(svgRef.current);
    if (actionRef.current) {
      setSelection({
        x1: event.clientX - svgRect.left + 439 - offsetX,
        y1: event.clientY - svgRect.top + 46 + actionRefHeight - offsetY,
        x2: event.clientX - svgRect.left + 439 - offsetX,
        y2: event.clientY - svgRect.top + 46 + actionRefHeight - offsetY,
      });
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragEnabled || !selection || !svgRef.current || !actionRefHeight) return;

    const svgRect = svgRef.current.getBoundingClientRect();
    const { offsetX, offsetY } = getScrollOffsets(svgRef.current);
    setSelection({
      ...selection,
      x2: event.clientX - svgRect.left + 439 - offsetX,
      y2: event.clientY - svgRect.top + 46 + actionRefHeight - offsetY,
    });
  };

  const handleMouseUp = () => {
    if (!isDragEnabled || !selection || !svgRef.current || !actionRefHeight || !tickets) return;

    const { x1, y1, x2, y2 } = selection;
    const rectX = Math.min(x1 - 439, x2 - 439);
    const rectY = Math.min(y1 - 46 - actionRefHeight, y2 - 46 - actionRefHeight);
    const rectWidth = Math.abs(x2 - x1);
    const rectHeight = Math.abs(y2 - y1);

    const svg = svgRef.current;
    const svgRect = svg.getBoundingClientRect();
    const { offsetX, offsetY } = getScrollOffsets(svgRef.current);
    const circles = svg.querySelectorAll('.seat');
    const viewBox = svg.getAttribute('viewBox');
    let Swidth, Sheight;
    if (viewBox) {
      const [, , width, height] = viewBox.split(' ').map(Number);
      Swidth = width;
      Sheight = height;
    }
    circles.forEach((circle) => {
      const cx = parseFloat(circle.getAttribute('cx')!);
      const cy = parseFloat(circle.getAttribute('cy')!);
      if (
        (cx * svgRect.width) / Swidth >= rectX - offsetX &&
        (cx * svgRect.width) / Swidth <= rectX + rectWidth - offsetX &&
        (cy * svgRect.height) / Sheight >= rectY + offsetY &&
        (cy * svgRect.height) / Sheight <= rectY + rectHeight + offsetY
      ) {
        const circleId = circle.getAttribute('id');
        if (circleId) {
          setSelectedCircles((prev) => {
            const newSet = new Set(prev);
            const existingCircle = Array.from(prev).find((circle) => circle.id === circleId);
            if (existingCircle) {
              existingCircle.color = rgbToHex(tickets[selectedTicketIndex].colour)!;
              existingCircle.ticketTypeId = tickets[selectedTicketIndex].id!;
            } else {
              newSet.add({
                id: circleId,
                color: rgbToHex(tickets[selectedTicketIndex].colour)!,
                ticketTypeId: tickets[selectedTicketIndex].id!,
              });
            }
            return newSet;
          });
        }
      }
    });

    setSelection(null);
  };

  return (
    <>
      {' '}
      <Modal
        centered
        closable={false}
        open={isModalOpen}
        zIndex={50}
        footer={[
          <Button key="back" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={isEditing} onClick={handleOk}>
            Save
          </Button>,
        ]}>
        <Typography type="h4">Select dates to apply Seating algorithm</Typography>
        {dates && (
          <div className={s.algoDates}>
            {dates.map((date, index) => (
              <Checkbox
                key={index}
                checked={selectedDates.includes(date.showId)}
                onChange={(e) => handleAlgorithmDateChange(e, date.showId)}>
                {date.startDate}
              </Checkbox>
            ))}
          </div>
        )}
      </Modal>
      <Modal
        centered
        closable={false}
        open={isConfirmModalOpen}
        zIndex={1000}
        footer={[
          <Button key="back" onClick={() => setConfirmModalOpen(false)}>
            CANCEL
          </Button>,
          <Button key="submit" type="primary" loading={transferring} onClick={handleBatchModalOk}>
            CHANGE
          </Button>,
        ]}>
        <div style={{ marginBottom: 16 }}>
          <Typography type="h4">Are you sure you want to change ticket type?</Typography>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Typography type="main">
            For ticket type from {fromTicket.name} to {toTicket.name}{' '}
          </Typography>
        </div>
      </Modal>
      <Modal
        centered
        closable={false}
        open={isBatchModalOpen}
        footer={[
          <div key="batchAction" className={s.batchModalButton}>
            <Button key="back" style={{ width: '100%' }} onClick={() => setBatchModalOpen(false)}>
              Cancel
            </Button>
            <Button
              key="submit"
              style={{ width: '100%' }}
              type="primary"
              onClick={() => {
                if (
                  toTicket.id !== '' &&
                  toTicket.id !== undefined &&
                  fromTicket.id !== '' &&
                  fromTicket.id !== undefined
                ) {
                  setConfirmModalOpen(true);
                } else {
                  setBatchTicketError(true);
                }
              }}>
              Save
            </Button>
          </div>,
        ]}>
        <Typography type="h3">Batch update ticket types</Typography>
        <p style={{ marginTop: 16, marginBottom: 16 }}>
          Use this function with caution. Basically this function will allow youto transfer all
          seats from one ticket type to another. This is very useful if you have for example Early
          Brids and you wish to change all seats to normal ticket types.
        </p>
        <div className={s.modalSelectBox}>
          <Typography className={s.label} type="main">
            From ticket type
          </Typography>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => {
              const selectedTicket = tickets.find((ticket) => ticket.id === value);
              setFromtTicket({
                id: selectedTicket?.id as string,
                name: selectedTicket?.name as string,
              });
              setBatchTicketError(false);
            }}
            options={[
              { label: 'Select ticket', value: '' },
              ...tickets.map((ticket) => ({
                label: ticket.name,
                value: ticket.id,
              })),
            ]}
            defaultValue=""
          />
        </div>
        <div className={s.modalSelectBox}>
          <Typography className={s.label} type="main">
            To ticket type
          </Typography>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => {
              const selectedTicket = tickets.find((ticket) => ticket.id === value);
              setToTicket({
                id: selectedTicket?.id as string,
                name: selectedTicket?.name as string,
              });
              setBatchTicketError(false);
            }}
            options={[
              { label: 'Select ticket', value: '' },
              ...tickets.map((ticket) => ({
                label: ticket.name,
                value: ticket.id,
              })),
            ]}
            defaultValue=""
          />
        </div>
        {batchTicketError && <span style={{ color: 'red' }}>Choose all ticket correctly.</span>}
      </Modal>
      <div className={s.wrapper}>
        <div className={s.actionGroup} ref={actionRef}>
          <Button
            icon={<ArrowLeftOutlined />}
            className={s.backButton}
            onClick={() =>
              navigate(
                `${getEditEventFormRoute(activeEvent?.eventId!)}?step=${
                  CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST
                }`,
              )
            }>
            Sections
          </Button>
          {seatingPlan && (
            <div className={s.title}>
              <Typography type="h2">Seating Plan -</Typography>
              <Typography type="h2">{seatingPlan[0].name}</Typography>
            </div>
          )}
          <div className={s.dates}>
            <Typography type="h5">Select the dates you wish to apply tickets to</Typography>
            <Select
              style={{ width: '50%', marginTop: 16, marginBottom: 36 }}
              onChange={(value) => setDateShowId(value)}
              options={dates?.map((item) => ({
                label: item.startDate,
                value: item.showId,
              }))}
              value={dateShowId}
              defaultValue=""
            />
          </div>

          <div>
            <Typography type="h3">Select the ticket type to apply to seats</Typography>
            <div>
              <div className={s.hint}>
                <div>
                  <Icon name="warning" />
                </div>
                <Typography type="main">
                  TIP. Want to update seats from one ticket type to another? Click on the button to
                  the right.
                </Typography>
                <Button type="primary" onClick={() => setBatchModalOpen(true)}>
                  CHANGE
                </Button>
              </div>
            </div>
          </div>

          <div className={s.ticketGroup}>
            {tickets &&
              tickets.map((ticket, index) => (
                <Button
                  type="primary"
                  className={`${s.ticket} ${selectedTicketIndex === index ? s.selected : ''}`}
                  key={index}
                  onClick={() => handleClick(index)}>
                  <span>{ticket.name}</span>
                  <span style={{ alignItems: 'center', display: 'flex' }}>
                    €{ticket.price}
                    <span
                      className={s.ticketCircle}
                      style={{ backgroundColor: rgbToHex(ticket.colour)! }}></span>
                  </span>
                </Button>
              ))}
          </div>
          <div style={{ width: '100%' }}>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={isLoading}
              style={{ width: '100%', marginBottom: 32 }}>
              SAVE
            </Button>
          </div>
          <div className={s.controlOptions}>
            <Checkbox onChange={handleAllSelectChange} style={{ marginBottom: '10px' }}>
              Select All
            </Checkbox>
            <div style={{ display: 'flex', gap: 8, width: 160, justifyContent: 'space-between' }}>
              <Switch
                checked={isDragEnabled}
                onChange={(checked) => setIsDragEnabled(checked)}
                style={{ marginBottom: '10px', height: 16, color: 'white' }}
              />
              <Typography type="main">
                {isDragEnabled ? 'Disable Dragging' : 'Enable Dragging'}
              </Typography>
            </div>
          </div>
        </div>
        <div style={{ width: '70%', overflowX: 'scroll' }}>
          {svgUrl && (
            <ReactSVG
              id="svg"
              style={{ width: '100%' }}
              src={svgUrl}
              loading={() => <Spin />}
              afterInjection={(svg) => {
                svgRef.current = svg;
                const circles = svg.querySelectorAll('.seat');
                circles.forEach((circle) => {
                  circle.addEventListener('click', handleCircleClick);
                });
                svg.addEventListener('click', handleCircleClick);
                svg.addEventListener('mousedown', handleMouseDown);
                svg.addEventListener('mousemove', handleMouseMove);
                svg.addEventListener('mouseup', handleMouseUp);
              }}
              beforeInjection={(svg) => {
                // Apply styles before the SVG is injected
                svgRef.current = svg;
                const circles = svg.querySelectorAll('.seat');
                circles.forEach((circle) => {
                  const circleElement = circle as SVGElement;
                  const circleId = circleElement.getAttribute('id');
                  if (circleId) {
                    const selectedCircle = Array.from(selectedCircles).find(
                      (circle) => circle.id === circleId,
                    );
                    if (selectedCircle) {
                      circleElement.style.fill = selectedCircle.color;
                    }
                  }

                  const elements = getControlledSVGElements(sectionData, dateShowId);
                  const filteredClassNames = elements
                    .filter(
                      (element) =>
                        element.appliesToAdmin === false && element.visibilityType === 'hide',
                    )
                    .map((element) => element.className);
                  filteredClassNames.forEach((item) => {
                    const hideElements = svg.querySelectorAll(`.${item}`);
                    hideElements!.forEach((element) => {
                      const svgElement = element as SVGElement;
                      svgElement.style.display = 'none';
                    });
                  });
                });
              }}
            />
          )}
        </div>
        <div className={s.algorithm}>
          <Typography type="h4">Use seating algorithm</Typography>
          <Typography type="h3">Select dates to apply Seating algorithm</Typography>
          <Button
            style={{ width: '50%' }}
            size="large"
            icon={<ControlOutlined />}
            onClick={showModal}>
            Select dates for algorithm
          </Button>
          <div style={{ display: 'flex', gap: 8, marginTop: 16 }}>
            {dates.map((date, index) =>
              selectedDates.includes(date.showId) ? (
                <Button key={index} style={{ borderRadius: '32px', backgroundColor: '#E7E9F1' }}>
                  {date.startDate}
                </Button>
              ) : (
                <></>
              ),
            )}
          </div>
          <div className={s.hint} style={{ width: '50%' }}>
            <div>
              <Icon name="warning" />
            </div>
            <Typography type="main">
              The seating algorithm will not allow a person to leave one empty seat in his seat
              selection
            </Typography>
          </div>
        </div>

        {selection && (
          <div
            style={{
              position: 'absolute',
              left: Math.min(selection.x1, selection.x2),
              top: Math.min(selection.y1, selection.y2),
              width: Math.abs(selection.x2 - selection.x1),
              height: Math.abs(selection.y2 - selection.y1),
              border: '1px solid blue',
              pointerEvents: 'none',
            }}
          />
        )}
      </div>
    </>
  );
};

export default SeatingPlan;
