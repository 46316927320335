import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { handleCopy } from 'tools/copyToClipboard';
import { handleCutString } from 'tools/cutstring';
import { t } from 'tools/i18n';
import { Icon, Label, Modal, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { usePublishEventMutation } from 'services/events/eventsApiService';
import { setIsPublishingEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { MY_EVENTS_ROUTE } from '../../../../routes/events/list';
import s from '../../PublishEventModal.module.scss';
import { PublishEventModalStepsType } from '../../publishEventModal.utils';

interface PublishEventModalSecondStepProps {
  handleClose: () => void;
  setActiveStep: Dispatch<SetStateAction<PublishEventModalStepsType>>;
}
const PublishEventModalSecondStep: FC<PublishEventModalSecondStepProps> = ({
  handleClose,
  setActiveStep,
}) => {
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [publishEvent, { isLoading }] = usePublishEventMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handlePublishEvent = useCallback(async () => {
    const res = await publishEvent({
      id: activeEvent?.eventId,
    });

    if ('data' in res) {
      handleClose();
      dispatch(setIsPublishingEvent(true));
      navigate(MY_EVENTS_ROUTE);
    }
  }, [activeEvent?.eventId, dispatch, handleClose, navigate, publishEvent]);

  const handleCloseModal = useCallback(() => {
    if (activeEvent?.basicInfo?.status === 'draft') {
      setActiveStep('second');
    } else {
      setActiveStep('second');
    }
    handleClose();
  }, [activeEvent, handleClose, setActiveStep]);

  return (
    <>
      <Modal.Header>
        <Typography type="h3" className={s.modalText}>
          {t('common_publish_event')}
        </Typography>
      </Modal.Header>
      <Modal.Content>
        <div className={s.secondWrapper}>
          <div className={s.secondTitle}>{t('publish_event_modal_reviewed_details')}</div>
          <div className={s.secondTitle}>{t('publish_event_modal_reviewed_time_text')}</div>
          <div>
            <Label>The link to see the event page</Label>
            <div className={s.linkBlock}>
              {!!activeEvent?.basicInfo?.url && handleCutString(activeEvent?.basicInfo?.url, 45)}
              <div className={s.linkActions}>
                <button
                  className={s.button}
                  onClick={() => handleCopy(activeEvent?.basicInfo?.url || '')}>
                  <Icon name="copy" size={26} color="#008eff" />
                </button>
                <a href={activeEvent?.basicInfo?.url} target="_blank" className={s.button}>
                  <Icon name="goTo" size={16} color="#008eff" />
                </a>
              </div>
            </div>

            <div className={s.warning}>
              <div>
                <Icon name="warning" />
              </div>
              <Typography type="main" weight={600}>
                To preview your event page, please ensure that you are logged in to your
                ShowsHappening account. Only logged-in users have access to the event preview
                feature.
              </Typography>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button size="large" block onClick={handleCloseModal}>
          {t('common_cancel')}
        </Button>
        <Button type="primary" block size="large" loading={isLoading} onClick={handlePublishEvent}>
          {t('common_submit')}
        </Button>
      </Modal.Actions>
    </>
  );
};

export default PublishEventModalSecondStep;
