import { FC } from 'react';

import { Collapse as CollapseAntd, CollapseProps as CollapseAntdProps } from 'antd';
import 'antd/lib/collapse/style/index';

import Icon from '../icon';
import s from './Collapse.module.scss';

const Collapse: FC<CollapseAntdProps> = (props) => {
  return (
    <CollapseAntd
      className={s.wrapper}
      expandIcon={({ isActive }) => <Icon name={isActive ? 'minusSquare' : 'plusSquare'} />}
      {...props}
    />
  );
};

export default Collapse;
