import { Navigate } from 'react-router-dom';

import { AdminUserList } from 'pages/admin-pages';
import Calendar from 'pages/calendar';
import Dashboard from 'pages/dashboard';
import Events from 'pages/events';
import EventAttendeesList from 'pages/events/event-attendees-list';
import EventOffer from 'pages/events/event-offer';
import EventScanningPage from 'pages/events/event-scanning-page';
import EventZreadingList from 'pages/events/event-z-reading-list';
import EventsCouponsPage from 'pages/events/events-coupons-page';
import EventsCreateCouponPage from 'pages/events/events-create-coupon-page';
import EventCreateFormPermission from 'pages/events/events-create-event-form/event-create-form-permission';
import EventsEditCouponPage from 'pages/events/events-edit-coupon-page';
import EventTaxReport from 'pages/events/events-tax-report';
import FAQ from 'pages/faq';
import Features from 'pages/features';
import Notifications from 'pages/notifications';
import Orders from 'pages/orders';
import OrderFullInfo from 'pages/orders/order-full-info';
import Outlets from 'pages/outlets';
import Redirect from 'pages/redirect';
import ScanPage from 'pages/scan-page';
import Sellers from 'pages/sellers';
import ForgotPassword from 'pages/user-management/forgot-password';
import Login from 'pages/user-management/login';
import Register from 'pages/user-management/register';
import { BankDetails, OrganiserDetails } from 'pages/user-management/user-setting';

import { RouteType } from 'types/core/routeTypes';

import AdminRoleGuard from '../component/admin-role-guard';
import EventsCreateEventForm from '../pages/events/events-create-event-form';
import { ADMIN_USERS_ROUTE } from './admin/list';
import { CALENDAR_ROUTE, NOTIFICATION_ROUTE, OUTLETS_ROUTE, SELLERS_ROUTE } from './core/list';
import { DASHBOARD_ROUTE } from './dashboard/list';
import {
  ATTENDEES_EVENT_ROUTE,
  CREATE_EVENT_FORM_ROUTE,
  EDIT_EVENT_FORM_ROUTE,
  EVENT_COUPONS_ROUTE,
  EVENT_COUPON_CREATE_ROUTE,
  EVENT_COUPON_EDIT_ROUTE,
  EVENT_OFFER_ROUTE,
  EVENT_PERMISSION_ROUTE,
  EVENT_SCANNING_ROUTE,
  EVENT_TAX_REPORT_ROUTE,
  MY_EVENTS_ROUTE,
  ZREADING_EVENT_ROUTE,
} from './events/list';
import { FAQ_ROUTE, FEATURE_ROUTE } from './help/list';
import { TICKETS_ORDERS_FULL, TICKETS_ORDERS_ROUTE, TICKETS_SCANS_ROUTE } from './tikets/list';
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  REDIRECT_ROUTE,
  REGISTER_ROUTE,
  USER_SETTING_BANK_DETAILS_ROUTE,
  USER_SETTING_ROUTE,
} from './user-management/list';

export const HOME_NON_AUTH_ROUTE = LOGIN_ROUTE;
export const UNKNOWN_ROUTE = '*';

export const AUTH_ROUTES: RouteType[] = [
  { path: DASHBOARD_ROUTE, element: <Dashboard /> },
  { path: MY_EVENTS_ROUTE, element: <Events /> },
  { path: TICKETS_ORDERS_ROUTE, element: <Orders /> },
  { path: CALENDAR_ROUTE, element: <Calendar /> },
  { path: TICKETS_SCANS_ROUTE, element: <ScanPage /> },
  { path: OUTLETS_ROUTE, element: <Outlets /> },
  { path: SELLERS_ROUTE, element: <Sellers /> },
  { path: EVENT_PERMISSION_ROUTE, element: <EventCreateFormPermission /> },
  {
    path: ADMIN_USERS_ROUTE,
    element: (
      <AdminRoleGuard>
        <AdminUserList />
      </AdminRoleGuard>
    ),
  },
  { path: USER_SETTING_ROUTE, element: <OrganiserDetails /> },
  { path: USER_SETTING_BANK_DETAILS_ROUTE, element: <BankDetails /> },
  { path: CREATE_EVENT_FORM_ROUTE, element: <EventsCreateEventForm /> },
  { path: EDIT_EVENT_FORM_ROUTE, element: <EventsCreateEventForm /> },
  { path: TICKETS_ORDERS_FULL, element: <OrderFullInfo /> },
  { path: ATTENDEES_EVENT_ROUTE, element: <EventAttendeesList /> },
  { path: ZREADING_EVENT_ROUTE, element: <EventZreadingList /> },
  { path: EVENT_SCANNING_ROUTE, element: <EventScanningPage /> },
  { path: EVENT_OFFER_ROUTE, element: <EventOffer /> },
  { path: FEATURE_ROUTE, element: <Features /> },
  { path: FAQ_ROUTE, element: <FAQ /> },
  { path: NOTIFICATION_ROUTE, element: <Notifications /> },
  { path: EVENT_TAX_REPORT_ROUTE, element: <EventTaxReport /> },
  { path: EVENT_COUPONS_ROUTE, element: <EventsCouponsPage /> },
  { path: EVENT_COUPON_CREATE_ROUTE, element: <EventsCreateCouponPage /> },
  { path: EVENT_COUPON_EDIT_ROUTE, element: <EventsEditCouponPage /> },
  { path: UNKNOWN_ROUTE, element: <Navigate to={DASHBOARD_ROUTE} /> },
];

export const NON_AUTH_ROUTES: RouteType[] = [
  { path: LOGIN_ROUTE, element: <Login /> },
  { path: REGISTER_ROUTE, element: <Register /> },
  { path: FORGOT_PASSWORD_ROUTE, element: <ForgotPassword /> },
  { path: REDIRECT_ROUTE, element: <Redirect /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={HOME_NON_AUTH_ROUTE} /> },
];
