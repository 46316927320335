import { Dispatch, FC, SetStateAction } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Icon, Modal, Typography } from 'ui';

import { CREATE_EVENT_FORM_ROUTE } from '../../../../../routes/events/list';
import s from './EventModal.module.scss';

interface EventModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const EventModal: FC<EventModalProps> = (props) => {
  const { open, setOpen } = props;
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const goToSettings = () => {
    navigate(CREATE_EVENT_FORM_ROUTE);
  };

  return (
    <Modal open={open} handleClose={handleClose} footer={false}>
      <Modal.Header className={s.header}>
        <Icon name="checked" size={40}></Icon>
        <Typography type="h2"> Promoter data has been successfully saved</Typography>
      </Modal.Header>
      <Modal.Content>
        <div className={s.wrapper}>
          <Typography type="main" className={s.text}>
            Data about the promoter is successfully saved,now you can create your first event! Click
            ‘Create Event’ and start your journey with ShowsManager
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          block
          size="large"
          style={{ fontSize: 14, letterSpacing: 0.84 }}
          type="primary"
          onClick={goToSettings}>
          CREATE EVENT
        </Button>
        <Button block size="large" style={{ fontSize: 14 }} onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EventModal;
