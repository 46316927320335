import { FC, memo } from 'react';

import {
  CalendarOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  PauseOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { handleCutString } from 'tools/cutstring';
import { extractFlyerName } from 'tools/extractFlyerName';
import { formatNumber } from 'tools/formatNumbers';
import { ConfigProvider, Icon, Table, Tooltip, Typography } from 'ui';

import EventListActionsDropdown from 'pages/events/event-list-actions-dropdown/eventListActionsDropdown';

import EventsEmptyState from 'containers/events-empty-state';

import { EventMetricsType, EventWithMetricsType, SingleEventType } from 'types/events/eventTypes';

import { CURRENCIES_SETTING } from '../../../constants/core-constants';
import s from './EventList.module.scss';

interface EventListProps {
  list?: EventWithMetricsType[];
  isLoading?: boolean;
}
const EventsListTableView: FC<EventListProps> = (props) => {
  const { list, isLoading } = props;
  // const [isShowedAllDates, setIsShowedAllDates] = useState(false);

  const content = {
    approved: (
      <div
        style={{
          background: '#D4FFF6',
          color: '#02C29A',
          padding: 4,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: 4,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginTop: 4,
          fontSize: 11,
        }}>
        <Icon name="ticket" width={12} />
        On Sale
      </div>
    ),
    closed: (
      <div
        style={{
          background: '#FFEAEB',
          color: '#FF4B53',
          padding: 4,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: 4,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginTop: 4,
          fontSize: 11,
        }}>
        <CloseOutlined />
        Closed
      </div>
    ),
    draft: (
      <div
        style={{
          background: '#EFF0F5',
          color: '#7F86A4',
          padding: 4,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: 4,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginTop: 4,
          fontSize: 11,
        }}>
        <EditOutlined />
        Draft
      </div>
    ),
    published: (
      <div
        style={{
          background: '#D4FFF6',
          color: '#02C29A',
          padding: 4,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: 4,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginTop: 4,
          fontSize: 11,
        }}>
        <RocketOutlined />
        Published and waiting for approval
      </div>
    ),
    paused: (
      <div
        style={{
          background: '#E4D43C',
          color: '#000',
          padding: 4,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: 4,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginTop: 4,
          fontSize: 11,
        }}>
        <PauseOutlined />
        Paused
      </div>
    ),
    acceptedTerms: (
      <div
        style={{
          background: '#E4D43C',
          color: '#000',
          padding: 4,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: 4,
          width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginTop: 4,
          fontSize: 11,
        }}>
        <EyeOutlined />
        Accepted terms and conditions
      </div>
    ),
  };
  const navigate = useNavigate();
  const formatDate = (input: string) => {
    const dateTimeObj = dayjs(input);

    // Get the day, month, year, and time
    const dayOfWeek = dateTimeObj.format('dddd'); // Day of the week (e.g., Monday)
    const day = dateTimeObj.format('D'); // Day (e.g., 23)
    const month = dateTimeObj.format('MMMM'); // Full month name (e.g., November)
    const year = dateTimeObj.format('YYYY'); // Full year (e.g., 2023)
    const time = dateTimeObj.format('HH:mm'); // 24-hour time (e.g., 12:00)

    // Combine everything into the desired format (without ordinal suffix)
    const formattedDate = `${dayOfWeek} ${day} ${month} ${year} at ${time}`;

    return formattedDate;
  };

  const handleGoReport = (event) => {
    navigate(`/reports?event=${event.eventId}`);
  };

  const handleGoOrder = (event) => {
    navigate(`/tickets-orders?event=${event.eventId}`);
  };
  const eventsColumns = [
    {
      dataIndex: 'eventFlyer',
      key: 'eventFlyer',
      render: (eventFlyer: string) => {
        if (extractFlyerName(eventFlyer)) {
          return <img src={eventFlyer} alt="eventFlyer" className={s.flyer} />;
        } else {
          return (
            <div className={s.emptyFlyer}>
              <Icon name="littleLogo" />
            </div>
          );
        }
      },
      width: 70,
    },
    {
      title: 'Event Name',
      dataIndex: 'eventName',
      key: 'eventName',
      render: (eventName: string, row: EventWithMetricsType) => {
        return (
          <div>
            <Typography type="main" weight={500} className={s.TooltipContent}>
              <Tooltip title={eventName} placement="top">
                {handleCutString(eventName, 35, true)}
              </Tooltip>
              {content[row?.status]}
            </Typography>
            <div className={s.salesDate}>
              <CalendarOutlined size={16} />
              <div>
                tickets on sale until{' '}
                <span style={{ fontWeight: 700 }}>{formatDate(row.salesEndAt)}</span>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: 'Event Date',
      dataIndex: 'dates',
      key: 'dates',
      render: (firstShowDate: { date: string }[], row: SingleEventType) => {
        return (
          <>
            <div className={cn(s.dateField)}>
              {/* <Icon name="calendar" size={14} /> */}
              <div>
                <Typography type="main" weight={500} className={s.dateField}>
                  {dayjs(firstShowDate[0]?.date).format('DD MMMM YYYY')}
                  {/* {firstShowDate?.length <= 1 ? (
                  ) : (
                    <>
                      {dayjs(firstShowDate[0]?.date).format('DD MMMM YYYY')}{' '}
                      <button
                        className={s.showButton}
                        onClick={() => setIsShowedAllDates(!isShowedAllDates)}>
                        <Typography type="small" color="grey" className={s.block}>
                          {isShowedAllDates ? 'Hide' : 'Show'} all dates{' '}
                          <Icon
                            name="arrowDropdown"
                            size={14}
                            className={cn({ [s.active]: isShowedAllDates })}
                          />
                        </Typography>
                      </button>
                    </>
                  )} */}
                </Typography>
              </div>
            </div>
            <Typography type="table" color="grey" className={s.dateField}>
              <Icon name="mapMarker" size={14} /> {handleCutString(row.venue.name, 35, true) || '-'}
            </Typography>
          </>
        );
      },
    },
    {
      title: 'Total Revenue',
      dataIndex: 'metrics',
      key: 'metrics',
      render: (metrics: EventMetricsType) => {
        return (
          <>
            <Typography type="main" weight={500}>
              {CURRENCIES_SETTING[metrics?.currency?.toUpperCase() || 'EUR']?.symbol}{' '}
              {formatNumber(metrics.totalSales?.allTime)}
            </Typography>
            <Typography type="small">
              <span className={s.green}>€ {formatNumber(metrics.totalSales?.today)}</span> today
            </Typography>
            <div
              onClick={() => handleGoReport(event)}
              className={s.linkButton}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}>
              <Icon size={14} name="ticketReports" />
              <span style={{ fontSize: 11 }}>VIEW REPORTS</span>
            </div>
          </>
        );
      },
    },
    {
      title: 'Tickets Sold',
      dataIndex: 'metrics',
      key: 'metrics',
      render: (metrics: EventMetricsType) => {
        return (
          <>
            <Typography type="main" weight={500}>
              {metrics?.totalTicketsSold?.allTime}
            </Typography>
            <Typography type="small">
              <span className={s.green}>{metrics?.totalTicketsSold?.today}</span> today
            </Typography>
            <div
              onClick={() => handleGoOrder(event)}
              className={s.linkButton}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}>
              <Icon size={14} name="listView" />
              <span style={{ fontSize: 11 }}>VIEW ORDERS</span>
            </div>
          </>
        );
      },
    },
    {
      title: 'Complimentary',
      dataIndex: 'metrics',
      key: 'metrics',
      render: (metrics: EventMetricsType) => {
        return (
          <>
            <Typography type="main" weight={500}>
              {metrics?.totalComplimentariesIssued?.allTime}
            </Typography>
            <Typography type="small">
              <span className={s.green}>{metrics?.totalComplimentariesIssued?.today}</span> today
            </Typography>
            <div className={s.linkButton} onKeyDown={() => {}} role="button" tabIndex={0}>
              <Icon size={14} name="ticketOrders" />
              <span style={{ fontSize: 11 }}>SEND TICKETS</span>
            </div>
          </>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (row) => {
        return <EventListActionsDropdown event={row} />;
      },
    },
  ];

  const renderEmpty = () => <EventsEmptyState />;

  return (
    <div className={s.wrapper}>
      <ConfigProvider renderEmpty={renderEmpty}>
        <Table
          rowKey="eventId"
          dataSource={list}
          columns={eventsColumns}
          pagination={false}
          loading={isLoading}
          scroll={{ x: 768 }}
          rowClassName={() => cn(s.row)}
        />
      </ConfigProvider>
    </div>
  );
};

export default memo(EventsListTableView);
