import { FC, memo } from 'react';

import s from './Icon.module.scss';
import { TIconsLiteral } from './types';
import { Icons } from './utils';

export interface IconProps {
  name: TIconsLiteral;
  size?: number;
  width?: number;
  height?: number;
  className?: string;
  color?: string;
  secondName?: TIconsLiteral;
  secondaryMainIconColor?: string;
}

const secondaryIconSize = 0.67;

const Icon: FC<IconProps> = (props) => {
  const {
    name,
    size = 24,
    width,
    height,
    className,
    color = 'currentColor',
    secondaryMainIconColor = 'transparent',
    secondName = '',
  } = props;

  if (!name || !Icons[name]) return null;

  const MainInnerIcon = Icons[name];

  const mainIconProps = {
    width: width ?? size,
    height: height ?? size,
    className,
    color,
  };
  if (secondName) {
    const SecondaryInnerIcon = Icons[secondName];
    return (
      <div style={{ width: width, height: height }} className={s.container}>
        <MainInnerIcon {...mainIconProps} />
        <SecondaryInnerIcon
          className={s.secondIcon}
          width={size * secondaryIconSize}
          height={size * secondaryIconSize}
          color={secondaryMainIconColor}
        />
      </div>
    );
  }
  return <MainInnerIcon {...mainIconProps} />;
};

export default memo(Icon);
