import { Typography } from 'ui';

import s from './TicketDateTab.module.scss';

interface AllDateCardProps {
  startDate: string;
  endDate: string;
  status: string;
  eventId: string;
  onClick: (eventId: string) => void;
  isActive: boolean;
}

const AllDateCard: React.FC<AllDateCardProps> = ({
  startDate,
  endDate,
  status,
  eventId,
  onClick,
  isActive,
}) => {
  const statusClass = s[status.toLowerCase()];
  const statusBorderClass = s[`${status.toLowerCase()}Border`];
  const activeClass = isActive ? s.active : '';

  const getStatusText = (status) => {
    switch (status.toLowerCase()) {
      case 'draft':
        return 'DRAFT';
      case 'available':
        return 'ON SALE';
      case 'differentonsomedates':
        return 'Different On Some Dates';
      case 'disabled':
        return 'DISABLED';
      case 'soldout':
        return 'SOLD OUT';
      case 'pastevent':
        return 'PAST EVENT';
      default:
        return ' ';
    }
  };

  const handleKeyPress = () => {};

  const getMonthName = (date: string): string => {
    const parts = date.split('-');
    const monthDay = `${parts[2]}.${parts[1]}`;
    return monthDay;
  };

  return (
    <div className={`${s.dateCardWrapper} ${activeClass}`}>
      <div
        className={`${s.dateCardBody} ${statusBorderClass}`}
        role="button"
        onKeyDown={handleKeyPress}
        tabIndex={0}
        onClick={() => onClick(eventId)}>
        <Typography type="h5" className={`${s.month} ${activeClass}`} weight={700}>
          ALL DATES
        </Typography>
        <div>
          <Typography type="h4" className={`${s.date} ${activeClass}`}>
            {getMonthName(startDate)} -
          </Typography>
          <Typography type="h4" className={`${s.date} ${activeClass}`}>
            {getMonthName(endDate)}
          </Typography>
        </div>
      </div>
      <div
        className={`${s.dateCardBottom} ${statusClass} ${activeClass}`}
        role="button"
        onKeyDown={handleKeyPress}
        tabIndex={0}
        onClick={() => onClick(eventId)}>
        {getStatusText(status)}
      </div>
    </div>
  );
};

export default AllDateCard;
