import { FC, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { getBankDetailEditRoute } from 'routes/user-management/list';
import { t } from 'tools/i18n';
import { Form, Icon, Typography, message } from 'ui';

import {
  useCreatePromoterMutation,
  useEditPromoterInfoMutation,
  useLazyGetPromoterInfoQuery,
} from 'services/promoter/promoterApiService';
import { setEditingPromoter } from 'slices/systemSlice';
import { useAppDispatch } from 'store';

import FormSubmit from 'containers/form-submit';

import s from './OrganiserDetails.module.scss';
import AboutCompany from './org-dtls-form-about-company';
import AddressInfo from './org-dtls-form-company-address';
import NameInputs from './org-dtls-form-name-inputs';
import UploadLogo from './org-dtls-form-upload-logo';
import YourPage from './org-dtls-form-your-page';
import { ORGANISER_DETAILS_FORM, ORGANISER_DETAILS_FORM_TYPE } from './organiserDetailsForm.utils';

const OrganiserDetails: FC = () => {
  const { promoterId } = useParams();
  const [getPromoterFullInfo, { data: promoterData }] = useLazyGetPromoterInfoQuery();
  const dispatch = useAppDispatch();
  const isEditingRef = useRef(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editPromoter, { isLoading: isEditLoading }] = useEditPromoterInfoMutation();
  const [createPromouter, { isLoading: isCreateLoading }] = useCreatePromoterMutation();
  const [editingList, setEditingList] = useState<ORGANISER_DETAILS_FORM_TYPE>({});
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const companyName = Form.useWatch(ORGANISER_DETAILS_FORM.COMPANY_NAME, form);

  const handleSubmit = useCallback(
    async (data) => {
      let res = {};

      if (promoterData?.id) {
        res = await editPromoter({
          ...promoterData,
          ...data,
          currency: data.currency?.value,
        });
      } else {
        res = await createPromouter({
          ...data,
          currency: data.currency?.value,
        });
      }

      if ('data' in res) {
        isEditingRef.current = false;
        setIsEditing(false);
        dispatch(setEditingPromoter(true));
        navigate(getBankDetailEditRoute(promoterId ? promoterId : 'create'));
        message.success('Data has been successfully updated');
      }
    },
    [promoterData, promoterId, editPromoter, createPromouter, dispatch, navigate],
  );

  const handleChange = (_, all) => {
    isEditingRef.current = true;
    setIsEditing(true);
    setEditingList(all);
  };

  useEffect(() => {
    if (promoterData) {
      Object.keys(ORGANISER_DETAILS_FORM).forEach((key) => {
        if (ORGANISER_DETAILS_FORM[key] in promoterData) {
          const label = ORGANISER_DETAILS_FORM[key];
          form?.setFieldValue(ORGANISER_DETAILS_FORM[key], promoterData[label]);
        }
      });
    } else {
      form?.resetFields();
    }
  }, [promoterData, form]);

  useEffect(() => {
    if (companyName) {
      // Replace spaces with hyphens and set the COMPANY_URL field
      const companyUrl = companyName.replace(/\s+/g, '-').toLowerCase();
      form.setFieldsValue({
        [ORGANISER_DETAILS_FORM.COMPANY_URL]: companyUrl,
      });
    }
  }, [companyName, form]);

  //TODO find a good solution to the refresh ban
  // useEffect(() => {
  //   return () => {
  //     if (isEditingRef.current) {
  //       confirm(t('setting_confirm_changed'));
  //     }
  //   };
  // }, []);
  useEffect(() => {
    if (promoterId && promoterId !== 'create') {
      getPromoterFullInfo(promoterId);
    }
  }, [promoterId, getPromoterFullInfo]);

  return (
    <div className={s.wrapper} style={{ marginBottom: isEditing ? 100 : 0 }}>
      <Typography type="h2" className={s.title}>
        {t('organiser_details_title')}
      </Typography>

      <Form form={form} onFinish={handleSubmit} onValuesChange={handleChange}>
        <div className={s.form}>
          <div className={s.info}>
            <div className={s.row}>
              {promoterData && <UploadLogo promoterData={promoterData} />}
              <NameInputs />
            </div>
            <div className={s.hint}>
              <div>
                <Icon name="hint" size={32} />
              </div>
              <b style={{ fontSize: '16px' }}>NOTE:</b> Image will be printed as your company logo
              on the tickets you sell.
            </div>
            <AboutCompany />
            <YourPage />
            <div className={cn(s.hint, s.start)}>
              <div>
                <Icon name="hint" size={32} />
              </div>{' '}
              <b style={{ fontSize: '16px' }}>TIP:</b> ShowsHappening automatically creates a
              profile page for your company. Visitors can view your company details as well as any
              upcoming events you might have.
            </div>
          </div>
          <div className={s.info}>
            <AddressInfo />
          </div>
        </div>

        <FormSubmit
          isEditing={isEditing}
          editingList={editingList}
          type="organiser"
          fetching={isEditLoading || isCreateLoading}
        />
      </Form>
    </div>
  );
};

export default OrganiserDetails;
