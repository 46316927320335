import { FC, memo, useMemo } from 'react';

import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';

import Icon from '../icon';
import s from './Date.module.scss';

dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(localeData);

export interface DateProps {
  value: dayjs.ConfigType;
  format?: string;
  expiration?: boolean;
  difference?: boolean;
}

const Date: FC<DateProps> = (props) => {
  const { value, format, expiration = false, difference = false } = props;
  const content = useMemo(() => {
    if (expiration) {
      return (
        <div className={s.expiration}>
          <Icon name="time" size={16} />
          {dayjs.utc(value).local().isBefore(dayjs(), 'second')
            ? 'Ended'
            : expiration && dayjs().to(dayjs.utc(value).local(), true)}
        </div>
      );
    }

    if (difference) {
      return dayjs(value).utc(true).fromNow();
    }

    return dayjs(value).utc(true).format(format);
  }, [expiration, difference, format, value]);

  return <div>{content}</div>;
};

export default memo(Date);
