export enum BENEFITS_CONTENT_TEXT_ENUM {
  AUTH_BENEFITS_CREATE_EVENT = 'AUTH_BENEFITS_CREATE_EVENT',
  AUTH_BENEFITS_MANAGE_EVENT = 'AUTH_BENEFITS_MANAGE_EVENT',
  AUTH_BENEFITS_MONITOR_TICKET = 'AUTH_BENEFITS_MONITOR_TICKET',
}

export const benefitsContent = [
  {
    label: 'auth_benefits_create_event',
    icon: 'loginEvent',
  },
  {
    label: 'auth_benefits_manage_event',
    icon: 'loginManage',
  },
  {
    label: 'auth_benefits_monitor_ticket',
    icon: 'loginMetric',
  },
];
