import { FC } from 'react';

import { Tooltip as TooltipAntd, TooltipProps } from 'antd';
import 'antd/lib/popover/style/index';
import cn from 'classnames';

import './Tooltip.module.scss';

const Tooltip: FC<TooltipProps> = (props) => {
  const { className = '', ...otherProps } = props;

  return <TooltipAntd {...otherProps} className={cn({ [className]: className })} />;
};

export default Tooltip;
