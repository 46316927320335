import React, { FC, useEffect, useState } from 'react';

import { InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getCouponEditRoute } from 'routes/events/list';
import { Button, Dropdown, Icon, Label, Modal, Typography } from 'ui';

import {
  useCloneCouponMutation,
  useDeleteCouponMutation,
  useDisableCouponMutation,
  useEnableCouponMutation,
} from 'services/coupons/couponApiService';

import s from 'pages/events/events-list-table-view/EventList.module.scss';

interface EventsCouponsPageActionDropdownProps {
  // Define your component props here
  eventId: string;
  couponId: string;
  status: boolean;
  updateData: () => void;
}

const EventsCouponsPageActionDropdown: FC<EventsCouponsPageActionDropdownProps> = (props) => {
  const DROPDOWN_ITEMS = () => [
    { key: 'edit', label: <div className={s.dropdownItem}>Edit</div> },
    // { key: 'sendEmail', label: <div className={s.dropdownItem}>Send Email</div> },
    {
      key: 'replicate',
      label: <div className={s.dropdownItem}>Replicate</div>,
    },
    { key: 'showRestrictions', label: <div className={s.dropdownItem}>Show Restrictions</div> },
    {
      key: 'disable',
      label: (
        <div className={s.dropdownItem}>
          {isEnabling
            ? 'Enabling...'
            : isLoading
            ? 'Disabling...'
            : isDisabled
            ? 'Enable'
            : 'Disable'}
        </div>
      ),
    },
    {
      key: 'delete',
      label: <div className={s.dropdownItem}>{isDeleting ? 'Deleting...' : 'Delete'}</div>,
    },
  ];
  const { eventId, couponId, status, updateData } = props;
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [cloneCoupon, { isLoading: isEditLoading }] = useCloneCouponMutation();
  const [disableCoupon, { isLoading: isLoading }] = useDisableCouponMutation();
  const [enableCoupon, { isLoading: isEnabling }] = useEnableCouponMutation();
  const [deleteCoupon, { isLoading: isDeleting }] = useDeleteCouponMutation();
  const [isDisabled, setIsDisabled] = useState<boolean>(status);
  const [isOpenHintModal, setIsOpenHintModal] = useState<boolean>(false);
  const [amount, setAmount] = useState<number>(0);
  const [dropdownItems, setDropdownItems] = useState(DROPDOWN_ITEMS);

  useEffect(() => {
    const updatedDropdownItems = [
      { key: 'edit', label: <div className={s.dropdownItem}>Edit</div> },
      // { key: 'sendEmail', label: <div className={s.dropdownItem}>Send Email</div> },
      {
        key: 'replicate',
        label: <div className={s.dropdownItem}>Replicate</div>,
      },
      { key: 'showRestrictions', label: <div className={s.dropdownItem}>Show Restrictions</div> },
      {
        key: 'disable',
        label: (
          <div className={s.dropdownItem}>
            {isEnabling
              ? 'Enabling...'
              : isLoading
              ? 'Disabling...'
              : isDisabled
              ? 'Enable'
              : 'Disable'}
          </div>
        ),
      },
      {
        key: 'delete',
        label: <div className={s.dropdownItem}>{isDeleting ? 'Deleting...' : 'Delete'}</div>,
      },
    ];

    setDropdownItems(updatedDropdownItems);
  }, [isDisabled, isLoading, isEnabling, isDeleting]);
  const handleAmountChange = (value: number | null) => {
    if (value !== null) {
      setAmount(value);
    }
  };

  const handleSubmit = async () => {
    const data = {
      couponId: couponId as string,
      qty: amount,
    };
    if (amount > 0) {
      const res = await cloneCoupon(data);
      if ('data' in res) {
        setOpenedDropdown(false);
        setIsOpenHintModal(false);
        updateData();
      }
    }
  };

  const handleDisable = async () => {
    const data = {
      couponId: couponId as string,
    };

    const res = await disableCoupon(data);
    if ('data' in res) {
      setIsDisabled(true);
      updateData();
      setOpenedDropdown(false);
    }
  };

  const handleEnable = async () => {
    const data = {
      couponId: couponId as string,
    };

    const res = await enableCoupon(data);
    if ('data' in res) {
      setIsDisabled(false);
      updateData();
      setOpenedDropdown(false);
    }
  };

  const handleDelete = async () => {
    const data = {
      couponId: couponId as string,
    };

    const res = await deleteCoupon(data);
    if ('data' in res) {
      updateData();
      setOpenedDropdown(false);
    }
  };
  const navigate = useNavigate();
  // Write your component code here

  return (
    <>
      <Modal handleClose={() => setIsOpenHintModal(false)} open={isOpenHintModal} footer={false}>
        <Modal.Header>
          <Typography type="h3">Coupon Replicate</Typography>
        </Modal.Header>

        <Modal.Content>
          <div>
            <div>
              <Label>Amount</Label>
              <InputNumber
                name="discountAmount"
                placeholder="0"
                size="large"
                style={{ width: '100%' }}
                value={amount}
                onChange={handleAmountChange}
              />
            </div>
            <Button
              style={{ marginTop: 24, width: '50%' }}
              onClick={handleSubmit}
              disabled={isEditLoading}>
              Submit
            </Button>
          </div>
        </Modal.Content>
      </Modal>
      <Dropdown
        items={dropdownItems}
        placement="bottomRight"
        open={openedDropdown}
        onOpenChange={setOpenedDropdown}
        handleItemClick={(ev: { key: string }) => {
          if (ev.key === 'edit') {
            navigate(getCouponEditRoute(String(eventId || ''), String(couponId || '')));
          }
          if (ev.key === 'replicate') {
            setIsOpenHintModal(true);
            setOpenedDropdown(false);
          }
          if (ev.key === 'disable') {
            if (!isDisabled) {
              handleDisable();
            } else if (isDisabled) {
              handleEnable();
            }
          }
          if (ev.key === 'delete') {
            handleDelete();
          }
        }}>
        <div className={s.dropdownButton}>
          <Icon name="verticalDots" />
        </div>
      </Dropdown>
    </>
  );
};

export default EventsCouponsPageActionDropdown;
