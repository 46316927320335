import { CSSProperties, FC, memo, useEffect, useState } from 'react';

import { rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, InputNumber, Modal, Select } from 'antd';
import { DatePicker } from 'antd';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'tools/i18n';
import { Dropdown, Form, Icon, Label, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import {
  useAddedTicketTypeMutation,
  useChangeTicketTypeAvailabilitiesMutation,
  useLazyGetDatesQuery,
  useLazyGetEventDetailQuery,
} from 'services/events/eventsApiService';
import { DatesType } from 'services/events/evetns.api.type';
import { setActiveDateId, setActiveEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { TIME_FORMAT } from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-dates/eventCreateFormBasicInfoDates.utils';

import {
  EventTicketType,
  TICKET_AVAILABILITY_CONTROL_TYPE,
  TICKET_SALES_STATUS,
} from 'types/events/eventTypes';

import { DateTicketStatus } from '../../eventCreateFormTickets';
import s from '../TicketsList.module.scss';

interface TicketItemProps {
  ticket: EventTicketType;
  index: number;
  id: string;
  handleDeleteItem: (id: string) => void;
  currency: string;
  setActiveEditTicket: (ticket: EventTicketType) => void;
  eventType: boolean;
  dates: DatesType | undefined;
  status?: DateTicketStatus;
  isAllDates?: boolean;
  currentDateId?: string;
  ticketTypes: EventTicketType[];
  maxAttendeesAmount?: string;
  eventId?: string | undefined;
  dateTicketStatus?: DateTicketStatus[];
}

export const manualStatusType = [
  {
    label: 'On sale',
    key: 'available',
    description: 'On sale to the public',
    icon: 'eyeOpen',
    height: 24,
    width: 32,
  },
  {
    label: 'Disabled',
    key: 'disabled',
    description: 'Grayed out and not available',
    icon: 'warning',
    height: 20,
    width: 32,
  },
  {
    label: 'Sold Out',
    key: 'soldOut',
    description: 'Grayed out and marked as sold out and not available',
    icon: 'close',
    height: 24,
    width: 32,
  },
  {
    label: 'Hidden',
    key: 'hidden',
    description: 'Hidden from the public. Visible to managers of the event',
    icon: 'eye',
    height: 30,
    width: 32,
  },
];

const DROPDOWN_ITEMS = [
  { key: 'edit', label: <div className={s.dropdownItem}>Edit</div> },
  { key: 'delete', label: <div className={s.dropdownItem}>Delete</div> },
];

const TicketItem: FC<TicketItemProps> = ({
  ticket,
  index,
  handleDeleteItem,
  currency,
  setActiveEditTicket,
  eventType,
  dates,
  currentDateId,
  ticketTypes,
  maxAttendeesAmount,
  eventId,
  dateTicketStatus,
}) => {
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeStatus, setActiveStatus] = useState<TICKET_SALES_STATUS>(
    TICKET_SALES_STATUS.AVAILABLE,
  );
  const [controlType, setControlType] = useState<TICKET_AVAILABILITY_CONTROL_TYPE>(
    TICKET_AVAILABILITY_CONTROL_TYPE.MANUAL,
  );
  const dispatch = useAppDispatch();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [createTickets, { isLoading }] = useAddedTicketTypeMutation();
  const [getEventDetail, { isLoading: fetchingEventDetail }] = useLazyGetEventDetailQuery();
  const [getDates] = useLazyGetDatesQuery();
  const [changeTicketTypeAvailabilities, { isLoading: changing }] =
    useChangeTicketTypeAvailabilitiesMutation();
  const [tickets, setTickets] = useState<EventTicketType[]>(ticketTypes);
  const [modalData, setModalData] = useState<any>(null);
  const [dateForm] = Form.useForm();
  const [percentForm] = Form.useForm();

  const [disableType, setDisableType] = useState<'hidden' | 'disabled'>('hidden');

  // const [individualStatus, setIndividualStatus] = useState<DateTicketStatus>(status!);

  const availableFromDate = Form.useWatch('availableFromDate', dateForm);
  const availableFromTime = Form.useWatch('availableFromTime', dateForm);
  const availableToDate = Form.useWatch('availableToDate', dateForm);
  const availableToTime = Form.useWatch('availableToTime', dateForm);
  const dateNotYetAvailableDisplayTypeValue = Form.useWatch(
    'dateNotYetAvailableDisplayType',
    dateForm,
  );
  const linkToTickeTypeIdValue = Form.useWatch('linkToTickeTypeId', percentForm);
  const percentageToActivateValue = Form.useWatch('percentageToActivate', percentForm);

  const getFormattedDateTime = (date: Dayjs | null, time: Dayjs | null) => {
    const effectiveDate = date || dayjs();
    const effectiveTime = time || dayjs().startOf('day');

    const formattedDate = effectiveDate.format('DD-MMM-YYYY');
    const formattedTime = effectiveTime.format('HH:mm');

    return `${formattedDate} ${formattedTime}`;
  };

  const ticketTypesSelect = ticketTypes
    .filter(({ id }) => id !== ticket.id)
    .map(({ id, name }) => ({ value: id, label: name }));

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `${ticket.name}_${ticket.price}_${index}`,
    strategy: rectSortingStrategy,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const handleClickDropdownItems = (event: { key: string }, id: string) => {
    if (event.key === 'delete') {
      setOpenedDropdown(false);
      handleDeleteItem(id);
    }

    if (event.key === 'edit') {
      setOpenedDropdown(false);
      setActiveEditTicket(ticket);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalSave = async () => {
    if (controlType !== TICKET_AVAILABILITY_CONTROL_TYPE.MANUAL) {
      const data = {
        availibilityControlType: controlType,
        availibilityControlInfo: {
          ...(controlType === TICKET_AVAILABILITY_CONTROL_TYPE.BY_DATE && {
            dateNotYetAvailableDisplayType: dateNotYetAvailableDisplayTypeValue as
              | 'hidden'
              | 'disabled',
            availableFrom: getFormattedDateTime(availableFromDate, availableFromTime),
            availableTo: getFormattedDateTime(availableToDate, availableToTime),
          }),
          ...(controlType === TICKET_AVAILABILITY_CONTROL_TYPE.BY_PERCENTAGE && {
            percentageToActivate: percentageToActivateValue,
            linkToTickeTypeId: linkToTickeTypeIdValue,
          }),
        },
      };

      const result = {
        ...data,
      };

      setTickets((prev) => {
        return prev.map((item) => {
          if (item.name === ticket.name) {
            return { ...item, ...ticket, ...result };
          }

          return item;
        });
      });

      setModalData(data);
    }

    if (controlType === TICKET_AVAILABILITY_CONTROL_TYPE.MANUAL) {
      const tempTicketTypes = dateTicketStatus?.map((item) => {
        if (item.id === ticket.id) {
          return {
            availabilityType: activeStatus,
            ticketTypeId: item.id!,
          };
        }
        return {
          availabilityType: item.status,
          ticketTypeId: item.id!,
        };
      });

      const temp_dateIds =
        currentDateId === 'allDates'
          ? dates?.dates.map((item) => item.dateId) || [] // Ensure dates is not null or undefined
          : [currentDateId!];

      const initialTicketTypes = {
        eventId: eventId!,
        maxAttendees: Number(maxAttendeesAmount),
        ticketTypes: tempTicketTypes,
        dateIds: temp_dateIds,
      };
      const res = await changeTicketTypeAvailabilities(initialTicketTypes);

      if (currentDateId) {
        dispatch(setActiveDateId(currentDateId));
      }

      if ('data' in res) {
        getDates(eventId!);
        const eventRes = await getEventDetail(eventId!);

        if ('data' in eventRes) {
          dispatch(setActiveEvent(eventRes?.data!));
        }
        handleModalClose();
      }
    }
  };

  const handleControlTypeChange = (key: string) => {
    const enumKey = key as TICKET_AVAILABILITY_CONTROL_TYPE;
    setControlType(enumKey);
  };

  useEffect(() => {
    const saveTickets = async () => {
      if (modalData) {
        const res = await createTickets({
          ticketTypes: tickets,
          ...(!!maxAttendeesAmount && { maxNumberOfAttendees: Number(maxAttendeesAmount) }),
          eventId: eventId || '',
        });

        if ('data' in res) {
          dispatch(setActiveEvent(res?.data));
          handleModalClose();
          getDates(eventId!);
        }
      }
    };

    saveTickets();
  }, [tickets, modalData, dispatch, getDates, createTickets, eventId, maxAttendeesAmount]);

  useEffect(() => {
    const tempDateFrom = activeEvent?.ticketTypes.find((item) => item.id === ticket?.id)
      ?.availibilityControlInfo?.availableFrom;

    const tempDateTo = activeEvent?.ticketTypes.find((item) => item.id === ticket?.id)
      ?.availibilityControlInfo?.availableTo;

    const temp = dateTicketStatus?.find((item) => item.id === ticket.id)?.status;

    setActiveStatus(temp!);

    if (activeEvent?.ticketTypes?.find((item) => item.id === ticket.id)?.availibilityControlInfo) {
      dateForm.setFieldValue('availableFromDate', tempDateFrom ? dayjs(tempDateFrom) : null);
      dateForm.setFieldValue('availableFromTime', tempDateFrom ? dayjs(tempDateFrom) : null);
      dateForm.setFieldValue('availableToDate', tempDateTo ? dayjs(tempDateTo) : null);
      dateForm.setFieldValue('availableToTime', tempDateTo ? dayjs(tempDateTo) : null);
      dateForm.setFieldValue(
        'dateNotYetAvailableDisplayType',
        activeEvent?.ticketTypes.find((item) => item.id === ticket?.id)?.availibilityControlInfo
          ?.dateNotYetAvailableDisplayType,
      );
      percentForm.setFieldValue(
        'linkToTickeTypeId',
        activeEvent?.ticketTypes.find((item) => item.id === ticket?.id)?.availibilityControlInfo
          ?.linkToTickeTypeId
          ? activeEvent?.ticketTypes.find((item) => item.id === ticket?.id)?.availibilityControlInfo
              ?.linkToTickeTypeId
          : null,
      );
      percentForm.setFieldValue(
        'percentageToActivate',
        activeEvent?.ticketTypes.find((item) => item.id === ticket?.id)?.availibilityControlInfo
          ?.percentageToActivate
          ? activeEvent?.ticketTypes.find((item) => item.id === ticket?.id)?.availibilityControlInfo
              ?.percentageToActivate
          : null,
      );
    }
  }, [activeEvent, ticket?.id, dateForm, percentForm, dateTicketStatus]);

  // useEffect(() => {
  //   if (status) {
  //     setIndividualStatus(status);
  //   }
  // }, [status]);

  const handleModalOpen = () => {
    const tempData = activeEvent?.ticketTypes!.find((item) => item.id === ticket?.id);
    setControlType(tempData?.availibilityControlType!);
    setModalOpen(true);
  };

  return (
    <>
      <div ref={setNodeRef} style={style} className={s.item}>
        {/* Your existing ticket item rendering code, except for the Icon component */}
        <div {...listeners} {...attributes} ref={setActivatorNodeRef} className={s.drag}>
          <Icon name="drag" size={36} />
        </div>
        <div className={s.rowGroup}>
          <div className={s.itemGroup}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <span
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  backgroundColor: ticket?.colour,
                  display: 'inline-block',
                  marginRight: '5px',
                }}></span>
              <Typography type="h4">{ticket?.name}</Typography>
            </div>
            {!eventType && (
              <div
                className={s.limit}
                style={{
                  visibility: ticket.maxNumberOfTicketsToBeSold === 0 ? 'hidden' : 'visible',
                }}>
                <Typography type="h4">{ticket.maxNumberOfTicketsToBeSold}</Typography>
                <Typography type="main" color="grey">
                  Limit
                </Typography>
              </div>
            )}
          </div>
          <div className={s.itemGroup}>
            <div>
              <Typography type="h4">
                {currency} {ticket.price}
              </Typography>
              <Typography type="main" color="grey">
                {t('common_face_value')}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              <button
                className={s.statusButton}
                type="button"
                onClick={handleModalOpen}
                disabled={fetchingEventDetail}>
                <Icon name="eyeOpen" />
                <Typography className={s.ticketItemLabel} type="main" weight={700}>
                  {dateTicketStatus &&
                  dateTicketStatus.find((item) => item.id === ticket.id)?.status === 'available'
                    ? 'ON SALE'
                    : dateTicketStatus?.find((item) => item.id === ticket.id)?.status}
                </Typography>
              </button>
              {/* {ticket.hideFromPublic ? (
              <button className={s.action} type="button">
                <Icon name="eye" />
              </button>
            ) : (
              <button className={s.action} type="button">
                <Icon name="eyeOpen" />
              </button>
            )} */}
              <Dropdown
                items={DROPDOWN_ITEMS}
                placement="bottomRight"
                handleItemClick={(event) => handleClickDropdownItems(event, ticket.id as string)}
                open={openedDropdown}
                onOpenChange={setOpenedDropdown}>
                <div className={s.dropdownButton}>
                  <Icon name="verticalDots" color="#7d7da2" />
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <Modal
        key={ticket.id}
        centered
        closable={false}
        open={isModalOpen}
        zIndex={1000}
        footer={[
          <div
            key="footer"
            style={{ justifyContent: 'center', display: 'flex', gap: 8, width: '100%' }}>
            <Button
              key="submit"
              type="primary"
              size="large"
              style={{ fontSize: 14, paddingLeft: 24, paddingRight: 24 }}
              loading={isLoading || changing}
              onClick={handleModalSave}>
              SAVE CHANGES
            </Button>

            <Button
              key="back"
              style={{ fontSize: 14, paddingLeft: 24, paddingRight: 24 }}
              size="large"
              onClick={handleModalClose}>
              Cancel
            </Button>
          </div>,
        ]}>
        <div style={{ marginBottom: 8 }}>
          <Typography type="h2" weight={700}>
            Ticket visibility
          </Typography>
        </div>
        <div style={{ marginBottom: 16 }}>
          <Label>Control Type</Label>
          <Select
            style={{ width: '100%' }}
            size="large"
            onChange={handleControlTypeChange}
            options={[
              { value: TICKET_AVAILABILITY_CONTROL_TYPE.MANUAL, label: 'Manual' },
              { value: TICKET_AVAILABILITY_CONTROL_TYPE.BY_DATE, label: 'By date' },
              {
                value: TICKET_AVAILABILITY_CONTROL_TYPE.BY_PERCENTAGE,
                label: 'By % of other ticket types',
              },
            ]}
            value={controlType}
            defaultValue=""
          />
        </div>

        <div className={s.hint}>
          <Icon name="warning" />
          {controlType === TICKET_AVAILABILITY_CONTROL_TYPE.MANUAL ? (
            <Typography type="main">
              You are modifying the Visibility of {ticket?.name} for{' '}
              {currentDateId === 'allDates' ? (
                <strong>All dates</strong>
              ) : (
                <strong>
                  {(() => {
                    const foundDateItem = dates?.dates?.find(
                      (item) => item.dateId === currentDateId,
                    );
                    if (foundDateItem) {
                      return `${dayjs(foundDateItem.date).format('DD.MM.YYYY')} ${dayjs(
                        foundDateItem.timeFrom,
                        'HH:mm',
                      ).format('HH:mm')}`;
                    }
                    return 'Date not found'; // Fallback if no date is found
                  })()}
                </strong>
              )}
            </Typography>
          ) : (
            <Typography type="main">
              This change will be applicable on <b>All dates</b>
            </Typography>
          )}
        </div>

        <div style={{ marginBottom: 8 }}>
          {controlType === TICKET_AVAILABILITY_CONTROL_TYPE.MANUAL && (
            <div className={s.manualWrapper}>
              {manualStatusType.map((item) => (
                <div
                  key={item.key}
                  className={cn(s.tabItem, { [s.active]: item.key === activeStatus })}
                  onClick={() => setActiveStatus(item.key as TICKET_SALES_STATUS)}
                  role="button"
                  onKeyDown={() => setActiveStatus(item.key as TICKET_SALES_STATUS)}
                  tabIndex={0}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                    <Icon
                      width={item.width}
                      height={item.height}
                      name={item.icon}
                      color="#7d7da2"
                    />
                    <Typography type="h5">{item.label}</Typography>
                  </div>
                  <div style={{ marginLeft: 40 }}>
                    <Typography type="main">{item.description}</Typography>
                  </div>
                </div>
              ))}
            </div>
          )}

          {controlType === TICKET_AVAILABILITY_CONTROL_TYPE.BY_DATE && (
            <Form form={dateForm} className={s.dateCardTabPane}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <div style={{ width: '100%' }}>
                  <Label>Available from date:</Label>
                  <Form.Item valuePropName="value" name="availableFromDate">
                    <DatePicker
                      style={{ width: '100%' }}
                      format="DD MMMM YYYY"
                      nextIcon={<Icon name="doubleRightArrow" size={18} />}
                      prevIcon={<Icon name="doubleLeftArrow" size={18} />}
                    />
                  </Form.Item>
                </div>
                <div style={{ width: '100%' }}>
                  <Label>Time</Label>
                  <Form.Item name="availableFromTime">
                    <DatePicker.TimePicker
                      style={{ width: '100%' }}
                      placeholder="Select time"
                      format={TIME_FORMAT}
                    />
                  </Form.Item>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 16 }}>
                <div style={{ width: '100%' }}>
                  <Label>Available to date:</Label>
                  <Form.Item valuePropName="value" name="availableToDate">
                    <DatePicker
                      format="DD MMMM YYYY"
                      style={{ width: '100%' }}
                      nextIcon={<Icon name="doubleRightArrow" size={18} />}
                      prevIcon={<Icon name="doubleLeftArrow" size={18} />}
                    />
                  </Form.Item>
                </div>
                <div style={{ width: '100%' }}>
                  <Label>Time</Label>
                  <Form.Item name="availableToTime">
                    <DatePicker.TimePicker
                      placeholder="Select time"
                      style={{ width: '100%' }}
                      format={TIME_FORMAT}
                    />
                  </Form.Item>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: 12, marginTop: 16 }}>
                <div style={{ width: '100%' }}>
                  <Label>Disabled type:</Label>
                  <Form.Item name="dateNotYetAvailableDisplayType">
                    <Select
                      defaultValue="hidden"
                      style={{ width: '100%' }}
                      allowClear
                      value={disableType}
                      onChange={setDisableType}
                      options={[
                        { value: 'hidden', label: 'Hidden' },
                        { value: 'disabled', label: 'Disabled' },
                      ]}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          )}
          {controlType === TICKET_AVAILABILITY_CONTROL_TYPE.BY_PERCENTAGE && (
            <Form form={percentForm} className={s.dateCardTabPane}>
              <div>
                <Label>Ticket</Label>
                <Form.Item name="linkToTickeTypeId" style={{ width: '100%' }}>
                  <Select style={{ width: '100%' }} allowClear options={ticketTypesSelect} />
                </Form.Item>
              </div>
              <div style={{ marginTop: 16 }}>
                <Label>Has less than</Label>
                <Form.Item name="percentageToActivate" style={{ width: '100%' }}>
                  <InputNumber
                    name="percentageToActivate"
                    defaultValue={0}
                    style={{ width: '100%', height: 36 }}
                  />
                </Form.Item>
              </div>
            </Form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default memo(TicketItem);
