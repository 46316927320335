import { Tooltip } from 'antd';
import { Typography } from 'ui';

import s from './TicketDateTab.module.scss';

interface DateCardProps {
  date: string;
  status: string;
  dateId: string;
  time: string;
  isPastDate: boolean;
  onClick: (dateId: string) => void;
  isActive: boolean;
  hint: string;
}

const DateCard: React.FC<DateCardProps> = ({
  date,
  status,
  dateId,
  time,
  onClick,
  isPastDate,
  isActive,
  hint,
}) => {
  const statusClass =
    isPastDate && status.toLowerCase() === 'available'
      ? s['pastAvailable']
      : isPastDate
      ? s['pastDate']
      : s[status.toLowerCase()];
  const statusBorderClass = s[`${status.toLowerCase()}Border`];
  const activeClass = isActive ? s.active : '';

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1); // monthNumber is 1-based, while setMonth expects 0-based
    return date.toLocaleString('default', { month: 'long' });
  };

  const getStatusText = (status) => {
    switch (status.toLowerCase()) {
      case 'available':
        if (isPastDate) {
          return 'PAST EVENT';
        } else {
          return 'ON SALE';
        }
      case 'fewremaining':
        if (isPastDate) {
          return 'PAST EVENT & ALMOST SOLDOUT';
        } else {
          return 'ALMOST SOLD OUT';
        }
      case 'soldout':
        if (isPastDate) {
          return 'PAST EVENT & SOLD OUT';
        } else {
          return 'SOLD OUT';
        }
      case 'hidden':
        if (isPastDate) {
          return 'PAST EVENT & HIDDEN';
        } else {
          return 'HIDDEN';
        }
      case 'disabled':
        if (isPastDate) {
          return 'PAST EVENT & DISABLED';
        } else {
          return 'DISABLED';
        }
      case 'tickettypesnotavailable':
        if (isPastDate) {
          return 'PAST & TICKETS NOT AVAILABLE';
        } else {
          return 'TICKET TYPES NOT AVAILABLE';
        }
      default:
        return ' ';
    }
  };

  const handleKeyPress = () => {};

  const getWeekdayName = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const dayIndex = date.getDay();
    return daysOfWeek[dayIndex];
  };
  return (
    <Tooltip title={hint}>
      <div className={`${s.dateCardWrapper} ${activeClass}`}>
        <div
          className={`${s.dateCardBody} ${statusBorderClass}`}
          role="button"
          onKeyDown={handleKeyPress}
          tabIndex={0}
          onClick={() => onClick(dateId)}>
          <Typography type="h5" className={`${s.month} ${activeClass}`} weight={700}>
            {getMonthName(date.split('-')[1])}
          </Typography>
          <Typography type="h2" className={`${s.date} ${activeClass}`}>
            {date.split('-')[2]}
          </Typography>
          <Typography type="main" color="grey" className={`${s.time} ${activeClass}`}>
            {time}
          </Typography>
          <Typography type="main" color="grey" className={`${s.week} ${activeClass}`}>
            {getWeekdayName(date)}
          </Typography>
        </div>
        {status !== 'none' && (
          <div
            className={`${s.dateCardBottom} ${statusClass} ${activeClass}`}
            role="button"
            onKeyDown={handleKeyPress}
            tabIndex={0}
            onClick={() => onClick(dateId)}>
            {getStatusText(status)}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default DateCard;
