import { forwardRef } from 'react';

import classNames from 'classnames';
import { CheckboxProps, Icon } from 'ui';

import s from './LayoutSwitcher.module.scss';

const LayoutSwitcher = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { onChange = () => {}, value = '', ...otherProps } = props;

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <div className={s.switchContainer}>
      <label className={s.switch}>
        <span className={classNames(s.switchLabel, { [s.active]: !value })}>
          <Icon name="blockView" size={14} />
        </span>
        <input ref={ref} type="checkbox" checked={value} onChange={handleChange} {...otherProps} />
        <span className={classNames(s.slider, { [s.checked]: value })}></span>
        <span className={classNames(s.switchLabel, { [s.active]: value })}>
          <Icon name="listView" size={18} />
        </span>
      </label>
    </div>
  );
});

export default LayoutSwitcher;
