import React, { FC, useCallback, useEffect, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE } from 'routes/user-management/list';
import { removeTokenCookies } from 'tools/cookieTools';

import { isOpenedSiderSelector } from 'selectors/systemSlice.selector';
import { useLazyGetPromoterInfoQuery } from 'services/promoter/promoterApiService';
import { useGetApiVersionQuery } from 'services/system/systemApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActivePromoterFullInfo, setOpenedSider } from 'slices/systemSlice';
import { logout } from 'slices/userSlice';
import { useAppDispatch, useAppSelector } from 'store';

import { isCreateEventRoute } from 'containers/app-navigation/appNavigation.utils';
import CreateEventNavigationTemplate from 'containers/app-navigation/create-event-navigation-template';

import CoreNavigationTemplate from './core-navigation-template';

interface AppNavigationProps {
  initiallyLogged: boolean;
}

const AppNavigation: FC<AppNavigationProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const isOpenedSidebar = useAppSelector(isOpenedSiderSelector);
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const { data: apiVersion } = useGetApiVersionQuery();
  const [getPromoterFullInfo] = useLazyGetPromoterInfoQuery();

  const sameString = useCallback(
    (str: string): boolean => pathname.slice(1).replace('-', '_').toUpperCase() === str,
    [pathname],
  );

  const handleNavigate = useCallback(
    (path: string) => {
      const to = path.replace('_', '-').toLowerCase();
      if (window.innerWidth < 991) {
        dispatch(setOpenedSider(false));
      }
      navigate(to);
    },
    [dispatch, navigate],
  );
  const handleLogout = useCallback(() => {
    dispatch(logout());
    if (removeTokenCookies()) {
      window.location.reload();
      navigate(LOGIN_ROUTE);
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991) {
        dispatch(setOpenedSider(false));
      } else {
        dispatch(setOpenedSider(true));
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    if (currentUser?.promoters?.length) {
      const promoter = currentUser.promoters[0].promoterId;
      getPromoterFullInfo(promoter).then(({ data }) => {
        if (data) {
          dispatch(setActivePromoterFullInfo(data));
        }
      });
    }
  }, [currentUser, dispatch, getPromoterFullInfo]);

  const navigationBar = useMemo(() => {
    // if (initiallyLogged) {
    //   if (isSettingRoute(pathname)) {
    //     return (
    //       <SettingNavigationTemplate
    //         handleLogout={handleLogout}
    //         sameString={sameString}
    //         handleNavigate={handleNavigate}
    //         isOpenedSidebar={isOpenedSidebar}
    //         apiVersion={apiVersion}
    //       />
    //     );
    //   }
    // }

    if (isCreateEventRoute(pathname)) {
      return (
        <CreateEventNavigationTemplate
          handleLogout={handleLogout}
          sameString={sameString}
          handleNavigate={handleNavigate}
          isOpenedSidebar={isOpenedSidebar}
          apiVersion={apiVersion}
        />
      );
    }

    return (
      <CoreNavigationTemplate
        isOpenedSidebar={isOpenedSidebar}
        currentUser={currentUser}
        apiVersion={apiVersion}
        handleLogout={handleLogout}
      />
    );
  }, [
    apiVersion,
    currentUser,
    handleLogout,
    handleNavigate,
    isOpenedSidebar,
    pathname,
    sameString,
  ]);

  return <>{navigationBar}</>;
};
export default AppNavigation;
