import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Typography } from 'ui';

import { setIsEditingEvent } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import { EventTicketType } from 'types/events/eventTypes';

import s from './TicketForm.module.scss';

type TicketTypesToConsiderForPromotionalOffers =
  | {
      qty?: number;
      name?: string;
      id?: string;
    }[]
  | undefined;

interface TicketFormProps {
  tickets?: EventTicketType[];
  promoteDetails: TicketTypesToConsiderForPromotionalOffers;
  setTicket: Dispatch<
    SetStateAction<{ name: string; price: number; amount: number; id: string }[]>
  >;
  setIsDirty: Dispatch<SetStateAction<boolean>>;
}

const TicketForm: FC<TicketFormProps> = ({ tickets, setTicket, promoteDetails, setIsDirty }) => {
  const [ticketsList, setTicketsList] = useState<
    { name: string; price: number; amount: number; id: string; colour: string }[]
  >([]);

  const dispatch = useAppDispatch();

  const handleDecrement = useCallback(
    (id: string) => {
      const newTicketList = ticketsList?.map((ticket) => {
        if (ticket.id === id && ticket.amount > 0) {
          ticket.amount -= 1;
        }
        return ticket;
      });

      setTicketsList(newTicketList);
      setIsDirty(true);
      dispatch(setIsEditingEvent(true));
    },
    [ticketsList, dispatch, setIsDirty],
  );

  const handleIncrement = useCallback(
    (id: string) => {
      const newTicketList = ticketsList?.map((ticket) => {
        if (ticket.id === id) {
          ticket.amount += 1;
        }
        return ticket;
      });

      setTicketsList(newTicketList);
      setIsDirty(true);
      dispatch(setIsEditingEvent(true));
    },
    [ticketsList, dispatch, setIsDirty],
  );

  useEffect(() => {
    if (tickets?.length) {
      setTicketsList(
        tickets.map((ticket) => {
          const matchingPromo = promoteDetails?.find((promo) => promo.name === ticket.name);
          return {
            name: ticket.name,
            price: ticket.price,
            colour: ticket.colour,
            amount: matchingPromo?.qty || 0, // Set amount from promo qty or default to 0
            id: ticket?.id || '',
          };
        }),
      );
    }
  }, [tickets, promoteDetails]);

  useEffect(() => {
    if (ticketsList?.length) {
      setTicket(ticketsList);
    }
  }, [setTicket, ticketsList]);

  return (
    <div className={s.wrapper}>
      {ticketsList?.map((ticket) => (
        <div key={ticket.id} className={s.ticket}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <span
              style={{
                width: '16px',
                height: '16px',
                borderRadius: '50%',
                backgroundColor: ticket?.colour,
                display: 'inline-block',
                marginRight: '5px',
              }}></span>
            <Typography type="h5">{ticket.name}</Typography>
          </div>
          <div className={s.price}>
            <Typography type="h5">{`€ ${ticket.price}`}</Typography>
            <Typography type="main">Face Value</Typography>
          </div>
          <div className={s.counter}>
            <button className={s.minusButton} onClick={() => handleDecrement(ticket.id)}>
              -
            </button>
            <Typography type="main" weight={600}>
              {ticket.amount}
            </Typography>
            <button className={s.button} onClick={() => handleIncrement(ticket.id)}>
              +
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TicketForm;
