import { FC } from 'react';

import { Button, Form, Input, Label, Modal } from 'ui';
import { formRules } from 'utils/formRules';

import { EMAIL_PLACEHOLDER } from '../../../../constants/core-constants';

interface EventTaxReportRecipientsModalProps {
  open: boolean;
  onClose: () => void;
  handleAddRecipients: (body: string) => void;
}

const EventTaxReportRecipientsModal: FC<EventTaxReportRecipientsModalProps> = (props) => {
  const { open, onClose, handleAddRecipients } = props;
  const [form] = Form.useForm();

  const handleSubmit = (data: { email: string }) => {
    handleAddRecipients(data.email);
    onClose();
    form.resetFields();
  };

  return (
    <Modal handleClose={onClose} open={open} footer={false}>
      <Modal.Header label="Recipient additions" />
      <Form onFinish={handleSubmit} form={form}>
        <Modal.Content>
          <div style={{ width: '100%' }}>
            <Label required>Email address</Label>
            <Form.Item name="email" rules={[formRules.required, formRules.email]} fullWidth>
              <Input name="email" fullWidth placeholder={EMAIL_PLACEHOLDER} />
            </Form.Item>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button htmlType="submit">Add Recipient</Button>
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default EventTaxReportRecipientsModal;
