import { SERVICES_TAGS } from 'constants/servicesTags';
import initialApiService from 'services/initialApiService';

import { PromoterFullInfoType } from 'types/promoters/promoterTypes';

const promoterApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getPromoterInfo: builder.query<PromoterFullInfoType, string>({
      query: (id) => ({
        url: `/promoters/${id}`,
        method: 'GET',
      }),
      providesTags: [SERVICES_TAGS.PROMOTER_FULL_INFO],
    }),
    editPromoterInfo: builder.mutation<PromoterFullInfoType, PromoterFullInfoType>({
      query: (data) => ({
        url: `/promoters/${data.id}/edit`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [SERVICES_TAGS.PROMOTER_FULL_INFO],
    }),
    createPromoter: builder.mutation<PromoterFullInfoType, PromoterFullInfoType>({
      query: (data) => ({
        url: `/promoters`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [SERVICES_TAGS.PROMOTER_FULL_INFO, SERVICES_TAGS.UPDATE_PROMOTER],
    }),
    uploadLogo: builder.mutation<PromoterFullInfoType, { promoterId: string; logo: string }>({
      query: (data) => ({
        url: `/promoters/logo`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [SERVICES_TAGS.UPDATE_PROMOTER],
    }),
  }),
});

export const {
  useLazyGetPromoterInfoQuery,
  useEditPromoterInfoMutation,
  useUploadLogoMutation,
  useCreatePromoterMutation,
} = promoterApiService;
