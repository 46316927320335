import { FC, useState } from 'react';

import { InputNumber } from 'antd';
import { Typography } from 'ui';

import s from './EditCouponWholeOrderType.module.scss';

interface EditCouponWholeOrderTypeProps {
  // Define your component props here
  totalAmount: number;
  setTotalAmount: (setTotalAmount: number) => void;
}

const EditCouponWholeOrderType: FC<EditCouponWholeOrderTypeProps> = (props) => {
  // Write your component code here
  const { setTotalAmount, totalAmount } = props;
  const [amount, setAmount] = useState<number>(totalAmount);
  const handleAmountChange = (value: number | null) => {
    if (value !== null) {
      setTotalAmount(value);
      setAmount(value);
    }
  };

  return (
    <div className={s.wrapper}>
      <Typography type="h3" className={s.blockTitle}>
        Discount Amount
      </Typography>

      <InputNumber
        name="amount"
        value={amount}
        placeholder="0.00"
        prefix="€"
        size="large"
        style={{ width: '50%' }}
        onChange={handleAmountChange}
      />
    </div>
  );
};

export default EditCouponWholeOrderType;
