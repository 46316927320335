import { FC, PropsWithChildren } from 'react';

import cn from 'classnames';

import s from './Background.module.scss';

interface BackgroundProps {
  // Define your component props here
}

const Background: FC<PropsWithChildren<BackgroundProps>> = ({ children }) => (
  <div className={s.wrapper}>
    <div className={cn(s.background, s.blue)}></div>
    {/* <div className={cn(s.background, s.green)}></div> */}
    <div className={cn(s.background, s.purple)}></div>
    <div className={s.content}>{children}</div>
  </div>
);

export default Background;
