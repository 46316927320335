import { FC, PropsWithChildren } from 'react';

import { Form as FormAntd, FormProps as FormPropsAntd } from 'antd';

import './Form.module.scss';

export type FormProps = PropsWithChildren<FormPropsAntd>;

const Form: FC<FormProps> = (props) => <FormAntd {...props} />;

export default Form;
