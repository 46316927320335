import { FC, useEffect, useState } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Icon, Modal, Typography } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import s from './WelcomeModal.module.scss';

interface WelcomeModalProps {
  // Define your component props here
}

const WelcomeModal: FC<WelcomeModalProps> = () => {
  const [open, setOpen] = useState<boolean>();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const goToSettings = () => {
    window.localStorage.setItem('onboarding', 'true');
    navigate('/organiser-details/create');
  };

  useEffect(() => {
    getCurrentUser().then(({ data }) => {
      if (data?.promoters[0]?.promoterId || data?.hasEvents) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    });
  }, [getCurrentUser]);

  return (
    <Modal handleClose={handleClose} open={open} footer={false}>
      <Modal.Header className={s.header}>
        <div className={s.logo}>
          <Icon name="logo" width={200} height={40} />
        </div>
      </Modal.Header>
      <Modal.Content className={s.content}>
        <Typography type="h3" weight={700}>
          Hey there!
        </Typography>
        <div className={s.wrapper}>
          <Typography type="main" color="grey" className={s.text}>
            Welcome to ShowsManger! Use this tool to create and publish your event. You can start
            preparing everything and publish later. To start working with our service, please fill
            in the data about the organizer. Click &apos;Get Started&apos; to fill in your organiser
            details before creating your first event.
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button type="primary" size="large" block onClick={goToSettings}>
          Get Started
        </Button>
        <Button block size="large" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WelcomeModal;
