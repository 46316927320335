type CouponEvent = {
  eventFlyer: string;
  eventName: string;
  eventId: string;
  eventStatus: 'Draft';
  lastShowDate: string;
  eventScheduleType: 'NonRecurring';
  promoter: {
    promoterId: string;
    name: string;
    slug: string;
    promoterShowsHappeningPageURL: string;
    email: string;
  };
  url: string;
};

type ShowRestriction = {
  id: string;
  showId: string;
  date: string;
  time: string;
};

export type ResCouponType = {
  id: string;
  code: string;
  eventId: string;
  deliveryType: string;
  discountType: string;
  couponType: string;
  email: string;
  disabled: boolean;
  restrictByIP: boolean;
  canBeRedeemedBySameUser: boolean;
  numberOfCouponsAvailable: number;
  minimumOrderTotal: number;
  maximumNumberOfTickets: number;
  totalDiscount: number;
  areTicketTypesEditable: boolean;
  couponTypeDescription: string;
  restrictedToSpecificShows: boolean;
  redeems: number;
  redeemsRemaining: number;
  creationDate: string;
  ticketTypes: {
    id: string;
    ticketTypeId: string;
    name: string;
    qty: number;
    value: number;
  }[];
  redeemed: boolean;
  orders: {
    orderId: string;
    orderNo: string;
    currency: string;
    orderDate: string;
    orderTotal: number;
    orderSubTotal: number;
    bookingFee: number;
    paymentType: string;
    orderStatus: string;
    user: {
      firstName: string;
      fullName: string;
      mobileNumber: string;
      lastName: string;
      userId: string;
      email: string;
    };
  }[];
  showRestrictions: ShowRestriction[];
  event: CouponEvent;
};

type TicketType = {
  ticketTypeId: string;
  value: number;
  qty: number;
};

export enum COUPON_DELIVERY_TYPE {
  SINGLE_USER = 'singleUser',
  MULTI_USER = 'multipleUser',
}

export enum COUPON_DISCOUNT_TYPE {
  FIXED_PRICE = 'fixedPrice',
  PERCENTAGE = 'percentage',
  DEDUCTION = 'deduction',
}

export enum COUPON_TYPE {
  DISCOUNT_ON_NUMBER_OF_TICKETS = 'discountOnNumberOfTickets',
  DISCOUNT_ON_ORDER_TOTAL = 'discountOnOrderTotal',
  DISCOUNT_ON_SPECIFIC_TYPES = 'discountOnSpecificTypes',
  DISCOUNT_ON_ALL_TYPES = 'discountOnAllTypes',
}

export enum COUPON_REQUEST_TYPE {
  DISABLE = 'disable',
  ENABLE = 'enable',
  VIEW_NUMBER_OF_COUPONS = 'viewNumberOfCoupons',
}

export type ReqCouponType = {
  id: string | null;
  code: string | undefined;
  description?: string;
  eventId: string | undefined;
  deliveryType: COUPON_DELIVERY_TYPE;
  discountType: string;
  couponType: string;
  email: string | null;
  disabled: boolean;
  restrictByIP: boolean;
  canBeRedeemedBySameUser: boolean;
  numberOfCouponsAvailable: number | null;
  minimumOrderTotal: number | null;
  maximumNumberOfTickets: number | null;
  totalDiscount: number | null | undefined;
  ticketTypes: TicketType[] | null;
  showRestrictions: ShowRestriction[] | null;
};

export type CloneCouponType = {
  couponId: string;
  qty: number;
};

export type CouponBatchReqType = {
  term: string;
  eventId: string;
  requestType: COUPON_REQUEST_TYPE;
};
