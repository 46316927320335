import { RegisterRequestProps } from 'types/user-management/auth';

export enum REGISTER_FORM_ENUM {
  FIRST_NAME = 'firstName',
  SECOND_NAME = 'lastName',

  PHONE = 'mobileNumber',

  EMAIL = 'email',
  CONFIRM_EMAIL = 'confirmEmail',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',

  SH_PROMOTIONS = 'optForShowsHappeningPromotions',
  PROMOTIONS = 'optForPromoterPromotions',
}

export const prepareRegisterFormData = (data: RegisterRequestProps) => {
  const result = {} as RegisterRequestProps;

  if (!data[REGISTER_FORM_ENUM.SH_PROMOTIONS]) {
    result[REGISTER_FORM_ENUM.SH_PROMOTIONS] = false;
  }

  if (!data[REGISTER_FORM_ENUM.PROMOTIONS]) {
    result[REGISTER_FORM_ENUM.PROMOTIONS] = false;
  }

  if (data[REGISTER_FORM_ENUM.PHONE]) {
    result[REGISTER_FORM_ENUM.PHONE] = `+${data[REGISTER_FORM_ENUM.PHONE]}`;
  }

  if (data[REGISTER_FORM_ENUM.EMAIL]) {
    result[REGISTER_FORM_ENUM.EMAIL] = data[REGISTER_FORM_ENUM.EMAIL];
  }

  if (data[REGISTER_FORM_ENUM.FIRST_NAME]) {
    result[REGISTER_FORM_ENUM.FIRST_NAME] = data[REGISTER_FORM_ENUM.FIRST_NAME];
  }

  if (data[REGISTER_FORM_ENUM.SECOND_NAME]) {
    result[REGISTER_FORM_ENUM.SECOND_NAME] = data[REGISTER_FORM_ENUM.SECOND_NAME];
  }

  if (data[REGISTER_FORM_ENUM.PASSWORD]) {
    result[REGISTER_FORM_ENUM.PASSWORD] = data[REGISTER_FORM_ENUM.PASSWORD];
  }

  if (data[REGISTER_FORM_ENUM.CONFIRM_PASSWORD]) {
    result[REGISTER_FORM_ENUM.CONFIRM_PASSWORD] = data[REGISTER_FORM_ENUM.CONFIRM_PASSWORD];
  }

  return result;
};
