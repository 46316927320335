import React, { FC, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Table } from 'ui';

import { useLazyGetZreadingQuery } from 'services/events/eventsApiService';

import Pagination from '../../../component/pagination';
import s from './transactions.module.scss';

const initialFilter = {
  PageNumber: 1,
  PageSize: 100,
};

interface TransactionProps {
  eventId?: string;
}

const Transactions: FC<TransactionProps> = (props) => {
  const { eventId } = props;
  const [requestFilter, setRequestFilter] = useState(initialFilter);
  const [getZreadingList, { data: zReadingList, isLoading }] = useLazyGetZreadingQuery();

  const columns = [
    {
      title: 'Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
    },
    {
      title: 'Transaction date',
      dataIndex: 'transactionLocalDate',
      key: 'transactionLocalDate',
      render: (transactionLocalDate: string) => (
        <div>{dayjs(transactionLocalDate).format('DD.MM.YYYY HH:mm')}</div>
      ),
    },
    {
      title: 'Event Name',
      dataIndex: 'eventName',
      key: 'eventName',
    },
    {
      title: 'Show Date',
      dataIndex: 'show',
      key: 'showDate',
      render: (show: { date: string; time: string }) => (
        <div>
          {dayjs(show.date).format('DD.MM.YYYY')} {dayjs(show.time).format('HH:mm')}
        </div>
      ),
    },
    {
      title: 'Order Date',
      dataIndex: 'order',
      key: 'orderDate',
      render: (order: { orderDate: string }) => (
        <div>{dayjs(order.orderDate).format('DD.MM.YYYY HH:mm')}</div>
      ),
    },
    {
      title: 'Order No',
      dataIndex: 'order',
      key: 'orderNo',
      render: (order: { orderNo: string }) => <div>{order.orderNo}</div>,
    },
    {
      title: 'Payment Type',
      dataIndex: 'order',
      key: 'orderPaymentType',
      render: (order: { paymentType: string }) => <div>{order.paymentType}</div>,
    },
    {
      title: 'Cash Type',
      dataIndex: 'cashPaymentType',
      key: 'cashPaymentType',
    },
    {
      title: 'Email',
      dataIndex: 'order',
      key: 'orderUserEmail',
      render: (order: { user: { email: string } }) => <div>{order.user.email}</div>,
    },
    {
      title: 'Name on ticket',
      dataIndex: 'ticketHolderPersonalInfo',
      key: 'ticketHolderPersonalInfo',
      render: (holderInfo: { firstName: string; lastName: string }) => (
        <div>{`${holderInfo.firstName} ${holderInfo.lastName}`}</div>
      ),
    },
    {
      title: 'Runner (if any)',
      dataIndex: 'runnerEmail',
      key: 'runnerEmail',
    },
    {
      title: 'Outlet (if any)',
      dataIndex: 'outlet',
      key: 'outlet',
    },
    {
      title: 'Receipt Number',
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
    },
    {
      title: 'Ticket Type',
      dataIndex: 'ticketType',
      key: 'ticketType',
      render: (ticketType: { name: string }) => <div>{ticketType.name}</div>,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'NET',
      dataIndex: 'total',
      key: 'total',
      render: (total: number, cell: { vatAmount: number }) => <div>{total - cell.vatAmount}</div>,
    },
    {
      title: 'VAT Amount',
      dataIndex: 'vatAmount',
      key: 'vatAmount',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Booking Fee',
      dataIndex: 'bookingFee',
      key: 'bookingFee',
    },
    {
      title: 'Refund Protection',
      dataIndex: 'refundProtectionTotal',
      key: 'refundProtectionTotal',
    },
    {
      title: 'All Total',
      dataIndex: 'allTotal',
      key: 'allTotal',
    },
    {
      title: 'Account',
      dataIndex: 'bankAccountName',
      key: 'bankAccountName',
    },
    {
      title: 'Payment Gateway',
      dataIndex: 'gatewayName',
      key: 'gatewayName',
    },
  ];

  const showedElements =
    Number(zReadingList?.transactions?.length) +
    (Number(zReadingList?.pageNumber) - 1) * Number(requestFilter.PageSize);

  useEffect(() => {
    if (eventId) {
      getZreadingList({ id: eventId, ...requestFilter });
    }
  }, [eventId, getZreadingList, requestFilter]);

  return (
    <div className={s.wrapper}>
      <Table
        columns={columns}
        dataSource={zReadingList?.transactions}
        pagination={false}
        scroll={{ x: '100%' }}
        loading={isLoading}
      />
      <Pagination
        handlePageChange={(page) =>
          setRequestFilter((prevValue) => ({ ...prevValue, PageNumber: page }))
        }
        showedElements={showedElements}
        totalElements={zReadingList?.totalRecords}
        total={zReadingList?.totalPages}
        currentPage={zReadingList?.pageNumber || 1}
        handleSizeChange={(size) =>
          setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
        }
        defaultPageSize={100}
      />
    </div>
  );
};

export default Transactions;
