import { FC, useMemo } from 'react';

import { formatNumber } from 'tools/formatNumbers';
import { Typography } from 'ui';

import { UserEventType } from 'types/core/userTypes';
import { EventMetricsType } from 'types/events/eventTypes';

import s from './DashboardSummaryStats.module.scss';

interface DashboardSummaryStatsProps {
  activeEvent?: UserEventType;
  eventMetricData?: EventMetricsType;
}

const DashboardSummaryStats: FC<DashboardSummaryStatsProps> = (props) => {
  const { eventMetricData, activeEvent } = props;

  const eventSalesStats = useMemo(() => {
    if (eventMetricData?.eventId) {
      const currencySymbol = activeEvent?.currency === 'eur' ? '€' : '£';
      return [
        {
          all: `${currencySymbol} ${formatNumber(eventMetricData.totalSales.allTime)}`,
          today: `${currencySymbol} ${formatNumber(eventMetricData.totalSales.today)}`,
          label: 'TOTAL REVENUE',
        },
        {
          all: eventMetricData?.totalTicketsSold.allTime,
          today: eventMetricData?.totalTicketsSold.today,
          label: 'TICKETS SOLD',
        },
        {
          all: eventMetricData.totalComplimentariesIssued.allTime,
          today: eventMetricData.totalComplimentariesIssued.today,
          label: 'COMPLIMENTARY',
        },
      ];
    }
  }, [eventMetricData, activeEvent]);

  return (
    <div className={s.stats}>
      {!!eventSalesStats?.length &&
        eventSalesStats.map((item, i) => (
          <div key={i} className={s.item}>
            <Typography type="h5" color="grey" className={s.title}>
              {item.label}
            </Typography>
            {eventMetricData?.maximumNumberOfAttendees! > 0 && item.label === 'TICKETS SOLD' ? (
              <Typography type="h4" className={s.subtitle}>
                {item?.all}
                <span className={s.limit_grey}>
                  {' '}
                  / {eventMetricData?.maximumNumberOfAttendees!}
                </span>
              </Typography>
            ) : (
              <Typography type="h4" className={s.subtitle}>
                {item?.all}
              </Typography>
            )}
            <Typography type="main" weight={500} className={s.value}>
              <span className={s.green}>+{item?.today} today</span>
            </Typography>
          </div>
        ))}
    </div>
  );
};

export default DashboardSummaryStats;
