import dayjs from 'dayjs';

import { EventShowDateType, MultipleDatesType } from 'types/events/eventTypes';

export const validateFields = [
  ['showDates', 'startDate'],
  ['showDates', 'startTime'],
  ['showDates', 'endDate'],
  ['showDates', 'endTime'],
  ['showDates', 'doorsOpen'],
  ['showDates', 'isShowHidden'],
  ['showDates', 'duration', 'hours'],
  ['showDates', 'duration', 'minutes'],
];

export const prepareMultipleDates = (
  showDates: EventShowDateType,
  hoursWhenSalesEnd?: number,
  beforeOrAfterEvent?: string,
): MultipleDatesType => {
  return {
    startDate: dayjs(showDates.startDate).format('DD-MMM-YYYY'),
    endDate:
      showDates.endDate !== null && showDates.endDate !== undefined
        ? dayjs(showDates.endDate).format('DD-MMM-YYYY')
        : '',
    startTime: dayjs(showDates.startTime).format('HH:mm'),
    endTime:
      showDates.endTime !== null && showDates.endDate !== undefined
        ? dayjs(showDates.endTime).format('HH:mm')
        : '',
    doorsOpen: showDates.doorsOpen ? dayjs(showDates.doorsOpen).format('HH:mm') : '',
    isShowHidden: showDates.isShowHidden,
    hoursWhenSalesEnd: hoursWhenSalesEnd!,
    beforeOrAfterEvent: beforeOrAfterEvent!,
    duration: Number(
      (showDates.duration && showDates.duration.hours * 60 + showDates.duration.minutes) || 0,
    ),
    ...(showDates.showId && { showId: showDates.showId }),
  };
};
