import { FC, useEffect } from 'react';

import { FormInstance } from 'antd/es/form/hooks/useForm';
import cn from 'classnames';
import { t } from 'tools/i18n';
import { Checkbox, Form, Input, Typography } from 'ui';

import { activePromoterSelector } from 'selectors/systemSlice.selector';

import { useAppSelector } from '../../../../../store';
import s from '../BankDetails.module.scss';
import { BANK_DETAILS_FORM_ENUM } from '../bankDetails.utils';

interface BankDetailsBillingInfoFormProps {
  form: FormInstance;
}
const BankDetailsBillingInfoForm: FC<BankDetailsBillingInfoFormProps> = (props) => {
  const { form } = props;
  const activePromoter = useAppSelector(activePromoterSelector);
  const isCheckedSameAsCompany = Form.useWatch(
    BANK_DETAILS_FORM_ENUM.BILLING_ADDRESS_SAME_AS_CONTACT,
    form,
  );

  useEffect(() => {
    if (activePromoter) {
      if (isCheckedSameAsCompany) {
        form.setFieldsValue({
          [BANK_DETAILS_FORM_ENUM.BILLING_NAME]: activePromoter.name,
          [BANK_DETAILS_FORM_ENUM.BILLING_ADDRESS]: activePromoter.address,
          [BANK_DETAILS_FORM_ENUM.BILLING_TOWN]: activePromoter.town,
          [BANK_DETAILS_FORM_ENUM.BILLING_POSTCODE]: activePromoter.postcode,
          [BANK_DETAILS_FORM_ENUM.BILLING_COUNTRY]: activePromoter.country,
        });
      } else {
        form.setFieldsValue({
          [BANK_DETAILS_FORM_ENUM.BILLING_NAME]: activePromoter.billingName || '',
          [BANK_DETAILS_FORM_ENUM.BILLING_ADDRESS]: activePromoter.billingAddress || '',
          [BANK_DETAILS_FORM_ENUM.BILLING_TOWN]: activePromoter.billingTown || '',
          [BANK_DETAILS_FORM_ENUM.BILLING_POSTCODE]: activePromoter.billingPostcode || '',
          [BANK_DETAILS_FORM_ENUM.BILLING_COUNTRY]: activePromoter.billingCountry || '',
        });
      }
    }
  }, [isCheckedSameAsCompany, activePromoter, form]);

  return (
    <>
      <Form.Item name={BANK_DETAILS_FORM_ENUM.BILLING_ADDRESS_SAME_AS_CONTACT}>
        <Checkbox>
          <Typography type="main">{t('bank_info_form_same_as_company')}</Typography>
        </Checkbox>
      </Form.Item>
      <div>
        <Typography type="small" className={s.label}>
          {t('common_billing_name')}
        </Typography>
        <Form.Item name={BANK_DETAILS_FORM_ENUM.BILLING_NAME}>
          <Input
            name={BANK_DETAILS_FORM_ENUM.BILLING_NAME}
            disabled={isCheckedSameAsCompany}
            size="large"
          />
        </Form.Item>
      </div>
      <div>
        <Typography type="small" className={s.label}>
          {t('common_billing_address')}
        </Typography>
        <Form.Item name={BANK_DETAILS_FORM_ENUM.BILLING_ADDRESS}>
          <Input
            name={BANK_DETAILS_FORM_ENUM.BILLING_ADDRESS}
            disabled={isCheckedSameAsCompany}
            size="large"
          />
        </Form.Item>
      </div>
      <div className={s.row}>
        <div>
          <Typography type="small" className={cn(s.label)}>
            {t('common_town')}
          </Typography>
          <Form.Item name={BANK_DETAILS_FORM_ENUM.BILLING_TOWN} fullWidth>
            <Input
              name={BANK_DETAILS_FORM_ENUM.BILLING_TOWN}
              disabled={isCheckedSameAsCompany}
              size="large"
            />
          </Form.Item>
        </div>
        <div>
          <Typography type="small" className={cn(s.label)}>
            {t('common_postcode')}
          </Typography>
          <Form.Item name={BANK_DETAILS_FORM_ENUM.BILLING_POSTCODE} fullWidth>
            <Input
              name={BANK_DETAILS_FORM_ENUM.BILLING_POSTCODE}
              disabled={isCheckedSameAsCompany}
              size="large"
            />
          </Form.Item>
        </div>
      </div>
      <div>
        <Typography type="small" className={s.label}>
          {t('common_country')}
        </Typography>
        <Form.Item name={BANK_DETAILS_FORM_ENUM.BILLING_COUNTRY} initialValue="Malta">
          <Input
            name={BANK_DETAILS_FORM_ENUM.BILLING_COUNTRY}
            disabled={isCheckedSameAsCompany}
            size="large"
          />
        </Form.Item>
      </div>
    </>
  );
};

export default BankDetailsBillingInfoForm;
