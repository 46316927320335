import React, { FC, useCallback, useEffect } from 'react';

import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useQuery } from 'tools/hooks';
import { Icon, Typography, message } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
// import { createEventFormActiveStepSelector } from 'selectors/eventsSlice.selector';
import {
  useLazyGetEventDetailQuery,
  usePauseEventMutation,
} from 'services/events/eventsApiService';
import { removeActiveEvent, setActiveEvent, setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType } from 'types/events/eventTypes';

import s from './EventsCreateEventForm.module.scss';
import CreateEventFormBasicInfo from './event-create-form-basic-info';
import EventCreateFormDetails from './event-create-form-details';
import EventCreateFormPermission from './event-create-form-permission';
import EventCreateFormPromotePage from './event-create-form-promote-page';
import EventCreateFormPublish from './event-create-form-publish';
import EventCreateFormTaxes from './event-create-form-taxes';
import EventCreateFormTickets from './event-create-form-tickets';
import SeatingDisable from './event-create-form-tickets/seating-disable';
import SeatingPlan from './event-create-form-tickets/seating-plan';
import SectionList from './event-create-form-tickets/section-list';

const EventsCreateEventForm: FC = () => {
  // const activeStep = useAppSelector(createEventFormActiveStepSelector);
  const [getEventDetail] = useLazyGetEventDetailQuery();
  const [pauseEvent, { isLoading: isPausing }] = usePauseEventMutation();
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const step = query.get('step') as CREATE_EVENT_FORM_STEPS_ENUM;
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);

  const handleGetEditingEvent = useCallback(
    async (eventId: string) => {
      const res = await getEventDetail(eventId);

      if ('data' in res) {
        const updatedData = {
          ...res.data,
          media: {
            ...res?.data?.media,
            flyer: res?.data?.media.flyer
              ? `${res?.data?.media.flyer}?v=${Math.random().toString(36).slice(2, 11)}`
              : null,
          },
        };
        dispatch(setActiveEvent(updatedData as EventCreatedType));
      }
    },
    [dispatch, getEventDetail],
  );

  const handlePauseEvent = useCallback(async () => {
    const res = await pauseEvent({ id: activeEvent?.eventId });
    if ('data' in res) {
      dispatch(setActiveEvent(res.data as EventCreatedType));
      message.success('Event successfully paused!');
    }
  }, [activeEvent?.eventId, pauseEvent, dispatch]);

  useEffect(() => {
    if (eventId) {
      dispatch(setEditingEventId(eventId));
      handleGetEditingEvent(eventId);
    }
  }, [dispatch, eventId, handleGetEditingEvent]);

  useEffect(() => {
    return () => {
      dispatch(removeActiveEvent());
    };
  }, [dispatch]);

  const content = {
    [CREATE_EVENT_FORM_STEPS_ENUM.BASIC_INFO]: <CreateEventFormBasicInfo />,
    [CREATE_EVENT_FORM_STEPS_ENUM.DETAILS]: <EventCreateFormDetails />,
    [CREATE_EVENT_FORM_STEPS_ENUM.TICKETS]: <EventCreateFormTickets />,
    [CREATE_EVENT_FORM_STEPS_ENUM.TAXES]: <EventCreateFormTaxes />,
    [CREATE_EVENT_FORM_STEPS_ENUM.PERMISSIONS]: <EventCreateFormPermission />,
    [CREATE_EVENT_FORM_STEPS_ENUM.PROMOTE]: <EventCreateFormPromotePage />,
    [CREATE_EVENT_FORM_STEPS_ENUM.SECTION_LIST]: <SectionList />,
    ['SEATING_PLAN']: <SeatingPlan />,
    ['DISABLE']: <SeatingDisable />,
    [CREATE_EVENT_FORM_STEPS_ENUM.PUBLISH]: <EventCreateFormPublish />,
  };

  return (
    <div
      className={
        activeEvent?.basicInfo?.status === 'published' ||
        activeEvent?.basicInfo?.status === 'approved'
          ? s.componentWrapper
          : ''
      }>
      {(activeEvent?.basicInfo?.status === 'published' ||
        activeEvent?.basicInfo?.status === 'approved') && (
        <div className={s.eventStatus}>
          <div>
            <Typography type="h5" weight={600}>
              THIS EVENT <b>{activeEvent?.basicInfo.eventName}</b> IS PUBLISHED
            </Typography>
            <Typography type="main" weight={500}>
              You can put it on hold by clicking the red button
            </Typography>
          </div>
          <Button
            onClick={handlePauseEvent}
            type="primary"
            danger
            loading={isPausing}
            className={s.button}
            size="large">
            <Icon name="pause" size={20} />
            PAUSE EVENT
          </Button>
        </div>
      )}
      {content[step]}
    </div>
  );
};

export default EventsCreateEventForm;
