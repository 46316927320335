export const editEventCouponType = [
  {
    label: 'Discount on a Number of Tickets',
    key: 'discountOnNumberOfTickets',
    description:
      'Use this type of discount ONLY when you would know the number of tickets, and the actual discount value you would like to give on each ticket. For example, you can specify that you will give €10 off on 2 Early Bird tickets. In such discount you would need to specify QTY and ACTUAL DISCOUNT to be given.',
  },
  {
    label: '% or Fixed Discount on All Ticket Types',
    key: 'discountOnAllTypes',
    description:
      'In this case you will be giving either a % or fixed € discount on ALL ticket types , and thus irrespective of the ticket type the user chooses.',
  },
  {
    label: 'Fixed Discount on Specific Ticket Types',
    key: 'discountOnSpecificTypes',
    description:
      'You can choose specific ticket types on which you wish to give a discount. In this case you can specify a fixed € amount on each ticket that will be deducted. An example of how this can be used is when you are noticing that a specific ticket type is not being sold much, and you need to give an incentive to customers to buy a specific ticket type.',
  },
  {
    label: 'Fixed Discount on Whole Order',
    key: 'discountOnWholeOrder',
    description:
      'Gives a fixed € discount on the whole order. For example you can decide to give €5 euro discount on the actual whole order amount.',
  },
];
