export enum TAXES_FORM_ENUM {
  PERMIT_NUMBER = 'permitNumber',
  VAT_RATE = 'vatRate',
  EMAIL = 'email',
  MOBILE_NUMBER = 'mobileNumber',
  ADDRESS = 'address',
  TOWN = 'town',
  POST_CODE = 'postcode',
  COUNTRY = 'country',
}

export type EventCreateTaxesFormType = {
  [TAXES_FORM_ENUM.VAT_RATE]: number;
  [TAXES_FORM_ENUM.PERMIT_NUMBER]: string;
  [TAXES_FORM_ENUM.EMAIL]: string;
  [TAXES_FORM_ENUM.MOBILE_NUMBER]: string;
  [TAXES_FORM_ENUM.ADDRESS]: string;
  [TAXES_FORM_ENUM.TOWN]: string;
  [TAXES_FORM_ENUM.POST_CODE]: string;
  [TAXES_FORM_ENUM.COUNTRY]: { value: string };
};
