import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import React from 'react';

import { Tabs } from 'antd';

import { activeDateIdSelector } from 'selectors/eventsSlice.selector';
import { useLazyGetDatesQuery } from 'services/events/eventsApiService';
import { useAppSelector } from 'store/index';

import { DateTicketStatus } from '../eventCreateFormTickets';
import s from './TicketDateTab.module.scss';
import AllDateCard from './allDateCard';
import DateCard from './dateCard';

interface TicketDateTabProps {
  eventId: string | undefined;
  setDateTicketStatus: Dispatch<SetStateAction<DateTicketStatus[] | undefined>>;
  setCurrentDateId: Dispatch<SetStateAction<string>>;
  eventStatus: string;
}

const TicketDateTab: React.FC<TicketDateTabProps> = ({
  eventId,
  setDateTicketStatus,
  setCurrentDateId,
  eventStatus,
}) => {
  const [getDates, { data: dates }] = useLazyGetDatesQuery();

  const [sortedDates, setSortedDates] = useState({});
  const [activeDateId, setActiveDateId] = useState<string | null>(null);
  const storedActiveDateId = useAppSelector(activeDateIdSelector);

  const handleDateCardClick = useCallback(
    (dateId) => {
      setActiveDateId(dateId);
      setCurrentDateId(dateId);
      if (dates) {
        const ticketTypes = dates.dates
          .filter((date) => date.dateId === dateId)
          .map((date) => date.ticketTypes)
          .flat();
        setDateTicketStatus(ticketTypes);
      }
    },
    [dates, setCurrentDateId, setDateTicketStatus],
  );

  const handleAllDateCardClick = useCallback(() => {
    setActiveDateId('allDates');
    setCurrentDateId('allDates');
  }, [setCurrentDateId]);

  const handleTabChange = useCallback(
    (key) => {
      if (key !== 'defaultKey' && sortedDates && eventId) {
        const dateId = sortedDates[key][0].dateId;
        setActiveDateId(dateId);
        setCurrentDateId(dateId);
        if (dates) {
          const ticketTypes = dates.dates
            .filter((date) => date.dateId === dateId)
            .map((date) => date.ticketTypes)
            .flat();
          setDateTicketStatus(ticketTypes);
        }
      } else if (key === 'defaultKey' && eventId && dates) {
        if (dates.dates?.length > 1) {
          setActiveDateId('allDates');
          setCurrentDateId('allDates');
        } else {
          setActiveDateId(dates.dates[0].dateId);
          setCurrentDateId(dates.dates[0].dateId);
        }
      }
    },
    [sortedDates, eventId, dates, setCurrentDateId, setDateTicketStatus],
  );

  useEffect(() => {
    if (eventId) {
      getDates(eventId);
    }
  }, [getDates, eventId]);

  useEffect(() => {
    const sortEvents = (dates) => {
      const sorted = dates.dates.reduce((acc, row) => {
        const month = row.date.split('-')[1]; // Extract month
        if (!acc[month]) {
          acc[month] = [];
        }
        acc[month].push(row);
        return acc;
      }, {});

      setSortedDates(sorted);
    };
    if (dates && eventId) {
      sortEvents(dates);
      if (dates.dates?.length > 1) {
        if (storedActiveDateId) {
          setActiveDateId(storedActiveDateId);
          setCurrentDateId(storedActiveDateId);
        } else {
          setActiveDateId('allDates');
          setCurrentDateId('allDates');
        }
        if (dates) {
          const ticketTypes = dates.dates
            .filter((date) => date.dateId === dates.dates[0].dateId)
            .map((date) => date.ticketTypes)
            .flat();
          setDateTicketStatus(ticketTypes);
        }
      } else {
        setActiveDateId(dates.dates[0].dateId);
        setCurrentDateId(dates.dates[0].dateId);
        if (dates) {
          const ticketTypes = dates.dates[0].ticketTypes;
          setDateTicketStatus(Array.isArray(ticketTypes) ? ticketTypes : [ticketTypes]);
        }
      }
    }
  }, [dates, setCurrentDateId, storedActiveDateId, setDateTicketStatus, eventId, eventStatus]);

  const getMonthName = useCallback((monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1); // monthNumber is 1-based, while setMonth expects 0-based
    return date.toLocaleString('default', { month: 'long' });
  }, []);

  const tabItems = [
    {
      label: 'All dates',
      key: 'defaultKey',
      children: (
        <div className={s.dateCardTabPane}>
          {dates && eventId && dates.dates.length > 1 && (
            <AllDateCard
              startDate={dates.dates[0].date}
              endDate={dates.dates[dates.dates.length - 1].date}
              status={eventStatus === 'draft' ? 'DRAFT' : dates.avaibilityStatus}
              eventId={eventId}
              onClick={handleAllDateCardClick}
              isActive={activeDateId === 'allDates'}
            />
          )}
          {dates &&
            eventId &&
            dates.dates.map((date, key) => (
              <DateCard
                key={key}
                date={date.date}
                isPastDate={date.pastDate}
                status={
                  eventStatus === 'published' || eventStatus === 'approved' ? date.status : 'none'
                }
                hint={eventStatus === 'published' || eventStatus === 'approved' ? date.hint! : ''}
                dateId={date.dateId}
                time={date.timeFrom}
                onClick={handleDateCardClick}
                isActive={activeDateId === date.dateId}
              />
            ))}
        </div>
      ),
    },
    ...Object.keys(sortedDates).map((month) => ({
      label: getMonthName(month),
      key: month,
      children: (
        <div className={s.dateCardTabPane}>
          {sortedDates[month] &&
            eventId &&
            sortedDates[month].map((date, key) => (
              <DateCard
                key={key}
                date={date.date}
                isPastDate={date.pastDate}
                status={
                  eventStatus === 'published' || eventStatus === 'approved' ? date.status : 'none'
                }
                hint={eventStatus === 'published' || eventStatus === 'approved' ? date.hint! : ''}
                dateId={date.dateId}
                time={date.timeFrom}
                onClick={handleDateCardClick}
                isActive={activeDateId === date.dateId}
              />
            ))}
        </div>
      ),
    })),
  ];

  return (
    <div>
      <Tabs
        size="large"
        defaultActiveKey="defaultKey"
        onChange={handleTabChange}
        tabBarStyle={{ color: '#8D949C' }}
        style={{ width: '100%' }}
        items={tabItems}
      />
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.eventType === nextProps.eventType && prevProps.eventId === nextProps.eventId;
};

// Wrap the component with React.memo and provide the custom comparison function
const MemoizedTicketDateTab = React.memo(TicketDateTab, areEqual);

export default MemoizedTicketDateTab;
