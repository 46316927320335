import { FC } from 'react';

import { Input as InputAntd, InputProps as InputPropsAntd } from 'antd';
import cn from 'classnames';

//
import s from '../Input.module.scss';

export interface PasswordInputProps extends Omit<InputPropsAntd, 'size'> {
  name: string;
  label?: string;
  iconColor?: string;
  size?: 'small' | 'middle' | 'large';
  className?: string;
  fullWidth?: boolean;
}

const PasswordInput: FC<PasswordInputProps> = (props) => {
  const {
    value,
    size = 'middle',
    disabled = false,
    name,
    placeholder,
    fullWidth = false,
    onChange,
    className = '',
    ...otherProps
  } = props;
  // const [passwordVisible, setPasswordVisible] = React.useState(false);

  return (
    <div className={cn(s.container, { [s.disabled]: disabled, [s.fullWidth]: fullWidth })}>
      <InputAntd.Password
        name={name}
        className={cn(s.input, s[size], {
          [className]: className,
        })}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
        id={name}
        {...otherProps}
      />
    </div>
  );
};

export default PasswordInput;
