import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Dropdown, Empty, MenuProps } from 'antd';
import cn from 'classnames';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useParams } from 'react-router-dom';
import { Marker, StaticGoogleMap } from 'react-static-google-map';
import { useClickOutside } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Form, Input, Select, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useGetAvailableCurrenciesQuery } from 'services/system/systemApiService';
import { useAppSelector } from 'store/index';

import { createEventFormBasicInfoContext } from 'pages/events/events-create-event-form/event-create-form-basic-info/basicInfo.utils';

import s from './LocationVenue.module.scss';
import { prepareLocationVenue } from './locationVenue.utils';

const LocationVenue: FC = () => {
  const { form, onChangeForm } = useContext(createEventFormBasicInfoContext);
  const { eventId } = useParams();

  const activeEditingEvent = useAppSelector(createEventFormActiveEventSelector);
  const ref = useRef(null);
  const [openLocationDropdown, setOpenLocationDropdown] = useState<boolean>(false);
  const [isCustomAddress, setIsCustomAddress] = useState<boolean>(false);
  const [venueValue, setVenueValue] = useState<string>('');
  const [searchIsDirty, setSearchIsDirty] = useState<boolean>(false);
  const selectedAddress = Form.useWatch('location', form);
  const selectedCountry = Form.useWatch(['location', 'country'], form);
  const [userInitCountry, setUserInitCountry] = useState<string>('');
  const { data: currencies } = useGetAvailableCurrenciesQuery();

  const [countries, setCountries] = useState<
    { name: string; flag: string; code?: string; latlng: string[] }[]
  >([]);
  const selectedCountryCode = countries?.find(
    (country) => country.name === selectedCountry?.value,
  )?.code;

  const getMapSize = (windowWidth) => {
    return windowWidth > 1280 ? '440x320' : '320x140';
  };

  const [mapSize, setMapSize] = useState(getMapSize(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setMapSize(getMapSize(window.innerWidth));
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading, placesService } =
    useGoogle({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

  const handleSelectAddress = useCallback(
    (id: string, name: string) => {
      placesService?.getDetails({ placeId: id }, (placeDetails) => {
        form?.setFieldValue('location', prepareLocationVenue(placeDetails, name));

        if (onChangeForm) {
          onChangeForm(
            { location: prepareLocationVenue(placeDetails, name) },
            form?.getFieldsValue(),
          );
        }
      });
      setVenueValue(name);
      setOpenLocationDropdown(false);
      setSearchIsDirty(false);
      setIsCustomAddress(false);
    },
    [form, onChangeForm, placesService],
  );

  const getUserCountry = useCallback(async (ip) => {
    const res = await fetch(`https://api.iplocation.net/?cmd=ip-country&ip=${ip}`);

    const data: {
      country_code2: string;
      country_name: string;
      ip: string;
      ip_number: string;
      ip_version: number;
      isp: string;
      response_code: string;
      response_message: string;
    } = await res.json();

    if (data?.country_name) {
      setUserInitCountry(data.country_name);
    }
  }, []);

  const getCountries = useCallback(async () => {
    const responseCountries = await fetch('https://restcountries.com/v3.1/all');
    const data = await responseCountries.json();
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        const ipAddress = data.ip;
        if (ipAddress) {
          getUserCountry(ipAddress);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    setCountries(
      data
        ?.map((item) => ({
          name: item.name.common,
          flag: item.flag,
          code: item?.altSpellings[0],
          latlng: item?.capitalInfo?.latlng,
        }))
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
    );
  }, [getUserCountry]);

  useClickOutside({
    ref,
    handler: () => {
      if (openLocationDropdown) {
        setOpenLocationDropdown(false);
        setSearchIsDirty(false);
      }
    },
  });
  const handleSetCustomAddress = () => {
    setIsCustomAddress(true);
  };

  const placesList: MenuProps['items'] = useMemo(() => {
    const result = placePredictions?.map((place) => ({
      label: (
        <button
          onClick={() => handleSelectAddress(place?.place_id, place?.description)}
          className={s.item}>
          {place.description}
        </button>
      ),
      key: place.place_id,
    }));

    if (result?.length) {
      result.push({
        label: (
          <button className={cn(s.item, s.customAddress)} onClick={handleSetCustomAddress}>
            {t('event_create_form_enter_custom_address')}
          </button>
        ),
        key: 'custom',
      });
    }

    if (!result.length && searchIsDirty && !isPlacePredictionsLoading) {
      return [
        {
          label: (
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{ height: 60 }}
              description={<span>{t('event_create_form_no_results')}</span>}>
              <button className={cn(s.item, s.customAddress)} onClick={handleSetCustomAddress}>
                {t('event_create_form_enter_custom_address')}
              </button>
            </Empty>
          ),
          key: 0,
        },
      ];
    } else {
      return result;
    }
  }, [handleSelectAddress, isPlacePredictionsLoading, placePredictions, searchIsDirty]);

  const handleChangeInput = (e) => {
    getPlacePredictions({
      input: e.target.value,
      componentRestrictions: { country: selectedCountryCode?.toLowerCase() },
    });
    setSearchIsDirty(true);
    setVenueValue(e.target.value);
    if (e.target.value === '') {
      setSearchIsDirty(false);
    }

    if (isCustomAddress) {
      form?.setFieldValue(['location', 'venueName'], e.target.value);
    }
  };

  useEffect(() => {
    const location = activeEditingEvent?.basicInfo?.location;
    // if (location?.fullAddress) {
    //   setVenueValue(activeEditingEvent?.basicInfo?.location?.fullAddress || '');
    // }
    if (location?.fullAddress) {
      form?.setFieldValue(['location', 'fullAddress'], location?.fullAddress);
    }

    if (location?.streetAddress) {
      form?.setFieldValue(['location', 'streetAddress'], location?.streetAddress);
    }

    if (location?.town) {
      form?.setFieldValue(['location', 'town'], location?.town);
    }

    if (location?.postCode) {
      form?.setFieldValue(['location', 'postCode'], location?.postCode);
    }

    if (location?.country) {
      form?.setFieldValue(['location', 'country'], { value: location?.country });
    } else {
      form?.setFieldValue(['location', 'country'], { value: userInitCountry });
    }
  }, [activeEditingEvent, form, userInitCountry]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const userCountry = countries.find((country) => country.name === selectedCountry?.value);

  return (
    <div>
      <div style={{ marginBottom: 24 }}>
        <Typography type="small" className={cn(s.label, s.required)}>
          Country
        </Typography>
        <Form.Item name={['location', 'country']} fullWidth>
          <Select
            options={countries?.map((item) => ({
              value: item.name,
              label: `${item.flag} ${item.name}`,
            }))}
            showSearch
            filterOption={(input, option) =>
              String(option?.label ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            fullWidth
          />
        </Form.Item>
      </div>
      <div>
        <Typography type="small" className={s.label}>
          Currency
        </Typography>
        <Form.Item name="currency">
          <Select
            options={currencies?.map((item) => ({
              label: `${item.symbol} ${item.name}`,
              value: item.code,
            }))}
            placeholder="Choose currency"></Select>
        </Form.Item>
      </div>
      {selectedCountry && (
        <div ref={ref} className={s.wrapper}>
          <Typography type="small" className={cn(s.label, s.required)}>
            {t('event_create_form_venue_name')}
          </Typography>

          <div className={s.location}>
            <Dropdown
              menu={{ items: placesList }}
              open={!!placesList.length && openLocationDropdown}>
              <Form.Item name={['location', 'fullAddress']} fullWidth>
                <Input
                  name="test"
                  onChange={handleChangeInput}
                  onFocus={() => setOpenLocationDropdown(true)}
                  icon="search"
                  value={venueValue}
                  isLoading={isPlacePredictionsLoading}
                  placeholder="Enter venue names"
                />
              </Form.Item>
            </Dropdown>
          </div>
        </div>
      )}

      {/*{isCustomAddress && <div>custom address</div>}*/}
      {(selectedAddress || activeEditingEvent?.basicInfo?.location) && (
        <div style={{ marginTop: 24 }}>
          <div className={s.infoLocation}>
            <div>
              <Typography type="small" className={cn(s.label, s.required)}>
                Address
              </Typography>
              <Form.Item name={['location', 'streetAddress']} fullWidth>
                <Input name="street" fullWidth />
              </Form.Item>
            </div>
            {/* <div className={s.block}>
              <div style={{ width: '100%' }}>
                <Typography type="small" className={cn(s.label)}>
                  {t('common_city')}
                </Typography>
                <Form.Item name={['location', 'town']} fullWidth>
                  <Input name="street" fullWidth />
                </Form.Item>
              </div>
              <div style={{ width: '100%' }}>
                <Typography type="small" className={cn(s.label)}>
                  {t('common_postcode')}
                </Typography>
                <Form.Item name={['location', 'postCode']} fullWidth>
                  <Input name="street" fullWidth />
                </Form.Item>
              </div>
            </div> */}

            {/*<div>*/}
            {/*  <Typography type="small" className={cn(s.label)}>*/}
            {/*    {t('common_country')}*/}
            {/*  </Typography>*/}
            {/*  <Form.Item name={['location', 'country']} fullWidth initialValue="Malta">*/}
            {/*    <Input name="street" fullWidth />*/}
            {/*  </Form.Item>*/}
            {/*</div>*/}
          </div>
          {((!isCustomAddress && activeEditingEvent) || !eventId) && (
            <div className={s.infoLocation}>
              <StaticGoogleMap
                size={mapSize}
                className="img-fluid"
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <Marker
                  location={`${
                    selectedAddress?.latLng?.lat ||
                    activeEditingEvent?.basicInfo?.location?.latLng?.lat ||
                    userCountry?.latlng[0]
                  },${
                    selectedAddress?.latLng?.lng ||
                    activeEditingEvent?.basicInfo?.location?.latLng?.lng ||
                    userCountry?.latlng[1]
                  }`}
                  color="red"
                />
              </StaticGoogleMap>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default LocationVenue;
