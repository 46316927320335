import { createContext } from 'react';

import { FormInstance } from 'ui';

import { EventCreateFormDetailsType } from 'types/events/eventTypes';

interface createEventFormBasicInfoContextInterface {
  form?: FormInstance;
}

export const createEventFormDetailsContext =
  createContext<createEventFormBasicInfoContextInterface>({});

export enum EVENT_CREATE_FORM_DETAILS_ENUM {
  CHILD_TICKET_REQUIRED = 'childTicketRequired',
  MINIMUM_AGES = 'minimumAge',
  FREE_UP_TO_AGE = 'freeUpToAge',
  SUMMARY = 'summary',
  FULL_DESCRIPTION = 'fullDescription',
  AGES_REQUIRING_TICKET = 'agesRequiringTicket',
  ACCESSIBLE_TICKETS_AVAILABLE = 'accessibleTicketsAvailable',
  ACCESSIBLE_TICKETS_DESCRIPTION = 'accessibleTicketsDescription',
  PROMOTER_TERMS = 'promoterTerms',
  SHOWS_HAPPENING_TERMS = 'showsHappeningTerms',
  FAQ = 'faQs',
}

export type EventCreateFormDetailsListType = Record<EVENT_CREATE_FORM_DETAILS_ENUM, string> | {};

export const prepareEventCreateFormDetails = (data): EventCreateFormDetailsType => {
  const result = {} as EventCreateFormDetailsType;

  Object.keys(data).forEach((key) => {
    Object.values(EVENT_CREATE_FORM_DETAILS_ENUM).forEach((value) => {
      if (key === value) {
        result[value] = data[key];
      }
    });
  });
  if (data[EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES]) {
    result[EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES] =
      data[EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES].value;
  }
  if (data[EVENT_CREATE_FORM_DETAILS_ENUM.AGES_REQUIRING_TICKET]) {
    result[EVENT_CREATE_FORM_DETAILS_ENUM.AGES_REQUIRING_TICKET] =
      data[EVENT_CREATE_FORM_DETAILS_ENUM.AGES_REQUIRING_TICKET].value;
  }

  return result;
};
