import { CSSProperties, FC, memo, useState } from 'react';

import { rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { handleCutString } from 'tools/cutstring';
import { Dropdown, Icon, Typography } from 'ui';

import { useChangeTicketTypeAvailabilitiesMutation } from 'services/events/eventsApiService';

import { EventTicketGroupResType } from 'types/events/eventTypes';

import s from '../TicketGroupsList.module.scss';

interface TicketGroupItemProps {
  ticketGroup: EventTicketGroupResType;
  index: number;
  setActiveEditTicketGroup: (ticket: EventTicketGroupResType) => void;
  handleDeleteItem: (id: string) => void;
}

const DROPDOWN_ITEMS = [
  { key: 'edit', label: <div className={s.dropdownItem}>Edit</div> },
  { key: 'delete', label: <div className={s.dropdownItem}>Delete</div> },
];

const TicketGroupItem: FC<TicketGroupItemProps> = ({
  ticketGroup,
  index,
  handleDeleteItem,
  setActiveEditTicketGroup,
}) => {
  const [openedDropdown, setOpenedDropdown] = useState(false);

  useChangeTicketTypeAvailabilitiesMutation();

  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `${ticketGroup.name}_${index}`,
    strategy: rectSortingStrategy,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const handleClickDropdownItems = (event: { key: string }, id: string) => {
    if (event.key === 'delete') {
      setOpenedDropdown(false);
      handleDeleteItem(id);
    }

    if (event.key === 'edit') {
      setOpenedDropdown(false);
      setActiveEditTicketGroup(ticketGroup);
    }
  };

  return (
    <>
      <div ref={setNodeRef} style={style} className={s.item}>
        {/* Your existing ticket item rendering code, except for the Icon component */}
        <div {...listeners} {...attributes} ref={setActivatorNodeRef} className={s.drag}>
          <Icon name="drag" size={36} />
        </div>
        <div className={s.GroupRowGroup}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <img
              src={ticketGroup.imageURL}
              width={56}
              height={56}
              style={{ borderRadius: 4 }}
              alt="ticket"
            />
            <div>
              <Typography type="h4">{ticketGroup?.name}</Typography>
              <Typography className={s.ticketGroupDescription} type="main">
                {handleCutString(ticketGroup?.description, 35, true)}
              </Typography>
            </div>
          </div>

          <Dropdown
            items={DROPDOWN_ITEMS}
            placement="bottomRight"
            handleItemClick={(event) => handleClickDropdownItems(event, ticketGroup.id as string)}
            open={openedDropdown}
            onOpenChange={setOpenedDropdown}>
            <div className={s.dropdownButton}>
              <Icon name="verticalDots" color="#7d7da2" />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default memo(TicketGroupItem);
