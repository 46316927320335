import { FC, useEffect, useState } from 'react';

import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import { Label, Typography } from 'ui';

import { TicketFullType } from 'types/tickets/tickets.types';

import s from './OrderRow.module.scss';

interface OrderRowProps {
  data: TicketFullType[];
}

const OrderRow: FC<OrderRowProps> = (props) => {
  const { data } = props;
  const [items, setItems] = useState<CollapseProps['items']>([]);

  useEffect(() => {
    if (data) {
      const tempData = data.map((item, index) => ({
        key: index,
        label: (
          <p className={s.label}>
            #{index + 1}:{item?.ticketType.name}
          </p>
        ),
        children: (
          <div className={s.children}>
            <div className={s.item}>
              <Label>Receipt No</Label>
              <Typography type="main">{item.receiptNumber}</Typography>
            </div>
            <div className={s.item}>
              <Label>Name on Ticket</Label>
              <Typography type="main">{item?.holder?.fullName}</Typography>
            </div>
            <div className={s.item}>
              <Label>Order Date</Label>
              <Typography type="main">
                {' '}
                {dayjs(item.attendanceDate).format('DD MMM, HH:mm')}
              </Typography>
            </div>
            <div className={s.item}>
              <Label>Value</Label>
              <Typography type="main">{item?.value}</Typography>
            </div>
            <div className={s.item}>
              <Label>Barcode Number</Label>
              <Typography type="main">{item?.barCode}</Typography>
            </div>
            <div className={s.item}>
              <Label>Ticket Holder Email</Label>
              <Typography type="main">{item?.holder?.email}</Typography>
            </div>
            <div className={s.item}>
              <Label>Show Date</Label>
              <Typography type="main">{dayjs(item?.show?.date).format('DD MMM YY')}</Typography>
            </div>
            <div className={s.item}>
              <Label>Show Time</Label>
              <Typography type="main">{dayjs(item?.show?.time).format('HH:mm')}</Typography>
            </div>
            {item.seat && (
              <div className={s.item}>
                <Label>Seat (if applicable)</Label>
                <Typography type="main">{item?.seat?.fullDisplayName}</Typography>
              </div>
            )}
          </div>
        ),
      }));
      setItems(tempData);
    }
  }, [data]);

  const keys = data.map((_, index) => index.toString());

  return (
    <div className={s.header}>
      <Collapse expandIconPosition="end" bordered={true} items={items} defaultActiveKey={keys} />
    </div>
  );
};

export default OrderRow;
