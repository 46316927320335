import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { NotificationsResType } from 'services/events/evetns.api.type';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType, EventTicketGroupResType } from 'types/events/eventTypes';

export type EventsSliceType = {
  activeStep: CREATE_EVENT_FORM_STEPS_ENUM;
  activeEvent?: EventCreatedType;
  editingEventId?: string;
  editingPromoterId?: string;
  isPublishingEvent?: boolean;
  ticketGroups?: EventTicketGroupResType[];
  isEditing?: boolean;
  activeEventName?: string;
  activeDateId?: string;
  notifications?: NotificationsResType[];
};

export const initialState: EventsSliceType = {
  activeStep: CREATE_EVENT_FORM_STEPS_ENUM.BASIC_INFO,
  isEditing: false,
};

export const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<CREATE_EVENT_FORM_STEPS_ENUM>) => {
      state.activeStep = action.payload;
    },
    setActiveEvent: (state, action: PayloadAction<EventCreatedType>) => {
      state.activeEvent = action.payload;
    },
    setActiveEventName: (state, action: PayloadAction<string>) => {
      state.activeEventName = action.payload;
    },
    setTicketGroups: (state, action: PayloadAction<EventTicketGroupResType[]>) => {
      state.ticketGroups = action.payload;
    },
    setEventNotifications: (state, action: PayloadAction<NotificationsResType[]>) => {
      state.notifications = action.payload;
    },
    setIsEditingEvent: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    setEventFlyer: (state, action: PayloadAction<string>) => {
      if (state?.activeEvent?.basicInfo.eventFlyer) {
        state.activeEvent.basicInfo.eventFlyer = action.payload;
      }
    },
    setEditingEventId: (state, action: PayloadAction<string>) => {
      state.editingEventId = action.payload;
    },
    setEditingPromoterId: (state, action: PayloadAction<string>) => {
      state.editingPromoterId = action.payload;
    },
    setActiveDateId: (state, action: PayloadAction<string>) => {
      state.activeDateId = action.payload;
    },
    removeActiveEvent: (state) => {
      state.activeEvent = undefined;
    },
    setIsPublishingEvent: (state, action: PayloadAction<boolean>) => {
      state.isPublishingEvent = action.payload;
    },
  },
});

export const {
  setActiveStep,
  setEventNotifications,
  setEditingPromoterId,
  setActiveEvent,
  setIsEditingEvent,
  setEventFlyer,
  setActiveEventName,
  setEditingEventId,
  removeActiveEvent,
  setIsPublishingEvent,
  setTicketGroups,
  setActiveDateId,
} = eventsSlice.actions;
