import { FC, useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_EVENT_FORM_ROUTE } from 'routes/events/list';
import { getEditEventFormRoute } from 'routes/events/list';
import { handleCutString } from 'tools/cutstring';
import { extractFlyerName } from 'tools/extractFlyerName';
import { useQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Button, Icon, Input, Popover } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import { UserEventType } from 'types/core/userTypes';

import s from './EventPopover.module.scss';

interface EventPopoverProps {
  handleEvent: (event: UserEventType) => void;
}

const EventPopover: FC<EventPopoverProps> = (props) => {
  const dispatch = useAppDispatch();
  const { handleEvent } = props;
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [openedPopover, setOpenedPopover] = useState(false);
  const [activeEvent, setActiveEvent] = useState<UserEventType>();
  const [isSearchDirty, setIsSearchDirty] = useState(false);
  const navigate = useNavigate();
  const [searchedEvents, setSearchedEvents] = useState<UserEventType[]>([]);
  const goToEventCreate = () => navigate(CREATE_EVENT_FORM_ROUTE);
  const query = useQuery();
  const location = useLocation();

  const handleSetActiveEvent = useCallback(
    (event: UserEventType) => {
      localStorage.setItem('currentEvent', JSON.stringify(event));
      dispatch(setEditingEventId(event?.eventId));
      setActiveEvent(event);
      handleEvent(event);
      setOpenedPopover(false);
      if (location.pathname.includes('edit-event')) {
        navigate(getEditEventFormRoute(event?.eventId));
      } else {
        navigate(`${location.pathname}?event=${event.eventId}`);
      }
    },
    [handleEvent, dispatch, location.pathname, navigate],
  );

  const searchEventsByLetter = useCallback(
    (letter) => {
      setIsSearchDirty(true);
      const lowercaseLetter = letter.toLowerCase(); // Convert the letter to lowercase for case-insensitive matching
      const matchingEvents: UserEventType[] = [];

      if (currentUser?.events) {
        currentUser.events.forEach((event) => {
          if (event.eventName.toLowerCase().startsWith(lowercaseLetter)) {
            matchingEvents.push(event);
          }
        });
      }

      return matchingEvents;
    },
    [currentUser?.events],
  );

  const handleSearch = (event) => {
    const letter = event.target.value;
    setSearchedEvents(searchEventsByLetter(letter));
  };

  useEffect(() => {
    if (!searchedEvents?.length && !isSearchDirty) {
      setSearchedEvents(currentUser?.events || []);
    }
  }, [currentUser?.events, isSearchDirty, searchedEvents?.length]);

  const content = (
    <div className={s.contentWrapper}>
      <div className={s.search}>
        <Input
          size="small"
          name="search"
          placeholder="Search event"
          icon="search"
          onChange={handleSearch}
        />
      </div>
      <div className={s.eventsList}>
        {searchedEvents?.map((event) => (
          <button
            key={event.eventId}
            className={s.eventContentButton}
            onClick={() => handleSetActiveEvent(event)}>
            {extractFlyerName(event.eventFlyer) ? (
              <img src={event.eventFlyer} alt="flyer" className={s.img} />
            ) : (
              <div className={s.emptyFlyer}>
                <Icon name="littleLogo" width={40} height={40} />
              </div>
            )}

            {handleCutString(event?.eventName, 100, true)}
          </button>
        ))}
      </div>
      <Button fullWidth className={s.createButton} onClick={goToEventCreate}>
        {t('common_create_event')}
      </Button>
    </div>
  );

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    if (location.pathname.includes('edit-event')) {
      const regex = /edit-event\/([\w-]+)(?=\/|\?|$)/;
      const match = location.pathname.match(regex);
      if (match && match.length > 1) {
        const guid = match[1];
        const tempUserEvent = currentUser?.events!.find((event) => event.eventId === guid);
        setActiveEvent(tempUserEvent);
      }
    } else {
      const storedEvent = localStorage.getItem('currentEvent');
      if (storedEvent) {
        setActiveEvent(JSON.parse(storedEvent!));
      } else if (currentUser?.events?.length) {
        setActiveEvent(currentUser?.events[0]);
        handleEvent(currentUser?.events[0]);
        localStorage.setItem('currentEvent', JSON.stringify(currentUser?.events[0]));
      }
    }
  }, [currentUser, location.pathname, handleEvent, setActiveEvent]);

  useEffect(() => {
    const eventId = query.get('event');
    if (eventId) {
      const event = searchedEvents?.find((event) => event.eventId === eventId);
      if (event) {
        setActiveEvent(event);
        handleEvent(event);
        localStorage.setItem('currentEvent', JSON.stringify(event));
      }
    }
  }, [currentUser, handleEvent, query, searchedEvents]);

  return (
    <Popover
      className={cn(s.contentWrapper)}
      isOpened={openedPopover}
      handleOpenChange={setOpenedPopover}
      circle
      content={content}>
      <div className={cn(s.event)}>
        {extractFlyerName(activeEvent?.eventFlyer || '') ? (
          <img src={activeEvent?.eventFlyer} alt="flyer" className={s.img} />
        ) : (
          <div className={s.emptyFlyer}>
            <Icon name="littleLogo" />
          </div>
        )}
        <div className={s.eventButton}>
          {activeEvent && handleCutString(activeEvent?.eventName, 100, true)}
          <Icon name="arrowDropdown" className={cn({ [s.arrowIcon]: openedPopover })} />
        </div>
      </div>
    </Popover>
  );
};

export default EventPopover;
