import { FC, useCallback, useEffect, useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { t } from 'tools/i18n';
import { Dropdown, Icon, Input, Label, Typography, message } from 'ui';

import { activeEventNameSelector } from 'selectors/eventsSlice.selector';
import {
  useDeleteUserEventPermissionsMutation,
  useLazyGetEventPermissionsQuery,
  useSetUserEventPermissionsMutation,
} from 'services/events/eventsApiService';
import { useLazySearchUserQuery } from 'services/user-management/userManagementApiService';
import { setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { ROLE_CODE_ENUM } from 'pages/events/events-create-event-form/event-create-form-permission/eventCreateFormPermission.utils';

import { UserProfileType } from 'types/core/userTypes';

import s from './EventCreateFormPermission.module.scss';

const DROPDOWN_ITEMS = Object.keys(ROLE_CODE_ENUM).map((key) => ({
  key: key,
  label: <Typography type="main">{ROLE_CODE_ENUM[key]}</Typography>,
}));
const EventCreateFormPermission: FC = () => {
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch();
  const activeEventName = useAppSelector(activeEventNameSelector);
  const [searchUser, { isFetching: isSearchFetching }] = useLazySearchUserQuery();
  const [getEventPermissions, { data: eventPermission }] = useLazyGetEventPermissionsQuery();
  const [setEventPermissions] = useSetUserEventPermissionsMutation();
  const [deleteEventPermission] = useDeleteUserEventPermissionsMutation();
  const [user, setUser] = useState<UserProfileType[]>();
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [selectedRole, setSelectedRole] = useState('PRM');
  const { eventId } = useParams();

  const navigate = useNavigate();
  const handleBack = useCallback(() => {
    navigate(MY_EVENTS_ROUTE);
  }, [navigate]);

  const handleSetEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSearchUser = useCallback(async () => {
    const res = await searchUser({ term: email });

    if (res.error) {
      message.error('No user found with the provided email address.');
    } else if ('data' in res) {
      setUser(res.data);
      setEmail('');
    }
  }, [email, searchUser]);

  const handleClearSearch = useCallback(async () => {
    setUser([]);
  }, []);

  const handleSetRole = useCallback(async () => {
    if (eventId && user?.length) {
      const res = await setEventPermissions({
        eventId: eventId,
        email: user?.[0]?.email,
        roleCode: selectedRole,
      });

      if ('data' in res) {
        setUser([]);
      }
    }
  }, [eventId, selectedRole, setEventPermissions, user]);

  const handleDeletePermission = useCallback(
    async (id: string) => {
      if (eventId) {
        await deleteEventPermission(id);
      }
    },
    [eventId, deleteEventPermission],
  );

  useEffect(() => {
    if (eventId || eventId) {
      getEventPermissions(eventId || '');
    }
    if (eventId) {
      dispatch(setEditingEventId(eventId));
    }
  }, [dispatch, eventId, getEventPermissions]);

  return (
    <div className={s.wrapper}>
      <Button className={s.changedButton} type="link" onClick={handleBack}>
        <ArrowLeftOutlined /> My Events
      </Button>

      <div className={s.permissionHeader}>
        <Typography type="h2" className={s.title}>
          {t('common_permissions')}
        </Typography>
        <Typography type="h3">{activeEventName}</Typography>
      </div>
      <div className={s.block}>
        <div className={s.hint}>
          <div>
            <Icon name="warning" />
          </div>
          <div className={s.hintText}>
            <Typography type="main">
              <strong>PROMOTER</strong> — Full Access to everything on the event.
            </Typography>
            <Typography type="main">
              <strong>REPORT VIEWER</strong> — Access ticket sales and statistics on the event.
            </Typography>
            <Typography type="main">
              <strong>BARCODE USER SCANNER</strong> — Can scan tickets on event, and can also view
              number of open and closed tickets for event.
            </Typography>
            <Typography type="main">
              <strong>BARCODE USER SCANNER ONLY</strong> — Can scan tickets on event, but cannot
              view number of open and closed tickets for event, on ShowsManager.
            </Typography>
            <Typography type="main">
              <strong>VIEW ALL TICKET TYPES</strong> — Views all ticket types even those that are
              hidden or disabled.
            </Typography>
          </div>
        </div>
        <div className={s.content}>
          <Typography type="main">
            Choose between a &quot;Promoter&quot; giving full acess to manage and change all
            settings for the event, or &quot;report viewer&quot; who can only view reports for this
            event/tour.
          </Typography>
          <div>
            <Label>{t('common_email')}</Label>

            <div className={s.formField}>
              <Input
                name="email"
                placeholder="Enter email address"
                value={email}
                onChange={handleSetEmail}
              />
              <Button
                type="primary"
                size="large"
                color="grey"
                onClick={handleSearchUser}
                loading={isSearchFetching}>
                Search
              </Button>
            </div>
          </div>

          {!!user?.length &&
            user.map((item) => (
              <div key={item?.userId} className={cn(s.permissionItem, s.search)}>
                <div className={s.userIcon}>
                  <Icon name="user" />
                </div>
                <div>
                  <Typography type="main" weight={600}>
                    {item?.fullName}
                  </Typography>
                  <Typography type="small" color="grey">
                    {item.email}
                  </Typography>
                </div>
                <div>
                  <Typography type="small" color="grey">
                    Role
                  </Typography>

                  <Dropdown
                    items={DROPDOWN_ITEMS}
                    handleItemClick={(e) => {
                      setOpenedDropdown(false);
                      setSelectedRole(e.key);
                    }}
                    open={openedDropdown}
                    onOpenChange={setOpenedDropdown}
                    placement="bottomCenter">
                    <button className={s.dropdownButton}>
                      <Typography type="main" weight={600}>
                        {ROLE_CODE_ENUM[selectedRole]}
                      </Typography>
                      <Icon name="arrowDropdown" />
                    </button>
                  </Dropdown>
                </div>
                <div className={s.actions}>
                  <button className={s.action} onClick={handleSetRole}>
                    <Icon name="checkWide" size={16} />
                  </button>
                  <button className={s.action} onClick={handleClearSearch}>
                    <Icon name="trashBox" size={16} />
                  </button>
                </div>
              </div>
            ))}

          {eventPermission?.length && (
            <ul className={s.permissions}>
              {eventPermission?.map((permission) => (
                <li key={permission?.permissionId} className={s.permissionItem}>
                  <div className={s.userIcon}>
                    <Icon name="user" />
                  </div>
                  <div>
                    <Typography type="main" weight={600}>
                      {permission?.user?.fullName}
                    </Typography>
                    <Typography type="small" color="grey">
                      {permission?.user.email}
                    </Typography>
                  </div>
                  <div>
                    <Typography type="small" color="grey">
                      Role
                    </Typography>
                    <Typography type="main" weight={600}>
                      {permission?.roleName}
                    </Typography>
                  </div>
                  <button
                    className={s.action}
                    onClick={() => handleDeletePermission(permission.permissionId)}>
                    <Icon name="trashBox" size={16} />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventCreateFormPermission;
