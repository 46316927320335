import { FC } from 'react';

import { DatePicker } from 'antd';
import { Button, Form, Icon, Input } from 'ui';

import s from './OrdersHeader.module.scss';

const { RangePicker } = DatePicker;

// typescript-eslint-disable @typescript-eslint/no-implicit-any
type RangeInfo = any;
// typescript-eslint-disable @typescript-eslint/no-implicit-any
type RangeValue = any;

interface OrdersHeaderProps {
  onRangeChange: (
    dates: RangeValue,
    formatString: [string, string],
    info: { range: RangeInfo },
  ) => void;
}

const OrdersHeader: FC<OrdersHeaderProps> = ({ onRangeChange }) => {
  const handleRangePickerChange = () => {};

  return (
    <div className={s.header}>
      <div className={s.searchBlock}>
        <Form.Item name="eventName" className={s.range}>
          <Input
            name="eventName"
            placeholder="Order or receipt number, seller or buyer"
            icon="search"
            fullWidth
          />
        </Form.Item>
      </div>

      <div className={s.actions}>
        <RangePicker
          onOpenChange={handleRangePickerChange}
          onCalendarChange={onRangeChange}
          className={s.rangePicker}
        />
        <Button color="white" className={s.refreshButton}>
          <Icon name="refreshArrows" />
        </Button>
      </div>
    </div>
  );
};

export default OrdersHeader;
