import { FC, SVGProps } from 'react';

import { ReactComponent as BankDetails } from './bank-details.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as Help } from './help.svg';
import { ReactComponent as LogoutWhite } from './logout-white.svg';
import { ReactComponent as Logout } from './logout.svg';
import { ReactComponent as MyEvents } from './my-events.svg';
import { ReactComponent as Notification } from './notification.svg';
import { ReactComponent as OrganiserDetails } from './organiser-details.svg';
import { ReactComponent as TicketSeller } from './ticket-seller.svg';
import { ReactComponent as TicketKiosk } from './tickets-kiosk.svg';
import { ReactComponent as TicketOrders } from './tickets-orders.svg';
import { ReactComponent as TicketReports } from './tickets-reports.svg';
import { ReactComponent as TicketScan } from './tickets-scan.svg';

export const NavbarIcons: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  dashboard: Dashboard,
  myEvents: MyEvents,
  ticketOrders: TicketOrders,
  ticketKiosk: TicketKiosk,
  ticketSeller: TicketSeller,
  ticketScan: TicketScan,
  ticketReports: TicketReports,
  notification: Notification,
  calendar: Calendar,
  organiser_details: OrganiserDetails,
  bank_details: BankDetails,
  help: Help,
  logout: Logout,
  logoutWhite: LogoutWhite,
};
