import { FC, useEffect, useState } from 'react';

import { Modal } from 'ui';

import { createEventFormEditingEventIdSelector } from 'selectors/eventsSlice.selector';
import { useLazyGetEventDetailQuery } from 'services/events/eventsApiService';
import { useLazyGetPromoterInfoQuery } from 'services/promoter/promoterApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { useAppSelector } from 'store/index';

import PublishEventModalPromoterStep from 'containers/publish-event-modal/publish-event-modal-steps/publish-event-modal-promoter-step';

import PublishEventModalFirstStep from './publish-event-modal-steps/publish-event-modal-first-step';
import PublishEventModalSecondStep from './publish-event-modal-steps/publish-event-modal-second-step';
import { PublishEventModalStepsType } from './publishEventModal.utils';

interface PublishEventModalProps {
  open: boolean;
  handleClose: () => void;
}
const PublishEventModal: FC<PublishEventModalProps> = (props) => {
  const { open, handleClose } = props;
  const [activeStep, setActiveStep] = useState<PublishEventModalStepsType>();
  const modalSize = activeStep === 'first' ? 720 : 540;
  const [getEventDetail, { data: activeEvent }] = useLazyGetEventDetailQuery();
  const activeEventId = useAppSelector(createEventFormEditingEventIdSelector);
  const [isChargeVat, setIsChargeVat] = useState(true);
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [getPromoterFullInfo, { data: promoter, isFetching: isFetchingPromoter }] =
    useLazyGetPromoterInfoQuery();

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    if (activeEvent?.basicInfo?.promoterId) {
      getPromoterFullInfo(activeEvent?.basicInfo?.promoterId);
    }
  }, [activeEvent, getPromoterFullInfo]);

  useEffect(() => {
    if (activeEventId) {
      getEventDetail(activeEventId);
    }
  }, [activeEventId, getEventDetail]);

  useEffect(() => {
    if (activeEvent?.basicInfo?.status === 'draft') {
      setActiveStep('second');
    } else {
      setActiveStep('second');
    }
  }, [activeEvent]);

  return (
    <Modal handleClose={handleClose} open={open} footer={null} width={modalSize}>
      {activeStep === 'promoter' && (
        <PublishEventModalPromoterStep
          setActiveStep={setActiveStep}
          promoter={promoter}
          activeEvent={activeEvent}
          isFetchingPromoter={isFetchingPromoter}
          currentUser={currentUser}
          setIsChargeVat={setIsChargeVat}
          isChargeVat={isChargeVat}
        />
      )}

      {activeStep === 'first' && (
        <PublishEventModalFirstStep
          handleClose={handleClose}
          setActiveStep={setActiveStep}
          activeEvent={activeEvent}
          promoter={promoter}
          isChargeVat={isChargeVat}
        />
      )}

      {activeStep === 'second' && (
        <PublishEventModalSecondStep handleClose={handleClose} setActiveStep={setActiveStep} />
      )}
    </Modal>
  );
};

export default PublishEventModal;
