import React, { FC, useEffect, useState } from 'react';

import { InputNumber, Radio, RadioChangeEvent } from 'antd';
import { Label, Typography } from 'ui';

import s from './EditCouponFixDiscountAllTicketType.module.scss';

interface EditCouponFixDiscountAllTicketTypeProps {
  discountType: string;
  totalAmount: number;
  setDiscountType: (discountType: string) => void;
  setTotalAmount: (setTotalAmount: number) => void;
}

const discountOption = [
  { label: 'Percentage (%) discount', value: 'percentage' },
  { label: 'Deduct € discount', value: 'deduction' },
];
const discountOptionMobile = [
  { label: '% Percentage', value: 'percentage' },
  { label: '€ Deduct', value: 'deduction' },
];
const EditCouponFixDiscountAllTicketType: FC<EditCouponFixDiscountAllTicketTypeProps> = (props) => {
  const { setDiscountType, setTotalAmount, totalAmount, discountType } = props;
  const [selectedOption, setSelectedOption] = useState(discountType);
  const [amount, setAmount] = useState<number>(totalAmount);

  useEffect(() => {
    setTotalAmount(totalAmount);
    setDiscountType(discountType);
  }, [setTotalAmount, setDiscountType, totalAmount, discountType]);
  // Write your component code here

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedOption(value);
    setDiscountType(value);
  };

  const handleAmountChange = (value: number | null) => {
    if (value !== null) {
      setTotalAmount(value);
      setAmount(value);
    }
  };

  return (
    <div className={s.wrapper}>
      <Typography type="h3" className={s.blockTitle}>
        Discount
      </Typography>

      <div className={s.options}>
        <div>
          <Label>Discount type</Label>
          <Radio.Group
            options={window.innerWidth < 600 ? discountOptionMobile : discountOption}
            optionType="button"
            value={selectedOption}
            onChange={onChange}
          />
        </div>
        <div>
          <Label>Discount amount</Label>
          <InputNumber
            name="discountAmount"
            placeholder="0"
            size="large"
            style={{ width: '50%' }}
            value={amount}
            onChange={handleAmountChange}
            prefix={selectedOption === 'deduction' ? '€' : '%'}
          />
        </div>
      </div>
    </div>
  );
};

export default EditCouponFixDiscountAllTicketType;
